import RestfulAPI from '../common/Restful';
import { TtpDeviceEditProps, TtpDeviceProps } from './interface';

export const getAPI = (
  companyId: string,
  TtpId?: string,
): RestfulAPI<TtpDeviceProps, TtpDeviceEditProps> => {
  if (!TtpId) {
    return new RestfulAPI<TtpDeviceProps, TtpDeviceEditProps>(
      `/companies/${companyId}/ttp-devices`,
    );
  }

  return new RestfulAPI<TtpDeviceProps, TtpDeviceEditProps>(
    `/companies/${companyId}/ttp-devices`,
    `/companies/${companyId}/ttp-devices/${TtpId}`,
  );
};
