export const THIS_YEAR = +new Date().getFullYear();

export const THIS_MONTH = +new Date().getMonth() + 1;

interface WEEK_DAYS_INTERFACE {
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  [index: string]: string;
}

export const WEEK_DAYS: WEEK_DAYS_INTERFACE = {
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
};

interface CALENDAR_MONTHS_INTERFACE {
  January: string;
  February: string;
  March: string;
  April: string;
  May: string;
  June: string;
  July: string;
  August: string;
  September: string;
  October: string;
  November: string;
  December: string;
  [index: number]: string;
}

export const CALENDAR_MONTHS: CALENDAR_MONTHS_INTERFACE = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

export const CALENDER_WEEKS = 6;

export const zeroPad = (value: number, length: number): string => {
  return `${value}`.padStart(length, '0');
};

export const getMonthDays = (month: number = THIS_MONTH, year: number = THIS_YEAR): number => {
  const months30 = [4, 6, 9, 11];
  const leapYear = year % 4 === 0;
  return month === 2 ? (leapYear ? 29 : 28) : months30.includes(month) ? 30 : 31;
};

export const getMonthFirstDay = (month: number = THIS_MONTH, year: number = THIS_YEAR): number => {
  return +new Date(`${year}-${zeroPad(month, 2)}-01`).getDay() + 1;
};

export const isDate = (date: any) => {
  const isDate = Object.prototype.toString.call(date) === '[object Date]';
  const isValidDate = date && !Number.isNaN(date.valueOf());
  return isValidDate && isDate;
};

export const isSameMonth = (date: Date, baseDate: Date = new Date()): boolean => {
  if (!(isDate(date) && isDate(baseDate))) {
    return false;
  }

  const baseDateMonth = +baseDate.getMonth() + 1;
  const baseDateYear = baseDate.getFullYear();

  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();

  return +baseDateMonth === +dateMonth && +baseDateYear === +dateYear;
};

export const isSameDay = (date: Date, baseDate: Date = new Date()): boolean => {
  if (!(isDate(date) && isDate(baseDate))) {
    return false;
  }

  const baseDateDate = baseDate.getDate();
  const baseDateMonth = +baseDate.getMonth() + 1;
  const baseDateYear = baseDate.getFullYear();

  const dateDate = date.getDate();
  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();

  return (
    +baseDateDate === +dateDate && +baseDateMonth === +dateMonth && +baseDateYear === +dateYear
  );
};

export const isCurrentYear = (year: number, baseDate: Date = new Date()): boolean => {
  if (!isDate(baseDate)) {
    return false;
  }
  return year === baseDate.getFullYear();
};

export const getDateISO = (date: Date = new Date()): string => {
  if (!isDate(date)) {
    return '';
  }

  return [date.getFullYear(), zeroPad(+date.getMonth() + 1, 2), zeroPad(+date.getDate(), 2)].join(
    '-',
  );
};

export interface getMonthObject {
  month: number;
  year: number;
}

export const getPreviousMonth = (month: number, year: number): getMonthObject => {
  const prevMonth = month > 1 ? month - 1 : 12;
  const prevMonthYear = month > 1 ? year : year - 1;

  return {
    month: prevMonth,
    year: prevMonthYear,
  };
};

export const getNextMonth = (month: number, year: number): getMonthObject => {
  const nextMonth = month < 12 ? month + 1 : 1;
  const nextMonthYear = month < 12 ? year : year + 1;

  return {
    month: nextMonth,
    year: nextMonthYear,
  };
};

const calender = (month: number = THIS_MONTH, year: number = THIS_YEAR) => {
  const monthDays = getMonthDays(month, year);
  const monthFirstDay = getMonthFirstDay(month, year);

  const daysFromPrevMonth = monthFirstDay - 1;
  const daysFromNextMonth = CALENDER_WEEKS * 7 - (daysFromPrevMonth + monthDays);

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year);
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year);

  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);

  const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
    return [prevMonthYear, zeroPad(prevMonth, 2), zeroPad(day, 2)];
  });

  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const day = index + 1;
    return [year, zeroPad(month, 2), zeroPad(day, 2)];
  });

  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
    const day = index + 1;
    return [nextMonthYear, zeroPad(nextMonth, 2), zeroPad(day, 2)];
  });

  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};
export default calender;
