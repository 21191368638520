import { FormContainer, FormHeader } from 'components/custom/form';
import { AuthenticationLayout } from 'components/layouts/authentication.layout';
import { useTranslate } from 'context/TranslateContext';
import Icon from '../../../assets/img/registrationForm/verificationIcon.png';

export const LoginServiceProviderConcent = ({ accept, denied, serviceProvider }: any) => {
  const { translate } = useTranslate();

  return (
    <AuthenticationLayout>
      <FormContainer>
        <div>
          <FormHeader title={translate('declaration')} src={Icon} />
          <strong>Do you consent {serviceProvider.english_name} to:</strong>
          <ul>
            <li>view your store data</li>
            <li>view and manage your store transactions</li>
            <li>create and view your API key and sign key</li>
          </ul>
          <br />

          <strong>你是否同意 {serviceProvider.name}：</strong>
          <ul>
            <li>查看商鋪資料</li>
            <li>查看及管理商鋪的交易</li>
            <li>新增及查看公司的簽名及 API 密鑰</li>
          </ul>
        </div>

        <div className="row mt-4">
          <div className="col-6 col-lg-4 d-grid">
            <button className="btn btn-outline-secondary" onClick={denied} type="button">
              {translate('back')}
            </button>
          </div>
          <div className="col-6 col-lg-8 d-grid">
            <button className="btn btn-primary" onClick={accept}>
              {translate('accept')}
            </button>
          </div>
        </div>
      </FormContainer>
    </AuthenticationLayout>
  );
};
