import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';

export const getUserActivatedGateways = (): Array<string> => {
  const {
    auth: { credentials },
  } = useSelector((state: GlobalTypes.RootState) => state);

  return credentials
    .filter((cred) => cred.status == 'activated')
    .map((cred) => {
      return cred.gateway_code;
    });
};
