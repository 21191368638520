import './index.scss';

interface IndicatorProps {
  id: number;
  current: number;
}

export interface StepIndicatorProps {
  current: number;
  totalStep: number;
}

export const StepIndicator = ({ totalStep, ...props }: StepIndicatorProps) => {
  return (
    <div className="row my-2">
      {[...new Array(totalStep)].map((_, index) => (
        <Indicator key={index} id={index} {...props} />
      ))}
    </div>
  );
};

const Indicator = ({ id, current }: IndicatorProps) => {
  const isActive = id <= current;

  return <div className={`${isActive ? 'active' : ''} rounded-pill indicator col`} />;
};
