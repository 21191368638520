import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { IntegrationTransactionProps } from 'services/API/FpsTransaction/interface';
import './bankRefTable.scss';
import { useTranslate } from 'context/TranslateContext';

interface BankRefTableProps {
  transaction?: IntegrationTransactionProps;
}

export const BankRefTable = ({ transaction }: BankRefTableProps) => {
  if (!transaction) {
    return <></>;
  }

  const [isCollapsed, setIsCollapsed] = useState(false);
  const { translate } = useTranslate();

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      {transaction?.data?.length >= 1 && (
        <>
          <h5 className="d-flex py-2 px-1 justify-content-between border-bottom align-items-center">
            <div className="ps-2 bank-ref-table-title">{translate('bank_transaction_record')}</div>
            <div className="px-2" onClick={toggleCollapsed}>
              <FontAwesomeIcon
                icon={isCollapsed ? faAngleUp : faAngleDown}
                className="me-2 mt-1 cursor-pointer"
              />
            </div>
          </h5>
          <div className={`collapse ${isCollapsed ? 'show' : ''}`}>
            <table className="table bank-ref-table">
              <thead>
                <tr>
                  <th className="border-top-0 border-end">
                    {translate('bank_transaction_reference_id')}
                  </th>
                  <th className="border-top-0 border-end">{translate('amount')}</th>
                  <th className="border-top-0">{translate('paid_at')}</th>
                </tr>
              </thead>
              <tbody>
                {transaction.data.map((item: any, index: any) => {
                  //DBS
                  if (item?.txnRefId) {
                    return (
                      <tr key={index}>
                        <td>{item?.txnRefId}</td>
                        <td>{item?.txnAmount ?? transaction.amount} HKD</td>
                        <td>{item?.txnDate ?? transaction.paid_at}</td>
                      </tr>
                    );
                  }
                  //HSBC
                  if (item?.bankTxnId) {
                    return (
                      <tr key={index}>
                        <td>{item?.bankTxnId}</td>
                        <td>{item?.subAmtPaid ?? transaction.amount} HKD</td>
                        <td>{item?.bankTxnTime ?? transaction.paid_at}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </>
        // </div>
        // </div>
      )}
    </>
  );
};
