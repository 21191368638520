import SparkMD5 from 'spark-md5';

export const MD5Hash = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const blobSlice = File.prototype.slice;
    const chunkSize = 2097152;
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    let md5 = null;

    fileReader.onload = function (e) {
      spark.append(e.target?.result as ArrayBuffer);
      currentChunk++;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        md5 = spark.end();
        resolve(md5);
      }
    };

    fileReader.onerror = function () {
      reject('Error');
    };

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= chunkSize ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    loadNext();
  });
