import { GET_ANALYSIS, RECEIVE_DATA, RECEIVE_LOGOS } from './actionTypes';

export const receiveData = (result: any, type: string) => ({
  type: RECEIVE_DATA,
  dataType: type,
  payload: result,
});

export const receiveLogos = (logos: Array<any>) => ({
  type: RECEIVE_LOGOS,
  logos,
});

export const receiveAnalysis = (result: any) => ({
  type: GET_ANALYSIS,
  payload: result,
});
