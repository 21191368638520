import Logo from 'assets/img/registrationForm/uploadDocumentIcon.png';
import { FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { ANY_OBJECT, MERCHANT_TYPE_INDIVIDUAL } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { compression } from 'helpers/functions/compressFile';
import { MD5Hash } from 'helpers/functions/md5Hash';
import JSZip from 'jszip';
import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { DocumentData } from 'services/API/Auth/interface';
import { useSwal } from 'helpers/sweetalert';
import { StepProps } from '../interface';

const md5State: { [key: string]: string } = {
  bank_statement: '',
  store_image_1: '',
  store_image_2: '',
  taxi_number: '',
};

export const Documents = forwardRef(
  (
    {
      setZip,
      form,
      setForm,
      documents,
      setDocuments,
      isTaxi,
      isOnlineStore,
      setIsLoading,
    }: StepProps,
    ref,
  ) => {
    const [Swal] = useSwal();
    const { translate } = useTranslate();
    const {
      auth: {
        user: {
          company: { merchant_type },
        },
      },
    } = useSelector((state: GlobalTypes.RootState) => state);
    const isIndividual =
      (merchant_type as string).toUpperCase() === MERCHANT_TYPE_INDIVIDUAL.toUpperCase();

    const [imageMD5, setImageMD5] = useState(md5State);

    const fileFields: ANY_OBJECT = {
      bank_statement: {
        placeholder: translate('bank_statement').toUpperCase(),
        legend: translate('bank_statement').toUpperCase(),
        fileNamePrefix: 'Bank_Statement_',
        allowImage: true,
        allowPdf: true,
        type: 'other',
      },
    };

    if (isTaxi) {
      fileFields['taxi_image_1'] = {
        placeholder: translate('taxi_image').toUpperCase() + ' 1',
        legend: translate('upload_taxi_image').toUpperCase(),
        fileNamePrefix: 'Taxi_Image_',
        allowImage: true,
        allowPdf: false,
        type: 'picture',
      };
      fileFields['taxi_image_2'] = {
        placeholder: translate('taxi_image').toUpperCase() + ' 2',
        legend: translate('upload_taxi_image').toUpperCase(),
        fileNamePrefix: 'Taxi_Image_',
        allowImage: true,
        allowPdf: false,
        type: 'picture',
      };
      fileFields['taxi_id'] = {
        placeholder: translate('taxi_id').toUpperCase(),
        legend: translate('upload_taxi_id').toUpperCase(),
        fileNamePrefix: 'Taxi_ID_Plate_',
        allowImage: true,
        allowPdf: false,
        type: 'picture',
      };
    } else if (isIndividual) {
      fileFields['hawker_license'] = {
        placeholder: translate('hawker_license').toUpperCase(),
        legend: translate('upload_hawker_license').toUpperCase(),
        fileNamePrefix: 'Hawker_License_',
        allowImage: true,
        allowPdf: false,
        type: 'picture',
      };
    } else if (!isOnlineStore) {
      fileFields['store_image_1'] = {
        placeholder: translate('store_image').toUpperCase() + ' 1',
        legend: translate('upload_store_image').toUpperCase(),
        fileNamePrefix: 'Store_Image_',
        allowImage: true,
        allowPdf: false,
        type: 'picture',
      };
      fileFields['store_image_2'] = {
        placeholder: translate('store_image').toUpperCase() + ' 2',
        legend: translate('upload_store_image').toUpperCase(),
        fileNamePrefix: 'Store_Image_',
        allowImage: true,
        allowPdf: false,
        type: 'picture',
      };
    }

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      const name = e.target.name;
      if (!file) {
        return;
      }
      validateFile(name, file);
    };

    const validateFile = async (name: string, file: File) => {
      const field = fileFields[name];

      let isValid = false;
      isValid = isValid || (field.allowImage && file.type.startsWith(`image`));
      isValid = isValid || (field.allowPdf && file.type.startsWith(`application/pdf`));

      if (field.allowPdf && !isValid) {
        await Swal.fire({
          title: translate('file_format_incorrect'),
          text: translate('only_support_image_or_pdf'),
          icon: 'error',
        });
        return;
      } else if (field.allowImage && !isValid) {
        await Swal.fire({
          title: translate('file_format_incorrect'),
          text: translate('only_support_image'),
          icon: 'error',
        });
        return;
      }

      setIsLoading(true);
      const compressed_file = await compression(file);
      setIsLoading(false);

      const md5 = await MD5Hash(compressed_file);
      const allMD5 = { ...imageMD5 };
      delete allMD5[name];

      const values = Object.values(allMD5);
      const isExist = values.includes(md5);

      if (isExist) {
        await Swal.fire({
          title: translate('duplicate_file_uploaded'),
          icon: 'error',
        });
        return;
      }

      setImageMD5({
        ...imageMD5,
        [name]: md5,
      });

      setDocuments({
        ...documents,
        [name]: compressed_file,
      });
    };

    useImperativeHandle(ref, () => ({
      validate: () => {
        const documentData: Array<DocumentData> = [];
        const zip = new JSZip();
        if (form.beneficial_owners[0].file) {
          zip.file(form.beneficial_owners[0].id_proof.name, form.beneficial_owners[0].file);
        }

        for (const [key, value] of Object.entries(fileFields)) {
          if (typeof documents[key] === 'undefined') {
            Swal.fire({
              icon: 'error',
              title: translate('upload'),
              text: translate('upload_all_files'),
            });
            return false;
          }

          const fileExtension = '.' + documents[key].name.split('.')[1];

          zip.file(`${value.fileNamePrefix}${key}${fileExtension}`, documents[key] as File);

          documentData.push({
            name: `${value.fileNamePrefix}${key}${fileExtension}`,
            type: value.type,
            shared: false,
            description: '',
          });
        }

        zip.generateAsync({ type: 'blob' }).then(setZip);

        setForm({
          ...form,
          documents: documentData,
        });
        return true;
      },
    }));

    return (
      <>
        <FormHeader src={Logo} title={translate('upload_other_documents')} />

        {Object.keys(fileFields).map((key) => (
          <Input
            key={key}
            type="file"
            legend={fileFields[key].legend}
            onChange={onFileChange}
            value={documents[key]}
            name={key}
            placeholder={fileFields[key].placeholder}
            isRequired
            accept={fileFields[key].allowPdf ? 'image/*,application/pdf' : 'image/*'}
          />
        ))}
      </>
    );
  },
);
