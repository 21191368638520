import { ANY_OBJECT, FIELD_REQUIRED } from 'constants/format';
import isEmpty from 'lodash/isEmpty';
import { UpdatePasswordBody } from 'services/API/Auth/interface';

export const validate = (data: UpdatePasswordBody) => {
  const error: ANY_OBJECT = {};

  const { new_password, new_password_confirmation } = data;

  for (const [key, value] of Object.entries(data)) {
    if (!value) {
      error[key] = FIELD_REQUIRED;
    }
  }
  if (new_password !== new_password_confirmation) {
    error.new_password = error.new_password_confirmation = 'Password not match';
  }

  return {
    isValid: isEmpty(error),
    error,
  };
};
