export const params = (obj: any) => {
  let str = '';
  let separator = '';
  for (const key in obj) {
    str += separator;
    str += encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    separator = '&';
  }
  return str.replace(/ /g, '%20');
};
