import { params } from 'helpers/functions/param';
import axios, { defaultRequestHandler, DefaultResponse, getToken } from '../common/Fetcher';
import { ContentType } from './../common/Headers';
import {
  CreateTransactionBody,
  GetTransactionListResponse,
  RefundTransactionBody,
  RefundTransactionResponse,
} from './interface';

// https://dev2.yedpay.com/document/admin/showTransaction
export const getOneTransaction = (id_transaction: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.get(`/transactions/${id_transaction}?include=store,online_payment`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/userTransactionList
export const getUserTransactionList = (
  user_id: string,
  data = {},
): Promise<GetTransactionListResponse> =>
  defaultRequestHandler(
    axios.get(
      `/users/${user_id}/transactions?include=store,fixed_code,company&gateway_id!=3&${params(
        data,
      )}`,
      {
        headers: {
          authorization: getToken(),
        },
      },
    ),
  );

// https://dev2.yedpay.com/document/admin/companyTransactionList
export const getCompanyTransactionList = (company_id: string, data = {}) => {
  const params = {
    include: 'store,fixed_code,company,online_payment',
    'gateway_code!': '3',
    ...data,
  };
  return defaultRequestHandler(
    axios.get(`/companies/${company_id}/transactions`, {
      headers: {
        authorization: getToken(),
      },
      params,
    }),
  );
};

export const createTransaction = (
  store_id: string,
  data: CreateTransactionBody,
): Promise<RefundTransactionResponse> =>
  defaultRequestHandler(
    axios.post(`/transactions/${store_id}`, params(data), {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/refundTransaction
export const refundTransaction = (
  transaction_id: string,
  body: RefundTransactionBody,
): Promise<RefundTransactionResponse> =>
  defaultRequestHandler(
    axios.post(`/transactions/${transaction_id}/refund`, params(body), {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );
