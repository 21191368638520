import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

type IdempotencyKeyReturn<T> = [(data: T) => T & { _idempotency: string }, () => void, string];

export const useIdempotencyKey = <T>(): IdempotencyKeyReturn<T> => {
  const [key, setKey] = useState<string>(uuidV4());

  const resetIdempotencyKey = () => setKey(uuidV4());

  const addIdempotencyKey = (data: T) => ({
    ...data,
    _idempotency: key,
  });

  return [addIdempotencyKey, resetIdempotencyKey, key];
};
