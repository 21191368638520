import logo from 'assets/img/registrationForm/yourBusinessIcon.png';
import { FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { DocPreviewModal } from 'components/custom/modals/DocPreviewModal';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { IrRegistration } from 'services/API/Auth/interface';
import { uploadFileToCompany } from 'services/API/Company';
import { OCRResult, UploadFileToCompanyResponse } from 'services/API/Company/interface';
import { useSwal } from 'helpers/sweetalert';
import { StepProps } from '../interface';
import { validateIr } from '../validator';

const initialState: IrRegistration = {
  file_id: '',
  ir_number: '',
  company_name: '',
};

export const IRUpload = forwardRef(({ form, setForm, setIsLoading }: StepProps, ref) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [ir, setIr] = useState<IrRegistration>(initialState);
  const [errors, setErrors] = useState<ANY_OBJECT>({});
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
  const [lockInput, setLockInput] = useState(true);
  const [uploadFile, setUploadFile] = useState<File>();
  const [uploadStatus, setUploadStatus] = useState<'' | 'uploading' | 'on_verification' | 'error'>(
    '',
  );

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.name === 'address') {
      setForm({
        ...form,
        address: e.target.value,
      });
      return;
    } else if (e.target.name === 'type_of_business') {
      e.target.value = `${parseInt(e.target.value) || 0}`;
    }

    setIr({
      ...ir,
      [e.target.name]: e.target.value,
    });
  };

  const onIrSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    setUploadFile(file);
    setIsUploadFileModalOpen(true);
  };

  const onIrChange = async (file: File) => {
    setIsLoading(true);
    const data = await getOCRResult(file);
    setIsLoading(false);
    if (!data) {
      setIr({
        ...ir,
        uploaded: false,
      });
      return;
    }

    const {
      id,
      ocr_result: { address, company_name, ir_number },
    } = data;

    setIr({
      ...ir,
      file_id: id ?? '',
      ir_number: ir_number ?? '',
      company_name: company_name ?? '',
      uploaded: true,
    });

    setForm({
      ...form,
      address: address ?? '',
    });
  };

  const getOCRResult = async (file: File) => {
    if (!user.company_id) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'ocr_ir');
    formData.append('shared', 'false');

    setUploadStatus('uploading');

    let res: UploadFileToCompanyResponse | null = null;
    try {
      res = await uploadFileToCompany(user.company_id, formData);
      setUploadStatus('on_verification');

      if (!res.success) {
        throw new Error(res?.message || 'Oops');
      } else if (!(res.data as OCRResult).ocr_result.is_valid) {
        throw new Error(translate('ocr_fail_msg'));
      }

      return res?.data as OCRResult;
    } catch (err: any) {
      setUploadStatus('error');
      await Swal.fire({
        title: translate('ocr_failed'),
        text: err.message,
        icon: 'error',
      });
      return;
    } finally {
      setLockInput(false); // Allow Input even when ocr is failed.
    }
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const ir_registration = {
        ...ir,
      };

      // ir_registration.company_name = user?.company?.name;

      let finalAddress = form.address && form.address.trim().replace(/['"]/g, '');
      const checkAddressLastWords = finalAddress.split(',').pop();
      if (checkAddressLastWords?.trim()?.toUpperCase() !== 'HONG KONG') {
        finalAddress += ', Hong Kong';
      }

      const { isValid, errors } = validateIr(ir_registration, form.address);

      setErrors(errors);
      if (!isValid) {
        return false;
      }

      setForm({
        ...form,
        ir_registration,
        address: finalAddress,
      });
      return true;
    },
  }));

  useEffect(() => {
    const ir = form.ir_registration;
    setIr(ir);

    if (ir && ir.uploaded) {
      setUploadStatus('on_verification');
      setLockInput(false);
    }
    // eslint-disable-next-line
  }, []);

  const { address } = form;
  const { company_name, ir_number } = ir;

  return (
    <>
      <FormHeader
        title={translate('ir_registration')}
        caption={`${translate('company_name')}: ${user?.company?.name}`}
        src={logo}
      />
      <div className="mt-2">
        <Input
          type="file-progress"
          name=""
          status={uploadStatus}
          onChange={onIrSelected}
          legend={translate('upload_ir').toUpperCase()}
          isRequired
          error={translate(...(errors.file_id ?? ''))}
        />
      </div>
      <Input
        name="ir_number"
        type="text"
        onChange={onChange}
        legend={translate('ir_number').toUpperCase()}
        value={ir_number}
        disabled={lockInput}
        isRequired
        error={translate(...(errors.ir_number ?? ''))}
      />
      <Input
        name="company_name"
        type="text"
        onChange={onChange}
        legend={translate('company_name').toUpperCase()}
        value={company_name as string}
        disabled={lockInput}
        isRequired
        error={translate(...(errors.company_name ?? ''))}
      />
      <Input
        name="address"
        type="text"
        onChange={onChange}
        legend={translate('address').toUpperCase()}
        value={address}
        disabled={lockInput}
        isRequired
        error={translate(...(errors.address ?? ''))}
      />
      <DocPreviewModal
        isOpen={isUploadFileModalOpen}
        setIsOpen={setIsUploadFileModalOpen}
        file={uploadFile}
        setFile={setUploadFile}
        onConfirm={onIrChange}
      />
    </>
  );
});
