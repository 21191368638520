import axios, { defaultRequestHandler, getToken } from '../common/Fetcher';
import { ExportReportResponse } from './interface';

export const getAllReport = (params: object = {}): Promise<ExportReportResponse> =>
  defaultRequestHandler(
    axios.get(`/exported-reports`, {
      headers: {
        authorization: getToken(),
      },
      params,
    }),
  );
