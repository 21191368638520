import { params } from 'helpers/functions/param';
import axios, { defaultRequestHandler, getToken } from '../common/Fetcher';
import { DefaultResponse } from './../common/Fetcher';
import { GetSettlementListResponse, RequestSettlementBody } from './interface';

// https://dev2.yedpay.com/document/admin/settlementList
export const getAllSettlement = (data: object = {}): Promise<GetSettlementListResponse> =>
  defaultRequestHandler(
    axios.get(`/settlements?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/reverseSettlement
export const reverseSettlement = (settlement_id: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(
      `/settlements/${settlement_id}/reverse`,
      {},
      {
        headers: {
          authorization: getToken(),
        },
      },
    ),
  );
export const requestSettlement = (data: RequestSettlementBody): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`settlements`, data, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
