import { combineReducers } from 'redux';
import auth from './authReducer';
import data from './dataReducer';

const rootReducer = combineReducers({
  auth,
  data,
});

export default rootReducer;
