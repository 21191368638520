import { logout as logoutUser } from 'actions/AuthActions';
import logout from 'assets/img/side_navbar_components/logout.png';
import {
  companyInfoSidebarRoutes,
  payLinkSidebarRoutes,
  payPlusSidebarRoutes,
  userRoutes,
} from 'constants/Routes';
import { TAP_TO_PHONE_GATEWAY } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { Fragment, FC, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { GlobalTypes } from 'redux/types';
import { getAccessibleRoutes, RouteProps } from '../routeSettings';
import './side_navbar.scss';

export interface SideNavbarProps {
  location: {
    pathname: string;
  };
  type: string;
  logoutUser: () => void;
}

interface NavLinkProps {
  name: string;
  path: string;
  img: string;
  activeImg: string;
}

const SideNavbar: FC<SideNavbarProps> = ({
  location: { pathname },
  type,
  logoutUser,
}: SideNavbarProps) => {
  const { translate } = useTranslate();

  const [routes, setRoutes] = useState<Array<RouteProps>>([]);

  const {
    auth: { user, credentials },
  } = useSelector((state: GlobalTypes.RootState) => state);

  useEffect(() => {
    setRoutes(getAccessibleRoutes(user.role ?? ''));
  }, [user]);

  const navLinks: { [key: string]: Array<NavLinkProps> } = {
    payplus: payPlusSidebarRoutes,
    paylink: payLinkSidebarRoutes,
    user: userRoutes,
  };
  if (credentials.filter((cred) => TAP_TO_PHONE_GATEWAY.includes(cred.gateway_code)).length === 0) {
    navLinks.admin = companyInfoSidebarRoutes.filter((route) => route.name !== 'ttp_device');
  } else {
    navLinks.admin = companyInfoSidebarRoutes;
  }

  return (
    <div className="yedpay-side-bar hide-scrollbar">
      {navLinks[type].map((navLink, index: number) => {
        const isActive = navLink.path === pathname;
        if (!routes.find((routeObj) => routeObj.path === navLink.path)) {
          return <Fragment key={index}></Fragment>;
        }
        return (
          <NavLink
            className={`d-block mt-2 btn ${isActive ? ' bg-primary text-white' : ''} btn-hover`}
            to={navLink.path}
            key={index}>
            <div className="d-flex align-items-center">
              <img src={isActive ? navLink.activeImg : navLink.img} alt="thumbnails" />
              <span style={{ textAlign: 'left' }}>{translate(navLink.name)}</span>
            </div>
          </NavLink>
        );
      })}

      {type === 'user' && (
        <a href="/logout" className="btn btn-hover d-block mt-2" onClick={() => logoutUser()}>
          <div className="d-flex align-items-center">
            <img src={logout} alt="thumbnails" />
            <span>{translate('logout')}</span>
          </div>
        </a>
      )}
    </div>
  );
};

export default connect(null, { logoutUser })(SideNavbar);
