import img from 'assets/img/table/data_not_found.png';
import { useTranslate } from 'context/TranslateContext';

interface EmptyResultProps {
  text: string;
}

export const EmptyResult = ({ text }: EmptyResultProps) => {
  const { translate } = useTranslate();

  return (
    <div className="d-flex flex-column text-center pb-2">
      <div>
        <img src={img} alt="" className="img-fluid my-4" />
      </div>
      <span className="grey-text">{translate(text)}</span>
    </div>
  );
};
