import { DetailRow } from 'components/custom/DetailRow';
import { CANCELLED, CAPTURED, PAID, REFUNDED } from 'constants/format';
import { owner } from 'constants/Roles';
import { YEDPAY_GATEWAY_CODE } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { findGatewayNameByCode } from 'helpers/functions/findGatewayByCode';
import html2canvas from 'html2canvas';
import { round } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { userProps } from 'redux/state/AuthState';
import { GlobalTypes } from 'redux/types';
import { TransactionProps } from 'services/API/Transactions/interface';
import { showUser } from 'services/API/User';
import { useSwal } from 'helpers/sweetalert';
import { RefundModal } from './RefundModal';

interface TransactionModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  transaction?: TransactionProps;
  reloadTransaction: () => void;
  isAgentOrGatewayProvider: boolean;
}

export const TransactionModal = ({
  isOpen,
  setIsOpen,
  transaction,
  reloadTransaction,
  isAgentOrGatewayProvider,
}: TransactionModalProps) => {
  if (!transaction) {
    return <></>;
  }
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [transactionUser, setTransactionUser] = useState<userProps>({} as userProps);

  const [isDownloading, setIsDownloading] = useState(false);

  const [showRefundModal, setShowRefundModal] = useState(false);

  const printRef = useRef<HTMLDivElement>(null);

  const settlement_status = transaction.is_balance_settled ? 'balance_settled' : 'balance_holding';

  const onPrint = async () => {
    setIsDownloading(true);
    const element = printRef?.current;
    if (!element) {
      return;
    }
    const canvas = await html2canvas(element, {
      useCORS: true,
      backgroundColor: '#ffffff',
    });
    const link = document.createElement('a');
    link.setAttribute('download', `transaction-${transaction.transaction_id}.png`);
    link.setAttribute(
      'href',
      canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'),
    );
    link.click();
    setIsDownloading(false);
  };

  const getUser = async (user_id: string) => {
    if (!user_id) {
      return;
    }
    const res = await showUser(user_id);
    if (!res.success) {
      Swal.fire({
        title: translate('user'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    setTransactionUser(res.data);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const closeAll = () => {
    setIsOpen(false);
    setShowRefundModal(false);
    reloadTransaction();
  };

  const openRefundModal = () => {
    setShowRefundModal(true);
  };

  useEffect(() => {
    if (!isAgentOrGatewayProvider) {
      getUser(transaction.user_id ?? '');
    }
    // eslint-disable-next-line
  }, [transaction]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>{translate('transaction_details')}</ModalHeader>
      <ModalBody>
        <div ref={printRef} className="p-3">
          <div className="transaction-content">
            <h4>
              <DetailRow
                name={translate('company_name')}
                value={user?.company?.name ?? ''}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('transaction_id')}
                value={transaction.transaction_id ?? ''}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('reference_id')}
                value={transaction.reference_id || '-'}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('custom_id')}
                value={transaction.custom_id || '-'}
                nameWidth="col-5"
              />
              {isAgentOrGatewayProvider && (
                <>
                  <DetailRow
                    name={translate('store_name')}
                    value={transaction.store?.name ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('store_address')}
                    value={transaction.store?.address ?? ''}
                    nameWidth="col-5"
                  />
                </>
              )}
              {!isAgentOrGatewayProvider && (
                <>
                  <DetailRow
                    name={translate('created_at')}
                    value={transaction.created_at ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('paid_at')}
                    value={transaction.paid_at ?? ''}
                    nameWidth="col-5"
                  />
                  {transaction.status === REFUNDED.toLowerCase() && transaction.refunded > 0 && (
                    <DetailRow
                      name={translate('refunded_at')}
                      value={transaction.refunded_at ?? ''}
                      nameWidth="col-5"
                    />
                  )}
                </>
              )}
            </h4>
          </div>
          <hr className="dash-border" />
          <div className="transaction-content">
            <h4>
              <div className="row">
                <div className="col-12">
                  <DetailRow
                    name={translate('store_name')}
                    value={transaction.store?.name ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('store_address')}
                    value={transaction.store?.address ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('phone')}
                    value={transaction.store?.phone ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate(transactionUser.role)}
                    value={
                      `${transactionUser.first_name ?? ''} ${transactionUser.last_name ?? ''}` ?? ''
                    }
                    nameWidth="col-5"
                  />
                </div>
              </div>
            </h4>
          </div>
          {!isAgentOrGatewayProvider && (
            <>
              <hr className="dash-border" />
              <div className="transaction-content">
                <h4>
                  <DetailRow
                    name={translate('payer')}
                    value={transaction.payer ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('amount')}
                    value={`${transaction.amount} ${transaction.currency}` ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('fee')}
                    value={
                      `${round(
                        parseFloat(transaction.charge) + parseFloat(transaction.refund_charge),
                        2,
                      )} ${transaction.currency}` ?? ''
                    }
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('tip_amount')}
                    value={`${transaction.tips} ${transaction.currency}` ?? ''}
                    nameWidth="col-5"
                  />

                  {transaction.status === REFUNDED.toLowerCase() && transaction.refunded > 0 && (
                    <>
                      <DetailRow
                        name={translate('refunded')}
                        value={`${transaction.refunded} ${transaction.currency}`}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('refund_reason')}
                        value={transaction.refund_details?.remark ?? ''}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('handled_person')}
                        value={`${transaction.refund_details?.first_name ?? ''} 
                        ${transaction.refund_details?.last_name ?? ''}`}
                        nameWidth="col-5"
                      />
                    </>
                  )}
                </h4>
              </div>
              <hr className="dash-border" />
              <div className="transaction-content">
                <h4>
                  <DetailRow
                    name={translate('net')}
                    value={`${transaction.net} ${transaction.currency}` ?? ''}
                    nameWidth="col-5"
                    valueWidth="col-6 h3"
                  />
                  <DetailRow
                    name={translate('status')}
                    value={translate(transaction.status ?? '')}
                    nameWidth="col-5"
                    valueWidth="col-6 h3"
                  />
                  {transaction.fail_details?.remark && (
                    <DetailRow
                      name={''}
                      value={transaction.fail_details.remark}
                      nameWidth="col-5 invisible"
                      valueWidth="col-6 fst-italic"
                    />
                  )}

                  <DetailRow
                    name={translate('settlement_status')}
                    value={translate(settlement_status)}
                    nameWidth="col-5"
                    valueWidth="col-6 h3"
                    display={transaction.gateway_id === 12 && transaction.status === PAID}
                  />
                </h4>
              </div>
              {transaction.extra_parameters && (
                <>
                  <div className="transaction-content">
                    <h4>
                      <div className="row">
                        <div className="col-12">
                          <DetailRow
                            name={translate('tip_amount')}
                            value={transaction.extra_parameters?.yb_tips_amount ?? ''}
                            nameWidth="col-5"
                          />
                        </div>
                      </div>
                    </h4>
                  </div>
                  <hr className="dash-border" />
                </>
              )}
              <hr className="dash-border" />
              {transaction.status === CANCELLED.toLowerCase() &&
                transaction.cancellation_details && (
                  <>
                    <div className="transaction-content">
                      <h4>
                        <div className="row">
                          <div className="col-12">
                            <DetailRow
                              name={translate('cancel_reason')}
                              value={transaction.cancellation_details.remark ?? ''}
                              nameWidth="col-5"
                            />

                            <DetailRow
                              name={translate('handled_person')}
                              value={
                                `${transaction.cancellation_details.first_name} ${transaction.cancellation_details.last_name}` ??
                                ''
                              }
                              nameWidth="col-5"
                            />
                          </div>
                        </div>
                      </h4>
                    </div>
                    <hr className="dash-border" />
                  </>
                )}

              {transaction.gateway_code !== YEDPAY_GATEWAY_CODE && (
                <>
                  <div className="transaction-content">
                    <h4>
                      <DetailRow
                        name={translate('gateway')}
                        value={findGatewayNameByCode(`${transaction.gateway_code}`) ?? ''}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('gateway_id')}
                        value={`${transaction.gateway_id}` ?? ''}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('wallet')}
                        value={translate(transaction.wallet_type) ?? '-'}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name="QR Code Id"
                        value={transaction.fixed_id ?? ''}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('forex')}
                        value={transaction.forex ?? '-'}
                        nameWidth="col-5"
                      />
                    </h4>
                  </div>
                  <hr className="dash-border" />
                </>
              )}

              {transaction.online_payment?.pay_by_link && (
                <div className="transaction-content">
                  <h4>
                    <DetailRow
                      name={translate('subject')}
                      value={transaction.online_payment.subject ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate('email')}
                      value={transaction.online_payment.payment_data?.email ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate('remarks')}
                      value={transaction.online_payment.remarks ?? ''}
                      nameWidth="col-5"
                    />
                  </h4>
                </div>
              )}
            </>
          )}
        </div>
        <RefundModal
          closeAll={closeAll}
          isOpen={showRefundModal}
          setIsOpen={setShowRefundModal}
          transaction={transaction}
        />
      </ModalBody>
      {!isAgentOrGatewayProvider && (
        <ModalFooter>
          <div className="text-center flex-fill">
            <button className="btn btn-primary btn-sm" onClick={onPrint}>
              {isDownloading ? <Spinner size="sm" /> : translate('print')}
            </button>
            {(user.role === owner || user.can_refund) &&
              (transaction.status === PAID.toLowerCase() ||
                transaction.status === CAPTURED.toLowerCase()) && (
                <>
                  &nbsp;
                  <button className="btn btn-danger btn-sm" onClick={openRefundModal}>
                    {translate('refund')}
                  </button>
                </>
              )}
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};
