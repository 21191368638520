import axios, { defaultRequestHandler, getToken, params } from '../common/Fetcher';
import { GetCompanyAnalysisResponse } from './interface';

// https://dev2.yedpay.com/document/admin/companySales
export const getCompanySalesAnalysis = (
  company_id: string,
  data: object,
): Promise<GetCompanyAnalysisResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/sales?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/gatewaySales
export const getGatewaySalesAnalysis = (
  gateway_id: string,
  data: object,
): Promise<GetCompanyAnalysisResponse> =>
  defaultRequestHandler(
    axios.get(`/gateways/${gateway_id}/sales?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/userSales
export const getUserSalesAnalysis = (
  user_id: string,
  data: object,
): Promise<GetCompanyAnalysisResponse> =>
  defaultRequestHandler(
    axios.get(`/users/${user_id}/sales?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
