import back from 'assets/img/print_pay_link/print_back.png';
import dialog from 'assets/img/print_pay_link/print_dialog.png';
import logo from 'assets/img/print_pay_link/print_logo.png';
import {
  ALIPAY_PNG,
  QUICKPASS_SQUARE_PNG,
  UNIONPAY_LOGO_PNG,
  WECHATPAY_LOGO_CHINESE_PNG,
} from 'constants/gateways/gatewayLogos';
import { PAY_LINK_GATEWAY } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { fabric } from 'fabric';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import html2canvas from 'html2canvas';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import './PrintModal.scss';

interface PrintModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  fpsCode: any;
}

export const PrintModal = ({ isOpen, setIsOpen, fpsCode }: PrintModalProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const hasAlipayOnline = getUserActivatedGateways().includes('4_1');
  const hasWeChatPayOnline = getUserActivatedGateways().includes('8_2');
  const hasUnionPayOnline = getUserActivatedGateways().includes('9_1');
  const hasUpop = getUserActivatedGateways().includes('9_5');

  const activatedPayByLinkGateway = getUserActivatedGateways().filter((value) =>
    PAY_LINK_GATEWAY.includes(value),
  );

  const iText = new fabric.IText(user.company.name, {
    fontFamily: 'colfax regular',
    fontSize: 60,
    fill: '#000000',
    left: 150,
    top: 90,
  });

  const payByLinkPrintRef = useRef<HTMLDivElement>(null);
  const payByLinkPage1Ref = useRef<HTMLDivElement>(null);
  const payByLinkPage2Ref = useRef<HTMLDivElement>(null);

  const [printPage1, setPrintPage1] = useState(true);
  const [printPage2, setPrintPage2] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const [fabricCanvas, setFabricCanvas] = useState<null | fabric.Canvas>(null);

  const [error, setError] = useState('');

  const { translate } = useTranslate();

  const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    if (e.target.id === 'page1') {
      setPrintPage1(e.target.checked);
      return;
    }
    setPrintPage2(e.target.checked);
  };

  const onPrint = () => {
    if (!payByLinkPrintRef.current && !payByLinkPage1Ref.current && !payByLinkPage2Ref.current) {
      console.error('Ref Object is not found');
      return;
    }
    if (printPage1 && printPage2) {
      print(payByLinkPrintRef.current!);
      return;
    }
    if (printPage1) {
      print(payByLinkPage1Ref.current!);
      return;
    }
    if (printPage2) {
      print(payByLinkPage2Ref.current!);
      return;
    }
  };

  const print = (el: HTMLDivElement) => {
    setIsPrinting(true);
    setTimeout(async () => {
      const canvas = await html2canvas(el, {
        useCORS: true,
        backgroundColor: '#ffffff',
        scale: 1,
      });
      const link = document.createElement('a');

      link.setAttribute('download', `pay-link-${fpsCode?.id}.png`);

      link.setAttribute(
        'href',
        canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'),
      );
      link.click();
      setIsPrinting(false);
    });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setPrintPage1(true);
    setPrintPage2(true);
  };

  const initializeFabric = async () => {
    await new Promise((res) =>
      setTimeout(() => {
        res(true);
      }, 1000),
    );

    if (!isOpen) {
      return;
    }

    if (fabricCanvas !== null) {
      iText.text = 'Tap and pay';
      fabricCanvas.renderAll();
      return;
    }

    const canvas = new fabric.Canvas('canvas', {
      width: 464,
      height: 786,
    });

    canvas.add(iText);
    canvas.renderAll();

    setFabricCanvas(canvas);
  };

  useEffect(() => {
    initializeFabric();
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (!printPage1 && !printPage2) {
      setError(translate('at_least_select_one_page_to_print'));
    }
    //eslint-disable-next-line
  }, [printPage1, printPage2]);

  if (!fpsCode) {
    return <></>;
  }

  //Should only use .png for printing

  const qrcode: string = fpsCode._links[1].href;
  const colWidth = 12 / activatedPayByLinkGateway.length || '1';

  return (
    <Modal isOpen={isOpen} toggle={toggle} unmountOnClose={false}>
      <ModalHeader toggle={toggle}>{translate('download_printable')}</ModalHeader>
      <ModalBody>
        <div className="" ref={payByLinkPrintRef}>
          {printPage1 && (
            <div className="page" ref={payByLinkPage1Ref}>
              <div className="payByLinkFirstPage">
                <div className="payByLinkFirstPage-container">
                  <div className="payByLinkFirstPage-title">
                    <h1>歡迎掃碼付款</h1>
                    <img src={dialog} alt="Dialog" />
                  </div>
                  {/* Place Canvas here for name editing */}

                  <canvas id="canvas"></canvas>

                  <div className="payByLinkFirstPage-border">
                    <div className="text-center">
                      <div className="m-3 row payByLinkFirstPage-container">
                        <img
                          src={ALIPAY_PNG}
                          alt="Alipay Online"
                          className={`${
                            hasAlipayOnline ? 'payByLinkFirstPage-AlipayLogo' : 'd-none'
                          }`}
                          draggable={false}
                        />

                        <img
                          src={WECHATPAY_LOGO_CHINESE_PNG}
                          alt="WeChat Pay Online"
                          className={`${
                            hasWeChatPayOnline ? 'payByLinkFirstPage-WeChatPayLogo' : 'd-none'
                          }`}
                          draggable={false}
                        />

                        <img
                          src={UNIONPAY_LOGO_PNG}
                          alt="UnionPay Online"
                          className={`${
                            hasUnionPayOnline ? 'payByLinkFirstPage-UnionPayLogo' : 'd-none'
                          }`}
                          draggable={false}
                        />

                        <img
                          src={QUICKPASS_SQUARE_PNG}
                          alt="UPOP"
                          className={`${hasUpop ? 'payByLinkFirstPage-UpopLogo' : 'd-none'}`}
                          draggable={false}
                        />
                      </div>
                      <img
                        src={qrcode}
                        alt="QR Code"
                        className="payByLinkFirstPage-qrCode"
                        draggable={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="payByLinkFirstPage-footer">
                  <img
                    src={logo}
                    alt="Yedpay"
                    className="img-fluid payByLinkFirstPage-yedpayLogo"
                  />
                </div>
              </div>
            </div>
          )}
          {printPage2 && (
            <div className="page" ref={payByLinkPage2Ref}>
              <img src={back} alt="" draggable={false} className="img-fluid img-container" />
            </div>
          )}
        </div>
        <div className="text-center">
          <div className="d-flex align-items-center justify-content-center">
            <input type="checkbox" id="page1" checked={printPage1} onChange={onCheck} />
            <label htmlFor="page1" className="form-label m-0 ms-1">
              Page 1
            </label>
            <div className="me-2"></div>
            <input type="checkbox" id="page2" checked={printPage2} onChange={onCheck} />
            <label htmlFor="page2" className="form-label m-0 ms-1">
              Page 2
            </label>
          </div>
          {error !== '' && <p className="text-danger">{error}</p>}
          <button
            className="btn btn-primary mt-3"
            onClick={onPrint}
            disabled={error !== '' || isPrinting}>
            {isPrinting ? <Spinner size="sm" /> : translate('print')}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
