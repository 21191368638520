import { email, packValidateResults, password, required } from 'helpers/validators';
import { ResetPasswordProps } from 'services/API/Auth/interface';

export const validate = (data: ResetPasswordProps, confirmPassword: string, type: string) => {
  const result = [];

  result.push(required(data, ['password', 'confirmPassword']));
  if (type === 'email') {
    result.push(required(data, ['email']));
    result.push(email(data, ['email']));
  } else if (type === 'phone') {
    result.push(required(data, ['phone', 'country_code']));
  }

  result.push(password(data, ['password']));
  if (data.password !== data.confirmPassword) {
    result.push({ confirmPassword: ['error_password_mismatch'] });
  }

  return packValidateResults(result);
};
