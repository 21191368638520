import { getDevicesListAction } from 'actions/DeviceActions';
import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { deleteDevice } from 'services/API/Device';
import { DeviceProps } from 'services/API/Device/interface';
import { useSwal } from 'helpers/sweetalert';
import { DeviceModal } from './DeviceModal';
import { DeviceTable } from './DeviceTable';

export interface DevicePageProps {
  location: RouteComponentProps;
}

export const Devices = ({ location }: DevicePageProps) => {
  const {
    auth: { user, roles },
    data: {
      devices: { devices, devicesPagination },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<DeviceProps | undefined>(undefined);

  const [currentPage, setCurrentPage] = useState(1);

  const fetchDevices = async () => {
    if (user?.company_id) {
      const data = {
        limit: 40,
        page: currentPage,
        sort: '-updated_at',
      };
      const res = await dispatch(getDevicesListAction(data));
      if (!res.success) {
        return Swal.fire({
          icon: 'error',
          title: translate('device'),
          text: res.message || 'Oops',
        });
      }
    }
  };

  const onDeleteDevice = async (device_id: string) => {
    const res = await deleteDevice(device_id);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('device'),
        text: res.message || 'Oops',
      });
    }
    fetchDevices();
  };

  const onSelectDevice = (device: DeviceProps) => {
    setSelectedDevice(device);
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setSelectedDevice(undefined);
  };

  useEffect(() => {
    fetchDevices();
    // eslint-disable-next-line
  }, [user, currentPage]);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location as any} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="company-info" name="device" />
          <DeviceTable
            roles={roles}
            devices={devices}
            devicesPagination={devicesPagination}
            onDeleteDevice={onDeleteDevice}
            onSelectDevice={onSelectDevice}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <DeviceModal
        onModalClose={onModalClose}
        device={selectedDevice}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};
