import Logo from 'assets/img/registrationForm/uploadDocumentIcon.png';
import { FormHeader } from 'components/custom/form';
import { ScrollView } from 'components/custom/ScrollView';
import { useTranslate } from 'context/TranslateContext';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { StepProps } from '../interface';

export const Declaration = forwardRef(({ setIsBottom }: StepProps, ref) => {
  const { translate } = useTranslate();

  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  // @todo Can remove this state and use the props.
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const resizeIFrame = () => {
    if (!iFrameRef.current || !iFrameRef.current.contentWindow) {
      return;
    }
    const iframeDocument = iFrameRef.current.contentWindow.document;
    iframeDocument.body.classList.add('iframe');

    const contentDiv = iframeDocument.getElementById('contentDiv');
    let height = 0;
    height += contentDiv?.getBoundingClientRect().y ?? 0;
    height += contentDiv?.getBoundingClientRect().height ?? 0;
    iFrameRef.current.height = '' + height;
  };

  const onScroll = () => {
    if (!scrollRef.current || scrolledToBottom) {
      return;
    }

    resizeIFrame();
    const object = scrollRef.current;
    const isBottom = object.scrollTop >= object.scrollHeight - object.clientHeight - 50;
    if (isBottom) {
      setScrolledToBottom(true);
      setIsBottom(true);
    }
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      return true;
    },
  }));

  return (
    <>
      <FormHeader
        title={translate('declaration')}
        src={Logo}
        captionRed={translate('please_read_and_sign_contract')}
      />
      <ScrollView maxVh={3} reference={scrollRef} onScroll={onScroll}>
        <iframe
          ref={iFrameRef}
          onLoad={resizeIFrame}
          src="/terms.html"
          style={{ width: '100%', border: 0, overflow: 'hidden' }}></iframe>
      </ScrollView>
    </>
  );
});
