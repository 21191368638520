import { getCompanyCodesList } from 'actions/MyFixedCodeActions';
import { Input } from 'components/custom/input';
import { SideFilter } from 'components/custom/sideFilter';
import { TopHeader } from 'components/layouts/page/topHeader';
import { ANY_OBJECT } from 'constants/format';
import { isAccountant, isSuperowner } from 'constants/Roles';
import { QR_CODE_AVAILABLE_GATEWAY_CODES } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { CompanyQrCode } from 'services/API/FixedCode/interface';
import { useSwal } from 'helpers/sweetalert';
import { ActionBox } from './ActionBox';
import { DataTable } from './DataTable';
import { DisplayCodeModal } from './DisplayCodeModal';
import { FixedCodeModal } from './FixedCodeModal';
import { PrintModal } from './PrintModal';

const initialFilter: ANY_OBJECT = {
  name: '',
  store_id: '',
  gateway: '',
};

export const MyCodes = () => {
  const {
    auth: { user, roles },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [isFetching, setIsFetching] = useState(false);
  const [filter, setFilter] = useState(initialFilter);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedFixedCode, setSelectedFixedCode] = useState<CompanyQrCode | null>(null);
  const [showCodeModal, setShowCodeModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [isCreateOrEditModalOpen, setIsCreateOrEditModalOpen] = useState(false);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const hasQrCodeAvailableGateway = getUserActivatedGateways().some((item) =>
    QR_CODE_AVAILABLE_GATEWAY_CODES.includes(item),
  );

  // Filter Functions
  const { name } = filter;

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  const resetFilter = () => {
    setFilter(initialFilter);
  };

  // Data Functions

  const getInitialData = async () => {
    setIsFetching(true);
    const data: ANY_OBJECT = {
      page: currentPage,
      limit: 10,
    };

    for (const key in filter) {
      if (filter[key] !== '') {
        data[key] = filter[key];
      }
    }

    const res = await dispatch(getCompanyCodesList(user.company_id, data));
    setIsFetching(false);
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('qr_code'),
        text: res?.message || 'Oops',
      });
    }

    // Update action box's button
    if (selectedFixedCode !== null) {
      const id = selectedFixedCode.id;
      const code = res.data.find((fixedCode) => fixedCode.id === id);
      if (code) {
        setSelectedFixedCode(code);
      }
    }
  };

  useEffect(() => {
    getInitialData();
  }, [currentPage]);

  // UI Control
  const onQrCodeClick = (fixedCode: CompanyQrCode) => () => {
    setSelectedFixedCode(fixedCode);
    setShowCodeModal(true);
  };

  const onRowClick = (fixedCode: CompanyQrCode) => () => {
    if (showCodeModal) {
      return;
    }
    setSelectedFixedCode(fixedCode);
    setIsCollapseOpen(true);
    return;
  };

  const onCreateBtnClicked = () => {
    setIsEdit(false);
    setIsCollapseOpen(false);
    setSelectedFixedCode(null);
    setIsCreateOrEditModalOpen(true);
  };

  const onEditBtnClicked = (fixedCode: CompanyQrCode) => {
    setIsEdit(true);
    setSelectedFixedCode(fixedCode);
    setIsCreateOrEditModalOpen(true);
  };

  const cannotCreateCodeAlert = () => {
    Swal.fire({
      title: translate('access_denied'),
      text: translate('no_gateway_available_for_qr_code_offline'),
      icon: 'error',
    });
  };

  return (
    <>
      <TopHeader title={translate('my_codes')}>
        {!roles.isOperator && !roles.isAccountant && !isSuperowner(user.role) && (
          <button
            className="header-button btn btn-primary"
            onClick={hasQrCodeAvailableGateway ? onCreateBtnClicked : cannotCreateCodeAlert}>
            {translate('create_qr_code')}
          </button>
        )}
      </TopHeader>
      <div id="main-content" className="hide-scrollbar">
        <DataTable
          setCurrentPage={setCurrentPage}
          onRowClick={onRowClick}
          onQrCodeClick={onQrCodeClick}
          selectedFixedCode={selectedFixedCode}
        />
      </div>

      <div id="side-content" className="hide-scrollbar">
        {!isAccountant(user.role) && !isSuperowner(user.role) && (
          <ActionBox
            setIsPrintModalOpen={setIsPrintModalOpen}
            reload={getInitialData}
            onEdit={onEditBtnClicked}
            isOpen={isCollapseOpen}
            fixedCode={selectedFixedCode}
          />
        )}
        <SideFilter isLoading={isFetching} resetFilter={resetFilter} onFilter={onFilterSearch}>
          <Input
            type="text"
            legend={translate('name').toUpperCase()}
            value={name}
            name="name"
            onChange={onFilterChange}
            placeholder={translate('name').toUpperCase()}
          />
        </SideFilter>
      </div>

      <DisplayCodeModal
        isOpen={showCodeModal}
        setIsOpen={setShowCodeModal}
        fixedCode={selectedFixedCode}
      />

      <FixedCodeModal
        isEdit={isEdit}
        isOpen={isCreateOrEditModalOpen}
        setIsOpen={setIsCreateOrEditModalOpen}
        fixedCode={selectedFixedCode}
        reloadCodes={getInitialData}
      />

      <PrintModal
        fixedCode={selectedFixedCode}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
      />
    </>
  );
};
