import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { useTranslate } from 'context/TranslateContext';
import { RouteComponentProps } from 'react-router-dom';
import { Token } from './Token';

interface MyPersonalTokenPageProps {
  location: RouteComponentProps;
}

export const MyPersonalToken = ({ location }: MyPersonalTokenPageProps) => {
  const { translate } = useTranslate();
  return (
    <>
      <TopHeader title={translate('user')} />
      <SideNavbar location={location as any} type="user" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="bank-accounts" name="my_personal_token" />
          <div className="bg-white round-bottom-lg pt-3 px-3 pb-4">
            <Token />
          </div>
        </div>
      </div>
    </>
  );
};
