import { HAS_NUMBER_REGEX } from 'constants/format';
export const validatePassword = (password: string): PasswordValidationRules => {
  const containsEightCharacters = password.length >= 8;
  const containsNumber = HAS_NUMBER_REGEX.test(password);
  const containsUpperAndLowerCase = /[a-z]/.test(password) && /[A-Z]/.test(password);

  return {
    overall: containsEightCharacters && containsNumber && containsUpperAndLowerCase,
    containsEightCharacters,
    containsNumber,
    containsUpperAndLowerCase,
  };
};

export interface PasswordValidationRules {
  overall?: boolean;
  containsEightCharacters: boolean;
  containsNumber: boolean;
  containsUpperAndLowerCase: boolean;
}

export const validateUsername = (username: string): UsernameValidationRules => {
  const lengthCorrect = username.length >= 8 && username.length <= 20;
  const startEndWithAlphanum = /^[a-zA-Z0-9].*[a-zA-Z0-9]$/.test(username);
  const charactersCorrect = /^[a-zA-Z0-9_.]+$/.test(username);

  return {
    overall: lengthCorrect && charactersCorrect && startEndWithAlphanum,
    lengthCorrect,
    startEndWithAlphanum,
    charactersCorrect,
  };
};

export interface UsernameValidationRules {
  overall?: boolean;
  lengthCorrect: boolean;
  startEndWithAlphanum: boolean;
  charactersCorrect: boolean;
}
