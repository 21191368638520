import { faLock, faTrash, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import edit from 'assets/img/icons/edit.png';
import UserLogoOffline from 'assets/img/icons/user_offline.png';
import UserLogoOnline from 'assets/img/icons/user_online.png';
import { Text } from 'components/custom/Text';
import { useTranslate } from 'context/TranslateContext';
import { FC } from 'react';
import { UserAndRolesProps } from 'services/API/User/interface';

export interface CardProps {
  user: UserAndRolesProps;
  onSelect?: () => void;
  confirmChangeStatus: (user_id: UserAndRolesProps, enable: boolean) => void;
  confirmDeleteUser: (user_id: UserAndRolesProps) => void;
  showAction: boolean;
}

export const Card: FC<CardProps> = ({
  user,
  onSelect,
  confirmChangeStatus,
  confirmDeleteUser,
  showAction,
}: CardProps) => {
  const { translate } = useTranslate();

  if (!user) {
    return <></>;
  }

  const { first_name, last_name, role, email, phone, username, disabled, id } = user;

  return (
    <div className="custom-card">
      <div className="custom-card-header">
        <img className="no-rounded" src={disabled ? UserLogoOffline : UserLogoOnline} />
        <span>{`${first_name} ${last_name}`}</span>
        <p>{translate(role)}</p>
      </div>

      <div className="custom-card-body">
        <Text type={translate('email')} value={email} />
        <Text type={translate('phone')} value={phone} />
        <Text type={translate('username')} value={username} />
        <Text
          type={translate('status')}
          value={translate(disabled ? 'disabled' : 'enabled')}
          className={disabled ? 'text-danger' : 'text-success'}
        />
      </div>

      {showAction && (
        <div className="custom-card-footer">
          <button className="btn " type="button" onClick={onSelect}>
            <img src={edit} alt="" className="d-block" />
          </button>

          <div style={{ flexGrow: 1 }}></div>

          <button
            className={`btn ms-2 ${disabled ? 'text-success' : 'text-warning'}`}
            type="button"
            onClick={() => {
              confirmChangeStatus?.(user, !disabled);
            }}
            style={{ fontSize: '1.2rem' }}>
            <FontAwesomeIcon icon={disabled ? faUnlockAlt : faLock} />
          </button>

          <button
            className="btn text-danger"
            type="button"
            onClick={() => {
              confirmDeleteUser?.(user);
            }}
            style={{ fontSize: '1.2rem' }}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      )}
    </div>
  );
};
