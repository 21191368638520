import img from 'assets/img/table/data_not_found.png';
import { useTranslate } from 'context/TranslateContext';
import { FC } from 'react';
import { PaginationProps } from 'services/API/common/Fetcher';
import { Pagination } from '../pagination';
import './CustomTable.scss';

interface CustomTableProps {
  title?: string;
  renderData?: () => Array<JSX.Element>;
  renderHeading?: () => JSX.Element;
  allowHover?: boolean;
  totalColumn: number;
  pagination?: PaginationProps | null;
  setCurrentPage?: (page: number) => void;
  renderPagination?: () => JSX.Element;
  centerPaginate?: boolean;
  setListIdStartFrom?: (id: number) => void;
  isLoading?: boolean;
}

export const CustomTable: FC<CustomTableProps> = (props: CustomTableProps) => {
  const {
    renderHeading,
    renderData,
    totalColumn,
    allowHover,
    pagination,
    setCurrentPage,
    title,
    renderPagination,
    centerPaginate,
    setListIdStartFrom,
    isLoading = false,
  } = props;

  const { translate } = useTranslate();

  return (
    <div className={`table-responsive ${centerPaginate && 'admin'} shadow-sm`}>
      {title && <h5 className="mb-3">{title}</h5>}
      <table className={`table ${allowHover ? 'table-hover' : ''}`}>
        <thead>{renderHeading?.()}</thead>
        <tbody className="mt-3">
          {renderData && renderData().length > 0 ? (
            renderData?.()
          ) : (
            <tr className="">
              <td colSpan={totalColumn} rowSpan={5} className="text-center">
                <div>
                  <img src={img} alt="" className="img-fluid my-4" />
                </div>
                <span className="grey-text">
                  {isLoading ? translate('loading') : translate('no_data')}
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {renderPagination?.() || (
        <Pagination
          pagination={pagination}
          setCurrentPage={setCurrentPage}
          setListIdStartFrom={setListIdStartFrom}
        />
      )}
      <div className="mb-5 mb-md-0"></div>
    </div>
  );
};
