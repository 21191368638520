export const translation = {
  en: {
    locale: 'en-US',
    messages: {
      abc_company: 'ABC COMPANY',
      accept_terms_and_conditions: 'Accept Terms and Conditions',
      accept: 'Accept',
      access_denied: 'Access Denied',
      access_token: 'Access Token',
      account_holder: 'Account Holder Name',
      account_number: 'Account Number',
      account_summary: 'Account Summary',
      account: 'Account',
      accountant: 'Accountant',
      actions: 'Actions',
      activate_account: 'Activate Account',
      activate_code: 'Activate Code',
      activated: 'Activated',
      activation_type: 'Activation Method',
      activation: 'Activation',
      active: 'Active',
      Active: 'Active',
      add_account: 'Add Account',
      add_api_key: 'Add an API Key',
      add_api_key2: "Pick a name and a store, and we'll give you a unique api key.",
      add_code: 'Add Code',
      add_gateway: 'Add Your Gateways',
      add_new_user: 'Add New User',
      add: 'Add',
      addendum_is_required_for_application:
        'Addendum is required for Visa / Mastercard application',
      addendum_is_required_for_application_amex:
        'Addendum is required for American Express application',
      addendum: 'Addendum',
      address: 'Address',
      address_or_url: 'Address / Store URL',
      admin: 'Admin',
      adyen: 'Credit Card (Online Payment)',
      affiliation_code_incorrect: 'Affiliation Code Incorrect',
      alipay_e_commerce: 'Alipay E-Commerce',
      alipay_in_store: 'Alipay Spot Payment',
      alipay_online: 'Alipay Online',
      alipay: 'Alipay',
      all_companies: 'All Companies',
      all_gateways: 'All Gateways',
      all_rights_reserved: 'All rights reserved.',
      all_stores: 'All Stores',
      all_types: 'All Types',
      all_your_activities: 'All your activities',
      all: 'All',
      allow_characters: 'Accept following character:',
      allow_special_characters: 'Accept the following special character:',
      already_have_account: 'Already have an account?',
      aml_domain: 'Company Register Domain',
      aml_domains: 'Company Register Domains',
      amount: 'Amount',
      analysis_summary: 'Summary',
      analysis: 'Sales Analysis',
      and: ' and ',
      api_keys: 'API Keys',
      app_keys: 'App Keys',
      applicable_gateways: 'Applicable Gateways',
      application_processing: 'Processing',
      application: 'Application',
      applied: 'Applied',
      apply_a_payment_gateway_first: 'Please apply a payment gateway first!',
      apply_filter: 'Apply',
      apply_for_alipay_e_commerce: 'Apply for Alipay E-Commerce',
      apply_for_alipay: 'Apply for Alipay',
      apply_for_credential: 'Apply for Credential',
      apply_for_unionpay: 'Apply for UnionPay',
      apply_immediately: 'Apply Immediately',
      apply_status: 'Apply Status',
      apply: 'Apply',
      approve_data: 'Approve data',
      april: 'April',
      are_you_going_to_apply_for: 'You are going to apply for payment gateway',
      are_you_sure_change_aml_status_pending:
        'Are you sure you want Yedpay to review your Company Register Domain again?',
      are_you_sure_change_document_type_to_type:
        'Are you sure you want to change the document type to {type}?',
      are_you_sure_delete_domain: 'Are you sure you want delete this domain?',
      are_you_sure_delete_device: 'Are you sure you want delete this device?',
      are_you_sure_delete_ttp_device: 'Are you sure you want delete this Tap-to-phone device?',
      are_you_sure_delete_document: 'Are you sure you want to delete this document?',
      are_you_sure_delete_personal_token: 'Are you sure you want to delete the personal token?',
      are_you_sure_delete_user: 'Are you sure you want delete this user?',
      are_you_sure_disable_user: 'Are you sure you want to disable this user?',
      are_you_sure_disable_ttp_device: 'Are you sure you want to disable this Tap-to-phone device?',
      are_you_sure_enable_user: 'Are you sure you want to enable this user?',
      are_you_sure_enable_ttp_device: 'Are you sure you want to enable this Tap-to-phone device?',
      are_you_sure_hide_gateway_from_checkout_page:
        'Are you sure you want to hide this gateway from checkout page?',
      are_you_sure_refund_transaction: 'Are you sure you want to refund this transaction?',
      are_you_sure_reverse_settlement: 'Are you sure you want to reverse this balance?',
      are_you_sure_show_gateway_from_checkout_page:
        'Are you sure you want to show this gateway on checkout page?',
      assign_stores: 'Assign Stores',
      assign_up_to_5_stores: 'Can assign up to 5 stores per request.',
      at_least_8_chars: 'at least 8 Characters',
      at_least_select_one_page_to_print: 'Please select at least one page to print',
      august: 'August',
      authorised: 'Authorised',
      authorized: 'Authorized',
      auto_settlement: 'Auto Settlement',
      average_monthly_sale: 'Average Monthly Sale',
      average_ticket: 'Average Ticket',
      back: 'Back',
      back_camera: 'Back Camera',
      balance_account: 'Balance Account',
      balance_available: 'Balance Available',
      balance_holding: 'Holding',
      balance_settled: 'Settled',
      balance: 'Balances',
      bank_account: 'Bank Account',
      bank_accounts: 'Bank Accounts',
      bank_code: 'Bank Code',
      bank_name: 'Bank Name',
      bank_statement: 'Bank Statement',
      bank_transaction_record: "Bank's Transaction Record",
      bank_transaction_reference_id: "Bank's Transaction Reference Id",
      bank: 'Bank',
      barcode_list: 'Barcode List',
      barcode: 'Barcode',
      br: 'Business Registration',
      br_branch_no: 'BR branch no.',
      br_number: 'BR Number',
      branch_code: 'Branch Code',
      branch: 'Branch',
      business_address: 'Business Address',
      business_description: 'Business Description',
      business_nature: 'Business Nature',
      business_registration: 'Business Registration',
      business_setup: 'Business Setup',
      business_type: 'Business Type',
      can_refund: 'Can refund',
      can_settle_after: 'Can Settle After',
      cancel_reason: 'Cancel Reason',
      cancel: 'Cancel',
      canceled: 'Canceled',
      cancelled_settlement: 'Settlement Canceled',
      cancelled_transfer: 'Cancelled Transfer',
      cancelled: 'Cancelled',
      cancellation_terms_en: 'Cancellation Terms (English)',
      cancellation_terms_zh: 'Cancellation Terms (Chinese)',
      cannot_delete_br_ci: 'BR and CI documents cannot be deleted',
      cannot_open_camera: 'Cannot open camera',
      certificate_of_incorporation: 'Certificate of Incorporation',
      change_a_field_to_submit: 'Please change a field to submit',
      change_aml_status_pending: 'Change Company Register Domain Status to Pending',
      change_default_gateway: 'Change Default Gateway',
      change_gateway_display_status: 'Change Gateway Display Status',
      chargeback: 'Chargeback',
      checkout_domain: 'Checkout Domain',
      checkout_domains: 'Checkout Domains',
      checkout_page: 'Checkout Page',
      chinese: '中文',
      choose_and_add_gateway: 'Choose and add the following available gateways to accept payment',
      choose_business: 'Choose Business',
      choose_business_type: 'Choose Business Type',
      choose_currency: 'Choose Currency',
      choose_document_type: 'Choose Document Type',
      choose_gateway: 'Choose gateway',
      choose_industry: 'Choose Industry',
      choose_logo: 'Choose Logo',
      choose_role: 'Choose Role',
      choose_store_type: 'Please choose store type',
      choose_store: 'Choose store',
      choose_type: 'Choose Type',
      ci_company_name: 'CI Company name',
      ci_number: 'CI Number',
      ci: 'Certificate of Incorporation',
      clear_filter: 'Clear',
      click_below_name_to_copy_token_to_clipboard:
        'Click the name below to copy the token to clipboard',
      client: 'Client',
      client_approved_at: 'Client Approved At',
      close_camera: 'Close Camera',
      close: 'Close',
      cn: 'China',
      CN: 'CN',
      code_CN: 'Accept CN Wallet',
      code_HK: 'Accept HK Wallet',
      code_HKCN: 'Accept HK/CN wallets',
      code_link: 'QR Code Link',
      code_name: 'Code Name',
      code: 'Code',
      company_bank_accounts: 'Company Bank Accounts',
      company_id: 'Company Id',
      company_info: 'Company Info',
      company_name: 'Company Name',
      company_phone: 'Company Phone',
      company: 'Company',
      completed_transfer: 'Completed Transfer',
      completed: 'Completed',
      confirm_new_password: 'Confirm New Password',
      confirm_password: 'Confirm Password',
      confirm_refund: 'Confirm Refund',
      confirm_to_cancel_it_as_the_default_fps_account_for_online_payment:
        'Confirm to cancel it as the default FPS account for online payment?',
      confirm_to_set_it_as_the_default_fps_account_for_online_payment:
        'Confirm to set it as the default FPS account for online payment?',
      confirm: 'Confirm',
      confirm_to_cancel: 'Confirm to cancel',
      cancel_pre_auth: 'Cancel Authorized Transaction',
      congratulations_account_successfully_updated: 'Account successfully updated!',
      congratulations_bank_account_successfully_created: 'Bank Account successfully updated!',
      congratulations_bank_account_successfully_updated: 'Bank Account successfully updated!',
      congratulations_bank_ccount_successfully_created: 'Bank Account successfully created!',
      congratulations_code_successfully_updated: 'Code successfully updated!',
      congratulations_terms_successfully_updated: 'Terms successfully updated!',
      congratulations_company_successfully_updated: 'Company successfully updated!',
      congratulations_document_successfully_uploaded: 'Document successfully uploaded!',
      congratulations_generate_sign_key_successful: 'Generate sign key successful!',
      congratulations_new_code_successfully_created: 'New code successfully created!',
      congratulations_new_device_successfully_created: 'New device successfully created!',
      congratulations_new_store_successfully_created: 'New store successfully created!',
      congratulations_new_user_successfully_created: 'New user successfully created!',
      congratulations_new_user_successfully_updated: 'New user successfully updated!',
      congratulations_password_was_success_reset: 'Password was successfully reset!',
      congratulations_password_was_success_set: 'Password setup was successfully!',
      congratulations_pers_token_successfully_created: 'Personal Token successfully created!',
      congratulations_pers_token_successfully_deleted: 'Personal Token successfully deleted!',
      congratulations_profile_successfully_updated: 'Profile successfully updated!',
      congratulations_regenerate_sign_key_successful: 'Re-Generate sign key successful!',
      congratulations_reverse_settlement_successful: 'Reverse settlement successful!',
      congratulations_store_successfully_updated: 'Store successfully updated!',
      congratulations_store_and_terms_successfully_updated: 'Store and Terms successfully updated!',
      congratulations_transaction_successfully_created: 'Transaction was successfully created!',
      congratulations_transaction_successfully_refunded: 'Transaction was successfully refunded!',
      congratulations_user_successfully_disabled: 'User successfully disabled!',
      congratulations_user_successfully_enabled: 'User successfully enabled!',
      congratulations_withdrawal_successfully: 'Withdrawal successful!',
      congratulations_you_successfully_applied: 'You successfully applied!',
      congratulations_your_acc_successfully_activated: 'Your account successfully activated!',
      congratulations_your_withdrawal_successfully: 'Withdrawal successful!',
      contact_name: 'Contact Name',
      contact_phone: 'Contact phone',
      contains_1_digit: 'Contains at least one number',
      contains_8_characters: 'Contains at least 8 characters',
      contains_8_20_characters: 'Contains 8-20 characters',
      contains_correct_characters:
        'Only Period(.) and Underscore (_) are allowed for special character, but cannot be consecutive',
      contains_upper_and_lower_case: 'Contains at least one upper and lower character',
      contract: 'Contract',
      contract_type: 'Contract Type',
      copied_at_your_clipboard: 'Copied at your clipboard',
      could_not_process: 'Could not process',
      country_code: 'Country Code',
      create_account: 'Create Account',
      create_an_account: 'Create an account',
      create_bank_account: 'Create Bank Account',
      create_qr_code: 'Create QR Code',
      create_device: 'Create Device',
      create_pay_link: 'Create Pay Link',
      create_personal_token: 'Create Personal Token',
      create_store: 'Create Store',
      create_token: 'Create Token',
      create_transaction_failed: 'Create Transaction Failed',
      create_transaction: 'Create Transaction',
      create_user: 'Create User',
      create: 'Create',
      created_at: 'Created at',
      creation_date: 'Creation Date',
      credential_list: 'Credential List',
      credit_card: 'Credit Card',
      cross_border_rate: 'Cross Border Rate',
      currency_amount: 'Currency amount',
      currency: 'Currency',
      custom_id: 'Custom Id',
      cybersource: 'Credit Card (In Store Payment)',
      date_of_birth: 'Date of Birth',
      date_of_establishment: 'Date Of Establishment',
      date: 'Date',
      date_range_last_7_days: 'Last 7 days',
      date_range_last_30_days: 'Last 30 days',
      date_range_last_1_month: 'Last month',
      date_range_last_3_months: 'Last 3 months',
      date_range_last_6_months: 'Last 6 months',
      date_range_today: 'Today',
      date_range_this_year: 'This year',
      date_range_last_year: 'Last year',
      date_range_all_date: 'All date',
      date_range_custom_range: 'Custom date range',
      deactivate_code: 'Deactivate Code',
      december: 'December',
      declaration: 'Declaration',
      default_gateway: 'Default Gateway',
      default_store: 'Default Store',
      define_name: 'Define Name',
      define_your_name: 'Define your name',
      delete_account: 'Delete Account',
      delete_aml_domain: 'Delete Company Register Domain',
      delete_domain: 'Delete Checkout Domain',
      delete_device: 'Delete Device',
      delete_personal_token: 'Delete Personal Token',
      delete_profile: 'Delete Profile',
      delete_store: 'Delete Store',
      delete_token: 'Delete Token',
      delete_ttp_device: 'Delete Tap-to-phone device',
      delete_user: 'Delete User',
      delete: 'Delete',
      delivery_terms: 'Delivery Time (in Days)',
      device_list: 'Device List',
      device_name: 'Device Name',
      device_was_deleted: 'Device was deleted!',
      device: 'Devices',
      disable_user: 'Disable User',
      disable_ttp_device: 'Disable Tap-to-phone device',
      disable: 'Disable',
      disabled: 'Disabled',
      display_name: 'Display Name',
      district: 'District',
      do_not_have_account: 'Do not have an account?',
      document: 'Document',
      document_company_name: 'Company Name on the Document',
      document_deleted: 'Document Successfully Deleted',
      document_name: 'Document Name',
      document_type: 'Document Type',
      documents: 'Documents',
      documents_list: 'Documents list',
      domain_display_name: 'Display Name Of Domain',
      domain_id: 'Domain ID',
      domain_name: 'Domain Name',
      domain_type: 'Domain Type',
      done: 'Done',
      dont_have_any_acc: 'Do not have an account',
      download_addendum: 'Download addendum (Visa/Mastercard application)',
      download_as_excel: 'Download Excel',
      download_as_pdf: 'Download PDF',
      download_contract: 'Download Contract',
      download_printable: 'Download Print Format',
      download: 'Download',
      driving_license: 'Driving License Number',
      duplicate_file_uploaded: 'Duplicate File Uploaded',
      dynamic_pay_links: 'One-time Link',
      e_commerce: 'E-Commerce',
      edit_code: 'Edit Code',
      edit_credential_successful: 'Edit Credential Successful',
      edit_credential: 'Edit Credential',
      edit_gateway: 'Edit Gateway',
      edit_store: 'Edit Store',
      edit_user: 'Edit User',
      edit: 'Edit',
      email_or_username: 'Email or Username',
      email: 'Email',
      empty: 'Empty',
      enable_user: 'Enable User',
      enable_ttp_device: 'Enable Tap-to-phone device',
      enable: 'Enable',
      enabled: 'Enabled',
      end_date: 'End Date',
      english: 'English',
      enter_account_holder_name: 'Enter Account Holder Name',
      enter_account_number: 'Enter Account Number',
      enter_activate_code: 'Enter activate code',
      enter_address: 'Enter Address',
      enter_amount: 'Enter Amount',
      enter_api_key_name: 'Enter Key Name',
      enter_bank_name: 'Enter Bank Name',
      enter_barcode: 'Enter Barcode',
      enter_br_branch_no: 'Enter BR branch no.',
      enter_br_expiry_date: 'Enter BR expiry date ( DD / MM / YYYY )',
      enter_br_number: 'Enter BR Number',
      enter_business_address: 'Enter business address',
      enter_ci_company_name: 'Enter CI Company Name',
      enter_ci_number: 'Enter CI Number',
      enter_country_code: 'Enter country code',
      enter_date_of_birth: 'Enter Date of Birth ( DD / MM / YYYY )',
      enter_date_of_establishment: 'Enter Date of Establishment',
      enter_display_name: 'Enter Display Name',
      enter_domain_display_name: 'Enter Display Name Of Domain',
      enter_domain_name: 'Enter Domain Name',
      enter_domain_type: 'Enter Domain Type',
      enter_first_name: 'Enter first name',
      enter_hawker_operation_id: 'Enter Enter hawker license number',
      enter_hkid_or_passport_number: 'Enter hkid / passport number',
      enter_ir_number: 'Enter IR Number',
      enter_last_name: 'Enter last name',
      enter_license_number: 'Enter license number',
      enter_nationality: 'Enter Nationality',
      enter_password: 'Enter password',
      enter_percentage_owned: 'Enter percentage of ownership',
      enter_phone: 'Enter phone',
      enter_position: 'Enter position',
      enter_store_address: 'Enter Store Address',
      enter_store_name: 'Enter Store Name',
      enter_store_phone: 'Enter Store Phone',
      enter_store_url: 'Enter website url',
      enter_taxi_number: 'Enter taxi number',
      enter_taxi_operation_id: 'Enter taxi driver ID plate number',
      enter_username: 'Enter username',
      enter_your_average_monthly_sale: 'Enter your average monthly sale',
      enter_your_average_ticket: 'Enter your average ticket',
      enter_your_business_description: 'Enter business description',
      enter_your_company_name: 'Enter your company name',
      enter_your_company_phone: 'Enter your company phone',
      enter_your_contact_mobile: 'Enter contact mobile',
      enter_your_contact_name: 'Enter contact name',
      enter_your_email_address_or_phone: 'Enter Your Email Address or Phone',
      enter_your_email_address: 'Enter Your Email Address',
      enter_your_email_or_phone_and_new_password: 'Enter your Email/Phone and new Password.',
      enterprise: 'Enterprise',
      error_duration_must_be_less_equal_than: 'Duration must be less or equal than {0} {1}',
      error_file_size_must_be_less_than: 'File size must be less than {0}',
      error_invalid_account_holder_format:
        'Invalid Account Holder Format. Accept only capital letters, numbers and special characters such as ._ and spacebar.',
      error_invalid_address_format:
        'Invalid Address Format. Accept only english letters, numbers and special characters such as -,/.&()\'"',
      error_invalid_date_format: 'Invalid Date Format',
      error_invalid_domain_format: 'Invalid Domain Format',
      error_invalid_email_format: 'Invalid Email Format',
      error_invalid_expiry_date: 'Invalid Expiry Date',
      error_invalid_password_format: 'Invalid Password Format',
      error_invalid_username_format: 'Invalid Username Format',
      error_invalid_url_format: 'Invalid URL Format',
      error_length_must_be_equal: 'Length must be equal {0} characters',
      error_length_must_be_equal_multi: 'Length must be equal {0} or {1} characters',
      error_length_must_be_less_equal_than: 'Length must be less or equal than {0} characters',
      error_length_must_between: 'Length must between {0} and {1} characters',
      error_must_be_digits: 'Must be digit(s)',
      error_must_be_less_equal_than_files: 'Must be less or equal than {0} files',
      error_must_be_greater_equal_than: 'Must be greater or equal than {0}',
      error_must_be_greater_than: 'Must be greater than {0}',
      error_must_between: 'Must between {0} and {1}',
      error_password_mismatch: 'Password Mismatch',
      error_invalid_delivery_time_format:
        'Invalid Delivery Time Format. Accept only numbers and - in between two numbers.',
      error_required: 'This field is required',
      error: 'Error',
      expired: 'Expired',
      expired_at: 'Expired at',
      expiry_date: 'Expiry Date',
      export_settlements: 'Export Settlements',
      export_transaction: 'Export Transactions',
      export: 'Export',
      face_to_face_payment: 'Face-to-Face Payment',
      failed_reason: 'Failed Reason',
      failed_to_upload_document: 'Failed to Upload Document',
      failed_to_generate_report: 'Failed to Generate Report',
      failed: 'Failed',
      false: 'False',
      fee: 'Fee',
      file_format_incorrect: 'File Format Incorrect',
      file_type: 'File Type',
      filter: 'Filter',
      finance: 'Finance',
      find_code: 'Find fixed code',
      first_name: 'First Name',
      fixed_qr_code: 'Fixed QR Code',
      food: 'Food',
      forex: 'Exchange Rate',
      forgot_password: 'Forgot Password',
      fps: 'HSBC FPS',
      fps_clearing_code: 'Bank code for FPS',
      fps_dbs_notice:
        'Please fill in the FPS Account Info which correspond with your DBS MAX Merchant ID.',
      fps_dbs_offline: 'DBS MAX FPS Offline',
      fps_dbs_online: 'DBS MAX FPS Online',
      fps_email: 'Email registered for FPS',
      fps_gateway: 'FPS Gateway',
      fps_id: 'FPS ID (7 or 9 digits)',
      fps_hsbc_offline: 'HSBC FPS Offline EMV Code',
      fps_hsbc_online: 'HSBC FPS Online',
      fps_merchant_id: 'FPS Merchant ID',
      fps_org_id: 'Organization ID (Ended with 1MS)',
      fps_phone: 'Phone registered for FPS',
      fps_notice:
        'Please fill in the FPS Account Info which correspond with your HSBC Business Collect Merchant ID.',
      fps_account_info: 'FPS Account Info',
      from: 'From',
      front_camera: 'Front Camera',
      gateway_fee: 'Gateway Fee',
      gateway_id: 'Gateway Id',
      gateway_name: 'Gateway Name',
      gateway_reference_id: 'Ref. ID',
      gateway_type: 'Gateway Type',
      gateway: 'Gateway',
      gateways: 'Gateways',
      generate: 'Generate',
      generated_at: 'Generated at',
      generate_csv_report: 'Generate CSV report',
      generate_excel_report: 'Generate Excel report',
      generate_pdf_report: 'Generate PDF report',
      generating_report: 'Generating report',
      get_code: 'Get Code',
      go_to_this_export: 'Go to this export',
      go_to: 'Go to',
      gross_amount: 'Gross Amount',
      group_by_company: 'Group By Company',
      group_by_gateway: 'Group By Gateway',
      group_by_mcc: 'Group By MCC',
      group_by_store: 'Group By Store',
      group_by: 'Group By',
      handled_person: 'Handled Person',
      hawker_license: 'Hawker License',
      hawker_operation_id: 'Hawker License Number',
      hidden: 'Hidden',
      HK: 'HK',
      hk: 'Hong Kong',
      OVERSEAS: 'OVERSEAS',
      HKCN: 'HK/CN',
      hkid_or_passport_number: 'HKID / Passport number',
      hkid_or_passport_proof: 'HKID / Passport proof',
      hkid_v1: 'HKID',
      hkid_v2: 'New HKID',
      hkid: 'Hong Kong ID Card',
      holder: 'Holder',
      holding_amount: 'Holding Amount',
      hotel: 'Hotel',
      i_have_read_and_understand: 'I have read and understand the',
      if_you_choose_to_continue_you_agree_to_our: 'By continuing, you agree to our ',
      if_you_didnt_receive_code: "Didn't receive your activation code?",
      if_you_want_to_create_please_click_to_create_button:
        "If you want to create a token, please click the 'Create' button.",
      if_you_want_to_create_please_enter_the_name_of_new_token:
        'If you want to create, please enter the name of new token.',
      img_size_less_than_2mb: 'file size must be less then 2MB',
      inactive: 'Inactive',
      Inactive: 'Inactive',
      include_a_no: 'including a Number',
      individual: 'Individual',
      invalid_credentials: 'Invalid Credentials',
      ir_registration:
        'Charitable Institutions and Trusts of Public Character Registration (Section 88)',
      ir: 'Charitable Institutions and Trusts of Public Character Registration (Section 88)',
      ir_number: 'IR Number',
      is_fixed_amount: 'Is fixed amount',
      is_permanent: 'Is Permanent',
      january: 'January',
      july: 'July',
      june: 'June',
      language: 'Language',
      last_name: 'Last Name',
      last_used: 'Last Used',
      last_withdrawal_was_on: 'Last withdrawal was on: ',
      legal_name: 'Legal Name',
      loading: 'Loading...',
      load_more: 'Load more',
      login: 'Login',
      login_failed: 'Login Failed',
      logo: 'Logo',
      logout: 'Log out',
      main_page: 'main page',
      main: 'Main',
      main_account: 'Main',
      manage_user: 'Manage User',
      management: 'Management',
      manual_settlement: 'Manual Settlement',
      march: 'March',
      mastercard: 'Mastercard',
      may: 'May',
      mcc_description: 'MCC Description',
      mcc: 'MCC',
      my_bank: 'My bank',
      my_codes: 'My QR Codes',
      pre_authorize: 'Pre-authorization',
      pre_authorize_details: 'Pre-authorization Details',
      my_gateways: 'My Gateways',
      my_personal_token: 'My Personal Token',
      na: 'Not Applicable',
      name: 'Name',
      nationality: 'Nationality',
      net: 'Net',
      new_api_key_notice: "Make sure you save it - you won't be able to access it again.",
      new_api_key: 'Your New API Key',
      new_orders: 'New orders',
      new_password: 'New Password',
      new_token_name: 'New Token name',
      new: 'new',
      next: 'Next',
      nickname: 'Nickname',
      ngo: 'Charitable institutions and trusts of public character',
      no_data: 'No data found!',
      no_gateway_available_for_qr_code:
        'QR Codes are only available for online Alipay, WeChat Pay, Visa / Mastercard, UnionPay and FPS. The above gateways are not activated or applied.',
      no_gateway_available_for_qr_code_offline:
        'QR Codes are only available for offline Alipay, WeChat Pay, UnionPay and FPS. The above gateways are not activated or applied.',
      no_logos: 'No Logos',
      no_recent_settlements: 'No recent settlements',
      no_recent_transactions: 'No recent transactions',
      no_result: 'No Result',
      no_sign_key: "You don't have any sign key yet.",
      no_stores: 'No Stores',
      no_transaction_found: 'No Transactions Found',
      no: 'No',
      normal: 'Normal',
      not_applied: 'Not applied',
      not_enough_balance: "You don't have enough balance!",
      not_fixed_amount: 'Not fixed amount',
      not_shared: 'Not shared',
      not_uploaded: 'Not uploaded',
      november: 'November',
      number: 'Number',
      obsoleted: 'Obsoleted',
      ocr_fail_msg: 'Please upload correct and clear br image.',
      ocr_failed: 'Optical Character Recognition Failed',
      october: 'October',
      octopus: 'Octopus',
      offline: 'Offline Payment',
      offline_gateway_agreement: 'Offline Gateway Agreement',
      offline_store_only: 'Available for Offline Stores Only',
      on_verification: 'On Verification',
      one_week: '1 week',
      online: 'Online Payment',
      Online: 'Online',
      online_gateways: 'Online Gateways',
      online_gateway_agreement: 'Online Gateway Agreement',
      online_store_only: 'Available for Online Store Only',
      only_available_for_alipay: 'Available For Offline Alipay Only',
      only_support_image: 'Only support image file',
      only_support_image_or_pdf: 'Only support image/pdf file',
      oops: 'Oops, something went wrong...',
      open_camera: 'Open Camera',
      operations: 'Operations',
      operator: 'Operator',
      or_back_to: 'or back to',
      order: 'Order',
      order_sort: 'Sorting',
      asc: 'ascending',
      desc: 'descending',
      other: 'Other',
      other_agreement: 'Other Agreement',
      others: 'Others',
      owner: 'Owner',
      page_does_not_exist: "The Page you are looking for doesn't exist or an other error occurred",
      page_not_found: 'Page not found',
      page: 'page',
      pagination_total: 'Showing {from} to {to} of {size} Results',
      paid_at: 'Paid at',
      paid: 'Paid',
      passport: 'Passport',
      password_characters: '~!@#$%^&*()=[]{};?',
      password_expired: 'Password Expired',
      password_hints:
        'Password MUST contain: at least 8 Characters, including a Number, an Uppercase and a Lowercase letter',
      password_must_contain: 'Password MUST contain:',
      password_will_expire_cancel_text: 'Not Now',
      password_will_expire_msg: 'Press OK to proceed to password change',
      password_will_expire: 'Password expires within {days} days',
      password: 'Password',
      pay_link: 'Pay Link',
      pay_links: 'Pay Links',
      payer: 'Payer',
      pay_plus: 'Pay by Bank',
      pay_plus_transactions: 'Pay by Bank Transactions',
      pay_plus_payment_gateway: 'Pay by Bank Payment Gateway',
      payment_cards_group_limited: '© The Payment Cards Group Limited. ',
      payment_gateways: 'Payment Gateways',
      payment_qr_code: 'Payment QR Code',
      payment_tech_provided_by_yedpay: 'Payment Technology Provided by',
      payment_time: 'Payment Time',
      pending_approve: 'Pending',
      pending_refund: 'Pending Refund',
      pending: 'Pending',
      pending_capture: 'Pending Capture',
      percentage_owned: '% own',
      permission_name: 'Permission name',
      permission: 'Permission',
      personal_token_at_your_clipboard: 'Token at your clipboard',
      personal_token_effective_in_1_year:
        'It will expire in 1 year or when another personal token is created',
      personal_token: 'Personal Token',
      phone: 'Phone',
      picture: 'Picture',
      platform: 'Platform',
      please_apply_offline_gateway:
        'Please apply at least one offline gateway such as Alipay or WeChat Pay',
      please_change_field_to_update: 'Please change field to update',
      please_change_password: 'Please change password before log into the system',
      please_choose_document_type: 'Please, choose document type',
      please_choose_file_to_upload: 'Please, choose file to upload',
      please_choose_store: 'Please choose store',
      please_enter_letter_without_the_bracket:
        'Please enter letter without the bracket or any other character, e.g. ABC HK CO LIMITED for company name.: ABC (HK) Co. Limited;',
      please_fill_out_all_the_forms: 'Please, fill out all the forms',
      please_note_only_one_fps_online_gateway_can_be_enabled:
        'Please note only one FPS online gateway can be enabled',
      please_read_and_sign_contract:
        'Please read and sign the user contract below for applying the gateway.',
      please_relogin: 'Please login again',
      please_setup_bank_account: 'Please setup bank account first',
      please_try_again_after_sec: 'Please try again after {sec}s',
      plsease_choose_currency: 'Please choose currency',
      position: 'Position',
      precreated: 'Precreated',
      preview: 'Preview',
      previous: 'Previous',
      print: 'Print',
      privacy_policy: 'Privacy Policy',
      processing_settlement_cannot_cancel: 'Settlement processing, not eligible for cancellation.',
      processing_time: 'Processing time for application is around 1 week.',
      processing: 'Processing',
      profile_info: 'Profile Info',
      profile: 'Profile',
      qr_code_successfully_disabled: 'QR Code Successfully Disabled',
      qr_code_successfully_enabled: 'QR Code Successfully Enabled',
      qr_code: 'QR Code',
      qr_format_incorrect: 'QR Code Format Incorrect',
      qr_name: 'QR Name',
      rate: 'Rate',
      read_agreement: 'Please read the following Agreement carefully',
      reason: 'Reason',
      ref_name: 'Ref Name',
      reference_id: 'Reference Id',
      refresh: 'Refresh',
      refund_reason: 'Refund Reason',
      refund_transaction: 'Refund Transaction',
      refund: 'Refund',
      refunded_at: 'Refunded at',
      refunded: 'Refunded',
      regenerate: 'Re-Generate',
      register_successful: 'Successfully Registered',
      register: 'Register',
      registration: 'Registration',
      reject_reason: 'Reason',
      rejected: 'Rejected',
      remark: 'Remark',
      remarks: 'Remarks',
      report: 'Report',
      reports: 'Reports',
      require_signature_text: 'Require manual signature from customers',
      require_signature: 'Require Signature',
      required_fields: 'Required Fields',
      resend_code: 'Resend Code',
      resend_email: 'Resend Email',
      restricted: 'Restricted',
      resend: 'Resend',
      reset_password_token: 'Reset Password Token',
      reset_password: 'Reset Password',
      residential_address: 'Residential Address',
      retail: 'Retail',
      reversal: 'Reversal',
      reverse_settlement_option: 'Settlement Canceled',
      reverse_settlement: 'Reverse',
      reverse: 'Reverse',
      revoked: 'Revoked',
      role: 'Role',
      sale: 'Sale',
      sales: 'Sales',
      save: 'Save',
      scan_barcode: 'Scan Barcode with a Camera',
      scan_with_yr_phone: 'Scan with following Apps',
      scroll_to_accept_agreement: 'Scroll to bottom to accept the Agreement',
      search_keyword: 'Search by keyword',
      search_store: 'Search Store',
      select_a_store: 'Select a store',
      select_bank_code: 'Select Bank Code',
      select_branch_code: 'Select Branch Code',
      select_companies: 'Filter Companies',
      select_date: 'Select a date',
      select_file: 'Select a file',
      select_gateway: 'Select a gateway',
      select_logo: 'Select a logo',
      select_store_type: 'Select store type',
      select_store: 'Select Store',
      select_stores: 'Filter Stores: eg: id1,id2,id3',
      select: 'Select',
      selected_files: 'Selected {0} file(s)',
      send_new_password: 'Send a new password',
      send: 'Send',
      september: 'September',
      server_error: 'A server error has encountered, please report to us for fix.',
      set_password: 'Set Password',
      set_password_token: 'Reset Password Token',
      set_as_default: 'Set As Default',
      settings: 'Settings',
      settle_amount: 'Settle Amount',
      settle_fee: 'Settlement Fee',
      settled_at: 'Settled at',
      settled: 'Settled',
      settlement_list: 'Settlement',
      settlement_requested_option: 'Settlement Requested',
      settlement_requested: 'Settlement Requested',
      settlement_setting: 'Settlement Setting',
      settlement_status: 'Settlement Status',
      settlement: 'Settlement',
      settlements: 'Settlements',
      setup_payment_gateways: 'Setup Payment Gateways',
      shared: 'Shared file',
      sign_and_upload_addendum: 'Sign and Upload the addendum here',
      sign_in: 'Sign In',
      sign_key: 'Sign Key',
      sms: 'SMS',
      something_wrong_with_file_upload_please_check: 'Some files cannot be uploaded correctly',
      start_date: 'Start Date',
      start_end_with_alphanum: 'Starts and ends with English letters or numbers',
      static_pay_links: 'PermaLink',
      status: 'Status',
      store_address: 'Store Address',
      store_br: 'Store Business Registration',
      store_details: 'Store Details',
      store_details_and_terms: 'Store Details and Terms',
      store_id: 'Store ID',
      store_identifier: 'Store Identifier',
      store_image: 'Store Image',
      store_name: 'Store Name',
      store_offline: 'Offline Store',
      store_online: 'Online Store',
      store_phone: 'Store Phone',
      store_type: 'Store Type',
      store_url: 'Store URL',
      store_was_updated: 'Store was updated!',
      stores_with_api_key: 'Stores with API keys',
      stores_without_api_key: 'Stores without API keys',
      store_term_default_cancellation_en:
        'The transaction herein is one-off in nature. Payment will be processed instantly once you have confirmed your card and other payment details through the gateway and credit card payment will be processed immediately. No change can be made on the order afterwards.',
      store_term_default_cancellation_zh:
        '此交易屬一次性的性質，交易一經確認，已繳付之費用不設退款﹑以及訂單不設取消。在閣下於付款平台確認信用卡及其他付款資料後，交易將會被即時處理。信用卡賬項將會立即自動過數，此後該訂單不能再作出任何更改。',
      store: 'Store',
      store_list: 'Store List',
      stores: 'Stores',
      subject: 'Subject',
      submit: 'Submit',
      success: 'Success',
      successful_disable_autosettlement: 'Successfully Disabled Auto Settlement',
      successful_enabled_autosettlement: 'Successfully Enabled Auto Settlement',
      successful_upload_contract: 'Contract successfully uploaded.',
      successful_upload: 'Upload Successful',
      successfully_update_aml_domain_status_to_pending:
        'Successfully Update Company Register Domain Status to Pending',
      superowner: 'Super Owner',
      taxi_id: 'Taxi ID Plate',
      taxi_image: 'Taxi Image',
      taxi_number: 'Taxi Number',
      taxi_operation_id: 'Taxi Driver ID Plate Number',
      taxi: 'Taxi',
      terminal_code: 'Terminal Code',
      terminated: 'Terminated',
      terms_and_conditions: ' Terms and Conditions',
      terms_of_use: 'Terms of Use',
      terms: 'Terms',
      this_token_can_make_someone_access_to_your_account:
        'Warning: This token can allow others to get access to your account.',
      time: 'Time',
      tip_amount: 'Tip Amount',
      title: 'Title',
      to_choose_new_direction: 'to choose a new direction.',
      to: 'to',
      today_net: "Today's Net",
      today_sales: "Today's Sales",
      token_is_not_found: 'Token is not found. Please contact admin',
      top_sale_day: 'Top Sale Day',
      total_balance: 'Total Balance',
      total_cancel: 'Total Cancel',
      total_charge: 'Total Charge',
      total_number_of_transaction: 'Total Number of Transaction',
      total_paid_amount: 'Total Paid Amount',
      total_paid: 'Total Paid',
      total_refund_amount: 'Total Refund Amount',
      total_refund: 'Total Refund',
      total_transactions: 'Total Transaction',
      total_void_amount: 'Total Void Amount',
      total_void: 'Total Void',
      total: 'Total',
      transaction_details: 'Transaction Details',
      transaction_id: 'Transaction ID',
      transaction_status: 'Transaction Status',
      transaction_timeout: 'Transaction Timeout',
      transaction_type: 'Transaction Type',
      transaction: 'Transaction',
      transactions_latest: 'Latest Transactions',
      transactions_list: 'Transactions List',
      transactions: 'Transactions',
      true: 'True',
      ttp_device: 'Tap-to-phone Device',
      txn_balance_adjustment: 'Balance Correction',
      type: 'Type',
      unauthenticated: 'Unauthenticated',
      under_review: 'Under review',
      unionpay_mex: 'UnionPay Mex',
      unionpay_online: 'UnionPay Online',
      unionpay_pos: 'UnionPay Card Payment (In Store Payment)',
      unionpay_qr: 'UnionPay QR Code (In Store Payment)',
      unionpay_upop: 'Unionpay Upop',
      unionpay: 'UnionPay',
      unit_day: 'day(s)',
      unit_month: 'month(s)',
      unit_year: 'year(s)',
      update_account: 'Update Account',
      update_bank_account: 'Update Bank Account',
      update_code: 'Update Code',
      update_company: 'Update Company',
      update_document: 'Update Document',
      update_document_type: 'Update Document Type',
      update_pay_link: 'Update Pay Link',
      update_qr_code: 'Update QR Code',
      update_store: 'Update Store',
      update_user: 'Update User',
      update: 'Update',
      updated_at: 'Updated at',
      updated_fail: 'Update Fail',
      update_store_detail_and_term: 'Update Store Detail and Terms',
      update_store_term: 'Update Terms',
      upload_addendum: 'Upload Addendum',
      upload_again: 'Re-Upload',
      upload_all_files: 'Please upload all the files required',
      upload_bank_statement: 'Upload Bank Statement',
      upload_br: 'Upload BR',
      upload_ci: 'Upload CI',
      upload_contract: 'Upload Contract',
      upload_document: 'Upload Document',
      upload_file: 'Upload File',
      upload_files_and_try_again: 'Please upload files and try again...',
      upload_hawker_license: 'Upload Hawker License',
      upload_hkid_or_passport_proof: 'Upload hkid / passport proof',
      upload_hkid: 'Upload Hong Kong ID Card',
      upload_ir: 'Upload IR (Section 88)',
      upload_logo: 'Upload Logo',
      upload_new_logo: 'Upload New Logo',
      upload_other_documents: 'Upload Other Documents',
      upload_store_image: 'Upload Store Image',
      upload_taxi_id: 'Upload Taxi ID Plate',
      upload_taxi_image: 'Upload Taxi Image',
      upload_type: 'Upload Type',
      upload_your_br: 'Upload your Company Business Registration. (Size limit: 2MB)',
      upload_your_ci_and_logo: 'Upload your Company Incorporation and Logo. (Size limit: 2MB)',
      upload_your_contract_and_press_upload: 'Upload your signed contract here and press upload',
      upload: 'Upload',
      uploaded: 'uploaded',
      uploading: 'Uploading',
      upper_and_lower_case: 'an Uppercase and a Lowercase letter',
      user_contract: 'User Contract',
      user_has_been_already_activated: 'User has been activated',
      user_information: 'User Information',
      user_management: 'User Management',
      user_profile: 'User Profile',
      user: 'User',
      username: 'Username',
      username_characters: '0-9 a-z A-Z . _',
      users_and_roles: 'Users & Roles',
      using_barcode_scanner: 'Use Barcode Scanner',
      validation_method: 'Validation Method',
      value_amount: 'Value amount',
      value: 'Value',
      view: 'View',
      view_applied_payment_gateway: 'View my payment gateways',
      view_transaction_details: 'View Transaction Details',
      visa_mastercard: 'Visa / Mastercard',
      visa_mastercard_online: 'Visa / Mastercard',
      visa: 'Visa',
      visible: 'Visible',
      void_reason: 'Void Reason',
      void: 'Void',
      voiding: 'Voiding',
      waiting_from_bank: 'Waiting from bank',
      wallet: 'Wallet',
      was_deleted: 'was deleted',
      we_are_sorry: 'We are sorry',
      we_send_activation_code_to_your_email: 'We sent an activation code to your email/phone',
      we_send_verification_code_to_your_email: 'We sent a verification code to your email',
      we_send_verification_code_to_your_phone: 'We sent a verification code to your phone',
      website_placeholder: 'https://www.example.com',
      website: 'Website',
      wechatpay: 'WeChat Pay',
      withdraw: 'Withdraw',
      withdrawal: 'Withdrawal',
      yedpay_contract: 'Yedpay Contract',
      yedpay: 'Yedpay',
      yes: 'Yes',
      you_are_not_authorized: 'You are not authorized',
      you_can_go_back_login_page: 'You can go back login page now',
      you_cannot_delete_yourself: 'You cannot delete your account',
      you_wont_be_able_to_revert_this: "You won't be able to revert this!",
      your_last_activity: 'Your latest activity',
      your_last_withdrawal: 'Your last withdrawal was on dd of mm HKD',
      sweetalert: {
        confirm_button_text: 'OK',
        deny_button_text: 'No',
        cancel_button_text: 'Cancel',
      },
      authorization_id: 'Authorization ID',
      charge: 'Charge',
      captured: 'Captured',
      pre_auth_captured: 'Captured',
      remaining_amount: 'Remaining Amount',
      authorized_at: 'Authorized At',
      captured_at: 'Captured At',
      preAuthStatus: {
        ledger: 'Ledger',
        cancelled: 'Cancelled',
        captured: 'Full Captured',
        partial_captured: 'Partial Captured',
        authorization: 'Authorization',
        authorized: 'Authorized',
        precreated: 'Precreated',
        pending_capture: 'Pending Capture',
        pending: 'Pending',
        pending_card_decision: 'Pending Card Decision',
        failed: 'Failed',
        pending_cancel: 'Pending Cancel',
      },
    },
  },
  zh: {
    locale: 'zh',
    messages: {
      abc_company: 'ABC COMPANY',
      accept_terms_and_conditions: '接受使用條款',
      accept: '接受',
      access_denied: '存取被拒',
      access_token: 'Access Token',
      account_holder: '戶口持有人姓名',
      account_number: '戶口號碼',
      account_summary: '帳戶概覽',
      account: '戶口',
      accountant: '會計',
      actions: '動作',
      activate_account: '啟用帳戶',
      activate_code: '啟用條碼',
      activated: '已啟用',
      activation_type: '啟動方式',
      activation: '啟動',
      active: '啟用',
      Active: '已啟用',
      add_account: '新增戶口',
      add_api_key: '新增API密鑰',
      add_api_key2: '填寫以下資料以新增API密鑰',
      add_code: '新增編碼',
      add_gateway: '新增收款方式',
      add_new_user: '新增用戶',
      add: '新增',
      addendum_is_required_for_application: '申請 Visa / Mastercard 補充協議',
      addendum_is_required_for_application_amex: '申請 American Express 補充協議',
      addendum: '補充協議',
      address: '地址(英文)',
      address_or_url: '地址(英文) / 店鋪網址',
      admin: '管理',
      adyen: '信用卡 (線上支付)',
      affiliation_code_incorrect: '聯繫人編碼不正確',
      alipay_e_commerce: 'Alipay 網上支付',
      alipay_in_store: 'Alipay 當面付',
      alipay_online: '線上支付寶',
      alipay: '支付寶',
      all_companies: '所有公司',
      all_gateways: '所有收款方式',
      all_rights_reserved: '版權所有。',
      all_stores: '所有店鋪',
      all_types: '所有種類',
      all_your_activities: '您所有的動作',
      all: '全部',
      allow_characters: '接受下列符號：',
      allow_special_characters: '接受下列符號：',
      already_have_account: '已有帳戶？',
      aml_domain: '公司註冊網域',
      aml_domains: '公司註冊網域',
      amount: '金額',
      analysis_summary: '總計',
      analysis: '數據分析',
      and: '和',
      api_keys: 'API密鑰',
      app_keys: '應用密鑰',
      applicable_gateways: '可使用的收款方式',
      application_processing: '申請處理中',
      application: '應用程式',
      applied: '已申請',
      apply_a_payment_gateway_first: '立即申請收款方式!',
      apply_filter: '套用',
      apply_for_alipay_e_commerce: '申請 Alipay 網上支付',
      apply_for_alipay: '申請支付寶',
      apply_for_credential: '申請收款方式',
      apply_for_unionpay: '申請銀聯',
      apply_immediately: '立即按此開通',
      apply_status: '申請狀態',
      apply: '立即申請',
      approve_data: '已驗證資料',
      April: '4 月',
      april: '四月',
      are_you_going_to_apply_for: '你即將申請以下收款方式',
      are_you_sure_change_aml_status_pending: '確認要Yedpay重新審視你的公司註冊網域嗎？',
      are_you_sure_change_document_type_to_type: '確認要更改文件類型為{type}嗎？',
      are_you_sure_delete_domain: '確認要移除網域？',
      are_you_sure_delete_device: '確認要移除裝置？',
      are_you_sure_delete_document: '確認要刪除這個文件？',
      are_you_sure_delete_personal_token: '你確認要刪除這個個人 token 嗎？',
      are_you_sure_delete_ttp_device: '確認要移除 Tap-to-phone 裝置？',
      are_you_sure_disable_user: '確認要停用用戶？',
      are_you_sure_disable_ttp_device: '確認要停用 Tap-to-phone 裝置？',
      are_you_sure_delete_user: '確認要移除用戶？',
      are_you_sure_enable_ttp_device: '確認要啟用 Tap-to-phone 裝置？',
      are_you_sure_hide_gateway_from_checkout_page: '確認要在收款頁面隱藏此收款方式?',
      are_you_sure_refund_transaction: '你確定要退款嗎？',
      are_you_sure_regenerate_sign_key: '你確定要重新生產簽名密鑰嗎？',
      are_you_sure_reverse_settlement: '確定要重置清算？',
      are_you_sure_show_gateway_from_checkout_page: '確認要在收款頁面顯示此收款方式?',
      assign_stores: '設定店鋪',
      assign_up_to_5_stores: '每次只可新增 5 間店鋪',
      at_least_8_chars: '至少 8 個英文字母及數字',
      at_least_select_one_page_to_print: '請最少選擇一頁',
      August: '8 月',
      august: '八月',
      authorised: '已授權',
      authorized: '已授權',
      auto_settlement: '自動清算',
      average_monthly_sale: '平均每月銷售',
      average_ticket: '平均金額',
      back: '返回',
      back_camera: '後置鏡頭',
      balance_account: '結餘帳戶',
      balance_available: '可用結餘',
      balance_holding: '待結算',
      balance_settled: '已結算',
      balance: '結餘',
      bank_account: '銀行戶口',
      bank_accounts: '銀行戶口',
      bank_code: '銀行代碼',
      bank_name: '銀行名稱',
      bank_statement: '銀行對帳單',
      bank_transaction_record: '銀行交易紀錄',
      bank_transaction_reference_id: '銀行交易參考編號',
      bank: '銀行',
      barcode_list: '條碼名單',
      barcode: '條碼',
      br: '商業登記證',
      br_branch_no: 'BR 分行號',
      br_number: 'BR 編號',
      branch_code: '分行代碼',
      branch: '分店',
      business_address: '公司地址(英文)',
      business_description: '業務簡介',
      business_nature: '業務性質',
      business_registration: '商業登記證',
      business_setup: '業務建立',
      business_type: '業務種類',
      can_refund: '可退款',
      can_settle_after: '可結算日',
      cancel_reason: '取消原因',
      cancel: '取消',
      canceled: '已取消',
      cancelled_settlement: '交收款取消',
      cancelled_transfer: '取消過戶',
      cancelled: '已取消',
      cancellation_terms_en: '退款/退貨/取消條款 (英文)',
      cancellation_terms_zh: '退款/退貨/取消條款 (中文)',
      cannot_delete_br_ci: '無法刪除 BR 及 CI 檔案',
      cannot_open_camera: '無法打開鏡頭',
      certificate_of_incorporation: '公司註冊證明書',
      change_a_field_to_submit: '請更新資料後再嘗試',
      change_aml_status_pending: '更改公司註冊網域狀態',
      change_default_gateway: '更改預設收款方式',
      change_gateway_display_status: '更改收款方式顯示狀態',
      chargeback: '銀行退款',
      checkout_domain: '付款網域',
      checkout_domains: '付款網域',
      checkout_page: '收款頁面',
      chinese: '中文',
      choose_and_add_gateway: '申請加入以下收款方法',
      choose_business: '選擇業務',
      choose_business_type: '選擇業務種類',
      choose_currency: '選擇貨幣',
      choose_document_type: '選擇文件',
      choose_gateway: '選擇收款方式',
      choose_industry: '選擇行業',
      choose_logo: '選擇頭像',
      choose_role: '選擇角色',
      choose_store_type: '請選擇店鋪類別',
      choose_store: '選擇店鋪',
      choose_type: '選擇類別',
      ci_company_name: 'CI 公司名稱',
      ci_number: 'CI 編號',
      ci: '公司註冊證明書',
      clear_filter: '清除',
      click_below_name_to_copy_token_to_clipboard: '按一下以下名稱複製Token到剪貼簿',
      client: '客戶',
      client_approved_at: '商戶簽署日期',
      close_camera: '關閉鏡頭',
      close: ' 關閉',
      cn: '中國',
      CN: '內地',
      code_CN: '暫時接受內地錢包',
      code_HK: '接受香港錢包',
      code_HKCN: '接受香港及內地錢包',
      code_link: 'QR Code 連結',
      code_name: '編號名稱',
      code: '二維碼',
      company_bank_accounts: '公司銀行帳戶',
      company_id: '公司編號',
      company_info: '公司資料',
      company_name: '公司名稱',
      company_phone: '公司電話',
      company: '公司',
      completed_transfer: '完成過戶',
      completed: '完成',
      confirm_new_password: '確認新密碼',
      confirm_password: '確認密碼',
      confirm_refund: '確定退款',
      confirm_to_cancel_it_as_the_default_fps_account_for_online_payment:
        '取消此收款方式為網上交易的預設轉數快戶口？',
      confirm_to_set_it_as_the_default_fps_account_for_online_payment:
        '設定此收款方式為網上交易的預設轉數快戶口？',
      confirm: '確定',
      confirm_to_cancel: '確定取消',
      cancel_pre_auth: '取消預授權',
      congratulations_account_successfully_updated: '成功更新帳戶信息!',
      congratulations_bank_account_successfully_created: '成功新增銀行帳戶',
      congratulations_bank_account_successfully_updated: '成功更新銀行帳戶',
      congratulations_bank_ccount_successfully_created: '銀行戶口創建',
      congratulations_code_successfully_updated: '成功更新二維碼',
      congratulations_terms_successfully_updated: '成功更新條款',
      congratulations_company_successfully_updated: '成功更新公司信息',
      congratulations_document_successfully_uploaded: '文件上載成功',
      congratulations_generate_sign_key_successful: '成功新增簽名密鑰',
      congratulations_new_code_successfully_created: '已成功新增條碼',
      congratulations_new_store_successfully_created: '成功新增店鋪',
      congratulations_new_user_successfully_created: '成功新增用戶',
      congratulations_new_user_successfully_updated: '成功更新用戶信息',
      congratulations_password_was_success_reset: '你的個人密碼已被重置',
      congratulations_password_was_success_set: '你的個人密碼已設置',
      congratulations_pers_token_successfully_created: '你的個人 Token 已成功創建',
      congratulations_pers_token_successfully_deleted: '你的個人 Token 已成功刪除',
      congratulations_profile_successfully_updated: '成功更新用戶信息',
      congratulations_regenerate_sign_key_successful: '成功更新簽名密鑰',
      congratulations_reverse_settlement_successful: '成功重置結算',
      congratulations_store_successfully_updated: '成功更新店鋪信息',
      congratulations_store_and_terms_successfully_updated: '成功更新店鋪資料及條款',
      congratulations_transaction_successfully_created: '交易成功',
      congratulations_transaction_successfully_refunded: '退款成功',
      congratulations_user_successfully_disabled: '成功刪除用戶',
      congratulations_user_successfully_enabled: '成功重啟用戶',
      congratulations_you_successfully_applied: '申請成功',
      congratulations_your_acc_successfully_activated: '你已成功啟用帳戶!',
      congratulations_your_withdrawal_successfully: '成功提現',
      contact_name: '聯絡人姓名',
      contact_phone: '聯絡電話',
      contains_1_digit: '包含最少一個數字',
      contains_8_characters: '包含最少八個字符',
      contains_8_20_characters: '包含八至二十個字符',
      contains_correct_characters: '特殊字符只接受句點(.) 和底線(_)，但不可相連',
      contains_upper_and_lower_case: '包含最少一個大寫及小寫字母',
      contract: '合約',
      contract_type: '合約種類',
      copied_at_your_clipboard: '已複製到你的剪貼板',
      could_not_process: '無法處理',
      country_code: '地區號碼',
      create_account: '創建帳戶',
      create_an_account: '建立帳戶',
      create_bank_account: '創建銀行戶口',
      create_qr_code: '創建二維碼',
      create_device: '創建裝置',
      create_pay_link: '創建 Pay Link',
      create_personal_token: '創建個人 Token',
      create_store: '建立店鋪',
      create_token: '創建 Token',
      create_transaction_failed: '建立交易失敗',
      create_transaction: '建立交易',
      create_user: '創建使用者',
      create: '建立',
      created_at: '創建日期',
      creation_date: '建立日期',
      credential_list: '憑證列表',
      credit_card: '信用卡',
      cross_border_rate: '海外費率',
      currency_amount: ' 貨幣金額',
      currency: '貨幣',
      custom_id: '自定編號',
      cybersource: '信用卡 (店內支付)',
      date_of_birth: '出生日期',
      date_of_establishment: '成立日期',
      date: '日期',
      date_range_last_7_days: '過去 7 日',
      date_range_last_30_days: '過去 30 日',
      date_range_last_1_month: '過去 1 月',
      date_range_last_3_months: '過去 3 月',
      date_range_last_6_months: '過去 6 月',
      date_range_today: '今天',
      date_range_this_year: '本年度',
      date_range_last_year: '上年度',
      date_range_all_date: '所有日子',
      date_range_custom_range: '自定日子',
      day: '日',
      deactivate_code: '停用條碼',
      December: '12 月',
      december: '十二月',
      declaration: '聲明',
      default_gateway: '預設收款方式',
      default_store: '默認店鋪',
      define_name: '自定義名稱',
      define_your_name: '自定義名稱',
      delete_account: '刪除帳戶',
      delete_aml_domain: '刪除公司註冊網域',
      delete_domain: '刪除付款網域',
      delete_device: '刪除裝置',
      delete_personal_token: '刪除個人 Token',
      delete_profile: '刪除簡介',
      delete_store: '移除店鋪',
      delete_token: '刪除token',
      delete_ttp_device: '刪除 Tap-to-phone 裝置',
      delete_user: '刪除用戶',
      delete: '刪除',
      delivery_terms: '送貨天數',
      device_list: '裝置名單',
      device_name: '裝置名稱',
      device_was_deleted: '裝置已被刪除',
      device: '裝置',
      disable_user: '停用用戶',
      disable_ttp_device: '停用 Tap-to-phone 裝置',
      disable: '停用',
      disabled: '停用',
      display_name: '顯示名稱',
      district: '地區',
      do_not_have_account: '沒有帳戶',
      document: '文件',
      document_company_name: '文件上的公司名稱',
      document_deleted: '文件已成功刪除',
      document_name: '文件名稱',
      document_type: '文件類別',
      documents: '文件',
      documents_list: '檔案名單',
      domain_display_name: '網域顯示名稱',
      domain_id: '網域ID',
      domain_name: '網域',
      domain_type: '網域種類',
      done: '完成',
      dont_have_any_acc: '尚未註冊',
      download_addendum: '下載補充協議',
      download_as_excel: '下載 Excel',
      download_as_pdf: '下載 PDF',
      download_contract: '下載合約',
      download_printable: '下載列印格式',
      download: '下載',
      driving_license: '駕駛執照號碼',
      duplicate_file_uploaded: '重覆上載檔案',
      dynamic_pay_links: '單次連結',
      e_commerce: 'E-Commerce',
      edit_code: '編輯編碼',
      edit_credential_successful: '成功更新收款方式',
      edit_credential: '更新收款方式',
      edit_gateway: '設定收款方式',
      edit_store: '編輯店鋪',
      edit_user: '更改用戶',
      edit: '編輯',
      email_or_username: '電郵或用戶名稱',
      email: '電郵',
      empty: '空',
      enable_user: '啟用用戶',
      enable_ttp_device: '啟用 Tap-to-phone 裝置',
      enable: '啟用',
      enabled: '啟用',
      end_date: '結束日期',
      english: 'English',
      enter_account_holder_name: '輸入戶口持有人姓名',
      enter_account_number: '輸入戶口號碼',
      enter_activate_code: '輸入啟用條碼',
      enter_address: '請輸入地址',
      enter_amount: '輸入金額',
      enter_api_key_name: '輸入密鑰名稱',
      enter_bank_name: '請輸入銀行名稱',
      enter_barcode: '請輸入條碼',
      enter_br_branch_no: '輸入BR 分行號',
      enter_br_expiry_date: '輸入BR 到期日 ( 日 / 月 / 年 )',
      enter_br_number: '輸入BR 編號',
      enter_business_address: '輸入公司地址',
      enter_ci_company_name: '輸入CI 公司名稱',
      enter_ci_number: '輸入CI 編號',
      enter_country_code: '輸入地區號碼',
      enter_date_of_birth: '輸入出生日期 ( 日 / 月 / 年 )',
      enter_date_of_establishment: '輸入成立時間',
      enter_display_name: '請輸入顯示名稱',
      enter_domain_display_name: '輸入網域顯示名稱',
      enter_domain_name: '輸入網域',
      enter_domain_type: '輸入網域種類',
      enter_first_name: '輸入名',
      enter_hawker_operation_id: '輸入小販牌照號碼',
      enter_hkid_or_passport_number: '輸入香港身份證 或 護照號碼',
      enter_ir_number: '輸入IR 編號',
      enter_last_name: '輸入姓',
      enter_license_number: '輸入駕駛執照號碼',
      enter_nationality: '輸入國籍',
      enter_password: '輸入密碼',
      enter_percentage_owned: '輸入所有權百分比',
      enter_phone: '輸入電話',
      enter_position: '輸入職位',
      enter_store_address: '輸入店鋪地址',
      enter_store_name: '輸入店鋪名稱',
      enter_store_phone: '輸入店鋪電話',
      enter_store_url: '輸入網址',
      enter_taxi_number: '輸入的士車牌號碼',
      enter_taxi_operation_id: '輸入的士司機證號碼',
      enter_username: '輸入用戶名稱',
      enter_your_average_monthly_sale: '輸入平均每月銷售',
      enter_your_average_ticket: '輸入平均金額',
      enter_your_business_description: '輸入業務簡介',
      enter_your_company_name: '輸入你的公司名稱',
      enter_your_company_phone: '輸入你的公司電話',
      enter_your_contact_mobile: '輸入聯絡人電話',
      enter_your_contact_name: '輸入聯絡人姓名',
      enter_your_email_address_or_phone: '輸入你的電子郵件或電話號碼',
      enter_your_email_address: '輸入你的電子郵件',
      enter_your_email_or_phone_and_new_password: '輸入你的電郵 / 電話及新密碼',
      enterprise: '公司',
      error_duration_must_be_less_equal_than: '時間長度必需少於或等於 {0} {1}',
      error_file_size_must_be_less_than: '檔案必須少於 {0}',
      error_invalid_account_holder_format:
        '銀行戶口持有人格式錯誤，只接受大階英文字母、數字、空白鍵及._符號。',
      error_invalid_address_format: '地址格式錯誤，只接受英文、數字及-,/.&()\'"符號。',
      error_invalid_date_format: '日期格式錯誤',
      error_invalid_domain_format: '域名格式錯誤',
      error_invalid_email_format: '電郵格式錯誤',
      error_invalid_expiry_date: '到期日錯誤',
      error_invalid_password_format: '密碼格式錯誤',
      error_invalid_username_format: '用戶名稱格式錯誤',
      error_invalid_url_format: '網址格式錯誤',
      error_length_must_be_equal: '長度必需等於 {0} 字',
      error_length_must_be_equal_multi: '長度必需等於 {0} 或 {1} 字',
      error_length_must_be_less_equal_than: '長度必需少於或等於 {0} 字',
      error_length_must_between: '長度必需為 {0} - {1} 字',
      error_must_be_digits: '必需為數字',
      error_must_be_less_equal_than_files: '必需少於或等於 {0} 個檔案',
      error_must_be_greater_equal_than: '必需大於或等於 {0}',
      error_must_be_greater_than: '必需大於 {0}',
      error_must_between: '必需為 {0} - {1}',
      error_password_mismatch: '密碼不匹配',
      error_invalid_delivery_time_format: '送貨天數格式錯誤，只接受數字及有-在中間的兩個數字。',
      error_required: '必須填寫',
      error: '錯誤',
      expired: '已過期',
      expired_at: '到期日',
      expiry_date: '到期日',
      export_settlements: '導出結算表',
      export_transaction: '導出交易記錄',
      export: '匯出',
      face_to_face_payment: '面對面交易',
      failed_reason: '失敗原因',
      failed_to_upload_document: '上傳檔案失敗',
      failed_to_generate_report: '報告產生失敗',
      failed: '交易失敗',
      false: '否',
      fee: '費用',
      file_type: '文件格式',
      file_format_incorrect: '文件格式不正確',
      filter: '篩選',
      finance: '財政',
      find_code: '搜索定額 QR',
      first_name: '名',
      fixed_qr_code: '定額 QR Code',
      food: '飲食',
      forex: '匯率',
      forgot_password: '忘記密碼',
      fps: '香港滙豐銀行轉數快',
      fps_clearing_code: 'FPS 收款銀行代碼',
      fps_dbs_notice: '請輸入於 DBS MAX 帳戶配對之轉數快戶口資料。',
      fps_dbs_offline: 'DBS MAX FPS Offline',
      fps_dbs_online: 'DBS MAX FPS Online',
      fps_email: '已登記的轉數快電郵',
      fps_gateway: 'FPS 收款方式',
      fps_id: '轉數快號碼 (由 7 或 9 位數字組成)',
      fps_hsbc_offline: 'HSBC 線下轉數快 (EMV 二維碼)',
      fps_hsbc_online: 'HSBC 線上轉數快',
      fps_merchant_id: '轉數快商戶編號',
      fps_org_id: '公司編號 (結尾為1MS)',
      fps_phone: '已登記的轉數快電話號碼',
      fps_notice: '請輸入於 滙豐「智豐收」帳戶配對之轉數快戶口資料。',
      fps_account_info: '轉數快帳戶資料',
      from: '從',
      front_camera: '前置鏡頭',
      gateway_fee: '交易費用',
      gateway_id: '方式ID',
      gateway_name: '收款方式',
      gateway_reference_id: '參考編號',
      gateway_type: '收款類型',
      gateway: '收款方式',
      gateways: '收款方式',
      generate: '生產',
      generated_at: '生產日期',
      generate_csv_report: '生產 CSV 報告',
      generate_excel_report: '生產 Excel 報告',
      generate_pdf_report: '生產 PDF 報告',
      generating_report: '報告產生中',
      get_code: '取得啟用條碼',
      go_to_this_export: '去匯出',
      go_to: '去',
      gross_amount: '總額',
      group_by_company: '以公司查看',
      group_by_gateway: '以收款方式查看',
      group_by_mcc: '以MCC查看',
      group_by_store: '以店鋪查看',
      group_by: '查看',
      handled_person: '處理人',
      hawker_license: '小販牌照',
      hawker_operation_id: '小販牌照號碼',
      hidden: '隱藏',
      hk: '香港',
      HK: '香港',
      OVERSEAS: '非本地',
      HKCN: '香港及內地',
      hkid_or_passport_number: '香港身份證 或 護照號碼',
      hkid_or_passport_proof: '香港身份證 或 護照証明文件',
      hkid_v1: '香港身份證',
      hkid_v2: '新香港身份證',
      hkid: '香港身份證',
      holder: '持有人',
      holding_amount: '總結餘',
      hotel: '酒店',
      i_have_read_and_understand: '已閱讀並同意',
      if_you_choose_to_continue_you_agree_to_our: '如果選擇繼續，表示你同意',
      if_you_didnt_receive_code: '沒有收到啟用帳戶編碼？',
      if_you_want_to_create_please_click_to_create_button:
        '如果您需要創建自己的 token, 請按 "建立"提交',
      if_you_want_to_create_please_enter_the_name_of_new_token:
        '如果您想創建，請輸入新 token 的名稱。',
      img_size_less_than_2mb: '檔案必須少於 2MB',
      inactive: '停用',
      Inactive: '已停用',
      include_a_no: '包含至少一個數字',
      individual: '個人',
      invalid_credentials: '無效憑證',
      ir_registration: '慈善機構及慈善信託註冊證書(88牌)',
      ir: '慈善機構及慈善信託註冊證書(88牌)',
      ir_number: 'IR 編號',
      is_fixed_amount: '是否定額',
      is_permanent: '是永久的',
      January: '1 月',
      january: '一月',
      July: '7 月',
      july: '七月',
      June: '6 月',
      june: '六月',
      language: '語言',
      last_name: '姓',
      last_used: '上次使用時間',
      last_withdrawal_was_on: '最後的提款是於:',
      legal_name: '法定名稱',
      loading: '載入中...',
      load_more: '載入更多',
      login: '登入',
      login_failed: '登入失敗',
      logo: '商標',
      logout: '登出',
      main_page: '主頁',
      main: '主要',
      main_account: '主要帳戶',
      manage_user: '管理用戶',
      management: '管理',
      manual_settlement: '手動清算',
      March: '3 月',
      march: '三月',
      mastercard: 'Mastercard',
      May: '5 月',
      may: '五月',
      mcc_description: 'MCC 名稱',
      mcc: 'MCC',
      my_bank: '我的銀行',
      my_codes: '我的二維碼',
      pre_authorize: '預授權',
      pre_authorize_details: '預授權詳細',
      my_gateways: '收款方式資訊',
      my_personal_token: '我的個人 token',
      na: '不適用',
      name: '名稱',
      nationality: '國籍',
      net: '凈額',
      new_api_key_notice: '只會顯示一次，請確保已妥善保存',
      new_api_key: '你的API密鑰',
      new_orders: '新訂單',
      new_password: '新密碼',
      new_token_name: '新 token 名稱',
      new: '全新登場',
      next: '下一頁',
      nickname: '自定義名稱',
      ngo: '慈善機構及慈善信託',
      no_data: '沒有資料！',
      no_gateway_available_for_qr_code:
        '二維碼只適用於線上Alipay、WeChat Pay、Visa / Mastercard、銀聯及 FPS，上述收款方式尚未開通或未曾申請。',
      no_gateway_available_for_qr_code_offline:
        '二維碼只適用於線下Alipay、WeChat Pay、銀聯及 FPS，上述收款方式尚未開通或未曾申請。',
      no_logos: '沒有頭像',
      no_recent_settlements: '沒有最近的清算',
      no_recent_transactions: '沒有最近的交易',
      no_result: '沒有資料',
      no_sign_key: '沒有簽名密鑰.',
      no_stores: '沒有店鋪',
      no_transaction_found: '找不到任何交易記錄',
      no: '否',
      normal: '普通',
      not_applied: '尚未開通',
      not_shared: '非公開檔案',
      not_enough_balance: '餘額不足',
      not_fixed_amount: '非定額金額',
      not_uploaded: '未上傳',
      November: '11 月',
      november: '十一月',
      number: '號碼',
      obsoleted: '已過時',
      ocr_fail_msg: '請上載清晰及正確的文件後再試',
      ocr_failed: '光學字符識別失敗',
      October: '10 月',
      october: '十月',
      octopus: '八達通',
      offline: '面對面支付',
      offline_gateway_agreement: '線下收款方式合約',
      offline_store_only: '只適用於實體店鋪',
      on_verification: '驗證中',
      one_week: '1 星期',
      online: '線上支付',
      online_gateway_agreement: '線上收款方式合約',
      online_gateways: '線上收款方式',
      Online: '線上',
      online_store_only: '只適用於線上店鋪',
      only_available_for_alipay: '只適用於線下支付寶',
      only_support_image: '只接受相片檔',
      only_support_image_or_pdf: '只接受相片或PDF檔',
      oops: '哎呀, 網頁發生錯誤...',
      open_camera: '開啟鏡頭',
      operations: '營運',
      operator: '操作員',
      or_back_to: '或回到',
      order: '排序',
      order_sort: '順序方式',
      asc: '順序',
      desc: '倒序',
      other: '其他',
      other_agreement: '其他合約',
      other_contract: '其他合約',
      others: '其他',
      owner: '擁有人',
      page_does_not_exist: '找不到頁面',
      page_not_found: '找不到頁面',
      page: '頁面',
      pagination_total: '顯示{from}至{to}, 共{size}筆資料',
      paid_at: '支付日期',
      paid: '已付款',
      passport: '護照証明文件',
      password_characters: '~!@#$%^&*()=[]{};?',
      password_expired: '密碼已過期',
      password_hints: '密碼必須包含至少8個字符，包括數字，大寫和小寫英文字母',
      password_must_contain: '密碼必須包含',
      password_will_expire_cancel_text: '以後再說',
      password_will_expire_msg: '請按確定以進行密碼變更',
      password_will_expire: '密碼在{days}天內失效',
      password: '密碼',
      pay_link: 'Pay Link',
      pay_links: 'Pay Links',
      payer: '客戶',
      pay_plus: 'Pay by Bank',
      pay_plus_transactions: 'Pay by Bank 交易紀錄',
      pay_plus_payment_gateway: 'Pay by Bank 收款方式',
      payment_cards_group_limited: '交易寶有限公司。',
      payment_gateways: '收款方式',
      payment_qr_code: '付款 QR Code',
      payment_tech_provided_by_yedpay: '技術服務提供商',
      payment_time: '交易時間',
      pending_approve: '申請等候中',
      pending_refund: '等候退款中',
      pending: '等待中',
      pending_capture: '等候扣款',
      percentage_owned: '所有權百分比',
      permission_name: '授權名稱',
      permission: '權限',
      personal_token_at_your_clipboard: '已複製 Token 到你的剪貼板',
      personal_token_effective_in_1_year:
        '個人Token的有效期為一年，如果你創建新的Token，舊的也會失效',
      personal_token: '個人 token',
      phone: '電話',
      picture: '圖片',
      platform: '平台',
      please_apply_offline_gateway: '請申請最少一個線下收款方式，例如 Alipay 或 WeChat Pay',
      please_change_field_to_update: '請更新資料後再提交',
      please_change_password: '請更新密碼後再重新登入',
      please_choose_document_type: '請選擇文件類別',
      please_choose_file_to_upload: '請選擇想要上載的文件',
      please_choose_store: '請選擇店鋪',
      please_enter_letter_without_the_bracket:
        '輸入所有英文字母，無須輸入括號及標點符號。例如你的公司名稱是 ABC (HK) Co. Limited，則輸入 ABC HK CO LIMITED；',
      please_fill_out_all_the_forms: '請填妥所有表格',
      please_note_only_one_fps_online_gateway_can_be_enabled: '請注意FPS網上收款只可選一款收款方式',
      please_read_and_sign_contract: '請閱讀及簽署下述合約並上傳作申請用途',
      please_relogin: '請重新登入',
      please_setup_bank_account: '請先設立銀行戶口',
      please_try_again_after_sec: '請在{sec}秒後再試',
      plsease_choose_currency: '請選擇貨幣',
      position: '職位',
      precreated: '預先創定',
      preview: '預覽',
      previous: '上一頁',
      print: '列印',
      privacy_policy: '隱私政策',
      processing_settlement_cannot_cancel: '清算處理中，無法取消。',
      processing_time: '申請處理時間約需一星期。',
      processing: '處理中',
      profile_info: '個人資料',
      profile: '設定',
      qr_code_successfully_disabled: 'QR Code 已成功停用',
      qr_code_successfully_enabled: 'QR Code 已成功啟用',
      qr_code: 'QR Code',
      qr_format_incorrect: '編碼格式不正確',
      qr_name: 'QR Code 名稱',
      rate: '費率',
      read_agreement: '請細閱以下的條款及細則',
      reason: '原因',
      ref_name: '附註',
      reference_id: '參考編號',
      refresh: '刷新',
      refund_reason: '退款原因',
      refund_transaction: '處理退款',
      refund: '退款',
      refunded_at: '退款日期',
      refunded: '商戶退款',
      regenerate: '重新生產',
      register_successful: '已成功註冊',
      register: '註冊',
      registration: '註冊',
      reject_reason: '原因',
      rejected: '拒絕',
      remark: '備註',
      remarks: '備註',
      reports: '報告',
      report: '報告',
      require_signature: '保留簽名',
      require_signature_text: '保留簽名結帳（消費者要在簽帳單上簽名）',
      required_fields: '必須填寫',
      resend_code: '重新傳送編碼',
      resend_email: '重發郵件',
      restricted: '受限制',
      resend: '重發',
      reset_password_token: '重設密碼金鑰',
      reset_password: '重設密碼',
      residential_address: '住宅地址',
      retail: '零售',
      reversal: '已退還',
      reverse_settlement_option: '交收款取消',
      reverse_settlement: '取消清算',
      reverse: '倒轉',
      revoked: '已撤銷',
      role: '角色',
      sale: '銷售',
      sales: '銷售額',
      save: '儲存',
      scan_barcode: '使用鏡頭掃描 Barcode',
      scan_with_yr_phone: '打開手機掃一掃繼續付款',
      scroll_to_accept_agreement: '滾動到底部以接受條款',
      search_keyword: '輸入關鍵字搜索',
      search_store: '搜尋店鋪',
      select_a_store: '選擇店鋪',
      select_bank_code: '選擇銀行代碼',
      select_branch_code: '選擇分行代碼',
      select_companies: '選擇公司',
      select_date: '選擇日期',
      select_file: '選擇檔案',
      select_gateway: '選擇收款方式',
      select_logo: '選擇商標',
      select_store_type: '選擇店鋪類型',
      select_store: '選擇店鋪',
      select_stores: '選擇店鋪: eg: id1,id2,id3',
      select: '請選擇',
      selected_files: '已選擇 {0} 檔案',
      send_new_password: '傳送新的密碼',
      send: '傳送',
      September: '9 月',
      september: '九月',
      server_error: '伺服器發生了錯誤，請與我們聯絡。',
      set_password: '設置密碼',
      set_password_token: '設置密碼金鑰',
      set_as_default: '設為預設',
      settings: '設定',
      settle_amount: '清算金額',
      settle_fee: '清算收費',
      settled_at: '清算日期',
      settled: '已清算',
      settlement_list: '清算',
      settlement_requested: '已請求清算',
      settlement_setting: '清算設定',
      settlement_status: '結算狀態',
      settlement: '清算',
      settlements: '清算',
      setup_payment_gateways: '設定收款方式',
      shared: '公開檔案',
      sign_and_upload_addendum: '簽署補充協議並在此上載',
      sign_in: '登入',
      sign_key: '簽名密鑰',
      sms: '短訊',
      something_wrong_with_file_upload_please_check: '部分文件上載出現異常',
      start_date: '開始日期',
      start_end_with_alphanum: '以英文字母或數字開頭、結尾',
      static_pay_links: '分店連結',
      status: '狀態',
      store_address: '店鋪地址(英文)',
      store_br: '店鋪商業登記證',
      store_details: '店鋪資料',
      store_details_and_terms: '店鋪資料及條款',
      store_id: '店鋪ID',
      store_identifier: '店鋪識別碼',
      store_image: '店鋪圖片',
      store_name: '店鋪名稱',
      store_offline: '實體店鋪',
      store_online: '網上店鋪',
      store_phone: '店鋪電話',
      store_type: '店鋪類型',
      store_url: '店鋪網址',
      store_was_updated: '店鋪已更新',
      stores_with_api_key: '有API密鑰的店鋪',
      stores_without_api_key: '沒有API密鑰的店鋪',
      store_term_default_cancellation_en:
        'The transaction herein is one-off in nature. Payment will be processed instantly once you have confirmed your card and other payment details through the gateway and credit card payment will be processed immediately. No change can be made on the order afterwards.',
      store_term_default_cancellation_zh:
        '此交易屬一次性的性質，交易一經確認，已繳付之費用不設退款﹑以及訂單不設取消。在閣下於付款平台確認信用卡及其他付款資料後，交易將會被即時處理。信用卡賬項將會立即自動過數，此後該訂單不能再作出任何更改。',
      store: '店鋪',
      store_list: '店鋪名單',
      stores: '店鋪',
      subject: '標題',
      submit: '確認',
      success: '成功',
      successful_disable_autosettlement: '已成功停用自動清算',
      successful_enabled_autosettlement: '已成功啟用自動清算',
      successful_upload_contract: '已成功上傳合約',
      successfully_update_aml_domain_status_to_pending: '已成功更新公司註冊網域至「等待中」',
      successful_upload: '已成功上傳',
      superowner: '超級用戶',
      taxi_id: '的士司機證',
      taxi_image: '的士圖片',
      taxi_number: '的士車牌號碼',
      taxi_operation_id: '的士司機證號碼',
      taxi: '的士',
      terminal_code: '終端編號',
      terminated: '取消服務',
      terms_and_conditions: '使用條款',
      terms_of_use: '服務條款',
      terms: '條款',
      this_token_can_make_someone_access_to_your_account:
        '請注意: 此Token可讓其他人獲取您的個人信息。',
      time: '時間',
      tip_amount: '小費金額',
      title: '標題',
      to_choose_new_direction: '選擇新方向',
      to: '至',
      today_net: '今天凈額',
      today_sales: '今天銷售額',
      token_is_not_found: '找不到金鑰，請聯絡管理員',
      top_sale_day: '總銷售日子',
      total_balance: '總結餘',
      total_cancel: '總取消',
      total_charge: '總收費',
      total_number_of_transaction: '總交易次數',
      total_paid_amount: '總成功交易金額',
      total_paid: '總成功交易',
      total_refund_amount: '總退款金額',
      total_refund: '總退款',
      total_transactions: '總交易',
      total_void_amount: '總撤銷金額',
      total_void: '總撤銷',
      total: '總計',
      transaction_details: '交易詳細',
      transaction_id: '交易編號',
      transaction_status: '交易狀態',
      transaction_timeout: '交易已逾時',
      transaction_type: '交易類型',
      transaction: '交易',
      transactions_latest: '最新交易記錄',
      transactions_list: '交易名單',
      transactions: '交易記錄',
      true: '是',
      ttp_device: 'Tap-to-phone 裝置',
      txn_balance_adjustment: '結餘修正',
      type: '種類',
      unauthenticated: '驗證失敗',
      under_review: '審視中',
      unionpay_mex: '銀聯MEX',
      unionpay_online: '線上銀聯',
      unionpay_pos: '銀聯信用卡 (店內支付)',
      unionpay_qr: '雲閃付 (店內支付)',
      unionpay_upop: '銀聯UPOP',
      unionpay: '銀聯',
      unit_day: '日',
      unit_month: '月',
      unit_year: '年',
      update_account: '更新帳戶',
      update_bank_account: '更新銀行戶口',
      update_code: '已更新 Code',
      update_company: '更新公司',
      update_document: '更新文件',
      update_document_type: '更新文件類別',
      update_store: '更新店鋪',
      update_user: '更新用戶',
      update: '更新',
      update_pay_link: '更新 Pay Link',
      update_qr_code: '更新二維碼',
      updated_at: '更新日期',
      updated_fail: '更新失敗',
      update_store_detail_and_term: '更新店鋪資料及條款',
      update_store_term: '更新條款',
      upload_addendum: '上載補充協議',
      upload_again: '重新上載',
      upload_all_files: '請上載所需之文件',
      upload_bank_statement: '上載銀行對帳單',
      upload_br: '上載商業登記證',
      upload_ci: '上載公司註冊證明書',
      upload_contract: '上載合約',
      upload_document: '上傳文件',
      upload_file: '上載文件',
      upload_files_and_try_again: '請上載文件後再試一次...',
      upload_hawker_license: '上載小販牌照',
      upload_hkid_or_passport_proof: '上載香港身份證 或 護照証明文件',
      upload_hkid: '上載香港身份證',
      upload_ir: '上載慈善機構及慈善信託註冊證書(88牌)',
      upload_logo: '更新頭像',
      upload_new_logo: '上載商標',
      upload_other_documents: '上載其他文件',
      upload_store_image: '上載店鋪圖片',
      upload_taxi_id: '上載的士司機證',
      upload_taxi_image: '上載的士圖片',
      upload_type: '上傳方式',
      upload_your_br: '上載你的商業登記證。 (大小限制: 2MB)',
      upload_your_ci_and_logo: '上載你的公司註冊，商標等。 (大小限制: 2MB)',
      upload_your_contract_and_press_upload: '選擇已簽署的合約檔案並按上載',
      upload_your_documents: '上載你的公司註冊文件(商業登記證，公司註冊，商標等)。 (大小限制: 2MB)',
      upload: '上載',
      uploaded: '已上傳',
      uploading: '上載中',
      upper_and_lower_case: '一個大寫子母及一個小寫子母',
      user_contract: '用戶合約',
      user_has_been_already_activated: '您已經激活了帳戶',
      user_information: '用戶資料',
      user_management: '用戶管理',
      user_profile: '用戶簡介',
      user: '用戶',
      username: '用戶名稱',
      username_characters: '0-9 a-z A-Z . _',
      users_and_roles: '使用者及角色',
      using_barcode_scanner: '使用條碼閱讀器',
      validation_method: '驗證方法',
      value_amount: '價值量',
      value: '金額',
      view: '查看',
      view_applied_payment_gateway: '查看已申請的收款方式',
      view_transaction_details: '查看交易詳情',
      visa_mastercard: 'Visa / Mastercard',
      visa_mastercard_online: 'Visa / Mastercard',
      visa: 'Visa',
      visible: '顯示',
      void_reason: '撤銷原因',
      void: '已撤銷',
      voiding: '撤銷中',
      waiting_from_bank: '等待銀行處理',
      wallet: '錢包',
      was_deleted: '已刪除',
      we_are_sorry: '對不起',
      we_send_activation_code_to_your_email: '已傳送啟用帳戶編碼至你的電郵/電話',
      we_send_verification_code_to_your_email: '我們已發送驗證碼到你的郵箱',
      we_send_verification_code_to_your_phone: '我們已發送驗證碼到你的電話號碼',
      website_placeholder: 'https://www.example.com',
      website: '網頁',
      wechatpay: '微信支付',
      wechatpay_online: '線上微信支付',
      withdraw: '提款',
      withdrawal: ' 提款',
      yedpay_contract: 'Yedpay 合約',
      yedpay: 'Yedpay',
      yes: '是',
      you_are_not_authorized: '你並未獲授權',
      you_can_go_back_login_page: '你現在可返回登入頁面',
      you_cannot_delete_yourself: '你不能刪除自己的戶口',
      you_wont_be_able_to_revert_this: '此動作無法逆轉',
      your_last_activity: '您最後的動作',
      your_last_withdrawal: '您最後的提款是於 月 日 HKD',
      sweetalert: {
        confirm_button_text: '確定',
        deny_button_text: '否定',
        cancel_button_text: '取消',
      },
      authorization_id: '預授權編號',
      charge: '收費',
      captured: '已收款',
      pre_auth_captured: '已扣除款項',
      remaining_amount: '剩餘金額',
      authorized_at: '授權日期',
      captured_at: '收款日期',
      preAuthStatus: {
        ledger: '已授權總額',
        cancelled: '交易取消',
        captured: '全數扣除',
        partial_captured: '扣除款項 （部分）',
        authorization: '預授權支付',
        authorized: '已授權',
        precreated: '已建立',
        pending: '等待中',
        pending_capture: '等待扣款',
        pending_card_decision: '等待回應中',
        failed: '失敗',
        pending_cancel: '等待取消',
      },
    },
  },
};
