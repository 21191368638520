import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { applyCredentialByType } from 'actions/GatewayActions';
import { CreateAmlDomainModal } from 'components/Admin/aml_domains/Modal';
import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import { reactDistrictsOptions } from 'constants/district';
import { OFFLINE_STORE, ONLINE_STORE } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadOptions } from 'react-select-async-paginate';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FunctionDispatch } from 'redux/types';
import { getAPI as getAmlDomainAPI } from 'services/API/Aml_Domain';
import { createCompanyStore } from 'services/API/Company';
import { CompanyStoreProps, CreateCompanyStoreProps } from 'services/API/Company/interface';
import { updateStore as apiUpdateStore } from 'services/API/Store';
import { validate } from './validator';

export interface StoreModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setStore: (store: CompanyStoreProps | undefined) => void;
  logos: Array<SelectOptionProps>;
  store?: CompanyStoreProps;
  reloadStore: () => Promise<void>;
  company_id: string;
}

const initialState = {
  name: '',
  phone: '',
  email: '',
  address: '',
  logo: '',
  type: 1,
  domains: [],
  district_id: 0,
};

const valueToLabel = (value: any) => {
  if (!value) {
    return { label: '', value: null };
  }
  value.toString = () => value.id;
  return {
    label: `${value.protocol}://${value.domain}`,
    value: value,
  };
};
export const StoreModal: FC<StoreModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  setStore,
  logos,
  store,
  reloadStore,
  company_id,
}: StoreModalProps) => {
  const dispatch = useDispatch<FunctionDispatch>();
  const [data, setData] = useState<CreateCompanyStoreProps>(initialState);
  const [agreeTerms, setAgreeTerms] = useState(0);
  const [errors, setErrors] = useState<any>({});
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const { name, phone, email, address, logo, type, district_id } = data as CreateCompanyStoreProps;

  const handleClose = () => {
    setErrors({});
    setData(initialState);
    setAgreeTerms(0);
    setStore(undefined);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (store) {
      updateStore(type);
    } else {
      createStore(type);
    }
  };

  const createStore = async (type: number) => {
    const body: any = {
      name,
      phone,
      email,
      address,
      logo,
      type,
      district_id,
    };
    if (type === ONLINE_STORE) {
      body.domains = data.domains?.map((d) => d.id);
    }
    body.address = body.address && body.address.trim().replace(/['"]/g, '');

    const { errors, isValid } = validate({ ...body, agreeTerms }, type);
    setErrors(errors);
    if (!isValid) {
      return;
    }

    const res = await createCompanyStore(company_id, body);
    if (!res.success) {
      Swal.fire({
        title: translate('create_store'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    if (type === ONLINE_STORE) {
      const applyOnlineGatewayRes = await dispatch(applyCredentialByType(company_id, { type: 2 }));

      if (!applyOnlineGatewayRes.success) {
        Swal.fire({
          title: translate('create_store'),
          text: applyOnlineGatewayRes?.message || 'Oops',
          icon: 'error',
        });
        return;
      }
    }
    await reloadStore();
    toggle();
  };

  const updateStore = async (type: number) => {
    let body: any = {
      name,
      phone,
      email,
      logo,
    };
    if (type === ONLINE_STORE) {
      body.domains = data.domains?.map((d) => d.id);
    } else if (type === OFFLINE_STORE) {
      body = { ...body, district_id, address };
      body.address = body.address && body.address.trim().replace(/['"]/g, '');
    }

    const { errors, isValid } = validate({ ...body, agreeTerms: 1 }, type);
    setErrors(errors);
    if (!isValid) {
      return;
    }

    const res = await apiUpdateStore(store?.id ?? '', body);
    if (!res.success) {
      Swal.fire({
        title: translate('update_store'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    await reloadStore();
    toggle();
  };

  const searchAmlDomain: LoadOptions<any, any> = async (keyword, loadedOptions, additional) => {
    const page = additional?.page || 1;
    const result = await getAmlDomainAPI(company_id).getAll({
      page,
      domain: keyword.toLowerCase(),
    });
    const hasMore = !!result?.meta?.pagination?.links.next ?? false;
    const selectedDomainIdArr = data.domains?.map((s) => s.id) ?? [];
    const domains = result.data.filter((store) => !selectedDomainIdArr.includes(store.id));

    return {
      options: domains.map(valueToLabel),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const handleDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (name: string) => (value: any) => {
    if (name === 'district_id') {
      value = +value;
    }
    setData({
      ...data,
      [name]: value,
    });
  };

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    handleClose();
  };

  useEffect(() => {
    if (store) {
      setData({
        name: store.name,
        phone: store.phone,
        email: store.email,
        address: store.address,
        logo: store.logo,
        type: store.type,
        domains: store.aml_domains ?? [],
        district_id: store.district_id,
      });
    } else {
      setData(initialState);
    }
  }, [store]);

  return (
    <Modal isOpen={isModalOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate(store ? 'edit_store' : 'create_store')}</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="p-2 ">
            <div className="mb-4">
              {!store && (
                <div className="text-center">
                  <div className="btn-group">
                    <button
                      className={`btn ${
                        type === OFFLINE_STORE ? 'btn-primary' : 'btn-outline-secondary'
                      }`}
                      onClick={() => handleChange('type')(1)}
                      type="button">
                      {translate('store_offline')}
                    </button>
                    <button
                      className={`btn ${
                        type === ONLINE_STORE ? 'btn-primary' : 'btn-outline-secondary'
                      }`}
                      onClick={() => handleChange('type')(2)}
                      type="button">
                      {translate('store_online')}
                    </button>
                  </div>
                </div>
              )}
              <Input
                value={name}
                onChange={handleDataChange}
                name="name"
                type="text"
                legend={translate('store_name').toUpperCase()}
                isRequired
                error={translate(...(errors.name ?? ''))}
              />
              <Input
                value={phone}
                onChange={handleDataChange}
                name="phone"
                type="text"
                legend={translate('phone').toUpperCase()}
              />
              <Input
                value={email}
                onChange={handleDataChange}
                name="email"
                type="text"
                legend={translate('email').toUpperCase()}
                error={translate(...(errors.email ?? ''))}
              />
              {type === ONLINE_STORE && (
                <>
                  {
                    <Input
                      type="react-select-async"
                      isMulti
                      legend={translate('store_url').toUpperCase()}
                      name="domain_id"
                      defaultOptions={data.domains?.map(valueToLabel)}
                      loadOptions={searchAmlDomain}
                      menuPortalTarget={document.getElementById('navlink-container')}
                      value={data.domains?.map(valueToLabel) ?? []}
                      onChange={handleChange('domains')}
                      isRequired={true}
                      error={translate(...(errors.domains ?? ''))}
                    />
                  }
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-link btn-sm link-underline link-underline-opacity-0"
                      onClick={() => setIsCreateModalOpen(true)}>
                      <FontAwesomeIcon icon={faPlus} />
                      {translate('create')}
                    </button>
                  </div>
                </>
              )}
              {type === OFFLINE_STORE && (
                <>
                  <Input
                    value={district_id}
                    onChange={handleChange('district_id')}
                    name="district_id"
                    type="react-select"
                    isRequired
                    legend={translate('district').toUpperCase()}
                    options={reactDistrictsOptions}
                    error={translate(...(errors.district_id ?? ''))}
                  />
                  <Input
                    value={address || ''}
                    onChange={handleDataChange}
                    name="address"
                    type="text"
                    legend={translate('address').toUpperCase()}
                    isRequired
                    error={translate(...(errors.address ?? ''))}
                  />
                </>
              )}
              <hr />
              <Input
                value={logo}
                onChange={handleChange('logo')}
                name="logo"
                options={logos}
                defaultLabel={translate('select_logo')}
                type="react-select"
                legend={translate('logo').toUpperCase()}
              />

              {!store && (
                <Input
                  type="checkbox"
                  name=""
                  legend={translate('terms_and_conditions').toUpperCase()}
                  value={agreeTerms}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAgreeTerms(e.target.checked ? 1 : 0)
                  }
                  isRequired
                  error={translate(...(errors.agreeTerms ?? ''))}>
                  <span>
                    {translate('accept')}
                    &nbsp;
                    <a
                      className="link-underline link-underline-opacity-0"
                      href="/terms.html"
                      target="_blank">
                      {translate('terms_and_conditions')}
                    </a>
                  </span>
                </Input>
              )}
            </div>
            <div className="d-grid">
              <button className="btn btn-primary round-lg">
                {translate(store ? 'update' : 'create')}
              </button>
            </div>
          </div>
        </form>
        {type === ONLINE_STORE && (
          <CreateAmlDomainModal
            isOpen={isCreateModalOpen}
            setIsOpen={setIsCreateModalOpen}
            getData={() => Promise.resolve()}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
