import { findGatewayByCode, HIDE_GATEWAY, PAY_PLUS_GATEWAY } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import '../../layouts/card/GatewayCard.scss';
import { GatewayList } from './GatewayList';
import { useGateways } from './hooks';

export const PaymentGateway = () => {
  const {
    auth: { user, credentials },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const { translate } = useTranslate();
  const [mccAvailableGatewayCodeList, getCredentials] = useGateways(user.company_id);
  if (mccAvailableGatewayCodeList.length === 0 && credentials.length === 0) {
    return <></>;
  }

  let isAppliedOnlineGateway = false;
  credentials.forEach((cred) => {
    const gateway = findGatewayByCode(cred.gateway_code);
    isAppliedOnlineGateway = isAppliedOnlineGateway || gateway?.gateway_type === 'online';
  });

  const availableApplyGatewayCodeList = mccAvailableGatewayCodeList
    .filter(
      (code) => !credentials.map((cred) => cred.gateway_code as string)?.includes(code) ?? false,
    ) // exclude applied gatways
    .filter((code) => !HIDE_GATEWAY.includes(code)) // exclude hidden gateways
    .filter((code) => !PAY_PLUS_GATEWAY.includes(code)); //exclude pay-plus gateways displayed separately

  return (
    <div id="gateway-content" className="hide-scrollbar" style={{ paddingBottom: '3rem' }}>
      {availableApplyGatewayCodeList.length > 0 && (
        <GatewayList
          title={translate('setup_payment_gateways')}
          caption={translate('choose_and_add_gateway')}
          codeList={availableApplyGatewayCodeList}
          isApplied={false}
          getCredentials={getCredentials}
          appliedOnlineGateway={isAppliedOnlineGateway}
          textOnly={false}
        />
      )}
      {credentials.length > 0 && (
        <GatewayList
          title={translate('my_gateways')}
          codeList={credentials
            .filter((cred) => !PAY_PLUS_GATEWAY.includes(cred.gateway_code))
            .map((cred) => cred.gateway_code)}
          isApplied={true}
          getCredentials={getCredentials}
          appliedOnlineGateway={isAppliedOnlineGateway}
          textOnly={availableApplyGatewayCodeList.length > 0}
        />
      )}
    </div>
  );
};
