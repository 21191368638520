import { createTransaction } from 'actions/TransactionActions';
import qrImage from 'assets/img/others/qrcode.png';
import { Html5QrcodePlugin } from 'components/custom/Html5QrcodePlugin';
import { Input } from 'components/custom/input';
import GATEWAYS from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { useIdempotencyKey } from 'helpers/hooks/useIdempotencyKey';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { FunctionDispatch } from 'redux/types';
import { CreateTransactionBody } from 'services/API/Transactions/interface';
import { useSwal } from 'helpers/sweetalert';

import { createTransactionEnterBarcodeValidator as validate } from './validator';

export interface WebCamReaderProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onCompleteCreate?: () => void;
  transaction: CreateTransactionBody;
}

export const WebCamReader: FC<WebCamReaderProps> = ({
  isModalOpen,
  setIsModalOpen,
  onCompleteCreate,
  transaction,
}: WebCamReaderProps) => {
  const [addIdempotencyKey, resetIdempotencyKey] = useIdempotencyKey<CreateTransactionBody>();
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [barcodeText, setBarcodeText] = useState('');
  const [tempBarcodeText, setTempBarcodeText] = useState('');
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { amount, currency, gateway_id, store_id } = transaction;

  const { translate } = useTranslate();
  const [Swal] = useSwal();

  const dispatch = useDispatch<FunctionDispatch>();

  const selectedGateway = GATEWAYS.find((gateway) => gateway.gateway_id === gateway_id);

  const toggleCamera = () => setIsCameraOpen(!isCameraOpen);
  const closeModal = () => {
    setIsCameraOpen(false);
    setIsModalOpen(false);
  };

  const submitResult = async () => {
    setIsLoading(true);
    if (!barcodeText) {
      setIsLoading(false);
      return;
    }
    setIsCameraOpen(false);
    setBarcodeText('');

    const data: CreateTransactionBody = {
      gateway_id,
      store_id,
      amount,
      currency,
      token: barcodeText,
    };
    if (isNaN(parseInt(data.token))) {
      Swal.fire({
        icon: 'error',
        title: translate('create_transaction'),
        text: translate('qr_format_incorrect'),
      });
      setBarcodeText('');
      setIsLoading(false);
      return;
    }

    const res = await dispatch(createTransaction(store_id, addIdempotencyKey(data)));
    if (!res.success) {
      setIsLoading(false);
      return Swal.fire({
        icon: 'error',
        title: translate('create_transaction'),
        text: res?.message || 'Oops',
      });
    }
    await Swal.fire({
      icon: 'success',
      title: translate('create_transaction'),
      text: translate('congratulations_transaction_successfully_created'),
    });
    resetIdempotencyKey();
    setIsLoading(false);
    closeModal();
    if (onCompleteCreate) {
      onCompleteCreate();
    }
  };

  useEffect(() => {
    resetIdempotencyKey();
    barcodeText !== '' && submitResult();
    // eslint-disable-next-line
  }, [barcodeText]);

  const submitEnterBarcode = () => {
    const { errors, isValid } = validate({ code: tempBarcodeText });
    setErrors(errors);
    if (isValid) {
      setBarcodeText(tempBarcodeText);
    }
  };

  const qrCodeSuccessCallback = (decodedText: string) => {
    setBarcodeText(decodedText);
    setIsCameraOpen(false);
  };

  useEffect(resetIdempotencyKey, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <img src={selectedGateway?.thumbnail} alt="" className="me-4" />
        {selectedGateway?.gateway_sub_name}
      </ModalHeader>
      <ModalBody>
        <div className="text-center">
          {!isCameraOpen && <img src={qrImage} alt="qr code" />}
          {isCameraOpen && (
            <div
              style={{
                width: 300,
                height: 300,
              }}
              className="d-inline-block">
              {<Html5QrcodePlugin aspectRatio={1} qrCodeSuccessCallback={qrCodeSuccessCallback} />}
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center">
            <small className="colfax-regular">{translate('amount')}:</small>
            <h3 className="amount" data-currency={currency}>
              {amount}&nbsp;
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-grid">
            <button
              className={`btn btn-success ${isCameraOpen ? 'd-none' : ''}`}
              onClick={() => toggleCamera()}
              disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : translate('open_camera')}
            </button>
          </div>
        </div>
        <hr className="dash-border"></hr>
        <div className="row">
          <div className="col-md-12">
            <Input
              legend={translate('barcode')}
              name="tempBarcodeText"
              onChange={(e: any) => setTempBarcodeText(e.target.value)}
              value={tempBarcodeText}
              type="number"
              placeholder={translate('enter_barcode')}
              error={translate(...(errors.code ?? ''))}
            />
            <div className="d-grid">
              <button
                className="btn btn-success mt-4"
                onClick={submitEnterBarcode}
                disabled={isLoading}>
                {isLoading ? <Spinner size="sm" /> : translate('create')}
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
