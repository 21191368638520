import axios, { defaultRequestHandler, getToken } from '../common/Fetcher';
import { GetMCCResponse } from './interface';

export const getMccList = (): Promise<GetMCCResponse> =>
  defaultRequestHandler(
    axios.get(`/mccgroup?include=mcc_list`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
