import { getCredentialsList } from 'actions/GatewayActions';
import {
  GET_CREDENTIALS,
  ON_SUPEROWNER_COMPANY_SELECT,
  SET_CURRENT_USER,
} from 'actions/actionTypes';
import { Input } from 'components/custom/input';
import { GATEWAY_ORDER } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { toggleLoader } from 'helpers/screenLoader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoadOptions } from 'react-select-async-paginate';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getCompanyBalance } from 'services/API/Balance';
import { getCompanyCredentialList, getCompanyList } from 'services/API/Company';
import { GatewayProviderGetCompanyProps } from 'services/API/Company/interface';

export const CompanyDropdown = () => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const company_id = user.company_id;
  const history = useHistory();
  const { translate } = useTranslate();
  const dispatch = useDispatch<FunctionDispatch>();

  const [companyList, setCompanyList] = useState<Array<GatewayProviderGetCompanyProps>>([]);
  const [hasMore, setHasMore] = useState(true);
  const [companyPage, setCompanyPage] = useState(1);

  if (!company_id) {
    return <></>;
  }

  const convertCompanyIdToOptions = () => {
    return {
      label: findCompanyName(company_id),
      value: company_id,
    };
  };
  const findCompanyName = (company_id: string) => {
    return companyList.find((company) => company.id === company_id)?.name ?? 'Company';
  };

  const searchCompany: LoadOptions<any, any> = async (input) => {
    let options: Array<any> = [];

    if (hasMore) {
      const res = await getCompanyList({ page: companyPage, include: 'daily_balance' });
      const page = res?.meta?.pagination?.current_page ?? 1;
      const hasMore = !!res?.meta?.pagination?.links?.next ?? false;

      if (hasMore) {
        setCompanyPage(page + 1);
      } else {
        setHasMore(false);
      }

      setCompanyList([...companyList, ...res.data]);
    }
    options = companyList;

    let filtered: Array<any> = [];

    if (!input) {
      filtered = [...convertCompaniesOptions(options)];
    } else {
      const searchLowerCase = input.toLowerCase();
      if (searchLowerCase.length < 3) {
        filtered = [...convertCompaniesOptions(companyList)];
      } else {
        const result = await getCompanyList({
          name: searchLowerCase,
        });
        setCompanyList(Array.from(new Set([...companyList, ...result.data])));
        filtered = convertCompaniesOptions(result.data);
      }
    }

    return {
      options: filtered,
      hasMore,
    };
  };

  const convertCompaniesOptions = (companies: Array<GatewayProviderGetCompanyProps>) => {
    return companies.map((company) => ({
      label: company.name,
      value: company.id,
    }));
  };

  const onCompanyNameSelect = async (compId: string) => {
    if (company_id !== compId) {
      dispatch({
        type: ON_SUPEROWNER_COMPANY_SELECT,
        payload: compId,
      });
      toggleLoader(true);

      // Refresh page
      const path = history.location.pathname + '';
      setTimeout(async () => {
        history.replace('/');
        history.replace(path);
      }, 1000);
    }
  };

  useEffect(() => {
    getCompanyBalance(company_id).then((res) => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: {
          ...user,
          balance: res,
        },
      });
    });
    dispatch(getCredentialsList(company_id));
  }, [company_id]);

  return (
    <div style={{ padding: '20px', marginTop: '-3rem' }}>
      <Input
        type="react-select-async"
        value={company_id !== '' ? convertCompanyIdToOptions() : []}
        loadOptions={searchCompany}
        onChange={onCompanyNameSelect}
        legend={translate('company').toUpperCase()}
        name="company_id"
        menuPortalTarget={document.getElementById('navlink-container')}
      />
    </div>
  );
};
