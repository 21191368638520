import { register as registerCallback } from 'actions/AuthActions';
import { FormContainer } from 'components/custom/form';
import { StepIndicator } from 'components/custom/form/StepIndicator/Indicator';
import { ProcessButton } from 'components/custom/form/StepIndicator/ProcessButton';
import { AuthenticationLayout } from 'components/layouts/authentication.layout';
import { MERCHANT_TYPE_ENTERPRISE, MERCHANT_TYPE_INDIVIDUAL, TAXI_MCC } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FunctionDispatch } from 'redux/types';
import { getAffiliationCodeList } from 'services/API/AffiliationCode';
import { register } from 'services/API/Auth';
import { RegisteredReturn } from 'services/API/Auth/interface';
import { DefaultResponse } from 'services/API/common/Fetcher';
import { useSwal } from 'helpers/sweetalert';
import { EmailFormProps, PhoneFormProps } from './interface';
import { Activation } from './steps/activation';
import { AssignStore } from './steps/assign-store';
import { BusinessSetup } from './steps/business-setup';

const config = (window as any).yedpayConfig;

const initialState = {
  client_id: undefined,
  client_secret: undefined,
  grant_type: 'password',

  username: '',
  email: '',

  password: '',
  password_confirmation: '',
  first_name: '',
  last_name: '',
  position: '',

  country_code: '852',
  phone: '',

  activation_type: 1,
  activation_code: '',

  company: {
    name: '',
    phone: '',
    contact_name: '',
    contact_mobile: '',
    website: '',
    business_description: '',
    mcc: '',
    merchant_type: MERCHANT_TYPE_ENTERPRISE,
    is_ngo: false,
  },
  stores: [],
  expected_activity: {
    transaction_avg: '',
    expected_monthly_inflow: '',
  },

  affiliation_code: '',
};

export const Register = () => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const dispatch = useDispatch<FunctionDispatch>();

  // 1: email 2. phone
  const [form, setForm] = useState<EmailFormProps | PhoneFormProps>(initialState);
  const [current, setCurrent] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const validationRef = useRef<any>(null);

  const {
    company: { merchant_type, mcc },
  } = form;

  const isIndividual =
    (merchant_type as string).toUpperCase() === MERCHANT_TYPE_INDIVIDUAL.toUpperCase();
  const steps = isIndividual
    ? [BusinessSetup, Activation]
    : [BusinessSetup, AssignStore, Activation];

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid: boolean = validationRef?.current?.validate?.();
    if (isValid === false) {
      return;
    }
    if (current < steps.length - 1) {
      setCurrent(current + 1);
      return;
    }

    const body = {
      ...form,
    };

    body.country_code = `+${body.country_code}`;
    if (`${mcc}` === TAXI_MCC.toString() && body.email) {
      body.activation_type = 1;
    } else if (body.activation_type === 1) {
      delete body.country_code;
      delete body.phone;
    } else if (`${mcc}` !== TAXI_MCC.toString() || !body.email) {
      delete body.email;
    }

    if (body.stores[0].type === 1) {
      body.stores[0].address =
        body.stores[0].address && body.stores[0].address.trim().replace(/['"]/g, '');
    } else if (body.stores[0].type === 2) {
      delete body.stores[0].district_id;
    }

    body.client_id = config.client_id;
    body.client_secret = config.client_secret;

    if (localStorage.urlParams) {
      const urlParams = new URLSearchParams(decodeURIComponent(localStorage.urlParams));
      urlParams.delete('affiliation_code');
      localStorage.urlParams = urlParams.toString();
    }

    setIsLoading(true);
    let res = await register(body);
    setIsLoading(false);

    if ((res as RegisteredReturn).access_token) {
      // Set the user to redux store,
      // It will automatically redirect the user
      dispatch(registerCallback(res as RegisteredReturn));
    } else {
      res = res as DefaultResponse;
      const message = res.errors ? res.errors![0].message : res.message;
      await Swal.fire({
        icon: 'error',
        title: translate('register'),
        text: message,
      });
    }
  };

  useEffect(() => {
    const windowLocationParams = new URLSearchParams(decodeURIComponent(window.location.search));
    const urlParams = new URLSearchParams(decodeURIComponent(localStorage.urlParams));
    windowLocationParams.forEach(function (value, key) {
      urlParams.set(key, value);
    });

    const auto_fill: any = {};
    urlParams.forEach((value, key) => {
      const match = /^auto_fill\[([^\]]+)\]$/.exec(key);
      if (match) {
        auto_fill[match[1]] = value;
      }
    });

    const affiliation_code = urlParams.get('affiliation_code') || '';
    if (affiliation_code !== '') {
      getAffiliationCodeList(affiliation_code).then((res) => {
        if (res.data.length === 0) {
          if (localStorage.urlParams) {
            urlParams.delete('affiliation_code');
            localStorage.urlParams = urlParams.toString();
          }

          Swal.fire({
            icon: 'error',
            title: translate('registration'),
            text: translate('affiliation_code_incorrect'),
          });
        }
      });
    }

    setForm({
      ...form,
      return_url: urlParams.get('return_url') || '',
      service_provider_code: urlParams.get('service_provider_id') || undefined,
      auto_fill,
      affiliation_code,
    });

    return () => {
      // We only need to save the mcc list when register page is loaded,
      // so remove the mcc list when the user leaves the page;
      // More Details, please refer to the path:
      // ../steps/business-setup.tsx > getMccList();
      const mccList = localStorage.getItem('mccList');
      if (mccList) {
        localStorage.removeItem('mccList');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationLayout>
      <FormContainer>
        <form onSubmit={onSubmit}>
          <StepIndicator totalStep={steps.length} current={current} />
          {steps.map((Step, id) => {
            if (id === current) {
              return <Step ref={validationRef} key={0} form={form} setForm={setForm} />;
            }
            return null;
          })}
          <ProcessButton
            current={current}
            setStep={setCurrent}
            totalStep={steps.length}
            submitText={translate('submit')}
            isLoading={isLoading}
          />
        </form>
      </FormContainer>
    </AuthenticationLayout>
  );
};
