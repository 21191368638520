import { faCheck, faCog, faEdit, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from 'components/custom/Text';
import { DISPLAY_STATUS_COLOR } from 'constants/format';
import { PAY_PLUS_ONLINE_GATEWAY, findGatewayByCode } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { updateCredential } from 'services/API/Gateway';
import { useSwal } from 'helpers/sweetalert';
import '../layouts/card/GatewayCard.scss';
import { UpdateGatewayModal } from './updateGatewayModal';

interface GatewayCard {
  gatewayCode: string;
  getCredentials: () => Promise<void>;
}

export const GatewayDetailCard = ({ gatewayCode, getCredentials }: GatewayCard) => {
  const {
    auth: { credentials },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate, language } = useTranslate();
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const gateway = findGatewayByCode(gatewayCode);
  const credential = credentials?.find((cred) => cred.gateway_code === gatewayCode);

  const DISPLAY_VISIBLE = true;

  const areYouSureEditDisplayStatus = (
    credential_id: string,
    credential_display_gateway: any,
    gateway_code: string,
  ) => {
    let displayTitle = translate('change_gateway_display_status');
    let displayText =
      credential_display_gateway === DISPLAY_VISIBLE
        ? translate('are_you_sure_hide_gateway_from_checkout_page')
        : translate('are_you_sure_show_gateway_from_checkout_page');

    if (PAY_PLUS_ONLINE_GATEWAY.includes(gateway_code)) {
      displayTitle = translate('change_default_gateway');

      displayText =
        credential_display_gateway === DISPLAY_VISIBLE
          ? translate('confirm_to_cancel_it_as_the_default_fps_account_for_online_payment')
          : translate('confirm_to_set_it_as_the_default_fps_account_for_online_payment');
    }

    Swal.fire({
      icon: 'warning',
      title: displayTitle,
      html: displayText,
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => editDisplayStatus(credential_id, credential_display_gateway, displayTitle),
    });
  };

  const editDisplayStatus = async (
    credential_id: string,
    credential_display_gateway: any,
    displayTitle: string,
  ) => {
    const body = credential_display_gateway === DISPLAY_VISIBLE ? { display: 0 } : { display: 1 };

    const res = await updateCredential(credential_id, body);
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: displayTitle,
      text: res.message ?? 'Oops',
    });
    if (res) {
      window.location.reload();
    }
    if (!res.success) {
      return;
    }
  };

  if (!gateway || !credential) {
    return <></>;
  }

  return (
    <div className="gateway-card">
      <div
        className={`gateway-card-header`}
        style={{
          backgroundImage: `url(${language === 'en' ? gateway.header_en : gateway.header})`,
        }}></div>
      <div className="gateway-card-body">
        <div>
          <Text type={translate('gateway')} value={gateway.gateway_sub_name} className={'fx-17'} />
          {gateway.wallet && (
            <Text
              type={translate('wallet')}
              value={translate(gateway.wallet ?? 'na')}
              className={'fx-17'}
            />
          )}
          <Text
            type={translate('gateway_type')}
            value={translate(gateway.gateway_type ?? 'na')}
            className={'fx-17'}
          />
          <Text
            type={translate('apply_status')}
            value={translate(credential.status ?? 'na')}
            className={'fx-17'}
          />
          {gateway.gateway_type === 'online' &&
            !PAY_PLUS_ONLINE_GATEWAY.includes(gateway.gateway_code) && (
              <Text
                className={`text-${DISPLAY_STATUS_COLOR[credential.display]} fx-17`}
                type={translate('checkout_page')}
                value={translate(credential.display ?? 'na')}
              />
            )}

          {PAY_PLUS_ONLINE_GATEWAY.includes(gateway.gateway_code) && (
            <Text
              className={`text-${DISPLAY_STATUS_COLOR[credential.display]} fx-17`}
              type={translate('default_gateway')}
              value={translate((credential.display_gateway ? 'true' : 'false') ?? 'na')}
            />
          )}

          {gateway.hasConfig && !PAY_PLUS_ONLINE_GATEWAY.includes(gateway.gateway_code) && (
            <Text
              type={translate('require_signature')}
              value={translate(credential.signature_required ? 'yes' : 'no')}
              className="fx-17"
            />
          )}
        </div>
        <div>
          <div className="mt-3 applied-btn-group d-grid">
            <button className="btn btn-outline-success fx-17" disabled>
              <FontAwesomeIcon icon={faCheck} />
              &nbsp; &nbsp;
              {translate('applied')}
            </button>
            {gateway.hasConfig && (
              <button
                className="btn btn-secondary btn-sm configure-btn"
                onClick={() => setIsConfigModalOpen(true)}>
                <FontAwesomeIcon icon={faCog} />
              </button>
            )}
          </div>
          <div className="row mt-2">
            {gateway.canControlDisplay == true && (
              <div className="col-md-6 small-btn-container d-grid">
                <button
                  className="btn btn-outline-secondary fx-17"
                  onClick={() =>
                    areYouSureEditDisplayStatus(
                      credential.id,
                      credential.display_gateway,
                      credential.gateway_code,
                    )
                  }>
                  <FontAwesomeIcon icon={faEdit} />
                  &nbsp;
                  {translate('edit')}
                </button>
              </div>
            )}
            <div
              className={
                gateway.canControlDisplay == true
                  ? 'col-md-6 small-btn-container d-grid'
                  : 'mx-1 btn d-grid'
              }>
              <a
                className="btn btn-outline-secondary fx-17 link-underline link-underline-opacity-0"
                href="/terms.html"
                target="_blank">
                <FontAwesomeIcon icon={faFileContract} />
                &nbsp;
                {translate('terms')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <UpdateGatewayModal
        isOpen={isConfigModalOpen}
        setIsOpen={setIsConfigModalOpen}
        credential={credential}
        getCredentials={getCredentials}
      />
    </div>
  );
};
