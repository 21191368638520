import { SelectOptionProps } from 'components/custom/input/interfaces';
import { LoadOptions } from 'react-select-async-paginate';

export const getSearchFunction = (
  getResultPromise: (arg0: string, arg1: any) => Promise<any>,
  params: string,
  data: any,
  valueToLabel: (value: any) =>
    | SelectOptionProps[]
    | {
        label: string;
        value: any;
      },
): LoadOptions<any, any> => {
  return async (keyword, loadedOptions, additional) => {
    const page = additional?.page || 1;

    const result = await getResultPromise(params, {
      ...data,
      keyword: keyword.toLowerCase(),
      page,
    });

    const hasMore = !!result?.meta?.pagination?.links.next ?? false;

    return {
      options: result.data.map(valueToLabel),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
};
