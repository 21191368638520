import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { Dispatch, ThunkResult } from 'redux/types';
import { getFeaturesConfigData } from 'services/API/FeaturesConfig';
import { AnyResponse } from 'services/API/common/Fetcher';
import { SET_FEATURES } from './actionTypes';

export const getFeaturesConfig = (): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(async (dispatch: Dispatch) => {
    const res = await getFeaturesConfigData();
    if (res.success) {
      const { data } = res;
      if (data.features) {
        dispatch({
          type: SET_FEATURES,
          payload: data.features,
        });
      }
    }

    // no matter success or not, not affecting the login process
    return Promise.resolve(res);
  });
