import { greaterEqualThan, greaterThan, packValidateResults, required } from 'helpers/validators';
import { QrCodeBodyProps } from 'services/API/FixedCode/interface';

export const validate = (
  data: QrCodeBodyProps,
  storeId = '',
  isAlipay = true,
  hasFpsOffline = false,
) => {
  const results = [];

  results.push(required({ ...data, storeId }, ['name', 'storeId']));
  if (data.is_fixed_amount) {
    results.push(required(data, ['amount']));
    results.push(greaterThan(data, ['amount'], { gt: 0 }));
    results.push(greaterEqualThan(data, ['amount'], { min: 0.5 }));
  }
  if (isAlipay) {
    results.push(required(data, ['subtitle_name']));
  }

  if (!isAlipay && hasFpsOffline) {
    results.push(required(data, ['fps_gateway_code']));
  }

  return packValidateResults(results);
};
