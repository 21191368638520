import { FC, useState } from 'react';
import './Input.scss';
import { InputBody } from './inputBody';
import { InputWrapper } from './inputWrapper';
import {
  CleaveInput,
  DateInput,
  DefaultInput,
  TextareaInput,
  FileInput,
  FileProgressInput,
  NumberProps,
  ReactSelectAsyncInput,
  ReactSelectInput,
  SelectInput,
  SelectWithText,
} from './interfaces';

export type type =
  | 'date'
  | 'checkbox'
  | 'cleave'
  | 'react-select'
  | 'text'
  | 'textarea'
  | 'file'
  | 'number'
  | 'password'
  | 'select'
  | 'select-text'
  | 'select'
  | 'file-progress'
  | 'react-select-async';

export const Input: FC<
  | DefaultInput
  | TextareaInput
  | ReactSelectInput
  | SelectWithText
  | CleaveInput
  | DateInput
  | FileInput
  | SelectInput
  | FileProgressInput
  | ReactSelectAsyncInput
  | NumberProps
> = (
  props:
    | DefaultInput
    | TextareaInput
    | ReactSelectInput
    | SelectWithText
    | CleaveInput
    | DateInput
    | FileInput
    | SelectInput
    | FileProgressInput
    | ReactSelectAsyncInput
    | NumberProps,
) => {
  const [showCalenderAlt, setShowCalenderAlt] = useState(false);
  // @todo put this upper level
  const { legend, error, prefix, type, isRequired, suffix } = props;
  // This is used to set the window control from upper level
  const { showCalender, setShowCalender } = props as DateInput;

  const wrapperProps = {
    legend,
    error,
    showCalender: showCalender ?? showCalenderAlt,
    prefix,
    type,
    suffix,
    isRequired,
  };

  // If the upper level doesn't pass in any control state, use default instead;
  const calenderProps = {
    showCalender: showCalender ?? showCalenderAlt,
    setShowCalender: setShowCalender ?? setShowCalenderAlt,
  };

  return (
    <InputWrapper {...wrapperProps}>
      <InputBody {...props} {...calenderProps} />
    </InputWrapper>
  );
};
