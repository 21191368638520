import { getUserList } from 'actions/AuthActions';
import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { AVAILABLE_ROLES_FOR_CREATE_FOR_REACT_SELECT, isOwner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { changeUserStatus, deleteUser as apiDeleteUser } from 'services/API/User';
import { UserProps } from 'services/API/User/interface';
import { useSwal } from 'helpers/sweetalert';
import { Card } from './Card';
import { UserModel } from './UserModel';
import { ANY_OBJECT, translateLabel } from 'constants/format';
import SideFilter from 'components/custom/sideFilter';
import { Input } from 'components/custom/input';
import { Pagination } from 'components/custom/pagination';
import { EmptyResult } from 'components/custom/table/emptyResult';

const initialFilterState: ANY_OBJECT = {
  username: '',
  email: '',
  phone: '',
  userRole: '',
};

export const UsersAndRoles = ({ location }: RouteComponentProps) => {
  const dispatch = useDispatch<FunctionDispatch>();
  const {
    auth: {
      user,
      user: { role },
    },
    data: {
      users: { users: usersData, usersPagination },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [filter, setFilter] = useState(initialFilterState);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState<UserProps>();

  const { username, email, phone, userRole } = filter;

  const filterToParams = () => {
    const data: { [key: string]: string } = {
      username,
      email,
      phone,
      role: userRole,
    };

    const names = Object.keys(data);
    const values = Object.values(data);

    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        delete data[names[index]];
      }
    });

    Object.assign(data, {
      limit: 10,
      page: currentPage,
    });

    return data;
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (name: string) => (val: string) => {
    setFilter({
      ...filter,
      [name]: val,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  const handleSelect = (user?: UserProps) => {
    setSelectedUser(user);
    setShowCreateModal(true);
  };

  const changeStatus = async (u: UserProps, disabled: boolean) => {
    if (u.id === user.id) {
      return Swal.fire({
        icon: 'error',
        title: translate('delete_account'),
        text: translate('you_cannot_delete_yourself'),
      });
    }
    const body = {
      disable: disabled,
    };

    const res = await changeUserStatus(u.id, body);
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate(disabled ? 'disable_user' : 'enable_user'),
      text: res.message ?? 'Oops',
    });
    if (!res.success) {
      return;
    }
    return getInitialData();
  };
  const confirmChangeStatus = (u: UserProps, disabled: boolean) => {
    Swal.fire({
      icon: 'warning',
      title: translate(disabled ? 'disable_user' : 'enable_user'),
      text: translate(disabled ? 'are_you_sure_disable_user' : 'are_you_sure_enable_user'),
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => changeStatus(u, disabled),
    });
  };

  const deleteUser = async (user: UserProps) => {
    const res = await apiDeleteUser(user.id);
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('delete_user'),
      text: res.message ?? 'Oops',
    });
    if (!res.success) {
      return;
    }

    await getInitialData();
  };
  const confirmDelete = (user: UserProps) => {
    Swal.fire({
      icon: 'warning',
      title: translate('delete_user'),
      text: translate('are_you_sure_delete_user'),
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => deleteUser(user),
    });
  };

  const getInitialData = async () => {
    setLoading(true);
    const params = filterToParams();
    const res = await dispatch(getUserList(user?.company_id, params));
    setLoading(false);

    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('user'),
        text: res.message || 'Oops',
      });
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="users-and-roles" name="users_and_roles" />
          <div className="bg-white round-bottom-lg">
            <SideFilter
              isLoading={loading}
              resetFilter={resetFilter}
              onFilter={onFilterSearch}
              defaultCollapsed={true}>
              <Input
                type="text"
                value={username}
                legend={translate('username').toUpperCase()}
                name="username"
                onChange={onFilterChange}
                placeholder={translate('username')}
              />
              <Input
                type="text"
                value={email}
                legend={translate('email').toUpperCase()}
                name="email"
                onChange={onFilterChange}
                placeholder={translate('email')}
              />
              <Input
                type="text"
                value={phone}
                legend={translate('phone').toUpperCase()}
                name="phone"
                onChange={onFilterChange}
                placeholder={translate('phone')}
              />
              <Input
                type="react-select"
                legend={translate('role').toUpperCase()}
                value={userRole}
                options={translateLabel(AVAILABLE_ROLES_FOR_CREATE_FOR_REACT_SELECT, translate)}
                defaultLabel={translate('all_types')}
                name="userRole"
                onChange={onFilterSelectChange('userRole')}
                placeholder={translate('role')}
                menuPortalTarget={document.body}
              />
            </SideFilter>
            {!loading && (
              <>
                <div className="card-container p-4">
                  {isOwner(user.role) && (
                    <div className="custom-card place-holder">
                      <button className="btn btn-outline-secondary" onClick={() => handleSelect()}>
                        {translate('create')}
                      </button>
                    </div>
                  )}
                  {usersData.map((user, index) => (
                    <Card
                      user={user}
                      onSelect={() => handleSelect(user)}
                      confirmChangeStatus={confirmChangeStatus}
                      confirmDeleteUser={confirmDelete}
                      showAction={isOwner(role)}
                      key={user.username + index}
                    />
                  ))}
                </div>
                {usersPagination && (
                  <>
                    {usersPagination.total < 1 && <EmptyResult text="no_data" />}
                    <div className="table-responsive admin mt-0">
                      <Pagination pagination={usersPagination} setCurrentPage={setCurrentPage} />
                      <div className="mb-5 mb-md-0"></div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showCreateModal && (
        <UserModel
          isOpen={showCreateModal}
          setIsOpen={setShowCreateModal}
          reloadUserList={getInitialData}
          userId={selectedUser?.id}
        />
      )}
    </>
  );
};
