import { getBalanceList } from 'actions/BalanceActions';
import { TransactionModal } from 'components/Transaction/TransactionModal';
import { BalanceBox } from 'components/custom/BalanceBox';
import { ReportSection } from 'components/custom/ReportSection';
import { Input } from 'components/custom/input';
import { SideFilter } from 'components/custom/sideFilter';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import { isAgent, isGatewayProvider } from 'constants/Roles';
import GATEWAY_ARRAY from 'constants/TransactionGateways';
import {
  BALANCE_SETTLEMENT_OPTIONS,
  TIME_ZONE_FORMAT,
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_TYPE_OPTIONS,
  translateLabel,
} from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { getNDaysEarlier } from 'helpers/functions/getDays';
import { useSwal } from 'helpers/sweetalert';
import moment from 'moment';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadOptions } from 'react-select-async-paginate';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getCompanyBalanceTransaction } from 'services/API/Balance';
import { BalanceTransactionProps } from 'services/API/Balance/interface';
import { getCompanyCredentialList } from 'services/API/Company';

const initialFilterState = {
  total_balance: '',
  transaction_id: '',
  transaction_type: '',
  start: new Date(getNDaysEarlier(7)),
  end: new Date(),
  is_balance_settled: '',
};

const valueToLabel = (value: any) => {
  if (!value) {
    return { label: '', value: null };
  }

  value.toString = () => value.id;
  return {
    label:
      GATEWAY_ARRAY.find((gateway) => gateway.gateway_code === value.gateway_code)
        ?.gateway_sub_name ?? value.gateway_sub_name,
    value: value,
  };
};

export const Balance = () => {
  const {
    auth: { user, roles },
    data: { balanceTransactions },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const { balanceTransactions: balanceData, balanceTransactionsPagination } = balanceTransactions;

  const reportSectionRef = useRef<any>(null);
  const [filter, setFilter] = useState(initialFilterState);
  const [isFetching, setIsFetching] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedBalance, setSelectedBalance] = useState<null | BalanceTransactionProps>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gateways, setGateways] = useState<Array<any>>([]);

  const [openExportDropdown, setOpenExportDropdown] = useState(false);

  const { start, end, total_balance, transaction_id, transaction_type, is_balance_settled } =
    filter;

  const getInitialData = async () => {
    const data = filterToParams();
    setIsFetching(true);
    const res = await dispatch(getBalanceList(user?.company_id, data));
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('balance'),
        text: res.message || 'Oops',
      });
    }
    setIsFetching(false);
  };

  const getCredentialOptions: LoadOptions<any, any> = async () => {
    const result = await getCompanyCredentialList(user?.company_id);
    const hasMore = !!result?.meta?.pagination?.links.next ?? false;

    return {
      options: result.data.map(valueToLabel),
      hasMore,
    };
  };

  const exportReport = async (type: string) => {
    const data = filterToParams();

    data.export = type;
    data.limit = balanceTransactionsPagination.total || 0;

    setIsFetching(true);
    const res = await getCompanyBalanceTransaction(user?.company_id, data);
    await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for the report to be generated
    await reportSectionRef?.current?.loadReports();
    setIsFetching(false);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('export'),
        text: res.message || 'Oops',
      });
      return;
    }
  };

  const onFilterDateChange = (date: Date, name: string) => {
    setFilter({
      ...filter,
      [name]: date,
    });
  };

  const onFilterChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name === 'balance') {
      // Cleave's Event handler has rawValue
      setFilter({
        ...filter,
        total_balance: (e.target as any).rawValue,
      });
      return;
    }

    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (name: string) => (value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  const filterToParams = () => {
    const data: { [key: string]: string } = {
      transaction_type,
      is_balance_settled,
      total_balance,
      transaction_id,
      gateway_code: gateways.map((g) => g.gateway_code).join(','),
    };

    const names = Object.keys(data);
    const values = Object.values(data);

    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        delete data[names[index]];
      }
    });

    Object.assign(data, {
      'created_at>': moment(start).startOf('day').format(TIME_ZONE_FORMAT),
      'created_at<': moment(end).endOf('day').format(TIME_ZONE_FORMAT),
      limit: 10,
      page: currentPage,
      include: 'transaction',
    });

    return data;
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
  };

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-end border-top-0">{translate('date')}</th>
        <th className="border-end border-top-0">{translate('transaction_id')}</th>
        <th className="border-end border-top-0">{translate('gateway')}</th>
        <th className="border-end border-top-0">{translate('amount')}</th>
        <th className="border-end border-top-0">{translate('fee')}</th>
        <th className="border-end border-top-0">{translate('net')}</th>
        <th className="border-end border-top-0">{translate('balance_available')}</th>
        <th className="border-end border-top-0">{translate('transaction_type')}</th>
        <th className="border-end border-top-0">{translate('settlement_status')}</th>
      </tr>
    );
  };

  const renderData = () => {
    return balanceData.map((balance, index) => {
      const {
        created_at,
        sale,
        charge,
        balance: amount,
        total_balance,
        currency,
        transaction_type,
      } = balance;
      const isSettled = balance.transaction.data.is_balance_settled;
      const settlement_status = isSettled ? 'balance_settled' : 'balance_holding';

      const onClick = () => {
        setSelectedBalance(balance as any);
        setIsModalOpen(true);
      };

      return (
        <tr className="cursor-pointer" key={index} onClick={onClick}>
          <td className="">{created_at}</td>
          <td className="">{(balance as any).transaction.data.transaction_id}</td>
          <td className="">{balance.transaction.data.gateway_sub_name}</td>
          <td className="">
            <span className="amount" data-currency={currency}>
              {sale}&nbsp;
            </span>
          </td>
          <td className="">{charge}</td>
          <td className="">{amount}</td>
          <td className="">{total_balance}</td>
          <td className="">
            <span
              className={`text-${TRANSACTION_STATUS_COLOR[transaction_type]} p-2 text-uppercase`}>
              {translate(transaction_type)}
            </span>
          </td>
          <td className={isSettled ? `text-success` : `text-warning`}>
            {translate(settlement_status)}
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <TopHeader title={translate('balance')}>
        <Dropdown
          disabled={isFetching}
          isOpen={openExportDropdown}
          toggle={() => setOpenExportDropdown(!openExportDropdown)}
          className="d-inline-block">
          <DropdownToggle
            disabled={isFetching}
            className="header-button btn btn-success"
            caret
            color="#ffffff">
            {isFetching ? <Spinner size="sm" /> : translate('export')}
          </DropdownToggle>
          <DropdownMenu classnames="lang-dropdown">
            <DropdownItem
              classnames="btn btn-sm btn-muted text-center"
              onClick={() => exportReport('xlsx')}>
              {translate('generate_excel_report')}
            </DropdownItem>
            <DropdownItem
              classnames="btn btn-sm btn-muted text-center"
              onClick={() => exportReport('csv')}>
              {translate('generate_csv_report')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </TopHeader>
      <div id="main-content" className="hide-scrollbar">
        <CustomTable
          renderHeading={() => tableHeader()}
          renderData={renderData}
          totalColumn={9}
          setCurrentPage={setCurrentPage}
          pagination={balanceTransactionsPagination}
        />
      </div>
      <div id="side-content" className="hide-scrollbar">
        <ReportSection ref={reportSectionRef} type="balances" />
        {!roles.isOperator && <BalanceBox />}
        <SideFilter isLoading={isFetching} onFilter={onFilterSearch} resetFilter={resetFilter}>
          <Input
            type="date"
            value={start}
            legend={translate('from').toUpperCase()}
            name="start"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="date"
            value={end}
            legend={translate('to').toUpperCase()}
            name="end"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="cleave"
            cleaveOptions={{
              numeral: true,
              numeralDecimalScale: 2,
              numeralIntegerScale: 7,
              numeralPositiveOnly: true,
            }}
            legend={translate('balance').toUpperCase()}
            value={total_balance}
            name="total_balance"
            onChange={onFilterChange}
            placeholder={translate('balance')}
          />
          <Input
            type="text"
            legend={translate('transaction_id').toUpperCase()}
            value={transaction_id}
            name="transaction_id"
            onChange={onFilterChange}
            placeholder={translate('transaction_id')}
          />
          <Input
            type="react-select"
            legend={translate('transaction_type').toUpperCase()}
            value={transaction_type}
            options={translateLabel(TRANSACTION_TYPE_OPTIONS, translate)}
            defaultLabel={translate('all_types')}
            name="transaction_type"
            onChange={onFilterSelectChange('transaction_type')}
            placeholder={translate('transaction_type')}
            menuPortalTarget={document.body}
          />
          <Input
            type="react-select"
            legend={translate('settlement_status').toUpperCase()}
            value={is_balance_settled}
            options={translateLabel(BALANCE_SETTLEMENT_OPTIONS, translate)}
            defaultLabel={translate('all_types')}
            name="is_balance_settled"
            onChange={onFilterSelectChange('is_balance_settled')}
            // placeholder={translate('settlement_status')}
            menuPortalTarget={document.body}
          />
          {!isGatewayProvider(user.role) && !isAgent(user.role) && (
            <Input
              isMulti
              type="react-select-async"
              legend={translate('gateway').toUpperCase()}
              loadOptions={getCredentialOptions}
              value={gateways.map(valueToLabel)}
              name="gateways"
              onChange={setGateways}
              placeholder={translate('gateway')}
              // defaultLabel={translate('all_gateways')}
              menuPortalTarget={document.body}
            />
          )}
        </SideFilter>
      </div>
      {selectedBalance && (
        <TransactionModal
          isAgentOrGatewayProvider={isAgent(user.role) || isGatewayProvider(user.role)}
          reloadTransaction={getInitialData}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          transaction={(selectedBalance as any).transaction.data as any}
        />
      )}
    </>
  );
};
