import { MERCHANT_TYPE_ENTERPRISE, MERCHANT_TYPE_INDIVIDUAL, TAXI_MCC } from 'constants/format';
import {
  address,
  email,
  greaterThan,
  isInt,
  username,
  lengthEqual,
  packValidateResults,
  password,
  required,
} from 'helpers/validators';
import { EmailFormProps, OfflineStoreProps, OnlineStoreProps, PhoneFormProps } from './interface';

export const businessSetupValidate = (data: EmailFormProps | PhoneFormProps) => {
  const requiredRules = [
    'company.merchant_type',
    'company.mcc',
    'company.name',
    'company.contact_name',
    'company.contact_mobile',
    'company.business_description',
    'expected_activity.transaction_avg',
    'expected_activity.expected_monthly_inflow',
  ];
  if (data.company.merchant_type === MERCHANT_TYPE_ENTERPRISE) {
    requiredRules.push('company.phone');
  }

  const rules = [
    required(data, requiredRules),
    isInt(data, ['expected_activity.transaction_avg', 'expected_activity.expected_monthly_inflow']),
    greaterThan(
      data,
      ['expected_activity.transaction_avg', 'expected_activity.expected_monthly_inflow'],
      { gt: 1 },
    ),
  ];
  if (data.company.merchant_type === MERCHANT_TYPE_ENTERPRISE) {
    rules.push(lengthEqual(data, ['company.phone'], { min: 8 }));
    rules.push(isInt(data, ['company.contact_mobile']));
  } else {
    rules.push(lengthEqual(data, ['company.contact_mobile'], { min: 8 }));
    rules.push(isInt(data, ['company.contact_mobile']));
  }

  return packValidateResults(rules);
};

export const storeValidate = (data: OnlineStoreProps | OfflineStoreProps) => {
  const result = [];

  result.push(required(data, ['name']));
  result.push(email(data, ['email']));
  if (data.type === 1) {
    result.push(required(data, ['address']));
    result.push(address(data, ['address']));
    result.push(required(data, ['district_id']));
    result.push(lengthEqual(data, ['phone'], { min: 8 }));
    result.push(isInt(data, ['phone']));
  } else {
    result.push(required(data, ['domain.domain']));
  }

  return packValidateResults(result);
};

const codeValidateFn = (data: EmailFormProps | PhoneFormProps) => {
  const result = [];
  if (data.email !== '') {
    result.push(required(data, ['email']));
    result.push(email(data, ['email']));
  } else {
    result.push(required(data, ['country_code', 'phone']));
    result.push(lengthEqual(data, ['phone'], { min: 8 }));
    result.push(isInt(data, ['phone']));
  }

  return result;
};

export const activationCodeValidate = (data: EmailFormProps | PhoneFormProps) =>
  packValidateResults(codeValidateFn(data));

export const activationValidate = (data: EmailFormProps | PhoneFormProps) => {
  const isTaxi = data.company.mcc === TAXI_MCC.toString();
  const result = [];
  result.push(...codeValidateFn(data));

  if (isTaxi) {
    result.push(required(data, ['operation_id', 'driving_license', 'taxi_number']));
  } else if (!isTaxi && data.company.merchant_type === MERCHANT_TYPE_INDIVIDUAL) {
    result.push(required(data, ['operation_id']));
  }
  result.push(
    required(data, [
      'first_name',
      'last_name',
      'username',
      'position',
      'password',
      'password_confirmation',
      'activation_code',
    ]),
  );
  result.push(username(data, ['username']));
  result.push(email(data, ['email']));
  result.push(password(data, ['password']));
  result.push(isInt(data, ['activation_code']));
  result.push(lengthEqual(data, ['activation_code'], { min: 6 }));

  if (data.password !== data.password_confirmation) {
    result.push({ password_confirmation: ['error_password_mismatch'] });
  }

  return packValidateResults(result);
};
