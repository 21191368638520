import _ from 'lodash';
import {
  between,
  lengthLessEqualThan,
  packValidateResults,
  required,
  validateParam,
  check,
  fileSizeLessThan,
  email,
} from 'helpers/validators';
import { UpdateStoreProps } from 'services/API/Store/interface';

export const validate = (body: any, storeId: string) => {
  return packValidateResults([
    required({ ...body, storeId }, ['amount', 'custom_id', 'subject', 'storeId']),
    between(body, ['amount'], { min: 0.1, max: 9999999.99 }),
    lengthLessEqualThan(body, ['subject'], { max: 256 }),
    lengthLessEqualThan(body, ['custom_id'], { max: 36 }),
  ]);
};

export const validateDynamicLink = (body: any, storeId: string, storeForm: UpdateStoreProps) => {
  return packValidateResults([
    required({ ...body, storeId, ...storeForm }, ['delivery_en', 'storeId', 'name']),
    email(storeForm, ['email']),
    lengthLessEqualThan(body, ['delivery_en'], { max: 7 }),
    deliveryDays(body, ['delivery_en']),
  ]);
};

const deliveryDays: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !/^(\d+|\d+\s*-\s*\d+)$/.test(_.get(data, field))) {
      return options.err_msg || ['error_invalid_delivery_time_format'];
    }
  });

export const validateUploadFile = (files: FileList) => {
  const result = [];
  const fileResult = fileSizeLessThan(files, Object.keys(files));
  if (!_.isEmpty(fileResult)) {
    result.push({ file: _.values(fileResult)[0] } as any);
  }

  return packValidateResults(result);
};
