import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, ThunkResult } from 'redux/types';
import { getCompanyDocumentsList, getCompanyStoreList } from 'services/API/Company';
import {
  GetCompanyDocumentListResponse,
  GetCompanyStoreListResponse,
} from 'services/API/Company/interface';
import { GET_STORE_OPTIONS } from './actionTypes';
import { receiveData, receiveLogos } from './DataAction';

export const getStores = (
  company_id: string,
  data = {},
): ThunkResult<Promise<GetCompanyStoreListResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await getCompanyStoreList(company_id, data);
    if (res.success) {
      dispatch(receiveData(res, 'stores'));
    }
    return res;
  });

export const getStoresOptions = (
  company_id: string,
  data = { limit: 300 },
): ThunkResult<Promise<GetCompanyStoreListResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await getCompanyStoreList(company_id, data);
    if (res.success) {
      dispatch({
        type: GET_STORE_OPTIONS,
        payload: res.data,
      });
    }
    return res;
  });

export const getStoreLogos = (
  company_id: string,
  data = {} as any,
): ThunkResult<Promise<GetCompanyDocumentListResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    data.type = 'logo';
    const res = await getCompanyDocumentsList(company_id, data);
    if (res.success) {
      const logos = [...res.data].filter(
        (document) => document.type === 'logo' && document.shared === true,
      );
      dispatch(receiveLogos(logos));
    }
    return res;
  });
