import { getStoreLogos, getStores } from 'actions/StoreActions';
import { InfoHeader } from 'components/custom/info_header';
import { Input } from 'components/custom/input';
import { Pagination } from 'components/custom/pagination';
import SideFilter from 'components/custom/sideFilter';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { ANY_OBJECT, ONLINE_STORE, STORE_TYPE_OPTIONS, translateLabel } from 'constants/format';
import { isOwner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { documentProps } from 'redux/reducers/dataProps';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { PaginationProps } from 'services/API/common/Fetcher';
import { CompanyStoreProps } from 'services/API/Company/interface';
import { useSwal } from 'helpers/sweetalert';
import { Card } from './Card';
import { StoreModal } from './StoreModal';
import { EmptyResult } from 'components/custom/table/emptyResult';
import { StoreProps } from 'services/API/Store/interface';
import { StoreSelect } from 'components/custom/ReactSelectAsync/Select/StoreSelect';

export interface StoresProps {
  location: RouteComponentProps;
}

const initialFilterState: ANY_OBJECT = {
  type: '',
  id: '',
  address_or_url: '',
};

const Stores = ({ location }: StoresProps) => {
  const {
    auth: { user },
    data: { logos },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [store, setStore] = useState<any>(null);
  const [stores, setStores] = useState<Array<CompanyStoreProps>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState<PaginationProps | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState<CompanyStoreProps | undefined>(undefined);

  const { type, address_or_url } = filter;

  const filterToParams = () => {
    const data: { [key: string]: string } = {
      id: store?.id ?? '',
      type,
      address_or_url,
    };

    const names = Object.keys(data);
    const values = Object.values(data);

    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        delete data[names[index]];
      }
    });

    Object.assign(data, {
      limit: 10,
      sort: '-created_at',
      page: currentPage,
    });

    return data;
  };

  const resetFilter = () => {
    setStore(null);
    setFilter(initialFilterState);
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (name: string) => (val: string | StoreProps) => {
    setFilter({
      ...filter,
      [name]: val,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      fetchStoresWithFilter();
    } else {
      setCurrentPage(1);
    }
  };

  const convertLogoToOptions = (logos: Array<documentProps>) => {
    return logos.map((logo) => ({
      label: logo.name,
      value: logo.id,
    }));
  };

  const fetchStoresWithFilter = async () => {
    setIsFetching(true);
    const params = filterToParams();

    const res = await dispatch(
      getStores(user?.company_id, { ...params, include: 'aml_domains, status' }),
    );
    setIsFetching(false);
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('store_list'),
        text: res.message || 'Oops!',
      });
    }

    setStores(res.data);
    setPagination(res.meta?.pagination ?? null);
  };

  const fetchLogos = async () => {
    const res = await dispatch(getStoreLogos(user?.company_id, { limit: 99999 }));
    if (!res.success) {
      await Swal.fire({
        title: 'Logos',
        text: res.message || 'Oops',
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    fetchStoresWithFilter();
    fetchLogos();
    //eslint-disable-next-line
  }, [user, currentPage]);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location as any} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="stores" name={'store_list'} />
          <div className="bg-white round-bottom-lg">
            <SideFilter
              isLoading={isFetching}
              resetFilter={resetFilter}
              onFilter={onFilterSearch}
              defaultCollapsed={true}>
              <StoreSelect
                legend={translate('store').toUpperCase()}
                setStore={setStore}
                store={store}
                defaultOption={[{ label: translate('all_stores'), value: '' }]}
              />
              <Input
                type="react-select"
                legend={translate('store_type').toUpperCase()}
                value={type}
                options={translateLabel(STORE_TYPE_OPTIONS, translate)}
                defaultLabel={translate('all_types')}
                name="type"
                onChange={onFilterSelectChange('type')}
                placeholder={translate('store_type')}
                menuPortalTarget={document.body}
              />
              <Input
                type="text"
                value={address_or_url}
                legend={translate(
                  type === '' ? 'address_or_url' : type === ONLINE_STORE ? 'store_url' : 'address',
                ).toUpperCase()}
                name="address_or_url"
                onChange={onFilterChange}
                placeholder={translate(
                  type === '' ? 'address_or_url' : type === ONLINE_STORE ? 'store_url' : 'address',
                ).toUpperCase()}
              />
            </SideFilter>
            <div className="card-container p-4">
              {isOwner(user.role) && (
                <div className="custom-card place-holder">
                  <button className="btn btn-outline-secondary" onClick={() => setShowModal(true)}>
                    {translate('create')}
                  </button>
                </div>
              )}
              {stores.map((store, index) => (
                <Card
                  store={store}
                  setShowModal={setShowModal}
                  setStore={setSelectedStore}
                  showAction={isOwner(user.role)}
                  key={store.id + index}
                />
              ))}
            </div>
            {pagination && (
              <>
                {pagination.total < 1 && <EmptyResult text="no_data" />}
                <div className="table-responsive admin mt-0">
                  <Pagination pagination={pagination} setCurrentPage={setCurrentPage} />
                  <div className="mb-5 mb-md-0"></div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <StoreModal
        company_id={user?.company_id}
        isModalOpen={showModal}
        setIsModalOpen={setShowModal}
        logos={convertLogoToOptions(logos)}
        store={selectedStore}
        setStore={setSelectedStore}
        reloadStore={fetchStoresWithFilter}
      />
    </>
  );
};

export default Stores;
