import logo from 'assets/img/registrationForm/bankAccountIcon.png';
import { BankCodeSelector } from 'components/custom/BankCodeSelector/BankCodeSelector';
import { BranchCodeSelector } from 'components/custom/BankCodeSelector/BranchCodeSelector';
import { FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';
import { BankAccountProps } from 'services/API/Auth/interface';
import { StepProps } from '../interface';
import { validateBank } from '../validator';

export const BankAccount = forwardRef(({ form, setForm }: StepProps, ref) => {
  const { translate } = useTranslate();

  const [bank, setBank] = useState<BankAccountProps>(form.bank_account!);
  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const { holder, name, code, branch, number } = bank;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'number') {
      const bankNumber = /^(\d+)/.exec(e.target.value);
      setBank({
        ...bank,
        [e.target.name]: (bankNumber && bankNumber[1]) ?? '',
      });
      return;
    }
    if (e.target.name === 'holder') {
      e.target.value = e.target.value.toUpperCase();
    }
    setBank({
      ...bank,
      [e.target.name]: e.target.value,
    });
  };

  const onSelectChange = (name: string) => async (value: string) => {
    setBank({
      ...bank,
      [name]: value,
    });
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const body = { ...bank };
      if (typeof body.code !== 'string') {
        body.code = (body.code as any).clearing_code;
      }
      if (typeof body.branch !== 'string') {
        body.branch = (body.branch as any).branch_code;
      }

      const { isValid, errors } = validateBank(body);
      setErrors(errors);
      if (!isValid) {
        return false;
      }
      setForm({
        ...form,
        bank_account: body,
      });
      return true;
    },
  }));

  return (
    <>
      <FormHeader title={translate('bank_account')} src={logo} />
      <div className="mt-2" />
      <Input
        type="text"
        name="holder"
        value={holder}
        legend={translate('account_holder').toUpperCase()}
        onChange={onChange}
        error={translate(...(errors.holder ?? ''))}
      />
      <Input
        type="text"
        name="name"
        value={name}
        legend={translate('bank_name').toUpperCase()}
        onChange={onChange}
        error={translate(...(errors.name ?? ''))}
      />
      <div className="row g-0">
        <div className="col-lg-6">
          <BankCodeSelector
            value={code}
            onChange={onSelectChange('code')}
            error={translate(...(errors.code ?? ''))}
          />
        </div>
        <div className="col-lg-6">
          {code && (
            <BranchCodeSelector
              clearingCode={(code as any).clearing_code ?? code}
              value={branch}
              onChange={onSelectChange('branch')}
              error={translate(...(errors.branch ?? ''))}
            />
          )}
        </div>
      </div>
      <Input
        type="text"
        name="number"
        value={number}
        onChange={onChange}
        legend={translate('account_number').toUpperCase()}
        error={translate(...(errors.number ?? ''))}
        maxLength={13}
      />
    </>
  );
});
