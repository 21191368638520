import no_found_image from 'assets/img/stores/no_logo_url.png';
import cancelLogo from 'assets/img/transaction_icons/payment_icon-01.png';
import successLogo from 'assets/img/transaction_icons/payment_icon-04.png';
import { Input } from 'components/custom/input';
import { CANCELLED, CAPTURED, FAILED, PAID } from 'constants/format';
import { findGatewayByCode } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { refundTransaction } from 'services/API/Transactions';
import { TransactionProps } from 'services/API/Transactions/interface';
import { useSwal } from 'helpers/sweetalert';
import { validate } from './validator';

interface RefundModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  transaction?: TransactionProps;
  closeAll: () => void;
}

interface TransactionDetailsProps {
  transaction?: TransactionProps;
}

interface DetailRowProps {
  name: string;
  value: string;
  nameWidth?: string;
  valueWidth?: string;
  right?: boolean;
}

const renderLogo = (transaction_status: string) => {
  switch (transaction_status) {
    case CANCELLED.toLowerCase():
      return cancelLogo;
    case PAID.toLowerCase():
    case CAPTURED.toLowerCase():
      return successLogo;
    case FAILED.toLowerCase():
      return cancelLogo;
    case 'pending':
      return no_found_image;
    default:
      return transaction_status;
  }
};

export const RefundModal = ({ isOpen, setIsOpen, transaction, closeAll }: RefundModalProps) => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [errors, setErrors] = useState<any>({});
  const [refundAmount, setRefundAmount] = useState<number | ''>(transaction?.amount ?? '');
  const [refundReason, setRefundReason] = useState('');
  const [isRefunding, setIsRefunding] = useState(false);

  const refund_amount = transaction?.amount ?? '0';

  const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name === 'refund_amount') {
      setRefundAmount((e.target as any).rawValue);
    } else {
      setRefundReason(e.target.value);
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      amount: refundAmount,
      refund_reason: refundReason,
    };

    const { errors, isValid } = validate(body, transaction?.amount ?? 0);
    setErrors(errors);

    if (!isValid) {
      setIsRefunding(false);
      return;
    }

    setIsRefunding(true);
    const result = await Swal.fire({
      title: translate('refund'),
      text: translate('are_you_sure_refund_transaction'),
      icon: 'warning',
      showCancelButton: true,
    });

    if (!result.value) {
      setIsRefunding(false);
      return;
    }

    const res = await refundTransaction(transaction!.id, body as any);

    if (!res.success) {
      Swal.fire({
        title: translate('refund'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      setIsRefunding(false);
      return;
    }
    closeAll();
    setIsRefunding(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate('refund_transaction')}</ModalHeader>
      <ModalBody>
        <div className="p-4 text-center">
          <TransactionDetails transaction={transaction} />
          <hr />
          <form onSubmit={onSubmit}>
            <div>
              <Input
                legend={translate('amount').toUpperCase()}
                type="cleave"
                value={refund_amount}
                name="refund_amount"
                onChange={onChange}
                prefix="$"
                cleaveOptions={{
                  numeral: true,
                  numeralDecimalScale: 2,
                  numeralIntegerScale: 7,
                  numeralPositiveOnly: true,
                }}
                error={translate(...(errors.amount ?? ''))}
              />
              <Input
                type="text"
                name="refund_reason"
                value={refundReason}
                legend={translate('refund_reason').toUpperCase()}
                onChange={onChange}
                error={translate(...(errors.refund_reason ?? ''))}
              />
              <div className="text-center mt-2">
                <button className="btn btn-primary btn-sm">
                  {isRefunding ? <Spinner size="sm" /> : translate('confirm_refund')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

const TransactionDetails = ({ transaction }: TransactionDetailsProps) => {
  const { translate } = useTranslate();
  const DetailRow = ({
    name,
    value,
    nameWidth = 'col-6',
    valueWidth = 'col-6',
    right = false,
  }: DetailRowProps) => {
    if (!value || !`${value}`.replace(/ /g, '')) {
      return null;
    }
    return (
      <div className="row">
        <div className={nameWidth}>
          <div>{name}:</div>
        </div>
        <div className={valueWidth}>
          <div className={right ? 'text-end' : ''}>{value}</div>
        </div>
      </div>
    );
  };
  return (
    <>
      <h2 className="colfax-regular text-secondary mt-0">
        {translate(transaction?.status ?? '').toUpperCase()}
      </h2>
      <img
        src={renderLogo(transaction?.status ?? '')}
        alt=""
        className="img-fluid transaction-status-logo"
      />
      <div className="refund-amount">
        {transaction?.amount}&nbsp;
        <span className="text-secondary">{transaction?.currency}</span>
      </div>
      <span className="text-secondary d-block">{transaction?.created_at}</span>
      <hr />
      <span>{translate('transaction_details')}</span>
      <div className="row justify-content-center  text-secondary mt-2">
        <div className="col-lg-8 px-lg-4 transaction-details text-start">
          <DetailRow
            name={translate('gateway')}
            value={findGatewayByCode(transaction?.gateway_code ?? '')?.name ?? ''}
          />
          <DetailRow name={translate('transaction_id')} value={transaction?.transaction_id ?? ''} />
          <DetailRow name={translate('status')} value={transaction?.status ?? ''} />
          <DetailRow name={translate('forex')} value={transaction?.forex ?? ''} />
          <DetailRow name={translate('fee')} value={transaction?.charge ?? ''} />
          <DetailRow name={translate('store_name')} value={transaction?.store?.name ?? ''} />
          <DetailRow name={translate('store_address')} value={transaction?.store?.address ?? ''} />
        </div>
      </div>
    </>
  );
};
