import logo from 'assets/img/registrationForm/yourBusinessIcon.png';
import { FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { DocPreviewModal } from 'components/custom/modals/DocPreviewModal';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { BusinessRegistration } from 'services/API/Auth/interface';
import { uploadFileToCompany } from 'services/API/Company';
import { OCRResult, UploadFileToCompanyResponse } from 'services/API/Company/interface';
import { useSwal } from 'helpers/sweetalert';
import { StepProps } from '../interface';
import { validateBr } from '../validator';

const initialState: BusinessRegistration = {
  file_id: '',
  br_number: '',
  branch_number: '',
  expiry_date: '',
  company_name: '',
  type_of_business: '',
};

export const BRUpload = forwardRef(({ form, setForm, setIsLoading }: StepProps, ref) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [br, setBr] = useState<BusinessRegistration>(initialState);
  const [errors, setErrors] = useState<ANY_OBJECT>({});
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
  const [lockInput, setLockInput] = useState(true);
  const [uploadFile, setUploadFile] = useState<File>();
  const [uploadStatus, setUploadStatus] = useState<'' | 'uploading' | 'on_verification' | 'error'>(
    '',
  );

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.name === 'address') {
      setForm({
        ...form,
        address: e.target.value,
      });
      return;
    } else if (e.target.name === 'type_of_business') {
      e.target.value = `${parseInt(e.target.value) || 0}`;
    }

    setBr({
      ...br,
      [e.target.name]: e.target.value,
    });
  };

  const onBrSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    setUploadFile(file);
    setIsUploadFileModalOpen(true);
  };

  const onBrChange = async (file: File) => {
    setIsLoading(true);
    const data = await getOCRResult(file);
    setIsLoading(false);
    if (!data) {
      setBr({
        ...br,
        uploaded: false,
      });
      return;
    }

    const {
      id,
      ocr_result: { address, br_number, branch_number, expiry_date },
    } = data;

    setBr({
      ...br,
      file_id: id ?? '',
      br_number: br_number ?? '',
      branch_number: branch_number ?? '',
      expiry_date: expiry_date ?? '',
      uploaded: true,
    });

    setForm({
      ...form,
      address: address ?? '',
    });
  };

  const getOCRResult = async (file: File) => {
    if (!user.company_id) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'ocr_br');
    formData.append('shared', 'false');

    setUploadStatus('uploading');

    let res: UploadFileToCompanyResponse | null = null;
    try {
      res = await uploadFileToCompany(user.company_id, formData);
      setUploadStatus('on_verification');

      if (!res.success) {
        throw new Error(res?.message || 'Oops');
      } else if (!(res.data as OCRResult).ocr_result.is_valid) {
        throw new Error(translate('ocr_fail_msg'));
      }

      return res?.data as OCRResult;
    } catch (err: any) {
      setUploadStatus('error');
      await Swal.fire({
        title: translate('ocr_failed'),
        text: err.message,
        icon: 'error',
      });
      return;
    } finally {
      setLockInput(false); // Allow Input even when ocr is failed.
    }
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const business_registration = {
        ...br,
      };

      business_registration.company_name = user?.company?.name;

      let finalAddress = form.address && form.address.trim().replace(/['"]/g, '');
      const checkAddressLastWords = finalAddress.split(',').pop();
      if (checkAddressLastWords?.trim()?.toUpperCase() !== 'HONG KONG') {
        finalAddress += ', Hong Kong';
      }

      const { isValid, errors } = validateBr(business_registration, finalAddress);

      setErrors(errors);
      if (!isValid) {
        return false;
      }

      setForm({
        ...form,
        business_registration,
        address: finalAddress,
      });
      return true;
    },
  }));

  useEffect(() => {
    const br = form.business_registration;
    setBr(br);

    if (br && br.uploaded) {
      setUploadStatus('on_verification');
      setLockInput(false);
    }
    // eslint-disable-next-line
  }, []);

  const { address } = form;
  const { br_number, branch_number, expiry_date } = br;

  return (
    <>
      <FormHeader
        title={translate('business_registration')}
        caption={`${translate('company_name')}: ${user?.company?.name}`}
        src={logo}
      />
      <div className="mt-2">
        <Input
          type="file-progress"
          name=""
          status={uploadStatus}
          onChange={onBrSelected}
          legend={translate('upload_br').toUpperCase()}
          isRequired
          error={translate(...(errors.file_id ?? ''))}
        />
      </div>
      <div className="row g-0">
        <div className="col-lg-6">
          <Input
            name="br_number"
            type="text"
            inputmode="numeric"
            maxLength={8}
            onChange={onChange}
            legend={translate('br_number').toUpperCase()}
            value={br_number}
            disabled={lockInput}
            isRequired
            error={translate(...(errors.br_number ?? ''))}
          />
        </div>
        <div className="col-lg-6">
          <Input
            name="branch_number"
            type="text"
            inputmode="numeric"
            maxLength={3}
            onChange={onChange}
            legend={translate('branch_code').toUpperCase()}
            value={branch_number}
            disabled={lockInput}
            isRequired
            error={translate(...(errors.branch_number ?? ''))}
          />
        </div>
      </div>
      <Input
        name="expiry_date"
        type="cleave"
        onChange={onChange}
        legend={translate('expiry_date').toUpperCase()}
        placeholder="YYYY-MM-DD"
        value={expiry_date}
        cleaveOptions={{
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        }}
        disabled={lockInput}
        isRequired
        error={translate(...(errors.expiry_date ?? ''))}
      />
      <Input
        name="address"
        type="text"
        onChange={onChange}
        legend={translate('address').toUpperCase()}
        value={address}
        disabled={lockInput}
        isRequired
        error={translate(...(errors.address ?? ''))}
      />
      <DocPreviewModal
        isOpen={isUploadFileModalOpen}
        setIsOpen={setIsUploadFileModalOpen}
        file={uploadFile}
        setFile={setUploadFile}
        onConfirm={onBrChange}
      />
    </>
  );
});
