import { Dispatch } from 'redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { AnyResponse } from 'services/API/common/Fetcher';
import { toggleLoader } from '../screenLoader';

export default <T = AnyResponse>(
    fn: (
      dispatch: Dispatch | FunctionDispatch,
      getState: () => GlobalTypes.RootState,
    ) => Promise<T>,
    isShowLoader = true,
  ) =>
  async (
    dispatch: Dispatch | FunctionDispatch,
    getState: () => GlobalTypes.RootState,
  ): Promise<T> => {
    if (isShowLoader) {
      toggleLoader(true);
    }

    try {
      return Promise.resolve(fn(dispatch, getState));
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      toggleLoader(false);
    }
  };
