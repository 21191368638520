import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import notFoundImg from 'assets/img/table/data_not_found.png';
import { useTranslate } from 'context/TranslateContext';
import { useState } from 'react';

export const SummaryTable = ({ summaryData }: any) => {
  const { translate } = useTranslate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (!summaryData) {
    return <></>;
  }

  return (
    <>
      <div className="filter-container d-flex flex-column justify-content-between bg-white shadow-sm mb-4 d-md-none">
        <div className="filter-title" onClick={() => setIsCollapsed(!isCollapsed)}>
          <span className="colfax-bold">{translate('analysis_summary')}</span>
          <span className="colfax-bold d-md-none">
            <FontAwesomeIcon icon={isCollapsed ? faCaretDown : faCaretUp} />
          </span>
        </div>

        <ul
          className={`list-group list-group-flush d-md-none p-4 ${isCollapsed ? 'collapsed' : ''}`}>
          {[
            'total_paid',
            'total_paid_amount',
            'total_charge',
            'total_refund',
            'total_refund_amount',
            'total_void',
            'total_void_amount',
            'total_cancel',
            'total_transactions',
            'average_ticket',
          ].map((field) => (
            <li
              className="list-group-item d-flex justify-content-between align-items-center"
              key={field}>
              <span>{translate(field)}</span>
              <span>{summaryData[field]}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="table-responsive  d-none d-md-block">
        <table className="table">
          <thead>
            <tr>
              <th className="border-end border-top-0">{translate('total_paid')}</th>
              <th className="border-end border-top-0">{translate('total_paid_amount')}</th>
              <th className="border-end border-top-0">{translate('total_charge')}</th>
              <th className="border-end border-top-0">{translate('total_refund')}</th>
              <th className="border-end border-top-0">{translate('total_refund_amount')}</th>
              <th className="border-end border-top-0">{translate('total_void')}</th>
              <th className="border-end border-top-0">{translate('total_void_amount')}</th>
              <th className="border-end border-top-0">{translate('total_cancel')}</th>
              <th className="border-end border-top-0">{translate('total_transactions')}</th>
              <th className="border-top-0">{translate('average_ticket')}</th>
            </tr>
          </thead>
          <tbody className="mt-3">
            {summaryData ? (
              <tr>
                <td>{summaryData.total_paid}</td>
                <td>{summaryData.total_paid_amount}</td>
                <td>{summaryData.total_charge}</td>
                <td>{summaryData.total_refund}</td>
                <td>{summaryData.total_refund_amount}</td>
                <td>{summaryData.total_void}</td>
                <td>{summaryData.total_void_amount}</td>
                <td>{summaryData.total_cancel}</td>
                <td>{summaryData.total_transactions}</td>
                <td>{summaryData.average_ticket}</td>
              </tr>
            ) : (
              <tr className="">
                <td colSpan={10} rowSpan={5} className="text-center">
                  <div>
                    <img src={notFoundImg} alt="" className="img-fluid my-4" />
                  </div>
                  <span className="grey-text">{translate('no_data')}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
