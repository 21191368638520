import { Input } from 'components/custom/input';
import { useTranslate } from 'context/TranslateContext';
import moment from 'moment';
import { FormEvent, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { validate } from './validator';

interface CustomDateRangeModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSelect: (value: any) => void;
}

export const CustomDateRangeModal = ({
  isOpen,
  setIsOpen,
  onSelect,
}: CustomDateRangeModalProps) => {
  const { translate } = useTranslate();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [errors, setErrors] = useState<any>({});

  const toggle = () => {
    const now = moment().format('YYYY-MM-DD');
    onSelect({ start_date: now, end_date: now });
    setIsOpen(!isOpen);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { errors, isValid } = validate(
      {
        start_date: startDate,
        end_date: endDate,
      },
      translate,
    );
    setErrors(errors);

    if (!isValid) {
      return;
    }

    onSelect({
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    });
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate('select_date')}</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <form onSubmit={onSubmit}>
            <div>
              <Input
                type="date"
                value={startDate}
                legend={translate('from').toUpperCase()}
                name="start_date"
                onChange={(date: Date) => setStartDate(date)}
                placeholder="YYYY-MM-DD"
                error={translate(...(errors.start_date ?? ''))}
              />
              <Input
                type="date"
                value={endDate}
                legend={translate('to').toUpperCase()}
                name="end_date"
                onChange={(date: Date) => setEndDate(date)}
                placeholder="YYYY-MM-DD"
                error={translate(...(errors.end_date ?? ''))}
              />
              <div className="text-center mt-2 d-grid">
                <button className="btn btn-primary mt-3">{translate('submit')}</button>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};
