import { TopHeader } from 'components/layouts/page/topHeader';
import {
  findGatewayGroupByCode,
  GatewayGroup,
  ONLINE_GATEWAY_GROUP,
} from 'constants/TransactionGateways';
import { uniqBy } from 'lodash';
import '../../layouts/card/GatewayCard.scss';
import { GatewayApplyCard } from '../GatewayApplyCard';
import { GatewayDetailCard } from '../GatewayDetailCard';

export interface GatewayListProps {
  codeList: Array<string>;
  getCredentials: () => Promise<void>;
  isApplied: boolean;
  appliedOnlineGateway: boolean;
  title: string;
  caption?: string;
  textOnly: boolean;
}

export const GatewayList = ({
  codeList,
  getCredentials,
  isApplied,
  appliedOnlineGateway,
  title,
  caption,
  textOnly,
}: GatewayListProps) => {
  if (codeList.length === 0) {
    return <></>;
  }

  const gatewayDetailCards: Array<any> = [];
  if (isApplied) {
    codeList.map((gatewayCode) => {
      gatewayDetailCards.push(
        <GatewayDetailCard
          gatewayCode={gatewayCode}
          getCredentials={getCredentials}
          key={gatewayCode}
        />,
      );
    });
  } else {
    let gatewayGroups: Array<GatewayGroup> = [];
    codeList.forEach((gatewayCode) => {
      const group = findGatewayGroupByCode(gatewayCode);
      if (group) {
        gatewayGroups.push(group);
      }
    });

    if (!appliedOnlineGateway) {
      gatewayGroups = gatewayGroups.filter((group) => group.type === 'offline');
      gatewayGroups.push(ONLINE_GATEWAY_GROUP);
    }

    gatewayGroups = uniqBy(gatewayGroups, 'name');
    gatewayGroups.map((gatewayGroup: any) => {
      return gatewayDetailCards.push(
        <GatewayApplyCard
          gatewayGroup={gatewayGroup}
          getCredentials={getCredentials}
          key={gatewayGroup.name}
        />,
      );
    });
  }
  for (let i = 0; i < gatewayDetailCards.length % 4; i++) {
    gatewayDetailCards.push(
      <div className="d-xs-none" style={{ width: 290, height: 0 }} key={`placeholder_${i}`}></div>,
    );
  }

  return (
    <>
      {title && <TopHeader title={title} caption={caption} textOnly={textOnly}></TopHeader>}
      <div className="d-md-none" style={{ flexGrow: 0.05 }}></div>
      <div className="main-content">
        <div className="gatewayList">{gatewayDetailCards.map((card) => card)}</div>
      </div>
    </>
  );
};
