import logo from 'assets/img/registrationForm/activationIcon.png';
import { Password } from 'components/custom/Auth/Password';
import { Username } from 'components/custom/Auth/Username';
import { FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { ANY_OBJECT, MERCHANT_TYPE_INDIVIDUAL, TAXI_MCC } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getActivationCode } from 'services/API/Auth';
import '../../../layouts/button/button.scss';
import { StepProps } from '../interface';
import { activationCodeValidate, activationValidate } from '../validator';

export const Activation = forwardRef(({ form, setForm }: StepProps, ref) => {
  const { translate } = useTranslate();

  const [countdown, setCountdown] = useState<number>(0);

  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const focusRef = useRef<HTMLInputElement>(null);

  const onActivationTypeChange = (type: 1 | 2) => {
    setForm({
      ...form,
      activation_type: type,
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const getVerificationCode = async () => {
    const { isValid, errors } = activationCodeValidate(form);
    setErrors(errors);
    if (!isValid) {
      return;
    }
    focusRef?.current?.focus();
    setCountdown(60);
    const body = {
      country_code: `+${form.country_code as string}`,
      phone: form.phone as string,
      email: form.email as string,
    } as any;

    if (body.email) {
      delete body.phone;
      delete body.country_code;
    } else {
      delete body.email;
    }

    const res = await getActivationCode(body);
    if (!res.success) {
      const err: ANY_OBJECT = {};
      res.errors?.forEach((e: ANY_OBJECT) => {
        err[e.field] = [e.message || 'Oops'];
      });
      setErrors(err);
    }
  };

  // 1: Email 2: Phone
  const {
    activation_type,
    email,
    country_code,
    phone,
    first_name,
    last_name,
    position,
    username,
    password,
    password_confirmation,
    operation_id,
    driving_license,
    taxi_number,
    activation_code,
    company: { mcc, merchant_type },
  } = form;

  useImperativeHandle(ref, () => ({
    validate: () => {
      const { isValid, errors } = activationValidate(form);
      setErrors(errors);
      return isValid;
    },
  }));

  // Countdown 60 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (countdown > 0) {
      timeout = setTimeout(() => setCountdown(countdown - 1), 1000);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [countdown]);

  return (
    <>
      <FormHeader title={translate('activation_type')} src={logo} />
      <div className="row" style={{ display: `${mcc}` === TAXI_MCC.toString() ? 'none' : '' }}>
        <div className="col-6 col-lg-4 d-grid">
          <button
            type="button"
            className={`btn btn-${
              activation_type === 1 ? '' : 'outline-'
            }primary colfax-regular btn-sm`}
            disabled={`${mcc}` === TAXI_MCC.toString()}
            onClick={() => onActivationTypeChange(1)}>
            {translate('email')}
          </button>
        </div>
        <div className="col-6 col-lg-4 d-grid">
          <button
            type="button"
            className={`btn btn-${
              activation_type === 2 ? '' : 'outline-'
            }primary colfax-regular btn-sm`}
            disabled={`${mcc}` === TAXI_MCC.toString()}
            onClick={() => onActivationTypeChange(2)}>
            {translate('sms')}
          </button>
        </div>
      </div>
      {activation_type === 1 ? (
        <Input
          isRequired
          type="text"
          legend={translate('email').toUpperCase()}
          name="email"
          value={email as string}
          onChange={onChange}
          placeholder={translate('enter_your_email_address')}
          error={translate(...(errors.email ?? ''))}
        />
      ) : (
        <div className="row g-0">
          <div className="col-lg-6">
            <Input
              type="text"
              legend={translate('country_code').toUpperCase()}
              placeholder=""
              prefix="+"
              value={country_code as string}
              onChange={onChange}
              name="country_code"
              isRequired
              disabled
              error={translate(...(errors.country_code ?? ''))}
            />
          </div>
          <div className="col-lg-6">
            <Input
              type="text"
              legend={translate('phone').toUpperCase()}
              placeholder={translate('enter_phone')}
              value={phone as string}
              onChange={onChange}
              name="phone"
              isRequired
              error={translate(...(errors.phone ?? ''))}
            />
          </div>
          {`${mcc}` === TAXI_MCC.toString() && (
            <div className="col-lg-12">
              <Input
                type="text"
                legend={translate('email').toUpperCase()}
                name="email"
                value={email as string}
                onChange={onChange}
                placeholder={translate('enter_your_email_address')}
                error={translate(...(errors.email ?? ''))}
              />
            </div>
          )}
        </div>
      )}
      <div className="row g-0">
        <div className="col-lg-6">
          <Input
            isRequired
            type="text"
            legend={translate('first_name')}
            name="first_name"
            value={first_name as string}
            onChange={onChange}
            placeholder={translate('enter_first_name')}
            error={translate(...(errors.first_name ?? ''))}
          />
        </div>
        <div className="col-lg-6">
          <Input
            isRequired
            type="text"
            legend={translate('last_name')}
            name="last_name"
            value={last_name as string}
            onChange={onChange}
            placeholder={translate('enter_last_name')}
            error={translate(...(errors.last_name ?? ''))}
          />
        </div>
      </div>
      {`${mcc}` !== TAXI_MCC.toString() && merchant_type === MERCHANT_TYPE_INDIVIDUAL && (
        <Input
          isRequired
          type="text"
          legend={translate('hawker_operation_id').toUpperCase()}
          name="operation_id"
          value={operation_id as string}
          onChange={onChange}
          placeholder={translate('enter_hawker_operation_id')}
          error={translate(...(errors.operation_id ?? ''))}
        />
      )}
      {`${mcc}` === TAXI_MCC.toString() && (
        <>
          <Input
            isRequired
            type="text"
            legend={translate('taxi_operation_id').toUpperCase()}
            name="operation_id"
            value={operation_id as string}
            onChange={onChange}
            placeholder={translate('enter_taxi_operation_id')}
            error={translate(...(errors.operation_id ?? ''))}
          />
          <Input
            isRequired
            type="text"
            legend={translate('driving_license').toUpperCase()}
            name="driving_license"
            value={driving_license as string}
            onChange={onChange}
            placeholder={translate('enter_license_number')}
            error={translate(...(errors.driving_license ?? ''))}
          />
          <Input
            isRequired
            type="text"
            legend={translate('taxi_number').toUpperCase()}
            name="taxi_number"
            value={taxi_number as string}
            onChange={onChange}
            placeholder={translate('enter_taxi_number')}
            error={translate(...(errors.taxi_number ?? ''))}
          />
        </>
      )}
      <Username
        username={username}
        setUsername={(username: string) => setForm({ ...form, username })}
        setErrors={setErrors}
        errors={errors}
      />
      <Input
        isRequired
        type="text"
        legend={translate('position').toUpperCase()}
        name="position"
        value={position as string}
        onChange={onChange}
        placeholder={translate('enter_position')}
        error={translate(...(errors.position ?? ''))}
      />
      <div className="row g-0">
        <div className="col-lg-6">
          <Password
            password={password}
            setPassword={(password) => setForm({ ...form, password })}
            setErrors={setErrors}
            errors={errors}
          />
        </div>
        <div className="col-lg-6">
          <Input
            isRequired
            type="password"
            legend={translate('confirm_password').toUpperCase()}
            name="password_confirmation"
            value={password_confirmation as string}
            onChange={onChange}
            placeholder={translate('enter_password')}
            error={translate(...(errors.password_confirmation ?? ''))}
          />
        </div>
      </div>
      <Input
        focusRef={focusRef}
        isRequired
        type="text"
        legend={translate('activate_code')}
        name="activation_code"
        value={activation_code as string}
        onChange={onChange}
        placeholder={translate('enter_activate_code')}
        error={translate(...(errors.activation_code ?? ''))}
        maxLength={6}
        suffix={
          <button
            type="button"
            className="btn btn-sm text-black colfax-regular"
            disabled={countdown !== 0}
            onClick={getVerificationCode}>
            {countdown === 0
              ? translate('get_code')
              : translate(`please_try_again_after_sec`).replace('{sec}', `${countdown}`)}
          </button>
        }
      />
    </>
  );
});
