import { Input } from 'components/custom/input';
import { ONLINE_STORE, PAY_LINK_STATUS_OPTIONS, translateLabel } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { rectifyAmount } from 'helpers/functions/rectifyAmount';
import { useSwal } from 'helpers/sweetalert';
import moment from 'moment';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { createPayByLinkCode, updatePayByLink } from 'services/API/PayByLinkCode';
import { CompanyPayByLinkCode, PayByLinkCodeBodyProps } from 'services/API/PayByLinkCode/interface';
import { validate } from './validator';
import { StoreSelect } from 'components/custom/ReactSelectAsync/Select/StoreSelect';

// @todo should create a global interface for modal
interface PayByLinkCodeModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  payByLinkCode: CompanyPayByLinkCode | null;
  reloadCodes: () => void;
}

const initialState = {
  amount: '',
  custom_id: '',
  currency: 'HKD',
  date: moment().add(1, 'months').toDate(),
  expiry_date: '',
  time: moment().format('HH:mm:ss'),
  status: 0,
  subject: '',
  remarks: '',
} as PayByLinkCodeBodyProps;

const getStatus = (code: number) => {
  switch (code) {
    case 0:
      return 'active';
    case 1:
      return 'inactive';
    case 2:
      return 'completed';
    case 3:
      return 'expired';
    default:
      return '';
  }
};

// @todo should create a modal wrapper, for efficiency
export const CreateEditCodeModal = ({
  isOpen,
  setIsOpen,
  payByLinkCode,
  reloadCodes,
}: PayByLinkCodeModalProps) => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [form, setForm] = useState<PayByLinkCodeBodyProps>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [store, setStore] = useState<any>(null);

  const { amount, custom_id, date, time, status, subject, remarks } = form;
  const isEdit = !!payByLinkCode;

  // Form Functions

  const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name === 'amount') {
      const { rawValue } = e.target as any;
      const amount: number | '' = rectifyAmount(rawValue as string);
      setForm({
        ...form,
        amount,
      });
      return;
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onDateChange = (date: Date) => {
    setForm({
      ...form,
      date,
    });
  };

  const onFieldChange = (name: string) => (val: string | number) => {
    if (name === 'is_fixed_amount' && val) {
      form.amount = 0;
    }
    setForm({
      ...form,
      [name]: val,
    });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!payByLinkCode) {
      setStore(null);
      setForm(initialState);
      return;
    }
    const attr: any = {};

    attr.amount = payByLinkCode.amount;
    attr.custom_id = payByLinkCode.custom_id;
    attr.currency = payByLinkCode.currency;
    attr.id = payByLinkCode.id;
    attr.store_name = payByLinkCode.store_name;
    attr.status = payByLinkCode.status;
    attr.subject = payByLinkCode.subject;
    attr.remarks = payByLinkCode.remarks;
    attr.date = new Date(payByLinkCode.expired_at);
    attr.time = payByLinkCode.expired_at.substr(11, 8);

    setForm(attr);
    setStore(payByLinkCode?.store);
  }, [payByLinkCode, isOpen]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let apiCall: () => Promise<any>;

    form.status = form.status ? 1 : 0;
    const { ...body } = form;
    const expiry_date: string = moment(date).format('YYYY-MM-DD');
    body.expiry_date = moment(expiry_date + ' ' + time).format('YYYY-MM-DD HH:mm:ss');
    delete body.date;
    delete body.time;

    const { errors, isValid } = validate(form, store?.id ?? '');
    setErrors(errors);
    if (!isValid) {
      return;
    }

    if (isEdit) {
      apiCall = () => updatePayByLink(payByLinkCode?.id, body);
    } else {
      apiCall = () => createPayByLinkCode(store?.id ?? '', body);
    }

    setIsLoading(true);
    const res = await apiCall();
    setIsLoading(false);

    if (!res.success) {
      await Swal.fire({
        title: translate('qr_code'),
        text: res.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    await Swal.fire({
      icon: 'success',
      title: translate('pay_link'),
      text: translate(
        isEdit
          ? 'congratulations_code_successfully_updated'
          : 'congratulations_new_code_successfully_created',
      ),
    });
    reloadCodes();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {payByLinkCode ? translate('update_pay_link') : translate('create_pay_link')}
      </ModalHeader>
      <ModalBody>
        <div className="p-2">
          <form onSubmit={onSubmit}>
            <div className="justify-content-center">
              <div className="text-center mb-2">
                <div className="text-start">
                  <Input
                    isRequired
                    type="text"
                    value={subject}
                    name="subject"
                    legend={translate('subject').toUpperCase()}
                    onChange={onChange}
                    error={translate(...(errors.subject ?? ''))}
                  />

                  <Input
                    isRequired
                    type="cleave"
                    prefix="$"
                    cleaveOptions={{
                      numeral: true,
                      numeralDecimalScale: 2,
                      numeralIntegerScale: 7,
                      numeralPositiveOnly: true,
                    }}
                    onChange={onChange}
                    value={amount as number}
                    name="amount"
                    legend={translate('amount').toUpperCase()}
                    error={translate(...(errors.amount ?? ''))}
                  />

                  <StoreSelect
                    legend={translate('store').toUpperCase()}
                    disabled={isEdit}
                    isRequired
                    showStatus
                    setStore={setStore}
                    store={store}
                    searchOptionsParams={{ include: 'status', type: ONLINE_STORE }}
                    error={errors.storeId}
                  />

                  <Input
                    type="textarea"
                    value={remarks}
                    name="remarks"
                    maxLength={255}
                    legend={translate('remarks').toUpperCase()}
                    onChange={onChange}
                    error={translate(...(errors.remarks ?? ''))}
                  />

                  <Input
                    isRequired
                    type="text"
                    value={custom_id}
                    name="custom_id"
                    legend={translate('custom_id').toUpperCase()}
                    onChange={onChange}
                    error={translate(...(errors.custom_id ?? ''))}
                  />

                  <Input
                    type="date"
                    value={date as Date}
                    legend={translate('expired_at').toUpperCase()}
                    name="date"
                    onChange={onDateChange}
                    placeholder="YYYY-MM-DD"
                  />
                  <Input
                    type="cleave"
                    cleaveOptions={{
                      time: true,
                      timePattern: ['h', 'm', 's'],
                    }}
                    value={time as string}
                    legend={translate('time').toUpperCase()}
                    name="time"
                    onChange={onChange}
                    placeholder="hh:mm:ss"
                  />
                  <Input
                    isRequired
                    legend={translate('status').toUpperCase()}
                    type="react-select"
                    options={translateLabel(PAY_LINK_STATUS_OPTIONS, translate, true)}
                    value={status}
                    onChange={onFieldChange('status')}
                    placeholder={getStatus(status)}
                    name="status"
                    error={translate(...(errors.status ?? ''))}
                  />
                  <div className="d-grid">
                    <button className="btn btn-primary mt-3" disabled={isLoading}>
                      {isLoading ? <Spinner size="sm" /> : translate('submit')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};
