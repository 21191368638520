import BusinessSetup from 'components/Auth/business-setup/wrapper';
import { ForceResetPassword } from 'components/Auth/force-reset-password';
import { ForgotPassword } from 'components/Auth/forgot_password';
import Login from 'components/Auth/login';
import Logout from 'components/Auth/login/logout';
import { Register } from 'components/Auth/register';
import { ResetPassword } from 'components/Auth/reset_password';
import { SetPassword } from 'components/Auth/setup_password';
import { RequestSetPassword } from 'components/Auth/setup_password_request';
import { RsoRegister } from 'components/RsoRegister';
import MainComponent from 'components/layouts/main.layout';
import history from 'helpers/history';
import { FC } from 'react';
import { hot } from 'react-hot-loader/root';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import checkAuth from 'services/auth_middleware/checkAuth.middleware';
import requireAuth from 'services/auth_middleware/requireAuth.middleware';
import { LoginRedirect } from './components/Auth/login/login-redirect';

if (!localStorage.getItem('initUrl') && window.location.pathname !== '/logout') {
  const currentPath = window.location.pathname;
  const regex = new RegExp('forget-password|set-password-request|set-password');
  const checkPath = regex.test(currentPath);
  if (!checkPath) {
    localStorage.setItem('initUrl', window.location.pathname);
  }
}

const App: FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Redirect exact path="/" to="/account-summary" />
        <Route exact path="/login/redirect" component={LoginRedirect} />
        <Route exact path="/login" component={checkAuth(Login)} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/register" component={checkAuth(Register)} />
        <Route exact path="/rso-register" component={checkAuth(RsoRegister)} />
        <Route exact path="/dbs-register" component={checkAuth(RsoRegister)} />
        <Route exact path="/meepay-register" component={checkAuth(RsoRegister)} />
        <Route exact path="/polly-register" component={checkAuth(RsoRegister)} />
        <Route exact path="/business-setup" component={BusinessSetup} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/users/:userID/password-update/:token" component={ForceResetPassword} />
        <Route exact path="/forget-password" component={ForgotPassword} />
        <Route exact path="/set-password-request" component={RequestSetPassword} />
        <Route exact path="/set-password/:token" component={SetPassword} />
        <Route component={requireAuth(MainComponent)} />
      </Switch>
    </Router>
  );
};
// export default hot(App)
export default process.env.NODE_ENV === 'development' ? hot(App) : App;
