import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import img from 'assets/img/table/data_not_found.png';
import { Pagination } from 'components/custom/pagination';
import { useTranslate } from 'context/TranslateContext';
import { useCountdown } from 'helpers/hooks/useCountdown';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { DisplayPayLinkCodeModal } from './DisplayPayLinkCodeModal';

export const StaticDataTable = ({ setCurrentPage, onRowClick, selectedCode }: any) => {
  const [showCodeModal, setShowCodeModal] = useState(false);

  const {
    data: {
      payByLinkCodes: { payByLinkCodes, payByLinkCodesPagination },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const { translate } = useTranslate();
  const [countdown, setCountdown] = useCountdown();

  const onGetLinkBtnClicked = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      setCountdown(5);
    });
  };

  const getLinks = (code: any) => {
    return code._links;
  };

  const getStatus = (code: number) => {
    switch (code) {
      case 0:
        return 'active';
      case 1:
        return 'inactive';
      case 2:
        return 'completed';
      case 3:
        return 'expired';
      default:
        return '';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';
      case 'active':
        return 'primary';
      case 'completed':
        return 'success';
      case 'expired':
        return 'warning';
      default:
        return '';
    }
  };

  return (
    <>
      <div className="table-responsive shadow-sm">
        <table className="table">
          <thead>
            <tr>
              <th className="border-end border-top-0">{translate('subject')}</th>
              <th className="border-end border-top-0">{translate('store_name')}</th>
              <th className="border-end border-top-0">{translate('custom_id')}</th>
              <th className="border-end border-top-0">{translate('amount')}</th>
              <th className="border-end border-top-0">{translate('qr_code')}</th>
              <th className="border-end border-top-0">{translate('pay_link')}</th>
              <th className=" border-top-0">{translate('status')}</th>
            </tr>
          </thead>
          <tbody className="mt-3">
            {payByLinkCodes &&
              payByLinkCodes.map((code) => (
                <tr key={`_${code.id}`} onClick={onRowClick(code)} className="cursor-pointer">
                  <td className="align-middle">{code.subject}</td>
                  <td className="align-middle">{code.store_name}</td>
                  <td className="align-middle">{code.custom_id}</td>
                  <td className="align-middle">
                    {code.amount === 0 ? translate('not_fixed_amount') : `$${code.amount} HKD`}
                  </td>
                  <td className="align-middle">
                    <div
                      className="btn p-0"
                      onClick={(e) => setShowCodeModal(true)}
                      style={{ minWidth: 150 }}>
                      <img
                        src={getLinks(code)[1].href}
                        alt=""
                        className="img-fluid"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                  </td>
                  <td className="align-middle">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => onGetLinkBtnClicked(getLinks(code)[0].href)}>
                      {countdown > 0 && code === selectedCode ? (
                        translate('copied_at_your_clipboard')
                      ) : (
                        <FontAwesomeIcon icon={faCopy} />
                      )}
                    </button>
                  </td>
                  <td className={`text-${getStatusColor(getStatus(code.status))}`}>
                    {translate(getStatus(code.status))}
                  </td>
                </tr>
              ))}
            {payByLinkCodes.length === 0 && (
              <tr className="">
                <td colSpan={8} rowSpan={5} className="text-center">
                  <div>
                    <img src={img} alt="" className="img-fluid my-4" />
                  </div>
                  <span className="grey-text">{translate('no_data')}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination pagination={payByLinkCodesPagination} setCurrentPage={setCurrentPage} />
        <div className="mb-5 mb-md-0"></div>
      </div>
      <DisplayPayLinkCodeModal
        isOpen={showCodeModal}
        setIsOpen={setShowCodeModal}
        selectedCode={selectedCode}
      />
    </>
  );
};
