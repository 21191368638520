import { RegistrationFormProps } from 'components/RsoRegister/interface';
import axios, { defaultRequestHandler, DefaultResponse } from '../common/Fetcher';
import { ContentType } from '../common/Headers';
import { RegisteredReturn } from '../RsoRegistration/interface';

export const register = (
  data: RegistrationFormProps,
): Promise<RegisteredReturn | DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/merchants/onboarding`, data, {
      headers: {
        'Content-Type': ContentType.json,
      },
    }),
  );
