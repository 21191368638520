import { OFFLINE_STORE } from 'constants/format';
import { DefaultResponse } from 'services/API/common/Fetcher';
import { CompanyStoreProps, GetCompanyStoreListResponse } from 'services/API/Company/interface';

export const checkStoreStatus = (res: DefaultResponse): GetCompanyStoreListResponse => {
  const storeList: Array<CompanyStoreProps> = [];
  res.data.forEach((store: any) => {
    if (store.type == OFFLINE_STORE) {
      store.status = store.status?.[0]?.status ?? 'activated';
    } else {
      switch (store.status?.[0]?.status ?? 2) {
        case 0:
          store.status = 'pending';
          break;
        case 1:
          store.status = 'processing';
          break;
        case 2:
          store.status = 'activated';
          break;
        case 3:
          store.status = 'disabled';
          break;
      }
    }
    storeList.push(store);
  });
  res.data = storeList;
  return res;
};
