import logo from 'assets/img/registrationForm/ultimateOwnerIcon.png';
import { FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { DocPreviewModal } from 'components/custom/modals/DocPreviewModal';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { compression } from 'helpers/functions/compressFile';
import { dataURLtoFile } from 'helpers/functions/dataUrl';
import { addWatermark } from 'helpers/functions/watermarkFile';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { BeneficialOwnerProps } from 'services/API/Auth/interface';
import { StepProps } from '../interface';
import { validateOwner } from '../validator';

const initialState: BeneficialOwnerProps = {
  user_id: '',
  legal_name: '',
  proof_of_identity: '',
  date_of_birth: '',
  nationality: '',
  percentage_owned: 100.0,
  id_proof: {
    name: '',
    type: 'hkid_v1',
    shared: false,
    description: '',
  },
  file: undefined,
};

export const BeneficialOwner = forwardRef(
  ({ form, setForm, isTaxi, setIsLoading }: StepProps, ref) => {
    const {
      auth: {
        user: { id, first_name, last_name },
      },
    } = useSelector((state: GlobalTypes.RootState) => state);

    const { translate } = useTranslate();

    const [idProof, setIdProof] = useState<File>();
    const [owner, setOwner] = useState<BeneficialOwnerProps>(initialState);

    const [errors, setErrors] = useState<ANY_OBJECT>({});
    const [hkid, setHkid] = useState<HTMLImageElement>();
    const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
    const [uploadFile, setUploadFile] = useState<File>();

    const {
      legal_name,
      proof_of_identity,
      date_of_birth,
      nationality,
      percentage_owned,
      id_proof,
    } = owner;
    const { address } = form;

    const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      if (e.target.name === 'percentage_owned') {
        const value = parseFloat((e.target as any).rawValue || 0);
        const percentage = value || 0;
        setOwner({
          ...owner,
          percentage_owned: percentage > 100 ? 100 : percentage,
        });
        return;
      } else if (e.target.name === 'address') {
        setForm({
          ...form,
          address: e.target.value,
        });
        return;
      } else if (e.target.name === 'proof_of_identity_type') {
        setOwner({
          ...owner,
          id_proof: {
            ...owner.id_proof,
            type: e.target.value,
          },
        });
        return;
      }

      setOwner({
        ...owner,
        [e.target.name]: e.target.value,
      });
    };

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      const name = `Id_Proof_${file?.name}`;

      if (file && file.type === 'application/pdf') {
        setUploadFile(file);
        setIsUploadFileModalOpen(true);
        return;
      } else if (!file) {
        setIdProof(undefined);
        setOwner({
          ...owner,
          id_proof: {
            ...owner.id_proof,
            name: '',
          },
          file: undefined,
        });
        return;
      }

      validateFile(name, file);
    };
    const validateFile = async (name: string, file: File) => {
      setIsLoading(true);
      const img = await addWatermark(file, 'COPY YEDPAY INTERNAL USE ONLY');
      setHkid(img);
      file = await compression(dataURLtoFile(img.src, file.name));
      setIsLoading(false);

      setIdProof(file);

      setOwner({
        ...owner,
        id_proof: {
          ...owner.id_proof,
          name,
        },
        file,
      });

      return true;
    };

    useImperativeHandle(ref, () => ({
      validate: () => {
        const { isValid, errors } = validateOwner(owner, address, isTaxi);
        setErrors(errors);
        if (!isValid) {
          return false;
        }

        const ownerObject = {
          ...owner,
          user_id: id,
        };

        setForm({
          ...form,
          beneficial_owners: [ownerObject],
        });

        return true;
      },
    }));

    useEffect(() => {
      const own = form.beneficial_owners?.[0];
      if (own) {
        setOwner(own);
        setIdProof(own.file);
      }

      if (isTaxi) {
        setOwner({
          ...owner,
          legal_name: `${first_name} ${last_name}`,
          nationality: 'Hong Kong',
          percentage_owned: 100,
        });
      }
      // eslint-disable-next-line
    }, []);

    return (
      <>
        <FormHeader src={logo} title={translate('user_information')} />
        <div className="mt-2" />
        <Input
          type="text"
          name="legal_name"
          value={legal_name}
          legend={translate('legal_name').toUpperCase()}
          onChange={onChange}
          isRequired
          error={translate(...(errors.legal_name ?? ''))}
        />
        <div className="row">
          <div className="col-5">
            <Input
              type="select"
              name="proof_of_identity_type"
              value={id_proof.type}
              options={[
                {
                  label: translate('hkid_v1'),
                  value: 'hkid_v1',
                },
                {
                  label: translate('hkid_v2'),
                  value: 'hkid_v2',
                },
                {
                  label: translate('passport'),
                  value: 'passport',
                },
              ]}
              legend={translate('document_type').toUpperCase()}
              onChange={onChange}
              isRequired
              error={translate(...(errors.proof_of_identity_type ?? ''))}
            />
          </div>
          <div className="col-7">
            <Input
              type="text"
              name="proof_of_identity"
              value={proof_of_identity}
              legend={translate('hkid_or_passport_number').toUpperCase()}
              onChange={onChange}
              isRequired
              error={translate(...(errors.proof_of_identity ?? ''))}
            />
          </div>
        </div>
        <Input
          type="file"
          legend={translate('hkid_or_passport_proof').toUpperCase()}
          onChange={onFileChange}
          value={idProof}
          name="id_proof"
          placeholder={idProof && idProof.name ? idProof.name : ''}
          isRequired
          accept="image/*,application/pdf"
          error={translate(...(errors.id_proof ?? ''))}
        />
        {hkid && (
          <div style={{ padding: '.5rem' }}>
            <img src={hkid.src} className="img-fluid" style={{ maxHeight: 300 }} />
          </div>
        )}
        <DocPreviewModal
          isOpen={isUploadFileModalOpen}
          setIsOpen={setIsUploadFileModalOpen}
          file={uploadFile}
          setFile={setUploadFile}
          onConfirm={(f) => {
            validateFile(f.name, f);
            setUploadFile(undefined);
          }}
        />
        <Input
          type="cleave"
          name="date_of_birth"
          value={date_of_birth}
          legend={translate('date_of_birth').toUpperCase()}
          placeholder="YYYY-MM-DD"
          onChange={onChange}
          cleaveOptions={{
            date: true,
            delimiter: '-',
            datePattern: ['Y', 'm', 'd'],
          }}
          isRequired
          error={translate(...(errors.date_of_birth ?? ''))}
        />
        {isTaxi && (
          <Input
            type="text"
            name="address"
            value={address}
            legend={translate('residential_address').toUpperCase()}
            onChange={onChange}
            isRequired
            error={translate(...(errors.address ?? ''))}
          />
        )}
        <Input
          type="text"
          name="nationality"
          value={nationality}
          legend={translate('nationality').toUpperCase()}
          onChange={onChange}
          isRequired
          error={translate(...(errors.nationality ?? ''))}
        />
        <Input
          type="cleave"
          cleaveOptions={{
            numeral: true,
            numeralDecimalScale: 2,
            numeralIntegerScale: 3,
            numeralPositiveOnly: true,
          }}
          name="percentage_owned"
          value={percentage_owned}
          legend={translate('percentage_owned').toUpperCase()}
          onChange={onChange}
          isRequired
          error={translate(...(errors.percentage_owned ?? ''))}
        />
      </>
    );
  },
);
