import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'components/custom/input';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { createCompanySignKey, getCompanySignKey } from 'services/API/Keys';
import { CompanySignKey } from 'services/API/Keys/interface';
import { useSwal } from 'helpers/sweetalert';

export const SignKey = () => {
  const { translate } = useTranslate();

  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const [Swal] = useSwal();

  const [signKeyObject, setSignKeyObject] = useState<CompanySignKey>();

  const [showCopiedMessage, setShowCopiedMessage] = useState(0);

  const getSignKey = async () => {
    if (!user?.company_id) {
      return;
    }
    const res = await getCompanySignKey(user.company_id);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('sign_key'),
        text: res?.message || 'Oops',
      });
      return;
    }
    setSignKeyObject(res.data);
  };

  const generateSignKey = async () => {
    if (signKeyObject) {
      const confirmResult = await Swal.fire({
        icon: 'warning',
        title: translate('sign_key'),
        text: translate('are_you_sure_regenerate_sign_key'),
        showConfirmButton: true,
        showCancelButton: true,
      });
      if (!confirmResult.isConfirmed) {
        return;
      }
    }

    const res = await createCompanySignKey(user.company_id);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('sign_key'),
        text: res?.message || 'Oops',
      });
      return;
    }
    Swal.fire({
      icon: 'success',
      title: translate('sign_key'),
      text: translate(
        signKeyObject
          ? 'congratulations_regenerate_sign_key_successful'
          : 'congratulations_generate_sign_key_successful',
      ),
    });
    setSignKeyObject(res.data);
  };

  //@todo Make this function global
  const copyToClipboard = () => {
    const key = signKeyObject?.key;
    navigator.clipboard.writeText(key!).then(() => {
      setShowCopiedMessage(5);
    });
  };

  useEffect(() => {
    getSignKey();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showCopiedMessage > 0) {
      timeout = setTimeout(() => {
        setShowCopiedMessage(showCopiedMessage - 1);
      }, 1000);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [showCopiedMessage]);

  return (
    <div className="px-3">
      <h4 className="colfax-regular text-secondary">{translate('sign_key')}</h4>
      <Input
        type="text"
        disabled
        name=""
        value={signKeyObject?.key || ''}
        legend={translate('sign_key')}
        suffix={
          showCopiedMessage > 0 ? (
            <p className="m-0 fx-16 colfax-regular text-primary">
              {translate('copied_at_your_clipboard')}
            </p>
          ) : (
            <button className="btn" type="button" onClick={copyToClipboard}>
              <FontAwesomeIcon icon={faCopy} />
            </button>
          )
        }
      />

      <div className="d-flex justify-content-end mt-2">
        <button className="btn btn-primary" onClick={generateSignKey}>
          {translate(signKeyObject ? 'regenerate' : 'generate')}
        </button>
      </div>
    </div>
  );
};
