import { getMe as apiGetMe } from 'actions/AuthActions';
import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { updateCompanyInfo } from 'services/API/Company';
import { UpdateCompanyProps } from 'services/API/Company/interface';
import { useSwal } from 'helpers/sweetalert';
import { CompanyField } from './CompanyField';
import { validate } from './validator';

const initialState = {
  id: '',
  name: '',
  address: '',
  contact_name: '',
  contact_mobile: '',
  website: '',
  business_description: '',
} as UpdateCompanyProps;

export const CompanyInfo = ({ location }: RouteComponentProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [companyForm, setCompanyForm] = useState(initialState);

  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const handleCompanyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCompanyForm({
      ...companyForm,
      [e.target.name]: e.target.value,
    });
  };

  const onCompanyFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, errors } = validate(companyForm);
    setErrors(errors);
    if (!isValid) {
      return;
    }

    companyForm.address = companyForm.address && companyForm.address.trim().replace(/['"]/g, '');
    let finalAddress = companyForm.address;
    const checkAddressLastWords = finalAddress.split(',').pop();
    if (checkAddressLastWords?.trim()?.toUpperCase() !== 'HONG KONG') {
      finalAddress += ', Hong Kong';
    }

    companyForm.address = finalAddress;

    const res = await updateCompanyInfo(user?.company_id, companyForm);
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('company_info'),
        text: res?.message || 'Oops',
      });
      return;
    }

    const res2 = await dispatch(apiGetMe());

    if (!res2.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('company_info'),
        text: res2.message ?? 'Oops',
      });
      return;
    }

    await Swal.fire({
      icon: 'success',
      title: translate('company_info'),
      text: translate('congratulations_company_successfully_updated'),
    });
  };

  useEffect(() => {
    const { id, name, address, contact_mobile, contact_name, website, business_description } =
      user.company;
    const state = {
      id,
      name,
      address,
      contact_mobile,
      contact_name,
      website,
      business_description,
    };
    setCompanyForm(state ?? initialState);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="company-info" name="company_info" />
          <div className="bg-white round-bottom-lg">
            <CompanyField
              data={companyForm}
              setData={handleCompanyChange}
              submitFunction={onCompanyFormSubmit}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </>
  );
};
