import { SelectOptionProps } from '../input/interfaces';
import { Input } from '../input';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { getSearchFunction } from 'helpers/functions/getSearchFunction';

export interface ReactSelectAsyncProps {
  name: string;
  legend: string;
  onSelectChange: (name: string) => (val: any) => void;
  value: any;
  valueToLabel: (value: any) => any;
  defaultOption?: Array<SelectOptionProps>;
  searchOptions: (...args: any[]) => Promise<any>;
  searchOptionsParams?: any;
  components?: any | null;
  error?: string;
  isRequired?: boolean;
  disabled?: boolean;
}

export const ReactSelectAsync = ({
  name,
  legend,
  onSelectChange,
  value,
  valueToLabel,
  defaultOption,
  searchOptions,
  searchOptionsParams = {},
  components,
  error,
  isRequired,
  disabled,
}: ReactSelectAsyncProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  return (
    <Input
      disabled={disabled}
      isRequired={isRequired}
      type="react-select-async"
      legend={legend}
      name={name}
      defaultOptions={defaultOption}
      loadOptions={getSearchFunction(
        searchOptions,
        user.company_id,
        searchOptionsParams,
        valueToLabel,
      )}
      components={components}
      value={valueToLabel(value)}
      onChange={onSelectChange(name)}
      error={error}
    />
  );
};
