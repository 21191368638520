import { DetailRow } from 'components/custom/DetailRow';
import { useTranslate } from 'context/TranslateContext';
import { findGatewayNameByCode } from 'helpers/functions/findGatewayByCode';
import html2canvas from 'html2canvas';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { userProps } from 'redux/state/AuthState';
import { GlobalTypes } from 'redux/types';
import { IntegrationTransactionProps } from 'services/API/FpsTransaction/interface';
import { showUser } from 'services/API/User';
import { useSwal } from 'helpers/sweetalert';
import { BankRefTable } from './bankRefTable';

interface FpsTransactionModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  transaction?: IntegrationTransactionProps;
  reloadTransaction: () => void;
  isAgentOrGatewayProvider: boolean;
}

export const FpsTransactionModal = ({
  isOpen,
  setIsOpen,
  transaction,
  isAgentOrGatewayProvider,
}: FpsTransactionModalProps) => {
  if (!transaction) {
    return <></>;
  }
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const status = transaction.status == 'pending' ? 'pending' : 'paid';

  const [transactionUser, setTransactionUser] = useState<userProps>({} as userProps);
  const [isDownloading, setIsDownloading] = useState(false);
  const printRef = useRef<HTMLDivElement>(null);

  const onPrint = async () => {
    setIsDownloading(true);
    const element = printRef?.current;
    if (!element) {
      return;
    }
    const canvas = await html2canvas(element, {
      useCORS: true,
      backgroundColor: '#ffffff',
    });
    const link = document.createElement('a');
    link.setAttribute('download', `transaction-${transaction.transaction_id}.png`);
    link.setAttribute(
      'href',
      canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'),
    );
    link.click();
    setIsDownloading(false);
  };

  const getUser = async (user_id: string) => {
    if (!user_id) {
      return;
    }
    const res = await showUser(user_id);
    if (!res.success) {
      Swal.fire({
        title: translate('user'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    setTransactionUser(res.data);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isAgentOrGatewayProvider) {
      getUser(transaction.user_id ?? '');
    }
    // eslint-disable-next-line
  }, [transaction]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>{translate('transaction_details')}</ModalHeader>
        <ModalBody>
          <div ref={printRef} className="p-3">
            <div className="transaction-content">
              <h4>
                <DetailRow
                  name={translate('company_name')}
                  value={user?.company?.name ?? ''}
                  nameWidth="col-5"
                />
                <DetailRow
                  name={translate('transaction_id')}
                  value={transaction.transaction_id ?? ''}
                  nameWidth="col-5"
                />
                <DetailRow
                  name={translate('reference_id')}
                  value={transaction.reference_id || '-'}
                  nameWidth="col-5"
                />
                <DetailRow
                  name={translate('custom_id')}
                  value={transaction.custom_id || '-'}
                  nameWidth="col-5"
                />

                {!isAgentOrGatewayProvider && (
                  <>
                    <DetailRow
                      name={translate('created_at')}
                      value={transaction.created_at ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate('paid_at')}
                      value={transaction.paid_at ?? ''}
                      nameWidth="col-5"
                    />
                  </>
                )}
              </h4>
            </div>
            <hr className="dash-border" />
            <div className="transaction-content">
              <h4>
                <div className="row">
                  <div className="col-12">
                    <DetailRow
                      name={translate('store_name')}
                      value={transaction.store?.name ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate('store_address')}
                      value={transaction.store?.address ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate('phone')}
                      value={transaction.store?.phone ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate(transactionUser.role)}
                      value={`${transactionUser.first_name} ${transactionUser.last_name}` ?? ''}
                      nameWidth="col-5"
                    />
                  </div>
                </div>
              </h4>
            </div>
            {!isAgentOrGatewayProvider && (
              <>
                <hr className="dash-border" />
                <div className="transaction-content">
                  <h4>
                    <DetailRow
                      name={translate('amount')}
                      value={`${transaction.amount} ${transaction.currency}` ?? ''}
                      nameWidth="col-5"
                    />

                    <DetailRow
                      name={translate('tip_amount')}
                      value={`${transaction.tips} ${transaction.currency}` ?? ''}
                      nameWidth="col-5"
                    />
                  </h4>
                </div>
                <hr className="dash-border" />

                <div className="transaction-content">
                  <h4>
                    <DetailRow
                      name={translate('status')}
                      value={translate(status ?? '')}
                      nameWidth="col-5"
                      valueWidth="col-6 h3"
                    />
                  </h4>
                </div>

                <hr className="dash-border" />
              </>
            )}

            <div className="transaction-content">
              <h4>
                <DetailRow
                  name={translate('gateway')}
                  value={
                    findGatewayNameByCode(
                      `${transaction.gateway_id}_${transaction.gateway_sub_id}`,
                    ) ?? ''
                  }
                  nameWidth="col-5"
                />
                <DetailRow
                  name={translate('gateway_id')}
                  value={`${transaction.gateway_id}` ?? ''}
                  nameWidth="col-5"
                />
              </h4>
            </div>
            <hr className="dash-border" />
            <BankRefTable transaction={transaction} />
          </div>
        </ModalBody>
        {!isAgentOrGatewayProvider && (
          <ModalFooter>
            <div className="text-center flex-fill">
              <button className="btn btn-primary btn-sm" onClick={onPrint}>
                {isDownloading ? <Spinner size="sm" /> : translate('print')}
              </button>
            </div>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};
