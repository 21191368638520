export const operator = 'operator';
export const owner = 'owner';
export const gatewayProvider = 'gateway-provider';
export const accountant = 'accountant';
export const agent = 'agent';
export const superOwner = 'superowner';
export const ALL_ROLES = [operator, owner, gatewayProvider, accountant, agent, superOwner];

export const AVAILABLE_ROLES_FOR_CREATE = [accountant, operator];
export const AVAILABLE_ROLES_FOR_CREATE_FOR_REACT_SELECT = [
  {
    label: accountant,
    value: accountant,
  },
  {
    label: operator,
    value: operator,
  },
];

export const EXCEPTION_OF_BUSINESS_SETUP = [operator, gatewayProvider, agent];
export const ALLOW_CREATE_SETTLEMENTS_ROLES = [operator, owner];

export const isGatewayProvider = (role: string) => gatewayProvider === role;
export const isAgent = (role: string) => agent === role;
export const isSuperowner = (role: string) => superOwner === role;
export const isAccountant = (role: string) => accountant === role;
export const isOwner = (role: string) => owner === role;
export const isOperator = (role: string) => operator === role;

export const SALES_ANALYSIS_GROUP = {
  owner: ['store', 'gateway'],
  accountant: ['store', 'gateway'],
  'gateway-provider': ['company', 'mcc'],
  agent: ['company', 'gateway', 'store'],
  superowner: ['store', 'gateway'],
};

export const convertSaleAnalysisGroupToOptions = (
  options: Array<string>,
  translate: (id: string) => string,
) => {
  return options.map((option) => ({
    label: translate(`group_by_${option}`),
    value: option,
  }));
};
