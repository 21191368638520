import { getMe as apiGetMe } from 'actions/AuthActions';
import { getSettlementAction } from 'actions/SettlementActions';
import { BalanceBox } from 'components/custom/BalanceBox';
import { Input } from 'components/custom/input';
import { SideFilter } from 'components/custom/sideFilter';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import {
  PENDING,
  SETTLEMENT_STATUS_COLOR,
  SETTLEMENT_STATUS_OPTIONS,
  TIME_ZONE_FORMAT,
  translateLabel,
} from 'constants/format';
import { isSuperowner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { getNDaysEarlier } from 'helpers/functions/getDays';
import moment from 'moment';
import React, { ChangeEvent, FormEvent, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import {
  reverseSettlement as apiReverseSettlement,
  getAllSettlement,
} from 'services/API/Settlement';
import onNotification from 'services/firebase';
import { useSwal } from 'helpers/sweetalert';
import { WithdrawalModal } from '../Account/summary/withdrawal';
import { SettlementSettingModal } from './SettlementSettingModal';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { ReportSection } from 'components/custom/ReportSection';

const initialFilterState = {
  amount: '',
  transaction_status: '',
  start: new Date(getNDaysEarlier(31)),
  end: new Date(),
};

export const Settlement = () => {
  const {
    auth: { user, roles },
    data: {
      settlements: { settlements, settlementsPagination },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [filter, setFilter] = useState(initialFilterState);

  const reportSectionRef = useRef<any>(null);
  const [openWithdrawalModal, setOpenWithdrawalModal] = useState(false);
  const [openSettlementModal, setOpenSettlementModal] = useState(false);
  const [isExportDropdownLoading, setIsExportDropdownLoading] = useState(false);
  const [openExportDropdown, setOpenExportDropdown] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const { start, end, amount, transaction_status } = filter;

  const getInitialData = async () => {
    const data = getParams();
    const res = await dispatch(getSettlementAction(data));

    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('settlement'),
        text: res.message || 'Oops',
      });
    }
  };

  useEffect(() => {
    return onNotification(getInitialData);
  }, []);

  const getMe = async () => {
    const res = await dispatch(apiGetMe());
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('settlement'),
        text: res.message,
      });
      return;
    }
  };

  const getParams = () => {
    return Object.assign(
      {
        'created_at>': moment(start).format(TIME_ZONE_FORMAT),
        'created_at<': moment(end).endOf('day').format(TIME_ZONE_FORMAT),
        limit: 10,
        sort: '-created_at',
        page: currentPage,
      },
      amount ? { amount } : {},
      transaction_status ? { status: transaction_status } : {},
    );
  };

  const exportReport = async (type: string) => {
    setIsExportDropdownLoading(true);
    const params: any = {
      ...getParams(),
    };

    if (type === 'pdf') {
      params.start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      params.end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const totalDays = Math.abs(moment(params.start_date).diff(params.end_date, 'days'));
      if (totalDays > 31) {
        Swal.fire({
          icon: 'error',
          title: translate('export'),
          text: translate('error_duration_must_be_less_equal_than', ['31', translate('unit_day')]),
        });
        setIsExportDropdownLoading(false);
        return;
      }
    }
    params.asyncExport = true;
    const res = await getAllSettlement({
      ...params,
      export: type,
    });
    await reportSectionRef?.current?.loadReports();
    setIsExportDropdownLoading(false);
    if (!res || !res.success) {
      return;
    }

    window.open(res.file, '_self');
  };

  const reverseSettlement = (settlement_id: string) => async () => {
    const result = await Swal.fire({
      icon: 'warning',
      title: translate('reverse_settlement'),
      text: translate('are_you_sure_reverse_settlement'),
      showCancelButton: true,
      confirmButtonText: translate('yes'),
      cancelButtonText: translate('no'),
    });

    if (!result.value) {
      return;
    }

    const res = await apiReverseSettlement(settlement_id);
    Swal.fire({
      title: translate('reverse_settlement'),
      text: res?.success
        ? translate('congratulations_reverse_settlement_successful')
        : res?.message,
      icon: res.success ? 'success' : 'error',
    });

    await getInitialData();
  };

  const onFilterDateChange = (date: Date, name: string) => {
    setFilter({
      ...filter,
      [name]: date,
    });
  };

  const onFilterChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name === 'amount') {
      // Cleave's Event handler has rawValue
      setFilter({
        ...filter,
        amount: (e.target as any).rawValue,
      });
      return;
    }
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (value: string) => {
    setFilter({
      ...filter,
      transaction_status: value,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
  };

  const tableHeader = () => {
    return (
      <tr className="">
        <th className="border-end border-top-0">{translate('date')}</th>
        <th className="border-end border-top-0">{translate('amount')}</th>
        <th className="border-end border-top-0">{translate('fee')}</th>
        <th className="border-end border-top-0">{translate('net')}</th>
        <th className="border-end border-top-0">{translate('created_at')}</th>
        <th className="border-end border-top-0">{translate('settled_at')}</th>
        <th className="border-end border-top-0">{translate('status')}</th>
        <th className="border-top-0">{translate('actions')}</th>
      </tr>
    );
  };

  const renderData = () => {
    return settlements.map((settlement, index) => {
      const {
        created_at,
        amount,
        total,
        status,
        charge,
        settled_at,
        currency,
        id,
        bank_name,
        account_code,
      } = settlement;

      return (
        <tr key={index} data-bs-toggle="tooltip" title={`Bank: ${bank_name} ${account_code}`}>
          <td>
            <span>{created_at}</span>
          </td>
          <td>
            <span>{`${currency} ${amount}`}</span>
          </td>
          <td>
            <span>{`${currency} ${charge}`}</span>
          </td>
          <td>
            <span>{`${currency} ${total}`}</span>
          </td>
          <td>
            <span>{created_at}</span>
          </td>
          <td>
            <span>{settled_at || '-'}</span>
          </td>
          <td>
            <span className={`text-${SETTLEMENT_STATUS_COLOR[status]} p-2 text-uppercase`}>
              {translate(status)}
            </span>
          </td>
          <td>
            {(status as string).toLowerCase() === PENDING.toLowerCase() ? (
              <>
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={reverseSettlement(id)}>
                  {translate('reverse_settlement')}
                </button>
              </>
            ) : (
              '-'
            )}
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <TopHeader title={translate('settlements')}>
        {!roles.isAccountant && !isSuperowner(user.role) && (
          <>
            <button
              className="header-button btn btn-danger "
              type="button"
              onClick={() => setOpenSettlementModal(true)}>
              {translate('settlement_setting')}
            </button>
          </>
        )}
        {roles.isOwner && (
          <>
            &nbsp;
            <Dropdown
              disabled={isExportDropdownLoading}
              isOpen={openExportDropdown}
              toggle={() => setOpenExportDropdown(!openExportDropdown)}
              className="d-inline-block">
              <DropdownToggle
                disabled={isExportDropdownLoading}
                className="header-button btn btn-success"
                caret
                color="#ffffff">
                {isExportDropdownLoading ? <Spinner size="sm" /> : translate('export')}
              </DropdownToggle>
              <DropdownMenu classnames="lang-dropdown">
                <DropdownItem
                  classnames="btn btn-sm btn-muted text-center"
                  onClick={() => exportReport('xlsx')}>
                  {translate('generate_excel_report')}
                </DropdownItem>
                <DropdownItem
                  classnames="btn btn-sm btn-muted text-center"
                  onClick={() => exportReport('csv')}>
                  {translate('generate_csv_report')}
                </DropdownItem>
                <DropdownItem
                  classnames="btn btn-sm btn-muted text-center"
                  onClick={() => exportReport('pdf')}>
                  {translate('generate_pdf_report')}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            &nbsp;
            <button
              className="header-button btn btn-success"
              type="button"
              onClick={() => setOpenWithdrawalModal(true)}>
              {translate('withdrawal')}
            </button>
          </>
        )}
      </TopHeader>
      <div id="main-content" className="hide-scrollbar">
        <CustomTable
          renderHeading={() => tableHeader()}
          totalColumn={7}
          renderData={renderData}
          setCurrentPage={setCurrentPage}
          pagination={settlementsPagination}
        />
      </div>
      <div id="side-content" className="hide-scrollbar">
        <ReportSection ref={reportSectionRef} type="settlement" />
        {!roles.isOperator && <BalanceBox />}
        <SideFilter onFilter={onFilterSearch} resetFilter={resetFilter}>
          <Input
            type="date"
            value={start}
            legend={translate('from').toUpperCase()}
            name="start"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="date"
            value={end}
            legend={translate('to').toUpperCase()}
            name="end"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="cleave"
            cleaveOptions={{
              numeral: true,
              numeralDecimalScale: 2,
              numeralIntegerScale: 7,
              numeralPositiveOnly: true,
            }}
            legend={translate('amount').toUpperCase()}
            value={amount}
            name="amount"
            onChange={onFilterChange}
            placeholder={translate('amount')}
          />
          <Input
            type="react-select"
            legend={translate('transaction_status').toUpperCase()}
            value={transaction_status}
            options={translateLabel(SETTLEMENT_STATUS_OPTIONS, translate)}
            defaultLabel={translate('transaction_status')}
            name="transaction_status"
            onChange={onFilterSelectChange}
            placeholder={translate('transaction_status')}
            menuPortalTarget={document.body}
          />
        </SideFilter>
      </div>
      <WithdrawalModal
        isOpenModal={openWithdrawalModal}
        setIsOpenModal={setOpenWithdrawalModal}
        reloadData={getInitialData}
      />
      <SettlementSettingModal
        reloadUser={getMe}
        isOpen={openSettlementModal}
        setIsOpen={setOpenSettlementModal}
        currentSettlementType={user.company.auto_settlement}
        company_id={user.company_id}
      />
    </>
  );
};
