import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getStoreLogos } from 'actions/StoreActions';
import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import SideFilter from 'components/custom/sideFilter';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { ONLINE_STORE } from 'constants/format';
import { isAccountant, isSuperowner } from 'constants/Roles';
import {
  DBS_FPS_ONLINE_GATEWAY_CODE,
  HSBC_FPS_ONLINE_GATEWAY_CODE,
  ONLINE_GATEWAY_GROUP,
} from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import { useCountdown } from 'helpers/hooks/useCountdown';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentProps } from 'redux/reducers/dataProps';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { PaginationProps } from 'services/API/common/Fetcher';
import { getAllStore } from 'services/API/Store';
import { useSwal } from 'helpers/sweetalert';
import { CreateEditTermModal } from './CreateEditTermModal';
import { DisplayCodeModal } from './DisplayCodeModal';
import { ActionBox } from './DynamicLinksActionBox';
import { PrintModal } from './DynamicLinksPrintModal';
import { getCredentialsList } from 'actions/GatewayActions';

const filterInitialState = {
  store: '',
};

const paginationInitialState: PaginationProps = {
  total: 0,
  per_page: 10,
  current_page: 1,
  total_pages: 1,
  links: {
    next: '',
    prev: '',
  },
};
export const DynamicPayLinks = () => {
  const {
    auth: { user, credentials },
    data: { logos },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate, language } = useTranslate();
  const [filter, setFilter] = useState(filterInitialState);
  const [pagination, setPagination] = useState(paginationInitialState);
  const [countdown, setCountdown] = useCountdown();
  const [allStoreOptions, setStoreOptions] = useState<Array<SelectOptionProps>>([]);
  const [filteredStoreOptions, setFilteredStoreOptions] = useState<Array<SelectOptionProps>>([]);
  const [selectedStore, setSelectedStore] = useState<any | null>(null);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreateOrEditModalOpen, setIsCreateOrEditModalOpen] = useState(false);
  const { store } = filter;
  const onlineGatewayWithFPS = [
    ...ONLINE_GATEWAY_GROUP.code,
    HSBC_FPS_ONLINE_GATEWAY_CODE,
    DBS_FPS_ONLINE_GATEWAY_CODE,
  ];
  const hasQrCodeAvailableGateway = getUserActivatedGateways().some((item) =>
    onlineGatewayWithFPS.includes(item),
  );

  const getStoreOptions = async () => {
    const res = await getAllStore(user?.company_id, {
      type: ONLINE_STORE,
      include: 'store_term',
    });
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('store'),
        text: res?.message || 'Oops',
      });
      return;
    }
    const list = res.data.map((store) => {
      return {
        label: store.name,
        value: store,
      };
    });
    setStoreOptions(list);
    setFilteredStoreOptions(list.slice(0, 10));
    setPagination({
      ...paginationInitialState,
      total: list.length,
      total_pages: Math.ceil(list.length / 10),
    });

    if (selectedStore) {
      setSelectedStore(list.find((store) => selectedStore.value.id == store.value.id));
      setIsEdit(selectedStore ? !!selectedStore.value.store_term?.id : false);
    }
  };

  const fetchLogos = async () => {
    const res = await dispatch(getStoreLogos(user?.company_id, { limit: 99999 }));
    if (!res.success) {
      await Swal.fire({
        title: 'Logos',
        text: res.message || 'Oops',
        icon: 'error',
      });
    }
  };

  const convertLogoToOptions = (logos: Array<documentProps>) => {
    return logos.map((logo) => ({
      label: logo.name,
      value: logo.id,
    }));
  };

  useEffect(() => {
    if (!hasQrCodeAvailableGateway) {
      return;
    }
    getStoreOptions();
    fetchLogos();
  }, [credentials]);

  const setCurrentPage = (page: number) => {
    setFilteredStoreOptions(
      allStoreOptions.slice(
        (page - 1) * 10,
        Math.min((page - 1) * 10 + 10, allStoreOptions.length),
      ),
    );
    setPagination({
      ...pagination,
      current_page: page,
    });
  };

  const onFilterSelectChange = (name: string) => (value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };
  const filterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!store) {
      setFilteredStoreOptions(allStoreOptions.slice(0, 10));
      setPagination({
        ...pagination,
        total: allStoreOptions.length,
        total_pages: Math.ceil(allStoreOptions.length / 10),
        per_page: 10,
        current_page: 1,
      });
    } else {
      setFilteredStoreOptions(allStoreOptions.filter((storeOption) => storeOption.value === store));
      setPagination({
        ...pagination,
        total: 1,
        total_pages: 1,
        per_page: 1,
        current_page: 1,
      });
    }
  };

  const onGetLinkBtnClicked = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      setCountdown(5);
    });
  };

  const onEditBtnClicked = (store: any) => {
    setIsEdit(store ? !!store.value.store_term?.id : false);
    setSelectedStore(store);
    setIsCreateOrEditModalOpen(true);
  };

  const getLinks = (store: any) => {
    return store._links;
  };

  const onRowClick = (store: any) => () => {
    setSelectedStore(store);
    setIsCollapseOpen(true);
    return;
  };

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-end border-top-0">{translate('store_name')}</th>
        <th className="border-end border-top-0">{translate('qr_code')}</th>
        <th className="border-end border-top-0">{translate('pay_link')}</th>
      </tr>
    );
  };

  const renderData = () => {
    return filteredStoreOptions.map((store, index) => {
      return (
        <tr key={index} className="cursor-pointer" onClick={onRowClick(store)}>
          <td>
            <span>{store.label}</span>
          </td>
          <td className="align-middle">
            <button
              className="btn p-0"
              onClick={(e) => setShowCodeModal(true)}
              style={{ minWidth: 150 }}>
              <img
                src={getLinks(store.value)[1].href}
                alt=""
                className="img-fluid"
                style={{ height: 150, width: 150 }}
              />
            </button>
          </td>
          <td className="align-middle">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => onGetLinkBtnClicked(getLinks(store.value)[0].href)}>
              {countdown > 0 && store == selectedStore ? (
                translate('copied_at_your_clipboard')
              ) : (
                <FontAwesomeIcon icon={faCopy} />
              )}
            </button>
          </td>
        </tr>
      );
    });
  };

  if (!hasQrCodeAvailableGateway) {
    return (
      <>
        <TopHeader title={translate('pay_links')}></TopHeader>
        <SideNavbar location={location as any} type="paylink" />
        <div id="main-content" className="hide-scrollbar">
          <div className="alert alert-danger" role="alert">
            {translate('no_gateway_available_for_qr_code')}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <TopHeader title={translate('pay_links')}></TopHeader>
      <SideNavbar location={location as any} type="paylink" />
      <div id="main-content" className="hide-scrollbar">
        <CustomTable
          totalColumn={2}
          renderHeading={() => tableHeader()}
          renderData={renderData}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <div id="side-content" className="hide-scrollbar">
        {!isAccountant(user.role) && !isSuperowner(user.role) && (
          <ActionBox
            setIsPrintModalOpen={setIsPrintModalOpen}
            reload={getStoreOptions}
            onEdit={onEditBtnClicked}
            isOpen={isCollapseOpen}
            // fixedCode={selectedFixedCode}
            selectedStore={selectedStore}
          />
        )}
        <SideFilter resetFilter={() => setFilter(filterInitialState)} onFilter={filterSearch}>
          <Input
            type="react-select"
            legend={translate('store').toUpperCase()}
            options={allStoreOptions}
            value={store}
            name="store"
            onChange={onFilterSelectChange('store')}
            placeholder={translate('store')}
            defaultLabel={translate('all')}
            menuPortalTarget={document.body}
          />
        </SideFilter>
      </div>
      <DisplayCodeModal
        isOpen={showCodeModal}
        setIsOpen={setShowCodeModal}
        selectedStore={selectedStore}
      />
      <CreateEditTermModal
        isOpen={isCreateOrEditModalOpen}
        setIsOpen={setIsCreateOrEditModalOpen}
        reload={getStoreOptions}
        logos={convertLogoToOptions(logos)}
        store={selectedStore}
        isEdit={isEdit}
      />
      <PrintModal
        selectedStore={selectedStore}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
      />
    </>
  );
};
