import { faCog, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteAmlDomainAction,
  getAmlDomainListAction,
  updateAmlDomainAction,
} from 'actions/AmlDomainActions';
import { InfoHeader } from 'components/custom/info_header';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import {
  AML_DOMAIN_STATUS_COLOR,
  ANY_OBJECT,
  DOMAIN_TYPE,
  WEB,
  translateLabel,
} from 'constants/format';
import { isSuperowner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { AmlDomainProps } from 'services/API/Aml_Domain/interface';
import { PaginationProps } from 'services/API/common/Fetcher';
import { useSwal } from 'helpers/sweetalert';
import { CreateAmlDomainModal } from './Modal';
import SideFilter from 'components/custom/sideFilter';
import { Input } from 'components/custom/input';

const initialFilterState: ANY_OBJECT = {
  name: '',
  domain: '',
  type: '',
};

export const AmlDomains = () => {
  const {
    auth: {
      user: { company_id, role },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();
  const { translate } = useTranslate();
  const [Swal] = useSwal();

  const [amlDomains, setAmlDomains] = useState<Array<AmlDomainProps>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState<PaginationProps | null>(null);
  const [listIdStartFrom, setListIdStartFrom] = useState<number>(1);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [selectedAmlDomain, setSelectedAmlDomain] = useState<AmlDomainProps>();
  const isReadonly = isSuperowner(role);

  const { name, domain, type } = filter;

  const filterToParams = () => {
    const data: { [key: string]: string } = {
      name,
      domain,
      type,
    };

    const names = Object.keys(data);
    const values = Object.values(data);

    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        delete data[names[index]];
      }
    });

    Object.assign(data, {
      limit: 10,
      page: currentPage,
      sort: '-created_at',
    });

    return data;
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (name: string) => (val: string) => {
    setFilter({
      ...filter,
      [name]: val,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getData();
    } else {
      setCurrentPage(1);
    }
  };

  const deleteAmlDomain = async (amlDomain: AmlDomainProps) => {
    const res = await dispatch(deleteAmlDomainAction(company_id, amlDomain.id));
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('delete_aml_domain'),
      text: res.message ?? 'Oops',
    });
    if (!res.success) {
      return;
    }

    await getData();
  };

  const confirmDeleteAmlDomain = (amlDomain: AmlDomainProps) => {
    Swal.fire({
      icon: 'warning',
      title: translate('delete_aml_domain'),
      text: translate('are_you_sure_delete_domain'),
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => deleteAmlDomain(amlDomain),
    });
  };

  const confirmEditAmlDomain = (amlDomain?: AmlDomainProps) => {
    setSelectedAmlDomain(amlDomain);
    setIsCreateModalOpen(true);
  };

  const confirmSetStatusPending = (amlDomain: AmlDomainProps) => {
    Swal.fire({
      icon: 'warning',
      title: translate('change_aml_status_pending'),
      text: translate('are_you_sure_change_aml_status_pending'),
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => setStatusPending({ ...amlDomain, status: 'pending' }),
    });
  };

  const setStatusPending = async (selectedAmlDomain: AmlDomainProps) => {
    const res = await dispatch(
      updateAmlDomainAction(company_id, selectedAmlDomain.id, selectedAmlDomain),
    );
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('change_aml_status_pending'),
      text: translate('successfully_update_aml_domain_status_to_pending'),
    });
    if (!res.success) {
      return;
    }
    await getData();
  };

  const getData = async () => {
    setIsFetching(true);
    const params = filterToParams();
    const res = await dispatch(getAmlDomainListAction(company_id, params));
    setIsFetching(false);
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('aml_domain'),
        text: res.message ?? 'Oops',
      });
    }
    setAmlDomains(res.data);
    setPagination(res.meta?.pagination ?? null);
  };
  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location as any} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="company-info" name="aml_domain" />
          <div className="pt-3 bg-white">
            <SideFilter
              isLoading={isFetching}
              resetFilter={resetFilter}
              onFilter={onFilterSearch}
              defaultCollapsed={true}>
              <Input
                type="text"
                value={name}
                legend={translate('name').toUpperCase()}
                name="name"
                onChange={onFilterChange}
                placeholder={translate('name')}
              />
              <Input
                type="text"
                value={domain}
                legend={translate('domain_name').toUpperCase()}
                name="domain"
                onChange={onFilterChange}
                placeholder={translate('domain_name')}
              />
              <Input
                type="react-select"
                legend={translate('type').toUpperCase()}
                value={type}
                options={translateLabel(DOMAIN_TYPE, translate)}
                defaultLabel={translate('all_types')}
                name="type"
                onChange={onFilterSelectChange('type')}
                placeholder={translate('store_type')}
                menuPortalTarget={document.body}
              />
            </SideFilter>
            {!isReadonly && (
              <div className="document mt-2 mb-3 mx-3 dash-border">
                <button
                  className="btn btn-outline-secondary"
                  style={{ margin: 'auto' }}
                  onClick={() => confirmEditAmlDomain()}>
                  {translate('add')}
                </button>
              </div>
            )}
            <CustomTable
              totalColumn={6}
              pagination={pagination}
              setCurrentPage={setCurrentPage}
              renderHeading={() => (
                <tr>
                  <th scope="col" className="border-top-0">
                    #
                  </th>
                  <th scope="col" className="border-top-0">
                    {translate('name')}
                  </th>
                  <th scope="col" className="border-top-0" style={{ minWidth: 200 }}>
                    {translate('domain_name')}
                  </th>
                  <th scope="col" className="border-top-0">
                    {translate('domain_type')}
                  </th>
                  <th scope="col" className="border-top-0">
                    {translate('status')}
                  </th>
                  {!isReadonly && (
                    <th scope="col" className="border-top-0">
                      {translate('actions')}
                    </th>
                  )}
                </tr>
              )}
              renderData={() =>
                amlDomains.map((amlDomain, index) => {
                  const allowEdit = amlDomain.status === 'pending';
                  const allowDelete =
                    amlDomain.status === 'pending' || amlDomain.status === 'activated';
                  const allowReactive =
                    amlDomain.status !== 'pending' && amlDomain.status !== 'activated';
                  return (
                    <tr key={amlDomain.id}>
                      <th scope="row" className="align-middle">
                        {listIdStartFrom + index}
                      </th>
                      <td className="align-middle">{amlDomain.name}</td>
                      <td className="align-middle">
                        <a
                          href={`${amlDomain.protocol}://${amlDomain.domain}`}
                          target="_blank"
                          style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
                          rel="noreferrer">
                          {amlDomain.protocol}://{amlDomain.domain}
                        </a>
                      </td>
                      <td className="align-middle">
                        {translate(+amlDomain.type! === WEB ? 'website' : 'application')}
                      </td>
                      <td>
                        <span
                          className={`text-${
                            AML_DOMAIN_STATUS_COLOR[amlDomain.status!]
                          } align-middle`}>
                          {translate(amlDomain.status)}
                        </span>
                      </td>

                      {!isReadonly && (
                        <td className="align-middle">
                          {(allowEdit || allowReactive) && (
                            <button
                              className={`btn btn-sm ${
                                allowReactive ? 'btn-warning' : 'btn-success'
                              }`}
                              onClick={() =>
                                allowReactive
                                  ? confirmSetStatusPending(amlDomain)
                                  : confirmEditAmlDomain(amlDomain)
                              }>
                              <FontAwesomeIcon icon={faCog} color="#FFF" />
                            </button>
                          )}
                          {!allowEdit && !allowReactive && (
                            <button className="btn btn-sm btn-outline-secondary" disabled={true}>
                              <FontAwesomeIcon icon={faCog} />
                            </button>
                          )}
                          &nbsp;
                          <button
                            className={`btn btn-sm ${
                              allowDelete ? 'btn-danger' : 'btn-outline-secondary'
                            }`}
                            onClick={() => confirmDeleteAmlDomain(amlDomain)}
                            disabled={!allowDelete}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })
              }
              centerPaginate={true}
              setListIdStartFrom={setListIdStartFrom}></CustomTable>
          </div>
        </div>
      </div>
      {isCreateModalOpen && (
        <CreateAmlDomainModal
          isOpen={isCreateModalOpen}
          setIsOpen={setIsCreateModalOpen}
          getData={getData}
          amlDomain={selectedAmlDomain}
        />
      )}
    </>
  );
};

export default AmlDomains;
