import ExportReportButton, { ReportExportType } from 'components/custom/ExportReportButton';
import { InfoHeader } from 'components/custom/info_header';
import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import { SideFilter } from 'components/custom/sideFilter';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { isAgent, isGatewayProvider } from 'constants/Roles';
import {
  PAY_PLUS_TRANSACTION_STATUS_OPTIONS,
  TIME_ZONE_FORMAT,
  TRANSACTION_STATUS_COLOR,
  translateLabel,
} from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { getNDaysEarlier } from 'helpers/functions/getDays';
import { useSwal } from 'helpers/sweetalert';
import moment from 'moment';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadOptions } from 'react-select-async-paginate';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getCompanyList } from 'services/API/Company';
import { GatewayProviderGetCompanyProps } from 'services/API/Company/interface';
import { getFpsTransactionList } from 'services/API/FpsTransaction';
import { IntegrationTransactionProps } from 'services/API/FpsTransaction/interface';
import { getAllStore } from 'services/API/Store';
import { FpsTransactionModal } from './fpsTransactionModal';
import { ReportSection } from 'components/custom/ReportSection';
import { getCredentialsList } from 'actions/GatewayActions';
import { CredentialProps } from 'redux/state/AuthState';

const filterInitialState = {
  from: new Date(getNDaysEarlier(31)),
  to: new Date(),
  start_date_time: '00:00:00',
  end_date_time: '23:59:59',
  amount: '',
  transaction_id: '',
  reference_id: '',
  custom_id: '',
  store: '',
  fixed_code: '',
  status: '',
  gateways: [],
  company_id: '',
};

export const PayPlusTransactions = () => {
  const {
    auth: { user, credentials },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [orderBy, setOrderBy] = useState<'created_at' | 'updated_at'>('created_at');
  const [filter, setFilter] = useState(filterInitialState);

  const [storeOptions, setStoreOptions] = useState<Array<SelectOptionProps>>([]);
  const [isFetching, setIsFetching] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState<IntegrationTransactionProps>();
  const [showTransactionDetailModal, setShowTransactionDetailModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [hasMore, setHasMore] = useState(true);
  const [companyPage, setCompanyPage] = useState(1);
  const [companyList, setCompanyList] = useState<Array<GatewayProviderGetCompanyProps>>([]);
  const [fpsTransactions, setFpsTransactions] = useState<Array<IntegrationTransactionProps>>([]);
  const [fpsTransactionsPagination, setFpsTransactionsPagination] = useState<any>([]);
  const [fpsCredentials, setFpsCredentials] = useState<Array<CredentialProps>>([]);

  const reportSectionRef = useRef<any>(null);

  const ORDER_TYPE = [
    {
      label: 'created_at',
      value: 'created_at',
    },
    {
      label: 'updated_at',
      value: 'updated_at',
    },
  ];

  const {
    from,
    to,
    start_date_time,
    end_date_time,
    amount,
    transaction_id,
    reference_id,
    custom_id,
    store,
    status,
    gateways,
    company_id,
  } = filter;

  const deleteEmptyStrings = (obj: any) => {
    const filteredEntries = Object.entries(obj).filter(([_key, value]) => value !== '');
    return Object.fromEntries(filteredEntries);
  };

  const convertCredentialOption = () => {
    return fpsCredentials.map((credential) => ({
      label: credential.gateway_sub_name,
      value: credential.gateway_code,
    }));
  };

  const findCompanyName = () => {
    return companyList.find((company) => company.id === company_id)?.name ?? 'Company';
  };

  const onCompanyNameSelect = (company_id: string) => {
    setFilter({
      ...filter,
      company_id,
    });
  };

  const searchCompany: LoadOptions<any, any> = async (input) => {
    let options: Array<any> = [];

    if (hasMore) {
      const data = Object.assign({ page: companyPage });
      const res = await getCompanyList(data);
      const page = res?.meta?.pagination?.current_page ?? 1;
      const hasMore = !!res?.meta?.pagination?.links?.next ?? false;

      if (hasMore) {
        setCompanyPage(page + 1);
      } else {
        setHasMore(false);
      }

      options = [...res.data];
      setCompanyList([...companyList, ...res.data]);
    }

    let filtered: Array<any> = [];

    if (!input) {
      filtered = [...convertCompaniesOptions(options)];
    } else {
      const searchLowerCase = input.toLowerCase();
      if (searchLowerCase.length < 3) {
        filtered = [...convertCompaniesOptions(companyList)];
      } else {
        const result = await getCompanyList({
          name: searchLowerCase,
        });
        setCompanyList(Array.from(new Set([...companyList, ...result.data])));
        filtered = convertCompaniesOptions(result.data);
      }
    }

    return {
      options: filtered,
      hasMore,
    };
  };

  const convertCompaniesOptions = (companies: Array<GatewayProviderGetCompanyProps>) => {
    return companies.map((company) => ({
      label: company.name,
      value: company.id,
    }));
  };

  const selectTransaction = (fpsTransaction: IntegrationTransactionProps | undefined) => {
    setSelectedTransaction(fpsTransaction);
    setShowTransactionDetailModal(true);
  };

  const getData = async () => {
    const params = filterToParams();
    setIsFetching(true);
    const res = await getFpsTransactionList(user.company_id, params);
    await dispatch(getCredentialsList(user.company_id));

    setFpsTransactions(res.data);
    setFpsTransactionsPagination(res.meta.pagination);
    setIsFetching(false);
  };

  const getStoreOptions = async () => {
    const res = await getAllStore(user?.company_id);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('store'),
        text: res?.message || 'Oops',
      });
      return;
    }
    const list = res.data.map((store) => {
      return {
        label: store.name,
        value: store.id,
      };
    });
    setStoreOptions(list);
  };

  const requestReportFromServer = async (exportType: ReportExportType) => {
    const params = filterToParams();
    params.export = exportType;
    params.limit = fpsTransactionsPagination?.total || 0;
    const res = await getFpsTransactionList(user.company_id, params);
    return res;
  };

  const onFilterDateChange = (date: Date, name: string) => {
    setFilter({
      ...filter,
      [name]: date,
    });
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.name === 'amount') {
      // Cleave's Event handler has rawValue
      setFilter({
        ...filter,
        amount: (e.target as any).rawValue,
      });
      return;
    }

    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (name: string) => (value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const filterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getData();
    } else {
      setCurrentPage(1);
    }
  };

  const resetFilter = () => {
    setFilter(filterInitialState);
  };

  const filterToParams = () => {
    const {
      from,
      to,
      start_date_time,
      end_date_time,
      amount,
      transaction_id,
      reference_id,
      custom_id,
      store,
      fixed_code,
      status,
      gateways,
      company_id,
    } = filter;

    const startDate: string = moment(from).format('YYYY-MM-DD').slice(0, 10);
    const endDate: string = moment(to).format('YYYY-MM-DD').slice(0, 10);

    let query: any = {
      [orderBy + '>']: moment(startDate + ' ' + start_date_time).format(TIME_ZONE_FORMAT),
      [orderBy + '<']: moment(endDate + ' ' + end_date_time).format(TIME_ZONE_FORMAT),
      gateway_code: gateways,
      amount,
      transaction_id,
      store_id: store,
      fixed_id: fixed_code,
      status,
      custom_id,
      reference_id,
      company_id: company_id ? company_id : '',
      page: currentPage,
      limit: 10,
      sort: `-${orderBy}`,
    };

    query = deleteEmptyStrings(query);

    return query;
  };

  const onOrderByChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setOrderBy(e.target.value as any);
  };

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-end border-top-0">{translate('transaction_id')}</th>
        <th className="border-end border-top-0">{translate('amount')}</th>
        <th className="border-end border-top-0">{translate('store')}</th>
        <th className="border-end border-top-0">{translate('gateway')}</th>
        <th className="border-end border-top-0">{translate('total_number_of_transaction')}</th>
        <th className="border-end border-top-0">{translate(orderBy)}</th>
        <th className="border-top-0">{translate('status')}</th>
      </tr>
    );
  };

  const renderData = () => {
    return fpsTransactions.map((integrationTransaction, index) => {
      const transactionStatus = integrationTransaction.status;

      return (
        <tr
          key={index}
          className="cursor-pointer"
          onClick={() => selectTransaction(integrationTransaction)}>
          <td>
            <span>{integrationTransaction.transaction_id}</span>
          </td>

          <td>
            <div className="amount" data-currency={integrationTransaction.currency}>
              {integrationTransaction.amount}&nbsp;
            </div>
          </td>

          <td>
            <span>
              {storeOptions.find((store) => store.value === integrationTransaction.store_id)?.label}
            </span>
          </td>

          <td>
            <span>{integrationTransaction.gateway_sub_name}</span>
          </td>

          <td>
            <span>{integrationTransaction.total_number_of_transaction}</span>
          </td>

          <td>
            <span>{integrationTransaction[orderBy]}</span>
          </td>

          <td>
            <div
              className={`text-${TRANSACTION_STATUS_COLOR[transactionStatus]} px-2 text-uppercase`}>
              {translate(transactionStatus)}
            </div>
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    if (!isGatewayProvider(user.role) && !isAgent(user.role)) {
      getStoreOptions();
    }
  }, []);

  useEffect(() => {
    getData();
  }, [orderBy, currentPage]);

  useEffect(() => {
    const filteredCredential = credentials.filter((credential) => credential.gateway_id === 13);
    setFpsCredentials(filteredCredential);
  }, [credentials]);

  return (
    <>
      <TopHeader title={translate('pay_plus')}>
        &nbsp;
        <ExportReportButton
          text={translate('export_transaction')}
          isDropdown={true}
          reportSectionRef={reportSectionRef}
          filter={{
            start_date: null,
            end_date: null,
          }}
          requestReportFromServer={requestReportFromServer}
          supportFileType={[ReportExportType.EXCEL, ReportExportType.CSV]}
        />
      </TopHeader>
      <SideNavbar location={location as any} type="payplus" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box payplus">
          <InfoHeader name="pay_plus_transactions" src="payplus" />
          <CustomTable
            totalColumn={7}
            renderHeading={() => tableHeader()}
            renderData={renderData}
            allowHover
            pagination={fpsTransactionsPagination}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <div id="side-content" className="hide-scrollbar">
        <ReportSection ref={reportSectionRef} type="integration-transactions" />
        <div className="filter-container d-flex flex-column justify-content-between bg-white shadow-sm mb-4">
          <form className="filter-form mt-0">
            <Input
              type="select"
              options={translateLabel(ORDER_TYPE, translate, true)}
              value={orderBy}
              onChange={onOrderByChange}
              legend={translate('order').toUpperCase()}
              name="type"
              disabled={isFetching}
            />
          </form>
        </div>
        <SideFilter isLoading={isFetching} onFilter={filterSearch} resetFilter={resetFilter}>
          <Input
            type="date"
            value={from}
            legend={translate('from').toUpperCase()}
            name="from"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="cleave"
            cleaveOptions={{
              time: true,
              timePattern: ['h', 'm', 's'],
            }}
            value={start_date_time}
            legend={translate('time').toUpperCase()}
            name="start_date_time"
            onChange={onFilterChange}
            placeholder="hh:mm:ss"
          />
          <Input
            type="date"
            value={to}
            legend={translate('to').toUpperCase()}
            name="to"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="cleave"
            cleaveOptions={{
              time: true,
              timePattern: ['h', 'm', 's'],
            }}
            value={end_date_time}
            legend={translate('time').toUpperCase()}
            name="end_date_time"
            onChange={onFilterChange}
            placeholder="HH:mm:ss"
          />
          {(isGatewayProvider(user.role) || isAgent(user.role)) && (
            <Input
              type="react-select-async"
              value={
                company_id !== ''
                  ? {
                      label: findCompanyName(),
                      value: company_id,
                    }
                  : {
                      label: translate('select_companies'),
                      value: '',
                    }
              }
              loadOptions={searchCompany}
              onChange={onCompanyNameSelect}
              legend={translate('company_id').toUpperCase()}
              name="company_id"
            />
          )}
          {!isAgent(user.role) && !isGatewayProvider(user.role) && (
            <Input
              type="cleave"
              cleaveOptions={{
                numeral: true,
                numeralDecimalScale: 2,
                numeralIntegerScale: 7,
                numeralPositiveOnly: true,
              }}
              legend={translate('amount').toUpperCase()}
              value={amount}
              name="amount"
              onChange={onFilterChange}
              placeholder={translate('amount')}
            />
          )}
          <Input
            type="text"
            legend={translate('transaction_id').toUpperCase()}
            value={transaction_id}
            name="transaction_id"
            onChange={onFilterChange}
            placeholder={translate('transaction_id')}
          />
          <Input
            type="text"
            legend={translate('reference_id').toUpperCase()}
            value={reference_id}
            name="reference_id"
            onChange={onFilterChange}
            placeholder={translate('reference_id')}
          />
          <Input
            type="text"
            legend={translate('custom_id').toUpperCase()}
            value={custom_id}
            name="custom_id"
            onChange={onFilterChange}
            placeholder={translate('custom_id')}
          />
          {!isAgent(user.role) && !isGatewayProvider(user.role) && (
            <>
              <Input
                type="react-select"
                legend={translate('store').toUpperCase()}
                options={storeOptions}
                value={store}
                name="store"
                onChange={onFilterSelectChange('store')}
                placeholder={translate('store')}
                defaultLabel={translate('all')}
                menuPortalTarget={document.body}
              />
            </>
          )}
          <Input
            type="react-select"
            legend={translate('transaction_status').toUpperCase()}
            options={translateLabel(PAY_PLUS_TRANSACTION_STATUS_OPTIONS, translate)}
            value={status}
            name="status"
            onChange={onFilterSelectChange('status')}
            defaultLabel={translate('all')}
            menuPortalTarget={document.body}
          />
          <Input
            isMulti
            type="react-select"
            legend={translate('gateway').toUpperCase()}
            options={convertCredentialOption()}
            value={gateways}
            name="status"
            onChange={onFilterSelectChange('gateways')}
            placeholder={translate('gateway')}
            menuPortalTarget={document.body}
          />
        </SideFilter>
      </div>
      <FpsTransactionModal
        isAgentOrGatewayProvider={isAgent(user.role) || isGatewayProvider(user.role)}
        reloadTransaction={getData}
        isOpen={showTransactionDetailModal}
        setIsOpen={setShowTransactionDetailModal}
        transaction={selectedTransaction}
      />
    </>
  );
};
