import { FC } from 'react';
import './Header.scss';

export interface HeaderProps {
  title: string;
  src?: string;
  alt?: string;
  caption?: string;
  captionRed?: string;
  noImage?: boolean;
}

export const Header: FC<HeaderProps> = (props: HeaderProps) => {
  const { src, title, alt = '', caption, captionRed, noImage = false } = props;
  return (
    <>
      <div className="form-header">
        {!noImage && <img src={src} alt={alt} />}
        <h1 className="open-sans-light m-0 text-muted">{title}</h1>
      </div>
      {caption && <span className="form-header-caption colfax-regular">{caption}</span>}
      {captionRed && <span className="form-header-caption-red colfax-regular">{captionRed}</span>}
    </>
  );
};
