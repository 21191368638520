import axios, {
  ContentType,
  defaultRequestHandler,
  DefaultResponse,
  getToken,
  params,
} from '../common/Fetcher';
import {
  CreatePayByLinkInterface,
  GetCompanyPayByLinkCodeListResponse,
  PayByLinkCodeBodyProps,
} from './interface';

export const getPayByLinkCodeList = (
  company_id: string,
  data = {},
): Promise<GetCompanyPayByLinkCodeListResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/payment-links?&${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const createPayByLinkCode = (
  store_id: string,
  body: PayByLinkCodeBodyProps,
): Promise<CreatePayByLinkInterface> =>
  defaultRequestHandler(
    axios.post(`/stores/${store_id}/payment-links`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

export const updatePayByLink = (id: string, data = {}): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.put(`/payment-links/${id}`, data, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );
