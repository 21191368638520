import {
  isInt,
  lengthBetween,
  lengthEqual,
  packValidateResults,
  required,
  accountHolder,
} from 'helpers/validators';
import { BankAccountFormProps } from 'services/API/Company/interface';

export const validate = (data: BankAccountFormProps) =>
  packValidateResults([
    required(data, ['code', 'branch', 'number', 'currency', 'name', 'holder']),
    isInt(data, ['code', 'branch', 'number']),
    lengthEqual(data, ['code', 'branch'], { min: 3 }),
    lengthBetween(data, ['number'], { min: 6, max: 13 }),
    accountHolder(data, ['holder']),
  ]);
