import { params } from 'helpers/functions/param';
import { AnyResponse } from 'services/API/common/Fetcher';
import axios, { defaultRequestHandler, getToken } from '../common/Fetcher';
import { GetBalanceCompanyTransactionResponse, GetBalanceCompanyResponse } from './interface';

export const getCompanyBalance = (company_id: string): Promise<GetBalanceCompanyResponse> =>
  defaultRequestHandler(
    axios.get(`/balance/${company_id}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
export const getCompanyBalanceTransaction = (
  company_id: string,
  data = {},
): Promise<GetBalanceCompanyTransactionResponse> =>
  defaultRequestHandler(
    axios.get(`/balance/${company_id}/transactions?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
      timeout: 60000,
    }),
  );
export const updateBalance = (): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.get(`me?include=balance,daily_balance,company`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
