import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { LoadOptions } from 'react-select-async-paginate';
import { getBankCodeList } from 'services/API/Bank_Info';

const valueToLabel = (value: any): SelectOptionProps => {
  if (!value) {
    return { label: '', value: null };
  }
  return {
    label: `${value.clearing_code} - ${value.institution_name}`,
    value: value,
  };
};

export const BankCodeSelector = ({ value, onChange, error, isRequired, disabled = false }: any) => {
  const { translate } = useTranslate();
  const [selectedValue, setSelectedValue] = useState<any>();

  const searchBankCode: LoadOptions<any, any> = async (search_bank, loadedOptions, additional) => {
    const page = additional?.page || 1;
    const result = await getBankCodeList({ page, search_bank });

    const options: Array<any> = result.data.map(valueToLabel);
    return {
      options,
      hasMore: !!result.meta.pagination?.links.next ?? false,
      additional: {
        page: page + 1,
      },
    };
  };

  const changeSelected = (value: any) => {
    setSelectedValue(value);
    onChange(value);
  };

  useEffect(() => {
    const initValue = async () => {
      let val = value;
      if (value && typeof value === 'string') {
        const bankInfoList = (await getBankCodeList({ clearing_code: value })).data;
        if (bankInfoList) {
          val = bankInfoList[0];
        } else {
          val = {
            institution_name: '',
            clearing_code: value,
          };
        }
      }

      setSelectedValue(val);
    };

    initValue();
  }, []);

  return (
    <Input
      type="react-select-async"
      value={valueToLabel(selectedValue)}
      loadOptions={searchBankCode}
      onChange={changeSelected}
      legend={translate('bank_code').toUpperCase()}
      name=""
      isRequired={isRequired}
      error={error}
      disabled={disabled}
    />
  );
};
