import 'animate.css/animate.min.css';
import 'assets/scss/main.scss';
import { TranslateProvider } from 'context/TranslateContext';
import { WidthDetector } from 'context/widthDetector.context';
import { FC } from 'react';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import store from 'redux/store';
import App from './App';

const Root: FC = () => (
  <Provider store={store}>
    <WidthDetector>
      <TranslateProvider>
        <App />
      </TranslateProvider>
    </WidthDetector>
  </Provider>
);

export default Root;
