import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from 'components/custom/pagination';
import { useTranslate } from 'context/TranslateContext';
import { FC, useState } from 'react';
import { Roles } from 'redux/state/AuthState';
import { DeviceProps } from 'services/API/Device/interface';
import { PaginationProps } from 'services/API/common/Fetcher';
import { EmptyResult } from 'components/custom/table/emptyResult';

export interface DeviceTableProps {
  devices: Array<DeviceProps>;
  devicesPagination: PaginationProps | null;
  roles: Roles;
  onDeleteDevice: (device_id: string) => void;
  onSelectDevice: (device: DeviceProps) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const DeviceTable: FC<DeviceTableProps> = ({
  devices,
  devicesPagination,
  roles,
  onDeleteDevice,
  onSelectDevice,
  setCurrentPage,
}: DeviceTableProps) => {
  const { translate } = useTranslate();
  const [listIdStartFrom, setListIdStartFrom] = useState<number>(1);

  return (
    <div className="d-flex flex-column bg-white pt-3 px-3 pb-4 round-bottom-lg">
      <table className="table table-hover px-2">
        <thead>
          <tr>
            <th scope="col" className="border-top-0">
              #
            </th>
            <th scope="col" className="border-top-0">
              {translate('device_name')}
            </th>
            <th scope="col" className="border-top-0">
              {translate('creation_date')}
            </th>
            <th scope="col" className="border-top-0">
              {translate('status')}
            </th>
            <th scope="col" className="border-top-0">
              {translate('actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {devices.length > 0 ? (
            devices.map((device, index) => {
              return (
                <tr key={device.id}>
                  <th scope="row" className="align-middle">
                    {listIdStartFrom + index}
                  </th>
                  <td className="align-middle">{device.name}</td>
                  <td className="align-middle">{device.created_at}</td>
                  <td className="align-middle">
                    {translate(device.enabled ? 'enabled' : 'disabled')}
                  </td>
                  <td className="align-middle">
                    {roles.isOwner ? (
                      <div>
                        <button
                          className="btn btn-success btn-sm"
                          type="button"
                          onClick={() => onSelectDevice(device)}>
                          <FontAwesomeIcon icon={faCog} color="#ffffff" />
                        </button>
                        {device.enabled && (
                          <>
                            &nbsp;
                            <button
                              className="btn btn-danger btn-sm"
                              type="button"
                              onClick={() => onDeleteDevice(device.id || '')}>
                              <FontAwesomeIcon icon={faTimes} color="#ffffff" />
                            </button>
                          </>
                        )}
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="">
              <td colSpan={5} rowSpan={5} className="text-center">
                <EmptyResult text="no_data" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {devicesPagination && (
        <div className="table-responsive admin mt-0">
          <Pagination
            pagination={devicesPagination}
            setCurrentPage={setCurrentPage}
            setListIdStartFrom={setListIdStartFrom}
          />
          <div className="mb-5 mb-md-0"></div>
        </div>
      )}
    </div>
  );
};
