import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteCheckoutDomainAction,
  getCheckoutDomainListAction,
} from 'actions/CheckoutDomainActions';
import { InfoHeader } from 'components/custom/info_header';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { isSuperowner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { CheckoutDomainProps } from 'services/API/Checkout_Domain/interface';
import { PaginationProps } from 'services/API/common/Fetcher';
import { useSwal } from 'helpers/sweetalert';
import { CreateCheckoutDomainModal } from './Modal';
import SideFilter from 'components/custom/sideFilter';
import { Input } from 'components/custom/input';
import { ANY_OBJECT } from 'constants/format';

const initialFilterState: ANY_OBJECT = {
  id: '',
  name: '',
  domain_pattern: '',
};

export const CheckoutDomains = () => {
  const {
    auth: {
      user: { company_id, role },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [checkoutDomains, setCheckoutDomains] = useState<Array<CheckoutDomainProps>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState<PaginationProps | null>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const isReadonly = isSuperowner(role);

  const { id, name, domain_pattern } = filter;

  const filterToParams = () => {
    const data: { [key: string]: string } = {
      id,
      name,
      domain_pattern,
    };

    const names = Object.keys(data);
    const values = Object.values(data);

    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        delete data[names[index]];
      }
    });

    Object.assign(data, {
      limit: 10,
      page: currentPage,
      sort: '-created_at',
    });

    return data;
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getData();
    } else {
      setCurrentPage(1);
    }
  };

  const deleteCheckoutDomain = async (checkoutDomain: CheckoutDomainProps) => {
    const res = await dispatch(deleteCheckoutDomainAction(company_id, checkoutDomain.id));
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('delete_domain'),
      text: res.message ?? 'Oops',
    });
    if (!res.success) {
      return;
    }

    await getData();
  };
  const confirmDeleteCheckoutDomain = (checkoutDomain: CheckoutDomainProps) => {
    Swal.fire({
      icon: 'warning',
      title: translate('delete_domain'),
      text: translate('are_you_sure_delete_domain'),
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => deleteCheckoutDomain(checkoutDomain),
    });
  };

  const getData = async () => {
    setIsFetching(true);
    const params = filterToParams();
    const res = await dispatch(getCheckoutDomainListAction(company_id, params));
    setIsFetching(false);
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('checkout_domain'),
        text: res.message ?? 'Oops',
      });
    }

    setCheckoutDomains(res.data);
    setPagination(res.meta?.pagination ?? null);
  };
  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location as any} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="company-info" name="checkout_domain" />
          <div className="pt-3 bg-white">
            <SideFilter
              isLoading={isFetching}
              resetFilter={resetFilter}
              onFilter={onFilterSearch}
              defaultCollapsed={true}>
              <Input
                type="text"
                value={id}
                legend={translate('domain_id').toUpperCase()}
                name="id"
                onChange={onFilterChange}
                placeholder={translate('domain_id')}
              />
              <Input
                type="text"
                value={domain_pattern}
                legend={translate('domain_name').toUpperCase()}
                name="domain_pattern"
                onChange={onFilterChange}
                placeholder={translate('domain_name')}
              />
              <Input
                type="text"
                value={name}
                legend={translate('name').toUpperCase()}
                name="name"
                onChange={onFilterChange}
                placeholder={translate('name')}
              />
            </SideFilter>
            {!isReadonly && (
              <div className="document mt-2 mb-3 mx-3 dash-border">
                <button
                  className="btn btn-outline-secondary"
                  style={{ margin: 'auto' }}
                  onClick={() => setIsCreateModalOpen(true)}>
                  {translate('add')}
                </button>
              </div>
            )}

            <CustomTable
              totalColumn={4}
              pagination={pagination}
              setCurrentPage={setCurrentPage}
              renderHeading={() => (
                <tr>
                  <th scope="col" className="border-top-0">
                    {translate('domain_id')}
                  </th>
                  <th scope="col" className="border-top-0">
                    {translate('name')}
                  </th>
                  <th scope="col" className="border-top-0">
                    {translate('domain_name')}
                  </th>
                  {!isReadonly && (
                    <th scope="col" className="border-top-0">
                      {translate('actions')}
                    </th>
                  )}
                </tr>
              )}
              renderData={() =>
                checkoutDomains.map((checkoutDomain, index) => {
                  return (
                    <tr key={checkoutDomain.id}>
                      <th scope="row" className="align-middle">
                        {checkoutDomain.id}
                      </th>
                      <td className="align-middle">{checkoutDomain.name}</td>
                      <td className="align-middle">
                        {checkoutDomain.protocol}://{checkoutDomain.domain_pattern}
                      </td>
                      {!isReadonly && (
                        <td className="align-middle">
                          <div>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => confirmDeleteCheckoutDomain(checkoutDomain)}>
                              <FontAwesomeIcon icon={faTrash} color="#ffffff" />
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })
              }
              centerPaginate={true}></CustomTable>
          </div>
        </div>
      </div>
      <CreateCheckoutDomainModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        getData={getData}
      />
    </>
  );
};

export default CheckoutDomains;
