import { logout as logoutUser } from 'actions/AuthActions';
import { FC } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

export interface SideNavbarProps {
  logoutUser: () => void;
}

const SideNavbar: FC<SideNavbarProps> = ({ logoutUser }: SideNavbarProps) => {
  logoutUser();
  return <Redirect to="/" push={false} />;
};

export default connect(null, { logoutUser })(SideNavbar);
