import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, ThunkResult } from 'redux/types';
import { AnyResponse } from 'services/API/common/Fetcher';
import * as CompanyApi from 'services/API/Company';
import {
  GetCompanyBankAccountsResponse,
  GetCompanyDocumentListResponse,
} from 'services/API/Company/interface';
import * as DocumentAPI from 'services/API/Document';
import { receiveData } from './DataAction';

export const uploadContract = (
  fd: FormData,
  company_id: string,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(async () => {
    const res = await CompanyApi.uploadFileToCompany(company_id, fd);
    return res;
  });

export const getCompanyDocumentsList = (
  company_id: string,
  data = {},
): ThunkResult<Promise<GetCompanyDocumentListResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await CompanyApi.getCompanyDocumentsList(company_id, data);
    if (res.success) {
      dispatch(receiveData(res, 'documents'));
    }
    return res;
  });

export const getDocument = (
  document_id: string,
  isShowLoader = true,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => DocumentAPI.getDocument(document_id), isShowLoader);

export const uploadDocument = (
  company_id: string,
  formData: FormData,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => CompanyApi.uploadFileToCompany(company_id, formData));

export const getBankAccounts = (
  company_id: string,
): ThunkResult<Promise<GetCompanyBankAccountsResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await CompanyApi.getCompanyBankAccounts(company_id);
    if (res.success) {
      dispatch(receiveData(res, 'accounts'));
    }
    return res;
  });
