import cross from 'assets/img/ValidationRule/cross.png';
import tick from 'assets/img/ValidationRule/tick.png';
import './index.scss';

interface ValidationRuleProps {
  isValid: boolean;
  rule: string;
}

export const ValidationRule = ({ isValid, rule }: ValidationRuleProps) => {
  return (
    <div className="rule">
      <img src={isValid ? tick : cross} alt="" />
      <span>{rule}</span>
    </div>
  );
};
