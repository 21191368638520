import { validatePassword, PasswordValidationRules } from './validator';
import { Input } from 'components/custom/input';
import { ValidationRule } from 'components/custom/ValidationRule';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Collapse } from 'reactstrap';

const initialValidationState: PasswordValidationRules = {
  containsEightCharacters: false,
  containsNumber: false,
  containsUpperAndLowerCase: false,
};

interface PasswordProps {
  password: string;
  setPassword: (password: string) => void;
  errors: ANY_OBJECT;
  setErrors: Dispatch<SetStateAction<ANY_OBJECT>>;
}

export const Password = ({ password, setPassword, setErrors, errors }: PasswordProps) => {
  const { translate } = useTranslate();

  const [passwordRules, setPasswordRules] =
    useState<PasswordValidationRules>(initialValidationState);
  const [isPasswordCollapseOpen, setIsPasswordCollapseOpen] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const result = validatePassword(e.target.value);
    setPasswordRules(result);

    setPassword(e.target.value);
    if (result.overall) {
      setErrors({ ...errors, password: null });
    }
  };

  return (
    <>
      <Input
        isRequired
        type="password"
        onFocus={() => setIsPasswordCollapseOpen(true)}
        legend={translate('password').toUpperCase()}
        name="password"
        value={password}
        onChange={onChange}
        placeholder={translate('enter_password')}
        error={translate(...(errors?.password ?? ''))}
      />

      <Collapse isOpen={isPasswordCollapseOpen}>
        <div className="mt-2">
          <div className="rule">
            <span>
              {translate('allow_special_characters')}
              <br />
              {translate('password_characters')}
            </span>
          </div>
          <ValidationRule
            isValid={passwordRules.containsEightCharacters}
            rule={translate('contains_8_characters')}
          />
          <ValidationRule
            isValid={passwordRules.containsNumber}
            rule={translate('contains_1_digit')}
          />
          <ValidationRule
            isValid={passwordRules.containsUpperAndLowerCase}
            rule={translate('contains_upper_and_lower_case')}
          />
        </div>
      </Collapse>
    </>
  );
};
