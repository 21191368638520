import { getCredentialsList } from 'actions/GatewayActions';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FunctionDispatch } from 'redux/types';
import * as ApiCompany from 'services/API/Company';
import { useSwal } from 'helpers/sweetalert';

import '../../layouts/card/GatewayCard.scss';

export const useGateways = (company_id: string): [Array<string>, () => Promise<void>] => {
  const { translate } = useTranslate();
  const [Swal] = useSwal();

  const dispatch = useDispatch<FunctionDispatch>();
  const [mccAvailableGatewayCodeList, setMccAvailableGatewayCodeList] = useState<Array<string>>([]);

  const getAvailableGatewayGroups = async () => {
    const { data } = await ApiCompany.getAvailableGatewayList(company_id);
    const gatewayCodes: Array<string> = [];
    data.forEach((gateway) => {
      gateway.subtype.forEach((subtype) => {
        if (subtype.gateway_code && !gatewayCodes.includes(subtype.gateway_code)) {
          gatewayCodes.push(subtype.gateway_code);
        }
      });
    });
    setMccAvailableGatewayCodeList(gatewayCodes);
  };

  const getCredentials = async () => {
    const { message, data } = await dispatch(getCredentialsList(company_id));

    if (!data) {
      Swal.fire({
        title: translate('payment_gateways'),
        text: message || 'Oops',
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    getCredentials();
    getAvailableGatewayGroups();
  }, []);

  return [mccAvailableGatewayCodeList, getCredentials];
};
