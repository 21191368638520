import { FC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { AuthStateProps } from 'redux/state/AuthState';
import { GlobalTypes } from 'redux/types';

export interface WrapperProps {
  auth: AuthStateProps;
  location: RouteComponentProps;
}

const requireAuth = function (Component: FC<any>) {
  const RequireAuth: FC<WrapperProps> = ({ auth: { isAuthenticated }, location }: WrapperProps) => {
    const history = useHistory();

    if (!isAuthenticated) {
      history.push('/login');
    }

    return <Component location={location} />;
  };

  const mapStateToProps = (state: GlobalTypes.RootState) => ({
    auth: state.auth,
  });

  return connect(mapStateToProps)(RequireAuth);
};
export default requireAuth;
