import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'components/custom/input';
import { useTranslate } from 'context/TranslateContext';
import { useCountdown } from 'helpers/hooks/useCountdown';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Spinner } from 'reactstrap';
import {
  createPersonalToken,
  deletePersonalToken,
  getPersonalTokenLists,
} from 'services/API/Tokens';
import { TokenProps } from 'services/API/Tokens/interface';
import { useSwal } from 'helpers/sweetalert';

export const Token = () => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [countdown, setCountdown] = useCountdown();

  const focusRef = useRef<HTMLInputElement>(null);

  const [tokenName, setTokenName] = useState('My Token');

  const [tokens, setTokens] = useState<Array<TokenProps>>([]);

  const [disableEdit, setDisableEdit] = useState(true);
  const [isCreatingNew, setIsCreatingNew] = useState(false);

  const [loading, setLoading] = useState(false);

  const getInitialData = async () => {
    setLoading(true);
    const res = await getPersonalTokenLists();
    setLoading(false);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('personal_token'),
        text: res?.message || 'Oops',
      });
      return;
    }

    const isActive = res.data?.[0]?.status === 'active' ?? false;
    setDisableEdit(isActive);
    if (isActive) {
      setTokens(res.data);
      setTokenName(res.data[0].name);
      setIsCreatingNew(false);
    } else {
      setIsCreatingNew(true);
    }
  };

  const submit = async () => {
    const body = {
      name: tokenName,
    };
    setLoading(true);
    const res = await createPersonalToken(body);
    setLoading(false);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('create_personal_token'),
        text: res?.message || 'Oops',
      });
      return;
    }

    await getInitialData();
  };

  const areYouSureDeleteToken = () => {
    Swal.fire({
      icon: 'warning',
      title: translate('delete_personal_token'),
      text: translate('are_you_sure_delete_personal_token'),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: translate('yes'),
      cancelButtonText: translate('no'),
      preConfirm: () => deleteToken(),
    });
  };

  const deleteToken = async () => {
    const token = tokens?.[0];
    const allowDelete = token?.status === 'active' ?? false;
    if (!allowDelete) {
      return;
    }
    setLoading(true);
    const res = await deletePersonalToken(token.id);
    Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('delete_personal_token'),
      text: res?.success
        ? translate('congratulations_pers_token_successfully_deleted')
        : res?.message || 'Oops',
    });
    setLoading(false);
    if (!res.success) {
      return;
    }
    setTokens([]);
    await getInitialData();
    enableInput();
  };

  const enableInput = () => {
    focusRef.current?.focus();
    setDisableEdit(false);
    setTokenName('My Token');
    setIsCreatingNew(true);
  };

  const createToken = () => {
    if (!isCreatingNew) {
      enableInput();
      return;
    }
    submit();
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTokenName(e.target.value);
  };

  const copyToClipboard = () => {
    const token = tokens[0].token ?? '';
    navigator.clipboard.writeText(token).then(() => {
      setCountdown(5);
    });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div>
      <Input
        focusRef={focusRef}
        type="text"
        disabled={disableEdit}
        onChange={onNameChange}
        name=""
        value={tokenName}
        legend={translate('name')}
        suffix={
          disableEdit ? (
            countdown > 0 ? (
              <p className="m-0 fx-16 colfax-regular text-primary">
                {translate('copied_at_your_clipboard')}
              </p>
            ) : (
              <button className="btn" type="button" onClick={copyToClipboard}>
                <FontAwesomeIcon icon={faCopy} />
              </button>
            )
          ) : undefined
        }
      />
      {disableEdit && (
        <p className="p-2 text-danger">{translate('personal_token_effective_in_1_year')}</p>
      )}
      <div className="d-flex justify-content-end mt-3">
        {(!tokens || tokens?.[0]?.status !== 'active') && (
          <button className="btn btn-primary" onClick={createToken} disabled={loading}>
            {loading ? <Spinner size="sm" /> : translate('add')}
          </button>
        )}
        {tokens?.[0]?.status === 'active' && (
          <>
            &nbsp;
            <button className="btn btn-danger" onClick={areYouSureDeleteToken} disabled={loading}>
              {loading ? <Spinner size="sm" /> : translate('delete')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};
