import { useEffect, useState } from 'react';

export const useCountdown = (
  initialTime = 0,
  interval = 1000,
): [number, (time: number) => void] => {
  const [countdown, setCountdown] = useState(initialTime);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (countdown) {
      timeout = setTimeout(() => {
        setCountdown(countdown - 1);
      }, interval);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
    //eslint-disable-next-line
  }, [countdown]);

  return [countdown, setCountdown];
};
