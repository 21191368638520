import { GATEWAY_ORDER } from 'constants/TransactionGateways';
import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { Dispatch, ThunkResult } from 'redux/types';
import { getCompanyCredentialList } from 'services/API/Company';
import { GetCompanyCredentialsResponse } from 'services/API/Company/interface';
import {
  applyCredential as apply,
  applyCredentialByType as applyByType,
} from 'services/API/Gateway';
import { ApplyCredentialBody, ApplyCredentialByTypeBody } from 'services/API/Gateway/interface';
import { AnyResponse } from 'services/API/common/Fetcher';
import { GET_CREDENTIALS } from './actionTypes';

export const getCredentialsList = (
  company_id: string,
): ThunkResult<Promise<GetCompanyCredentialsResponse>> =>
  asyncRequestHandler(async (dispatch: Dispatch) => {
    const res = await getCompanyCredentialList(company_id);
    if (res.success) {
      res.data = res.data.sort(
        (a, b) => GATEWAY_ORDER.indexOf(a.gateway_code) - GATEWAY_ORDER.indexOf(b.gateway_code),
      );
      dispatch({
        type: GET_CREDENTIALS,
        payload: res.data,
      });
    }

    return res;
  });

export const applyCredential = (
  company_id: string,
  data: ApplyCredentialBody,
): ThunkResult<Promise<AnyResponse>> => asyncRequestHandler(() => apply(company_id, data));

export const applyCredentialByType = (
  company_id: string,
  data: ApplyCredentialByTypeBody,
): ThunkResult<Promise<AnyResponse>> => asyncRequestHandler(() => applyByType(company_id, data));
