import { Input } from 'components/custom/input';
import { OFFLINE_STORE, reactSelectBoolean, translateLabel } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { rectifyAmount } from 'helpers/functions/rectifyAmount';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import { createEmvCode, createFixedCode, updateFixedCode } from 'services/API/FixedCode';
import {
  AttributesEmvCode,
  AttributesFixedCode,
  CompanyQrCode,
  QrCodeBodyProps,
} from 'services/API/FixedCode/interface';
import { useSwal } from 'helpers/sweetalert';
import { CodeSelectionBox } from './CodeSelectionBox';
import { validate } from './validator';
import { StoreSelect } from 'components/custom/ReactSelectAsync/Select/StoreSelect';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import { PAY_PLUS_OFFLINE_GATEWAY } from 'constants/TransactionGateways';

// @todo should create a global interface for modal
interface FixedCodeModalProps {
  isEdit: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  fixedCode: CompanyQrCode | null;
  reloadCodes: () => void;
}

const initialState = {
  name: '',
  subtitle_name: '',
  amount: '',
  isAlipay: true,
  is_fixed_amount: true,
  fps_gateway_code: '',
} as QrCodeBodyProps;

// @todo should create a modal wrapper, for efficiency
export const FixedCodeModal = ({
  isEdit,
  isOpen,
  setIsOpen,
  fixedCode,
  reloadCodes,
}: FixedCodeModalProps) => {
  const {
    auth: { user, credentials },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [form, setForm] = useState<QrCodeBodyProps>(initialState);
  const [store, setStore] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [fpsOfflineOption, setFpsOfflineOption] = useState<any>([]);
  const hasFpsOffline = getUserActivatedGateways().some((code) =>
    PAY_PLUS_OFFLINE_GATEWAY.includes(code),
  );

  const { name, amount, is_fixed_amount, subtitle_name, isAlipay, fps_gateway_code } = form;

  // Form Functions
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isEdit = !!fixedCode;

    let apiCall: () => Promise<any>;
    const { isAlipay, ...data } = form;
    const { errors, isValid } = validate(data, store?.id, isAlipay, hasFpsOffline);
    setErrors(errors);
    if (!isValid) {
      return;
    }
    setErrors({});

    data.is_fixed_amount = data.is_fixed_amount ? '1' : '0';
    if (data.amount === 0 || data.amount === '') {
      delete data.amount;
    }
    if (data.subtitle_name === '') {
      delete data.subtitle_name;
    }

    if (fixedCode) {
      // Update
      if (isAlipay) {
        apiCall = () => updateFixedCode(fixedCode.id, data);
      } else {
        apiCall = () => Promise.reject();
      }
    } else {
      // Create
      if (isAlipay) {
        apiCall = () => createFixedCode(user.company_id, store?.id, data);
      } else {
        apiCall = () => createEmvCode(store?.id, data);
      }
    }

    setIsLoading(true);
    const res = await apiCall();
    setIsLoading(false);

    if (!res.success) {
      await Swal.fire({
        title: translate('qr_code'),
        text: res.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    await Swal.fire({
      icon: 'success',
      title: translate('qr_code'),
      text: translate(
        isEdit
          ? 'congratulations_code_successfully_updated'
          : 'congratulations_new_code_successfully_created',
      ),
    });
    reloadCodes();
    toggle();
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name === 'amount') {
      const { rawValue } = e.target as any;
      const amount = rectifyAmount(rawValue);
      setForm({
        ...form,
        amount,
      });
      return;
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSelectGateway = (isAlipay: boolean) => () => {
    setForm({
      ...form,
      isAlipay: isAlipay,
    });
  };

  const onFieldChange = (name: string) => (val: string | number) => {
    if (name === 'is_fixed_amount' && val) {
      form.amount = 0;
    }
    setForm({
      ...form,
      [name]: val,
    });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const getFpsOfflineOption = () => {
    return credentials
      .filter(
        (cred) =>
          cred.status == 'activated' && PAY_PLUS_OFFLINE_GATEWAY.includes(cred.gateway_code),
      )
      .map((cred) => {
        return {
          label: cred.gateway_sub_name,
          value: cred.gateway_code,
        };
      });
  };

  useEffect(() => {
    if (fixedCode) {
      const attr: any = {};
      if (fixedCode.type === 'fixed_code') {
        const attributes = fixedCode?.attributes as AttributesFixedCode;
        attr.isAlipay = true;
        attr.amount = Number(attributes.amount);
        attr.name = attributes.name;
        attr.subtitle_name = attributes.subtitle_name;
        attr.is_fixed_amount = attr.amount !== 0;
      } else if (fixedCode.type === 'emv_code') {
        const attributes = fixedCode?.attributes as AttributesEmvCode;
        attr.isAlipay = false;
        attr.amount = Number(attributes.amount);
        attr.name = attributes.name;
        attr.subtitle_name = '';
        attr.is_fixed_amount = attr.amount !== 0;
      }

      setForm(attr);
      setStore(fixedCode?.attributes?.store);
    } else {
      setStore(null);
      setForm(initialState);
    }
  }, [fixedCode, isOpen]);

  useEffect(() => {
    setFpsOfflineOption(getFpsOfflineOption);
  }, [credentials]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {fixedCode ? translate('update_qr_code') : translate('create_qr_code')}
      </ModalHeader>
      <ModalBody>
        <div className="px-lg-3 px-2">
          <form onSubmit={onSubmit}>
            <div className="d-flex justify-content-center">
              <div className="text-center mb-2 col-10">
                <h5 className="text-secondary text-start">{translate('select_gateway')}</h5>

                <CodeSelectionBox
                  isEdit={isEdit}
                  store_type={OFFLINE_STORE}
                  onSelectGateway={onSelectGateway}
                  isAlipay={isAlipay}
                />

                <div className="text-start">
                  <Input
                    isRequired
                    type="react-select"
                    options={translateLabel(reactSelectBoolean, translate, true)}
                    value={is_fixed_amount}
                    onChange={onFieldChange('is_fixed_amount')}
                    legend={translate('is_fixed_amount').toUpperCase()}
                    name="is_fixed_amount"
                  />
                  {is_fixed_amount && (
                    <Input
                      isRequired
                      type="cleave"
                      prefix="$"
                      cleaveOptions={{
                        numeral: true,
                        numeralDecimalScale: 2,
                        numeralIntegerScale: 7,
                        numeralPositiveOnly: true,
                      }}
                      onChange={onChange}
                      value={amount as number}
                      name="amount"
                      legend={translate('amount').toUpperCase()}
                      error={translate(...(errors.amount ?? ''))}
                    />
                  )}
                  <Input
                    isRequired
                    type="text"
                    value={name}
                    name="name"
                    legend={translate(isAlipay ? 'display_name' : 'name').toUpperCase()}
                    onChange={onChange}
                    error={translate(...(errors.name ?? ''))}
                  />
                  {isAlipay && (
                    <Input
                      isRequired
                      type="text"
                      value={subtitle_name as string}
                      name="subtitle_name"
                      legend={translate('ref_name').toUpperCase()}
                      onChange={onChange}
                      error={translate(...(errors.subtitle_name ?? ''))}
                    />
                  )}

                  {!isAlipay && (
                    <Input
                      isRequired
                      type="text"
                      value={subtitle_name as string}
                      name="subtitle_name"
                      legend={translate('ref_name').toUpperCase()}
                      onChange={onChange}
                      error={translate(...(errors.subtitle_name ?? ''))}
                    />
                  )}

                  {!isAlipay && hasFpsOffline && (
                    <Input
                      isRequired
                      type="react-select"
                      options={fpsOfflineOption}
                      value={fps_gateway_code as string}
                      defaultLabel={translate('select')}
                      placeholder={translate('select')}
                      onChange={onFieldChange('fps_gateway_code')}
                      legend={translate('fps_gateway').toUpperCase()}
                      name="fps_gateway_code"
                      error={translate(...(errors.fps_gateway_code ?? ''))}
                    />
                  )}
                  <StoreSelect
                    legend={translate('store').toUpperCase()}
                    disabled={isEdit}
                    isRequired
                    showStatus
                    setStore={setStore}
                    store={store}
                    searchOptionsParams={{ include: 'status', type: OFFLINE_STORE }}
                    error={errors.storeId}
                  />

                  <div className="d-grid">
                    <button className="btn btn-primary mt-3" disabled={isLoading}>
                      {isLoading ? <Spinner size="sm" /> : translate('submit')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};
