import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { ThunkResult } from 'redux/types';
import { AnyResponse } from 'services/API/common/Fetcher';
import { getIntegratorAPI } from 'services/API/Integration';

export const getIntegratorAction = (
  code: string,
  params?: object,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => getIntegratorAPI(code).get(params));
