import yedpay from 'assets/img/navbar_components/logo.png';
import scanLogo from 'assets/img/print_qrcode/scan-logo.png';
import {
  ALIPAY_CN_SVG,
  FPS_SVG,
  QUICKPASS_HORIZONTAL_WHITE,
  WECHATPAY_LOGO_CHINESE_PNG,
} from 'constants/gateways/gatewayLogos';
import { useTranslate } from 'context/TranslateContext';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  AttributesEmvCode,
  AttributesFixedCode,
  CompanyQrCode,
} from 'services/API/FixedCode/interface';

interface DisplayCodeModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  fixedCode: CompanyQrCode | null;
}

interface GatewayLogoProps {
  src: string;
  alt?: string;
}

export const DisplayCodeModal = ({ isOpen, setIsOpen, fixedCode }: DisplayCodeModalProps) => {
  const { translate } = useTranslate();

  const hasAlipayOffline = getUserActivatedGateways().includes('1_1');
  const hasWeChatPayOffline = getUserActivatedGateways().includes('8_1');
  const hasUnionPayOffline = getUserActivatedGateways().includes('10_1');
  const hasFpsOffline = getUserActivatedGateways().some((code) => ['13_2', '13_4'].includes(code));
  let codeSupportFps = false;

  const emvCodeGatewaysCount = [hasWeChatPayOffline, hasUnionPayOffline, hasFpsOffline].filter(
    Boolean,
  ).length;
  const emvCodeLogoWidth = Math.floor(12 / emvCodeGatewaysCount);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const attr: any = {};
  if (fixedCode?.type === 'fixed_code') {
    const attributes = fixedCode?.attributes as AttributesFixedCode;
    attr.name = attributes.name;
    attr.subtitle_name = attributes.subtitle_name;
    attr.store_name = attributes.store_name;
    attr.amount = Number(attributes.amount);
    attr.status = attributes.status.toLowerCase() === 'active';
    attr._links = {
      qrcode: attributes._links.qrcode.zh,
      checkout: attributes._links.checkout.zh,
    };
  } else if (fixedCode?.type === 'emv_code') {
    const attributes = fixedCode?.attributes as AttributesEmvCode;
    attr.name = attributes.name;
    attr.subtitle_name = '';
    attr.store_name = attributes.store_name;
    attr.amount = Number(attributes.amount);
    attr.status = !!attributes.status;
    attr._links = attributes._links;
    attr.support_gateway_codes = attributes.support_gateway_codes;

    codeSupportFps =
      attr.support_gateway_codes.includes('13_2') || attr.support_gateway_codes.includes('13_4');
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate('qr_code')}</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <h1 className="fx-35">{attr.name}</h1>
          <h2 className="fx-28">{attr.subtitle_name}</h2>
          <h1 className="text-danger font-bold fx-35">
            {attr.amount === 0 ? translate('not_fixed_amount') : `$${attr.amount} HKD`}
          </h1>
          <div className="row justify-content-center">
            <div className="col-9 border bg-muted rounded py-2">
              <img src={attr._links?.qrcode} alt="qr-code" className="img-fluid w-80" />
              <div className="mt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={scanLogo} alt="" className="img-fluid me-3 w-15" />
                  <p className="fx-14 m-0">{translate('scan_with_yr_phone')}</p>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-2">
                {fixedCode?.type === 'fixed_code' && (
                  <>
                    <img
                      src={ALIPAY_CN_SVG}
                      alt="Alipay"
                      className={hasAlipayOffline ? `h-50 w-30 me-4` : `d-none`}
                      draggable={false}
                    />
                    <img
                      src={WECHATPAY_LOGO_CHINESE_PNG}
                      alt="WeChat Pay"
                      className={hasWeChatPayOffline ? `h-50 w-30` : `d-none`}
                      draggable={false}
                    />
                  </>
                )}
                {fixedCode?.type === 'emv_code' && (
                  <>
                    <img
                      src={QUICKPASS_HORIZONTAL_WHITE}
                      alt="Quick Pass"
                      className={
                        attr.support_gateway_codes.includes('10_1') ? `h-75 w-30 me-4` : `d-none`
                      }
                      draggable={false}
                    />
                    <img
                      src={FPS_SVG}
                      alt="FPS"
                      className={codeSupportFps ? `h-75 w-10 me-4` : `d-none`}
                      draggable={false}
                    />
                    <img
                      src={WECHATPAY_LOGO_CHINESE_PNG}
                      alt="WeChat Pay"
                      className={
                        attr.support_gateway_codes.includes('8_1') ? `h-75 w-30` : `d-none`
                      }
                      draggable={false}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="text-center mt-2">
              <h4 className="fx-16">{translate('payment_tech_provided_by_yedpay')}</h4>
              <img src={yedpay} alt="" className="img-fluid w-30" />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const GatewayLogo = ({ src, alt }: GatewayLogoProps) => {
  return (
    <div className="col">
      <img src={src} alt={alt} className="w-100" />
    </div>
  );
};
