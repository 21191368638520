import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { PaginationProps } from 'services/API/common/Fetcher';
import { Input } from '../input';
import { validate } from './validator';

export interface PaginationComponentProps {
  pagination?: PaginationProps | null;
  setCurrentPage?: (page: number) => void;
  setListIdStartFrom?: (id: number) => void;
}

export interface PaginationItemProps {
  disable?: boolean;
  active?: boolean;
  text: string;
  onClick?: () => void;
}

export const Pagination = ({
  pagination,
  setCurrentPage,
  setListIdStartFrom,
}: PaginationComponentProps) => {
  if (!pagination || !setCurrentPage) {
    return <></>;
  }
  const { translate } = useTranslate();
  const paginationEl = useRef<HTMLDivElement>(null);
  const { total, per_page, current_page, total_pages } = pagination;
  const [tempPage, setTempPage] = useState(current_page);

  const from = () => {
    const pageCount = per_page * (current_page - 1) + 1;
    if (total === 0) {
      return 0;
    }
    return pageCount || 0;
  };

  const to = () => {
    return Math.min(from() + per_page - 1, total);
  };

  const handleSetPage = (page: number) => {
    if (!page) {
      return;
    }

    let current = paginationEl?.current;
    while (current && current?.parentNode) {
      current.scrollTo(0, 0);
      current = current.parentNode as HTMLDivElement;
    }
    setCurrentPage(page);
  };

  const handleCustomPage = (e: ChangeEvent<HTMLInputElement>) => {
    setTempPage(e.target.value as unknown as number);
  };

  const handleKeyUpCustomPage = (e: KeyboardEvent) => {
    const body: any = {
      tempPage,
      total_pages,
    };

    const { errors, isValid } = validate(body);
    if (isValid) {
      handleSetPage(tempPage);
    } else {
      setTempPage(current_page);
    }
  };

  const loopForPaginationItem = () => {
    const itemArr = [];
    for (let i = 1; i <= total_pages; i++) {
      itemArr.push(
        <PaginationItem
          key={i}
          active={i === current_page ? true : false}
          text={i?.toString()}
          onClick={() => handleSetPage(i)}
        />,
      );
    }
    return itemArr;
  };

  useEffect(() => {
    setTempPage(current_page);
  }, [current_page]);

  useEffect(() => {
    if (setListIdStartFrom) {
      setListIdStartFrom(from());
    }
  }, [pagination]);

  return (
    <div ref={paginationEl} className="pagination-container hide-scrollbar">
      <div className="d-block m-0 colfax-regular text-nowrap justify-content-center pb-2 pagination-desc">
        {translate('pagination_total')
          .replace('{from}', ` ${from()} `)
          .replace('{to}', ` ${to()} `)
          .replace('{size}', ` ${total || 0} `)}
      </div>
      <div className="pagination-wrapper">
        <ul className="pagination mb-0">
          {total_pages > 5 ? (
            <>
              <PaginationItem
                disable={current_page - 1 === 0}
                text="&laquo;"
                onClick={() => handleSetPage(1)}
              />
              <PaginationItem
                disable={current_page - 1 === 0}
                text="‹"
                onClick={() => handleSetPage(current_page - 1)}
              />
              <div className="custom-page-container">
                <div className="custom-page-number">
                  <Input
                    type="text"
                    value={tempPage}
                    name="tempPage"
                    onChange={handleCustomPage}
                    onEnterKeyUp={handleKeyUpCustomPage}
                    autoComplete={'off'}
                  />
                </div>
                <div className="custom-page-desc text-primary"> &nbsp; / {total_pages}</div>
              </div>
              <PaginationItem
                disable={total_pages === current_page || total_pages === 0}
                text="›"
                onClick={() => handleSetPage(current_page + 1)}
              />
              <PaginationItem
                disable={total_pages === current_page || total_pages === 0}
                text="&raquo;"
                onClick={() => handleSetPage(total_pages)}
              />
            </>
          ) : (
            loopForPaginationItem()
          )}
        </ul>
      </div>
    </div>
  );
};

const PaginationItem = ({ disable, active, text, onClick }: PaginationItemProps) => {
  return (
    <li
      className={`page-item cursor-pointer ${disable ? 'disabled' : ''} ${active ? 'active' : ''}`}>
      {/* eslint-disable-next-line */}
      <a className="page-link" onClick={onClick}>
        {text}
      </a>
    </li>
  );
};
