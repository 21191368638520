import {
  CREATE_DEVICE,
  DISABLE_DEVICE,
  GET_COMPANY_BALANCE,
  GET_COMPANY_DETAILS,
  GET_CREDENTIALS,
  GET_ME,
  GET_STORES,
  LOGOUT,
  ON_SUPEROWNER_COMPANY_SELECT,
  SET_AUTH,
  SET_COMPANY,
  SET_CURRENT_USER,
  SET_FEATURES,
  UPDATE_BALANCE,
  UPDATE_DEVICE,
  UPDATE_ME,
} from 'actions/actionTypes';
import { AuthStateProps, userProps } from '../state/AuthState';
import { companyProps } from './../state/AuthState';

const initialState: AuthStateProps = {
  isAuthenticated: localStorage.authYDP ? true : false,
  user: null,
  token: localStorage.authYDP ? localStorage.authYDP : null,
  roles: {
    isOwner: false,
    isAccountant: false,
    isOperator: false,
  },
  credentials: [],
  device: {
    id: '',
  },
  features: null,
};

interface actionProps {
  type: string;
  payload: any;
}

const authReducer = (state: AuthStateProps = initialState, action: actionProps): AuthStateProps => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload,
        roles: {
          ...state.roles,
          isOwner: action.payload.role === 'owner',
          isOperator: action.payload.role === 'operator',
          isAccountant: action.payload.role === 'accountant',
        },
      };
    case LOGOUT:
      return {
        ...initialState,
        isAuthenticated: false,
        token: null,
      };
    case ON_SUPEROWNER_COMPANY_SELECT:
      return {
        ...state,
        user: {
          ...(state.user as userProps),
          company_id: action.payload,
          balance: null,
          company:
            state?.user?.companies?.find((company) => company.id === action.payload) ??
            ({} as companyProps),
          stores: [],
        },
      };
    case GET_COMPANY_BALANCE:
      return {
        ...state,
        user: {
          ...(state.user as userProps),
          balance: { data: action.payload },
        },
      };
    case GET_COMPANY_DETAILS:
      return {
        ...state,
        user: {
          ...(state.user as userProps),
          company: action.payload,
        },
      };
    case GET_STORES:
      return {
        ...state,
        user: {
          ...(state.user as userProps),
          stores: action.payload,
        },
      };
    case GET_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      };
    case GET_ME:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    case UPDATE_ME:
      localStorage.current_user_id = state.user?.id;
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case UPDATE_BALANCE: {
      return {
        ...state,
        user: {
          ...(state.user as userProps),
          company: action.payload.company,
          ...(action.payload.balance && {
            balance: action.payload.balance,
          }),
        },
      };
    }
    case CREATE_DEVICE: {
      return {
        ...state,
        device: action.payload,
      };
    }
    case UPDATE_DEVICE: {
      return {
        ...state,
        device: action.payload,
      };
    }
    case DISABLE_DEVICE: {
      return {
        ...state,
        device: action.payload,
      };
    }
    case SET_COMPANY: {
      return {
        ...state,
        user: {
          ...(state.user as userProps),
          company_id: action.payload.id,
          company: action.payload,
        },
      };
    }
    case SET_FEATURES: {
      return {
        ...state,
        features: action.payload,
      };
    }
    default:
      return state;
  }
};
export default authReducer;
