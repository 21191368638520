import logo from 'assets/img/navbar_components/logo.png';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import './NavLogo.scss';

export const NavLogo: FC = () => {
  return (
    <NavLink to="/">
      <div className="yedpay-nav-logo">
        <img src={logo} alt="Yedpay" className="align-middle" />
      </div>
    </NavLink>
  );
};
