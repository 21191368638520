import { Input } from 'components/custom/input';
import { useTranslate } from 'context/TranslateContext';
import { FC } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DeviceProps } from 'services/API/Device/interface';

export interface DeviceModalProps {
  device: DeviceProps | undefined;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onModalClose: () => void;
}

export const DeviceModal: FC<DeviceModalProps> = ({
  device,
  isOpen,
  setIsOpen,
  onModalClose,
}: DeviceModalProps) => {
  const { translate } = useTranslate();

  const toggle = () => {
    onModalClose();
    setIsOpen(!isOpen);
  };

  if (!device) {
    return <></>;
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate('device')}</ModalHeader>
      <ModalBody>
        <Input
          disabled
          type="text"
          name=""
          value={device.name}
          legend={translate('device_name').toUpperCase()}
        />
        <Input
          disabled
          type="text"
          name=""
          value={translate(device.enabled ? 'enabled' : 'disabled')}
          legend={translate('status').toUpperCase()}
        />
        <Input
          disabled
          type="text"
          name=""
          value={device.created_at || ''}
          legend={translate('creation_date').toUpperCase()}
        />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-success" type="button" onClick={toggle}>
          {translate('close')}
        </button>
      </ModalFooter>
    </Modal>
  );
};
