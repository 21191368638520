import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from 'actions/AuthActions';
import { LanguageDropdown } from 'components/custom/TranslateDropdown';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FunctionDispatch } from 'redux/types';
import './topHeader.scss';

interface topHeader {
  title: string;
  caption?: string;
  children?: ReactNode;
  textOnly?: boolean;
}

export const TopHeader = ({ title, caption, children, textOnly }: topHeader) => {
  const history = useHistory();

  const dispatch = useDispatch<FunctionDispatch>();
  const openSidebar = () => {
    document.body.classList.toggle('show-menu');
  };

  const logoutUser = () => {
    dispatch(logout());
    history.push('/login');
  };

  return (
    <div className="topHeader" style={{ zIndex: textOnly ? '5' : '' }}>
      <div className="header-title flex-fill">
        <div className="d-block d-lg-none">
          <div className="d-flex float-start h-100 pe-3 align-items-center">
            <button className="btn" onClick={openSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </div>
        <h1 className="text-nowrap">{title}</h1>
        {caption && <small>{caption}</small>}
      </div>
      {!textOnly && (
        <>
          <LanguageDropdown className="ms-2 header-lang" />
          <button type="button" className="btn btn-#ffffff ms-1 header-logout" onClick={logoutUser}>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </button>
        </>
      )}
      {children && <div className="header-btn">{children}</div>}
    </div>
  );
};
