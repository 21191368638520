import { Password } from 'components/custom/Auth/Password';
import { FormContainer, FormHeader } from 'components/custom/form';
import { ProcessButton } from 'components/custom/form/StepIndicator/ProcessButton';
import { Input } from 'components/custom/input';
import { AuthenticationLayout } from 'components/layouts/authentication.layout';
import { ANY_OBJECT, RESET_PASSWORD_MEDIA_OPTIONS, translateLabel } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { resetPassword } from 'services/API/Auth';
import { ResetPasswordProps } from 'services/API/Auth/interface';
import { useSwal } from 'helpers/sweetalert';
import '../../layouts/button/button.scss';
import { validate } from './validator';

const initialState: ResetPasswordProps = {
  token: '',
  email: '',
  country_code: '+852',
  phone: '',
  password: '',
  confirmPassword: '',
};

interface RouteProps {
  match: {
    params: {
      token: string;
    };
  };
}

export const ResetPassword = (props: RouteProps) => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const history = useHistory();

  const [form, setForm] = useState(initialState);
  const [type, setType] = useState('email');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const { token, email, country_code, phone, password, confirmPassword } = form;

  // Form Event
  const onTypeChange = (value: string) => {
    setType(value);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    const { isValid, errors } = validate(form, confirmPassword, type);
    setErrors(errors);

    // When token is not found or not correctly set to state
    if (errors.token) {
      Swal.fire({
        icon: 'error',
        title: translate('reset_password_token'),
        text: translate('error.token'),
      });
    }

    if (!isValid) {
      return;
    }

    const body = {
      token,
      password,
      confirmPassword,
    } as ResetPasswordProps;

    if (type === 'email') {
      body.email = email;
    }
    if (type === 'phone') {
      body.country_code = country_code;
      body.phone = phone;
    }

    setIsLoading(true);
    const res = await resetPassword(body);
    setIsLoading(false);

    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('reset_password'),
      text: res.success
        ? translate('congratulations_password_was_success_reset')
        : res.message || 'Oops',
    });

    if (res.success) {
      history.replace('/login');
    }
  };

  useEffect(() => {
    const token = props?.match?.params?.token ?? '';
    setForm({
      ...form,
      token,
    });
    //eslint-disable-next-line
  }, []);

  return (
    <AuthenticationLayout>
      <FormContainer>
        <FormHeader title={translate('reset_password')} noImage />
        <form onSubmit={onSubmit}>
          <Input
            type="react-select"
            onChange={onTypeChange}
            value={type}
            name="type"
            legend={translate('validation_method').toUpperCase()}
            options={translateLabel(RESET_PASSWORD_MEDIA_OPTIONS, translate)}
          />

          {type === 'email' && (
            <Input
              type="text"
              legend={translate('email').toUpperCase()}
              onChange={onChange}
              name="email"
              value={email}
              disabled={isLoading}
              isRequired
              placeholder={translate('enter_your_email_address')}
              error={translate(...(errors.email ?? ''))}
            />
          )}
          {type === 'phone' && (
            <div className="row g-0">
              <div className="col-lg-4">
                <Input
                  type="text"
                  legend={translate('country_code').toUpperCase()}
                  placeholder=""
                  prefix="+"
                  value={country_code as string}
                  onChange={onChange}
                  name="country_code"
                  isRequired
                  disabled
                  error={translate(...(errors.country_code ?? ''))}
                />
              </div>
              <div className="col-lg-8">
                <Input
                  type="text"
                  legend={translate('phone').toUpperCase()}
                  onChange={onChange}
                  name="phone"
                  value={phone}
                  disabled={isLoading}
                  isRequired
                  placeholder={translate('enter_phone')}
                  error={translate(...(errors.phone ?? ''))}
                />
              </div>
            </div>
          )}
          <Password
            password={password}
            setPassword={(password) => setForm({ ...form, password })}
            setErrors={setErrors}
            errors={errors}
          />
          <Input
            legend={translate('confirm_new_password').toUpperCase()}
            value={confirmPassword}
            name="confirmPassword"
            onChange={onChange}
            placeholder={translate('enter_password')}
            type="password"
            isRequired
            error={translate(...(errors.confirmPassword ?? ''))}
          />
          <ProcessButton
            current={0}
            totalStep={1}
            isLoading={isLoading}
            submitText={translate('submit')}
          />
        </form>
      </FormContainer>
    </AuthenticationLayout>
  );
};
