import { checkStoreStatus } from 'helpers/functions/checkStoreStatus';
import axios, { defaultRequestHandler, getToken } from '../common/Fetcher';
import { ContentType } from './../common/Headers';
import { GetAllStoreResponse, UpdateStoreProps, UpdateStoreResponse } from './interface';

// https://dev2.yedpay.com/document/admin/showStore
export const getAllStore = (company_id: string, data = {}): Promise<GetAllStoreResponse> => {
  const params = {
    limit: 99999,
    ...data,
  };
  return defaultRequestHandler(
    axios.get(`/companies/${company_id}/stores`, {
      headers: {
        authorization: getToken(),
      },
      params,
    }),
  ).then(checkStoreStatus);
};

export const updateStore = (
  store_id: string,
  params: UpdateStoreProps,
): Promise<UpdateStoreResponse> =>
  defaultRequestHandler(
    axios.put(`/stores/${store_id}`, params, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );
