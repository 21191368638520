export const BR = 'br';
export const STORE_BR = 'store_br';
export const CI = 'ci';
export const IR = 'ir';
export const HKID = 'hkid';
export const LOGO = 'logo';
export const CONTRACT = 'contract';
export const PICTURE = 'picture';
export const OTHERS = 'other';

export const options = [BR, CI, IR, LOGO, CONTRACT, PICTURE, OTHERS];

export const ALLOW_MULTIPLE_UPLOAD_TYPE = [PICTURE, OTHERS];
export const DISABLE_SHARED_TYPE = [BR, STORE_BR, CI, CONTRACT, IR];

export const reactSelectDocumentTypeOptions = [
  {
    label: 'business_registration',
    value: BR,
  },
  {
    label: 'store_br',
    value: STORE_BR,
  },
  {
    label: 'certificate_of_incorporation',
    value: CI,
  },
  {
    label: 'ir_registration',
    value: IR,
  },
  {
    label: 'contract',
    value: CONTRACT,
  },
  {
    label: 'logo',
    value: LOGO,
  },
  {
    label: 'picture',
    value: PICTURE,
  },
  {
    label: 'others',
    value: OTHERS,
  },
];
