import { AnimationEvent, ReactNode, useRef } from 'react';

interface AnimatedProps {
  className?: string;
  children?: ReactNode;
  type: animationType;
}

type animationType =
  | 'bounce'
  | 'flash'
  | 'pulse'
  | 'rubberBand'
  | 'shakeX'
  | 'shakeY'
  | 'headShake'
  | 'swing'
  | 'tada'
  | 'wobble'
  | 'jello'
  | 'heartBeat'
  | 'backInDown'
  | 'backInLeft'
  | 'backInUp'
  | 'backInRight'
  | 'bounceIn'
  | 'bounceInDown'
  | 'bounceInLeft'
  | 'bounceInRight'
  | 'bounceInUp'
  | 'bounceOut'
  | 'bounceOutDown'
  | 'bounceOutLeft'
  | 'bounceOutRight'
  | 'bounceOutUp'
  | 'fadeIn'
  | 'fadeInDown'
  | 'fadeInDownBig'
  | 'fadeInLeft'
  | 'fadeInLeftBig'
  | 'fadeInRight'
  | 'fadeInRightBig'
  | 'fadeInUp'
  | 'fadeInUpBig'
  | 'fadeBottomLeft'
  | 'fadeBottomRight'
  | 'fadeTopRight'
  | 'fadeTopLeft'
  | 'fadeOut'
  | 'fadeOutDown'
  | 'fadeOutDownBig'
  | 'fadeOutLeft'
  | 'fadeOutLeftBig'
  | 'fadeOutRight'
  | 'fadeOutRightBig'
  | 'fadeOutUp'
  | 'fadeOutUpBig'
  | 'fadeOutTopLeft'
  | 'fadeOutTopRight'
  | 'fadeOutBottomRight'
  | 'fadeOutBottomLeft'
  | 'flip'
  | 'flipInX'
  | 'flipInY'
  | 'flipOutX'
  | 'flipOutY';

export const Animated = ({ className, children, type }: AnimatedProps) => {
  const classRef = useRef<HTMLDivElement>(null);

  const onAnimationEnd = (e: AnimationEvent<HTMLDivElement>) => {
    if (!classRef.current) {
      onAnimationEnd(e);
      return;
    }
    classRef.current.classList.add('animated-mode-none');
  };

  return (
    <div ref={classRef} onAnimationEnd={onAnimationEnd} className={`animated ${type} ${className}`}>
      {children}
    </div>
  );
};
