import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ALLOW_MULTIPLE_UPLOAD_TYPE } from 'constants/documents';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { useTranslate } from 'context/TranslateContext';
import { Input } from 'components/custom/input';
import { validateUploadFile } from './validator';
import { uploadDocument } from 'services/API/Company';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getArrayStatus } from 'helpers/functions/arrayStatus';
import { useSwal } from 'helpers/sweetalert';
import { getStoreLogos } from 'actions/StoreActions';

export interface UpdateLogoModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}

export const UploadLogoModal = ({ isModalOpen, setIsModalOpen }: UpdateLogoModalProps) => {
  const [Swal] = useSwal();
  const reset = () => {
    setFiles([] as any);
    setErrors({});
  };

  const toggle = () => {
    reset();
    setIsModalOpen(!isModalOpen);
  };

  const fetchLogos = async () => {
    const res = await dispatch(getStoreLogos(user?.company_id, { limit: 99999 }));
    if (!res.success) {
      await Swal.fire({
        title: 'Logos',
        text: res.message || 'Oops',
        icon: 'error',
      });
    }
  };

  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();
  const { translate } = useTranslate();
  const [files, setFiles] = useState<FileList>([] as any);
  const [errors, setErrors] = useState<any>({});
  const [updating, setUpdating] = useState(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setFiles(files);
    }
  };

  const handleUpload = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const { isValid, errors } = validateUploadFile(files);
    setErrors(errors);

    if (!isValid) {
      return;
    }
    setUpdating(true);

    const appendAndUpload = async (file?: File) => {
      const fd = new FormData();
      fd.append('document_type', 'logo');
      fd.append('type', 'logo');
      fd.append('file', file!);

      const api = uploadDocument;
      const res = await api(user.company_id, fd);
      return res.success;
    };

    const fileArray = Array.from(files!);

    const status = await Promise.all(
      fileArray.map(async (file) => {
        return await appendAndUpload(file);
      }),
    );

    const { allPassed, partialPassed, allFailed } = getArrayStatus(status);
    if (allPassed) {
      Swal.fire({
        icon: 'success',
        title: translate('upload_document'),
        text: translate('congratulations_document_successfully_uploaded'),
      });
    }
    if (partialPassed) {
      Swal.fire({
        icon: 'warning',
        title: translate('upload_document'),
        text: translate('something_wrong_with_file_upload_please_check'),
      });
    }
    if (allFailed) {
      Swal.fire({
        icon: 'error',
        title: translate('upload_document'),
        text: translate('failed_to_upload_document'),
      });
    }

    setUpdating(false);

    fetchLogos();
    reset();
    toggle();
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate('upload_new_logo')}</ModalHeader>
      <ModalBody>
        <Input
          isRequired
          type="file"
          name="file"
          files={files}
          onChange={handleFileChange}
          multiple={ALLOW_MULTIPLE_UPLOAD_TYPE.includes('LOGO')}
          placeholder={translate('select_file')}
          legend={translate('upload_new_logo').toUpperCase()}
          accept="image/*,application/pdf,.doc,docx,application/msword"
          error={translate(...(errors.file ?? ''))}
        />
        <div className="d-grid">
          <button
            className="btn btn-primary mt-3 rounded-3"
            onClick={handleUpload}
            disabled={updating}>
            {translate('upload')}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
