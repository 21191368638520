import { translation } from 'constants/Translate';
import _ from 'lodash';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import axios from 'services/API/common/Fetcher';

type TranslateType = (
  id?: string,
  replace?: string | { [key: string]: string } | Array<string>,
) => string;

interface TranslateProps {
  hasTranslate: (id: string) => boolean;
  translate: TranslateType;
  changeLanguage: (lang: string) => void;
  language: string;
}

const initialState = {
  hasTranslate: () => {
    return false;
  },
  translate: () => {
    return '';
  },
  changeLanguage: () => null,
  language: '',
};

export const TranslateContext = createContext<TranslateProps>(initialState);

export const useTranslate = () => {
  return useContext(TranslateContext);
};

export const TranslateProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState(localStorage.langYDP ?? 'zh');

  const hasTranslate = (id: string): boolean => {
    const lang_pack = (translation as { [key: string]: any })[language];
    if (!lang_pack || !lang_pack?.messages || !lang_pack?.messages?.[id]) {
      return false;
    }
    return true;
  };

  const translate: TranslateType = (id, replace): string => {
    if (!id) {
      return '';
    }

    const lang_pack = (translation as { [key: string]: any })[language];
    if (!lang_pack || !lang_pack?.messages) {
      console.error('Unknown or Current Language is not supported yet.');
    }
    let result = _.get(lang_pack?.messages, id, id);

    if (_.isString(replace)) {
      result = result.replace('{0}', replace);
    } else if (_.isObject(replace)) {
      for (const key in replace) {
        result = result.replace(`{${key}}`, (replace as { [key: string]: string })[key]);
      }
    } else if (_.isArray(replace)) {
      for (let index = 0; index < (replace as Array<string>).length; index++) {
        result = result.replace(`{${index}}`, replace[index]);
      }
    }

    return result;
  };

  useEffect(() => {
    localStorage.langYDP = language;
    axios.defaults.headers.common['Accept-Language'] = language;
  }, [language]);

  return (
    <TranslateContext.Provider
      value={{
        hasTranslate,
        translate,
        changeLanguage: setLanguage,
        language,
      }}>
      {children}
    </TranslateContext.Provider>
  );
};
