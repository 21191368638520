import { ANY_OBJECT, TAXI_MCC } from 'constants/format';
import { email, packValidateResults, password, required } from 'helpers/validators';
import { editProfileProps } from 'types';

export const validate = (data: editProfileProps) => {
  const results: Array<ANY_OBJECT> = [
    required(data, ['first_name', 'last_name']),
    email(data, ['email']),
    password(data, ['password']),
  ];

  if (data.password && data.password !== data.confirm_password) {
    results.push(required(data, ['confirm_password']));
    results.push({ confirm_password: ['error_password_mismatch'] });
  }

  if (data.mcc_description === TAXI_MCC.toString()) {
    results.push(required(data, ['phone', 'taxi_number', 'driving_license']));
  }

  return packValidateResults(results);
};
