import {
  accountant,
  agent,
  ALL_ROLES,
  gatewayProvider,
  operator,
  owner,
  superOwner,
} from 'constants/Roles';
import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import isEmpty from 'lodash/isEmpty';
import { Dispatch } from 'redux';
import { FunctionDispatch, GlobalTypes, ThunkResult } from 'redux/types';
import {
  getMe as apiGetMe,
  login as apiLogin,
  registerServiceProvider as apiRegisterServiceProvider,
} from 'services/API/Auth';
import {
  LoginBodyProps,
  RegisteredReturn,
  RegisterServiceProviderProps,
} from 'services/API/Auth/interface';
import { AnyResponse } from 'services/API/common/Fetcher';
import { getUserList as apiGetUserList } from 'services/API/User';
import { LOGOUT, SET_AUTH, SET_CURRENT_USER } from './actionTypes';
import { receiveData } from './DataAction';
import { getCredentialsList } from './GatewayActions';
import { getFeaturesConfig } from './FeaturesConfigActions';

export const getMe = (): ThunkResult<Promise<Record<string, any>>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch, getState: () => GlobalTypes.RootState) => {
    if (getState().auth?.user && getState().auth?.user?.role === superOwner) {
      return { success: true, data: getState().auth.user };
    }

    let res = await apiGetMe();
    if (!res.success) {
      await dispatch(logout());
      return res;
    }

    const me = res.data;
    if (me.role === superOwner) {
      me.company_id = me.companies[0].id;
      me.company = me.companies[0];
    }

    if (
      !Object.values(ALL_ROLES).includes(me.role) ||
      ([agent, gatewayProvider].includes(me.role) && isEmpty(me.gateways))
    ) {
      res = {
        success: false,
        message: "You don't have access right to the merchant portal.",
        data: null,
      };
    }
    const promises: Array<any> = [];
    if (res.success) {
      promises.push(
        dispatch({
          type: SET_CURRENT_USER,
          payload: me,
        }),
      );
    }
    if (
      res.success &&
      me.activated &&
      [operator, owner, accountant, superOwner].includes(me.role)
    ) {
      if ([operator, owner, accountant].includes(me.role)) {
        promises.push(dispatch(getFeaturesConfig()));
      }
      promises.push(dispatch(getCredentialsList(me.company_id)));
    }

    await Promise.all(promises);
    return res;
  });

export const logout = (): ThunkResult<Promise<Record<string, any>>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    localStorage.removeItem('current_user_id');
    localStorage.removeItem('authYDP');
    localStorage.removeItem('filter');
    return dispatch({ type: LOGOUT });
  });

export const login = (data: LoginBodyProps): ThunkResult<Promise<Record<string, any>>> =>
  asyncRequestHandler(async (dispatch: Dispatch) => {
    const res = await apiLogin(data);
    if (res.error || res.error_code || !res.access_token) {
      return res;
    }

    localStorage.authYDP = res.access_token;
    return dispatch({
      type: SET_AUTH,
      payload: res.access_token,
    });
  });

export const register = (data: RegisteredReturn): ThunkResult<Promise<Record<string, any>>> =>
  asyncRequestHandler(async (dispatch: Dispatch) => {
    localStorage.authYDP = data.access_token;
    return dispatch({
      type: SET_AUTH,
      payload: data.access_token,
    });
  });

export const getUserList = (
  company_id: string,
  data = {},
): ThunkResult<Promise<Record<string, any>>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await apiGetUserList(company_id, data);
    if (res.success) {
      dispatch(receiveData(res, 'users'));
    }
    return res;
  });

export const registerServiceProvider = (
  data: RegisterServiceProviderProps,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(async () => {
    return await apiRegisterServiceProvider(data);
  });
