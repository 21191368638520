import axios, { AnyResponse, defaultRequestHandler, getToken, params } from '../common/Fetcher';
import { ContentType } from './../common/Headers';
import {
  ChangeUserStatus,
  ChangeUserStatusResponse,
  CreateUserResponse,
  EditUserProps,
  ShowUserProps,
  UpdateProfileProps,
  UpdateUserResponse,
  UserProps,
} from './interface';

// https://dev2.yedpay.com/document/admin/updateProfile
export const updateProfile = (body: UpdateProfileProps) =>
  defaultRequestHandler(
    axios.put(`/me`, params(body), {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/createOperator
export const createUser = (body: UserProps): Promise<CreateUserResponse> =>
  defaultRequestHandler(
    axios.post(`/users`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/updateUser
export const updateUser = (user_id: string, body: EditUserProps): Promise<UpdateUserResponse> =>
  defaultRequestHandler(
    axios.put(`/users/${user_id}`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

export const deleteUser = (user_id: string): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.delete(`/users/${user_id}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// @todo Find this Endpoint url
export const changeUserStatus = (
  user_id: string,
  body: ChangeUserStatus,
): Promise<ChangeUserStatusResponse> =>
  defaultRequestHandler(
    axios.put(`/users/${user_id}/status`, body, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/showUser
export const showUser = (user_id: string, params = {}): Promise<ShowUserProps> =>
  defaultRequestHandler(
    axios.get(`/users/${user_id}`, {
      headers: {
        authorization: getToken(),
      },
      params,
    }),
  );
export const getUserList = (company_id: string, params = {}): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.get(`/users?sort=-created_at&company_id=${company_id}`, {
      headers: {
        authorization: getToken(),
      },
      params,
    }),
  );
