export const reactDistrictsOptions = [
  {
    label: '港島 Hong Kong Island',
    options: [
      {
        label: '中區 Central',
        value: 1,
      },
      {
        label: '上環區 Sheung Wan',
        value: 2,
      },
      {
        label: '灣仔 / 銅鑼灣 Wan Chai / Causeway Bay',
        value: 6,
      },
      {
        label: '北角 / 鰂魚涌 North Point / Quarry Bay',
        value: 10,
      },
      {
        label: '南區 Southern District',
        value: 16,
      },
    ],
  },
  {
    label: '九龍 Kowloon',
    options: [
      {
        label: '尖沙咀 Tsim Sha Tsui',
        value: 13,
      },
      {
        label: '油麻地 / 旺角 Yau Ma Tei / Mong Kok',
        value: 14,
      },
      {
        label: '深水埗區 Sham Shui Po',
        value: 15,
      },
      {
        label: '九龍城區 Kowloon City',
        value: 3,
      },
      {
        label: '黃大仙區 Wong Tai Sin',
        value: 20,
      },
      {
        label: '紅磡 / 土瓜灣 Hung Hom / To Kwa Wan',
        value: 12,
      },
      {
        label: '觀塘區 Kwun Tong',
        value: 18,
      },
    ],
  },
  {
    label: '新界 New Territories',
    options: [
      {
        label: '元朗 / 屯門 Yuen Long / Tuen Mun',
        value: 4,
      },
      {
        label: '天水圍 Tin Shui Wai',
        value: 8,
      },
      {
        label: '葵青 Kwai Tsing',
        value: 17,
      },
      {
        label: '將軍澳 Tseung Kwan O',
        value: 9,
      },
      {
        label: '北區 North',
        value: 5,
      },
      {
        label: '大埔區 Tai Po',
        value: 7,
      },
      {
        label: '沙田區 Sha Tin',
        value: 11,
      },
      {
        label: '西貢區 Sai Kung',
        value: 21,
      },
      {
        label: '離島區 Islands',
        value: 19,
      },
    ],
  },
];
