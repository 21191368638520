import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { ThunkResult } from 'redux/types';
import { getAPI } from 'services/API/Checkout_Domain';
import { CheckoutDomainProps } from 'services/API/Checkout_Domain/interface';
import { AnyResponse } from 'services/API/common/Fetcher';
import { ArrayResponse } from 'services/API/common/Restful';

export const getCheckoutDomainListAction = (
  companyId: string,
  params?: object,
): ThunkResult<Promise<ArrayResponse<CheckoutDomainProps>>> =>
  asyncRequestHandler(() => getAPI(companyId).getAll(params));

export const deleteCheckoutDomainAction = (
  companyId: string,
  checkoutDomainId: string,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => getAPI(companyId, checkoutDomainId).delete());
