import { useTranslate } from 'context/TranslateContext';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

interface ImageModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  document: string;
  setDocument: (document: string) => void;
}

export const ImageModal = ({ isOpen, setIsOpen, document, setDocument }: ImageModalProps) => {
  const { translate } = useTranslate();

  const toggle = () => {
    setIsOpen(!isOpen);
    setDocument('');
  };

  if (!document) {
    return <></>;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{`${translate('document')}`}</ModalHeader>
      <ModalBody>
        <div className="px-2 px-lg-5 text-center">
          <img src={document} alt="" className="img-fluid" />
        </div>
      </ModalBody>
    </Modal>
  );
};
