import { ALLOW_MULTIPLE_UPLOAD_TYPE, BR, CI, IR, CONTRACT, STORE_BR } from 'constants/documents';
import { BRANCH_NUMBER_LENGTH, BR_NUMBER_LENGTH } from 'constants/format';
import {
  fileCountLessEqualThan,
  fileSizeLessThan,
  greaterEqualThan,
  isDate,
  isInt,
  lengthEqual,
  packValidateResults,
  required,
} from 'helpers/validators';
import _ from 'lodash';
import moment from 'moment';
import { updateDocumentProps, uploadDocumentProps } from 'types';

export const validate = (
  data: uploadDocumentProps | updateDocumentProps,
  files: FileList,
  hasEditingDocument: boolean,
) => {
  const isMultiple = ALLOW_MULTIPLE_UPLOAD_TYPE.includes(data.document_type.toUpperCase());

  const result = [];

  if (!hasEditingDocument) {
    result.push(required({ file: files.length ? '1' : '' || '' }, ['file']));
  }

  if (isMultiple) {
    result.push(fileCountLessEqualThan({ file: files }, ['file']));
  }

  const fileResult = fileSizeLessThan(files, Object.keys(files));
  if (!_.isEmpty(fileResult)) {
    result.push({ file: _.values(fileResult)[0] } as any);
  }

  switch (data.document_type) {
    case BR:
      result.push(required(data, ['br_number', 'branch_number', 'company_name', 'expiry_date']));
      result.push(isInt(data, ['br_number', 'branch_number']));
      result.push(lengthEqual(data, ['br_number'], { min: BR_NUMBER_LENGTH }));
      result.push(lengthEqual(data, ['branch_number'], { min: BRANCH_NUMBER_LENGTH }));
      result.push(isDate(data, ['expiry_date']));
      result.push(
        greaterEqualThan(
          { expiry_date: moment(data.expiry_date).startOf('day').unix() },
          ['expiry_date'],
          {
            min: moment().startOf('day').unix(),
            err_msg: 'error_invalid_expiry_date',
          },
        ),
      );
      break;

    case STORE_BR:
      result.push(required(data, ['br_number', 'branch_number', 'expiry_date', 'store_id']));
      result.push(lengthEqual(data, ['br_number'], { min: BR_NUMBER_LENGTH }));
      result.push(lengthEqual(data, ['branch_number'], { min: BRANCH_NUMBER_LENGTH }));
      result.push(isDate(data, ['expiry_date']));
      break;

    case CI:
      result.push(required(data, ['ci_number', 'company_name', 'date_of_establishment']));
      break;

    case IR:
      result.push(required(data, ['ir_number', 'company_name']));
      break;

    case CONTRACT:
      result.push(required(data, ['contract_type', 'client_approved_at', 'generated_at', 'type']));
      result.push(isDate(data, ['generated_at']));
      break;

    default:
      break;
  }
  return packValidateResults(result);
};
