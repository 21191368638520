import axios, { defaultRequestHandler, getToken, params } from '../common/Fetcher';
import { CreateDeviceResponse, DeviceProps } from './interface';

// https://dev2.yedpay.com/document/admin/deviceList
export const getDeviceList = (data = {}) =>
  defaultRequestHandler(
    axios.get(`/devices?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/createDevice
export const createDevice = (body: DeviceProps): Promise<CreateDeviceResponse> =>
  defaultRequestHandler(
    axios.post(`/devices`, body, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/updateDevice
export const updateDevice = (device_id: string, body: DeviceProps): Promise<CreateDeviceResponse> =>
  defaultRequestHandler(
    axios.put(`/devices/${device_id}?`, params(body), {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/deleteDevice
export const deleteDevice = (device_id: string) =>
  defaultRequestHandler(
    axios.delete(`/devices/${device_id}?`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
