import yedpay from 'assets/img/navbar_components/logo.png';
import scanLogo from 'assets/img/print_qrcode/scan-logo.png';
import {
  ALIPAY_QR_PNG,
  FPS_QR_PNG,
  MASTERCARD_QR_PNG,
  UNIONPAY_QR_PNG,
  VISA_QR_PNG,
  WECHAT_QR_PNG,
} from 'constants/gateways/gatewayLogos';
import { useTranslate } from 'context/TranslateContext';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

interface DisplayCodeModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedStore: any | null;
}

interface GatewayLogoProps {
  src: string;
  alt?: string;
}

export const DisplayCodeModal = ({ isOpen, setIsOpen, selectedStore }: DisplayCodeModalProps) => {
  const { translate } = useTranslate();

  const hasAlipayOnline = getUserActivatedGateways().includes('4_1');
  const hasWeChatPayOnline = getUserActivatedGateways().includes('8_2');
  const hasUnionPayOnline = getUserActivatedGateways().some((code) =>
    ['9_1', '9_5'].includes(code),
  );

  const hasVisaOnline = getUserActivatedGateways().includes('12_1');
  const hasMastercardOnline = getUserActivatedGateways().includes('12_2');
  const hasFpsOnline = getUserActivatedGateways().some((code) => ['13_1', '13_3'].includes(code));

  const emvCodeGatewaysCount = [
    hasVisaOnline,
    hasMastercardOnline,
    hasWeChatPayOnline,
    hasAlipayOnline,
    hasUnionPayOnline,
    hasFpsOnline,
  ].filter(Boolean).length;

  const emvCodeLogoWidthCal = (emvCodeGatewaysCount: any) => {
    switch (true) {
      case emvCodeGatewaysCount > 0 && emvCodeGatewaysCount <= 2:
        return 4;
      case emvCodeGatewaysCount >= 3:
        return 4;
      default:
        return 4;
    }
  };

  const emvCodeLogoWidth = emvCodeLogoWidthCal(emvCodeGatewaysCount);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const attr: any = {};
  attr.name = selectedStore?.value?.name;
  attr._links = {
    qrcode: selectedStore?.value?._links?.[1]?.href,
    checkout: selectedStore?.value?._links?.[0]?.href,
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate('qr_code')}</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <h1 className="fx-35">{attr.name}</h1>
          <div className="row justify-content-center">
            <div className="col-9 border bg-muted rounded py-2">
              <img src={attr._links?.qrcode} alt="qr-code" className="img-fluid w-80" />
              <div className="mt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={scanLogo} alt="" className="img-fluid me-3 w-15" />
                  <p className="fx-14 m-0">{translate('scan_with_yr_phone')}</p>
                </div>
              </div>
              <div
                className={`row mt-2 align-middle ${
                  emvCodeGatewaysCount <= 2 && 'justify-content-center'
                }`}>
                {
                  <>
                    <div
                      className={
                        hasAlipayOnline
                          ? `col-${emvCodeLogoWidth} d-flex justify-content-center my-2`
                          : `d-none`
                      }>
                      <img
                        src={VISA_QR_PNG}
                        alt="Visa"
                        className={hasAlipayOnline ? `w-100 align-self-center h-auto` : `d-none`}
                        draggable={false}
                      />
                    </div>
                    <div
                      className={
                        hasAlipayOnline
                          ? `col-${emvCodeLogoWidth} d-flex justify-content-center my-2`
                          : `d-none`
                      }>
                      <img
                        src={MASTERCARD_QR_PNG}
                        alt="Mastercard"
                        className={hasAlipayOnline ? `w-100 align-self-center h-auto` : `d-none`}
                        draggable={false}
                      />
                    </div>
                    <div
                      className={
                        hasUnionPayOnline
                          ? `col-${emvCodeLogoWidth} d-flex justify-content-center my-2`
                          : `d-none`
                      }>
                      <img
                        src={UNIONPAY_QR_PNG}
                        alt="Quick Pass"
                        className={hasUnionPayOnline ? `w-100 align-self-center h-auto` : `d-none`}
                        draggable={false}
                      />
                    </div>
                    <div
                      className={
                        hasAlipayOnline
                          ? `col-${emvCodeLogoWidth} d-flex justify-content-center my-2`
                          : `d-none`
                      }>
                      <img
                        src={ALIPAY_QR_PNG}
                        alt="Alipay"
                        className={hasAlipayOnline ? `w-100 align-self-center h-auto` : `d-none`}
                        draggable={false}
                      />
                    </div>
                    <div
                      className={
                        hasWeChatPayOnline
                          ? `col-${emvCodeLogoWidth} d-flex justify-content-center my-2`
                          : `d-none`
                      }>
                      <img
                        src={WECHAT_QR_PNG}
                        alt="WeChat Pay"
                        className={hasWeChatPayOnline ? `w-100 align-self-center h-auto` : `d-none`}
                        draggable={false}
                      />
                    </div>
                    <div
                      className={
                        hasFpsOnline
                          ? `col-${emvCodeLogoWidth} d-flex justify-content-center my-2`
                          : `d-none`
                      }>
                      <img
                        src={FPS_QR_PNG}
                        alt="FPS"
                        className={hasFpsOnline ? `w-100 align-self-center h-auto` : `d-none`}
                        draggable={false}
                      />
                    </div>
                  </>
                }
              </div>
            </div>
            <div className="text-center mt-2">
              <h4 className="fx-16">{translate('payment_tech_provided_by_yedpay')}</h4>
              <img src={yedpay} alt="" className="img-fluid w-30" />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const GatewayLogo = ({ src, alt }: GatewayLogoProps) => {
  return (
    <div className="col">
      <img src={src} alt={alt} className="w-100" />
    </div>
  );
};
