import { login as loginAction } from 'actions/AuthActions';
import { FormContainer, FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { AuthenticationLayout } from 'components/layouts/authentication.layout';
import { useTranslate } from 'context/TranslateContext';
import { removeErrorOnChange } from 'helpers/functions/removeErrorOnChange';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useSwal } from 'helpers/sweetalert';
import { login, redirectProps } from 'types';
import version from 'version.json';
import { validate } from './validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const initialState = {
  username: '',
  password: '',
};

const config = (window as any).yedpayConfig;

const Login = ({ loginAction }: any) => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const history = useHistory();

  const [form, setForm] = useState<login>(initialState);
  const [redirect, setRedirect] = useState<redirectProps>({
    to: '',
    shouldRedirect: false,
  });
  const [errors, setError] = useState<any>({});
  const [serviceProviderCode, setSetServiceProviderCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { username, password } = form;
  const { to, shouldRedirect } = redirect;

  useEffect(() => {
    const windowLocationParams = new URLSearchParams(decodeURIComponent(window.location.search));
    const urlParams = new URLSearchParams(decodeURIComponent(localStorage.urlParams || ''));

    windowLocationParams.forEach(function (value, key) {
      urlParams.set(key, value);
    });
    setSetServiceProviderCode(urlParams.get('service_provider_id') ?? null);
    localStorage.urlParams = urlParams.toString();
  }, []);

  const handleFormChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const error = removeErrorOnChange(errors, evt.target.name);
    setForm({
      ...form,
      [evt.target.name]: evt.target.value,
    });
    setError(error);
  };

  const handleRedirect = (path: string) => {
    setRedirect({
      to: path,
      shouldRedirect: true,
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const { isValid, errors } = validate(form);

    setError(errors);
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    try {
      localStorage.username = username;
      const res = (await loginAction({
        client_id: config.client_id,
        client_secret: config.client_secret,
        username,
        password,
        grant_type: 'password',
      })) as any;
      if (res?.error_code !== undefined || res?.success === false) {
        await Swal.fire({
          icon: 'error',
          title: translate('login_failed'),
          text: res.message,
        });
      }
      if (res?.error_code === 'password_expired') {
        const url = res.action.url;
        return history.replace(url);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (shouldRedirect) {
    return <Redirect to={to} />;
  }

  return (
    <AuthenticationLayout>
      <FormContainer>
        <FormHeader title={translate('login')} noImage />
        <form onSubmit={onSubmit}>
          <Input
            legend={translate('username')}
            value={username}
            name="username"
            onChange={handleFormChange}
            placeholder={translate('enter_username')}
            type="text"
            autoComplete="username"
            error={translate(...(errors.username ?? ''))}
            disabled={isLoading}
          />
          <Input
            legend={translate('password')}
            value={password}
            name="password"
            onChange={handleFormChange}
            placeholder={translate('enter_password')}
            type="password"
            autoComplete="current-password"
            error={translate(...(errors.password ?? ''))}
            disabled={isLoading}
          />
          {!serviceProviderCode && (
            <div className="text-end mt-2">
              {isLoading ? (
                <span className="disabled-link">{translate('forgot_password')}?</span>
              ) : (
                <Link to="/forget-password">
                  <span className="colfax-regular">{translate('forgot_password')}?</span>
                </Link>
              )}
            </div>
          )}

          <div className="row mt-4">
            {!serviceProviderCode && (
              <div className="col d-grid">
                <button
                  type="button"
                  className="btn btn-outline-secondary mb-lg-0 mb-2"
                  onClick={(e) => handleRedirect('/register')}
                  disabled={isLoading}>
                  {translate('register')}
                </button>
              </div>
            )}
            <div className="col d-grid">
              <button className="btn btn-primary mb-lg-0 mb-2" disabled={isLoading}>
                {translate('login')} {isLoading && <FontAwesomeIcon spin={true} icon={faSpinner} />}
              </button>
            </div>
          </div>
        </form>
      </FormContainer>
      <div style={{ position: 'fixed', bottom: '.5rem', right: '.5rem' }}>
        <span>v.{version.version}</span>&nbsp;©&nbsp;The Payment Cards Group Limited. All rights
        reserved.
      </div>
    </AuthenticationLayout>
  );
};

export default connect(null, { loginAction })(Login);
