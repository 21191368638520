import { useTranslate } from 'context/TranslateContext';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';

export const BalanceBox = () => {
  const { auth } = useSelector((state: GlobalTypes.RootState) => ({
    auth: state?.auth,
    stores: state?.auth?.user?.stores,
    transactions: state?.data?.transactions,
  }));
  const { translate } = useTranslate();

  return (
    <>
      <div className="balance-box bg-white mb-4 round-sm shadow-sm">
        <label className="form-label text-warning">{translate('balance_available')}:</label>
        <div className="text-end" style={{ flex: 1 }}>
          <span className="balance text-warning">
            {auth?.user?.balance?.data?.available_balance_float}
          </span>
          <span className="currency text-warning">&nbsp;{auth?.user?.balance?.data?.currency}</span>
        </div>
        <div className="break"></div>

        <label className="form-label">{translate('holding_amount')}:</label>
        <div className="text-end" style={{ flex: 1 }}>
          <span className="balance text-warning">
            {auth?.user?.balance?.data?.holding_balance_float}
          </span>
          <span className="currency text-warning">&nbsp;{auth?.user?.balance?.data?.currency}</span>
        </div>
        <div className="break"></div>

        <label className="form-label">{translate('auto_settlement')}:</label>
        <span className="auto-settlement text-success">
          {translate(auth?.user.company.auto_settlement ? 'enabled' : 'disabled')}
        </span>
      </div>
    </>
  );
};
