import { useTranslate } from 'context/TranslateContext';
import moment from 'moment';
import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import Swal from 'sweetalert2';

export enum ReportExportType {
  PDF = 'pdf',
  EXCEL = 'xlsx',
  CSV = 'csv',
}

export const reportExportTypeOption = [
  {
    label: 'generate_excel_report',
    value: ReportExportType.EXCEL,
  },
  {
    label: 'generate_csv_report',
    value: ReportExportType.CSV,
  },
  {
    label: 'generate_pdf_report',
    value: ReportExportType.PDF,
  },
];

const DEFAULT_CLASS_NAME = 'header-button btn btn-primary';

interface Props {
  isDropdown?: boolean;
  text: string;
  reportSectionRef?: React.MutableRefObject<any>;
  filter: {
    start_date: string | null;
    end_date: string | null;
  };
  requestReportFromServer: (reportType: ReportExportType) => Promise<any>;
  className?: string;
  supportFileType?: Array<ReportExportType>;
}

export interface GetDataFunction {
  (type: ReportExportType, params: IParamsProps): Promise<any>;
}
export interface IParamsProps {
  asyncExport?: boolean;
  export?: string;
  start_date?: string;
  end_date?: string;
  group_by?: string;
  include?: string;
}

export const withPdfExportDurationValidation = (func: GetDataFunction) => {
  const { translate } = useTranslate();
  return async (type: ReportExportType, params: IParamsProps) => {
    if (type === ReportExportType.PDF) {
      const { start_date, end_date } = params;
      const totalDays = Math.abs(moment(start_date).diff(end_date, 'days'));
      if (totalDays > 31) {
        Swal.fire({
          icon: 'error',
          title: translate('export'),
          text: translate('error_duration_must_be_less_equal_than', ['31', translate('unit_day')]),
        });
        return;
      }
    }
    return func(type, params);
  };
};

const ExportReportButton = ({
  isDropdown = false,
  text,
  reportSectionRef,
  requestReportFromServer,
  filter: { start_date, end_date },
  className,
  supportFileType = [],
}: Props): JSX.Element => {
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [openExportDropdown, setOpenExportDropdown] = useState(false);
  const { translate } = useTranslate();
  const exportReport = async (reportType: ReportExportType = ReportExportType.EXCEL) => {
    setIsExportLoading(true);
    if (reportType === ReportExportType.PDF) {
      const totalDays = Math.abs(moment(start_date).diff(end_date, 'days'));
      if (totalDays > 31) {
        Swal.fire({
          icon: 'error',
          title: translate('export'),
          text: translate('error_duration_must_be_less_equal_than', ['31', translate('unit_day')]),
        });
        setIsExportLoading(false);
        return;
      }
    }
    const res = await requestReportFromServer(reportType);
    if (reportSectionRef) {
      await reportSectionRef.current.loadReports();
    }
    window.open(res?.file, '_self');
    setIsExportLoading(false);
    if (!res) return;
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('export'),
        text: res.message || 'Oops',
      });
    }
    if (res.message) {
      Swal.fire({
        icon: 'success',
        title: translate('export'),
        text: res.message,
      });
    }
  };

  return !isDropdown ? (
    <button
      className={className ?? DEFAULT_CLASS_NAME}
      onClick={() => exportReport()}
      disabled={isExportLoading}>
      {isExportLoading ? <Spinner size="sm" /> : text}
    </button>
  ) : (
    <Dropdown
      disabled={isExportLoading}
      isOpen={openExportDropdown}
      toggle={() => setOpenExportDropdown(!openExportDropdown)}
      className="d-inline-block">
      <DropdownToggle
        disabled={isExportLoading}
        className="header-button btn btn-success"
        caret
        color="#ffffff">
        {isExportLoading ? <Spinner size="sm" /> : text}
      </DropdownToggle>
      {supportFileType.length >= 1 && (
        <DropdownMenu classnames="lang-dropdown">
          {supportFileType?.map((item, index) => (
            <DropdownItem
              key={index}
              classnames="btn btn-sm btn-muted text-center"
              onClick={() => exportReport(item)}>
              {translate(reportExportTypeOption.find((type) => type.value === item)?.label ?? '')}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default ExportReportButton;
