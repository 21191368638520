import { greaterEqualThan, packValidateResults, required } from 'helpers/validators';
import { CreateTransactionBody } from 'services/API/Transactions/interface';
import { createTransactionEnterBarcodeProps } from 'types';

export const createTransactionValidator = (data: CreateTransactionBody) =>
  packValidateResults([
    required(data, ['amount', 'store_id', 'currency']),
    greaterEqualThan(data, 'amount', { min: 0.5 }),
  ]);

export const createTransactionEnterBarcodeValidator = (data: createTransactionEnterBarcodeProps) =>
  packValidateResults([required(data, ['code'])]);
