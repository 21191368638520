import { OFFLINE_STORE, ONLINE_STORE } from 'constants/format';
import { address, email, packValidateResults, required } from 'helpers/validators';
import { CreateCompanyStoreProps } from 'services/API/Company/interface';
import { UpdateStoreProps } from 'services/API/Store/interface';

export const validate = (
  data: (CreateCompanyStoreProps | UpdateStoreProps) & { agreeTerms?: number },
  type: number,
) => {
  const results = [
    required(
      {
        ...data,
        agreeTerms: data.agreeTerms ? data.agreeTerms : '',
      },
      ['name', 'agreeTerms'],
    ),
    email(data, ['email']),
  ];

  if (type === OFFLINE_STORE) {
    results.push(
      required(
        {
          ...data,
          district_id: data.district_id ? data.district_id : '',
        },
        ['address', 'district_id'],
      ),
      address(data, ['address']),
    );
  } else if (type === ONLINE_STORE) {
    results.push(required(data, ['domains']));
  }

  return packValidateResults(results);
};
