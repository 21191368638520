import { useTranslate } from 'context/TranslateContext';
import { ReactSelectAsync } from '..';
import { getCompanyStoreList } from 'services/API/Company';
import { components as ReactSelectComponents } from 'react-select';
import { STORE_STATUS_COLOR } from 'constants/format';
import { SelectOptionProps } from 'components/custom/input/interfaces';

export interface StoreSelectProps {
  showStatus?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  defaultOption?: Array<SelectOptionProps>;
  setStore: (store: any) => void;
  store: any;
  searchOptionsParams?: Record<string, any>;
  error?: string;
  legend?: string;
}

export const StoreSelect = ({
  isRequired,
  disabled,
  defaultOption,
  showStatus,
  setStore,
  store,
  searchOptionsParams,
  error,
  legend,
}: StoreSelectProps) => {
  const { translate } = useTranslate();
  defaultOption = defaultOption ?? [{ label: translate('select'), value: null }];

  const onSelectStoreChange = () => (val: any) => {
    setStore(val);
  };

  const valueToLabel = (value: any) => {
    if (!value) {
      return defaultOption?.[0];
    }
    value.toString = () => value.id;
    return {
      label: `${value.name}`,
      value: value,
      status: value.status ?? null,
    };
  };

  const Option = (props: any) => {
    return (
      <ReactSelectComponents.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{props.data.label}</span>
          {showStatus && (
            <span>
              <small className={`text-${STORE_STATUS_COLOR[props.data.status]}`}>
                {translate(props.data.status)}
              </small>
            </span>
          )}
        </div>
      </ReactSelectComponents.Option>
    );
  };

  return (
    <ReactSelectAsync
      disabled={disabled}
      isRequired={isRequired}
      name="store"
      legend={legend ? legend : ''}
      onSelectChange={onSelectStoreChange}
      value={store}
      valueToLabel={valueToLabel}
      defaultOption={defaultOption}
      searchOptions={getCompanyStoreList}
      searchOptionsParams={{ limit: 20, ...searchOptionsParams }}
      components={{ Option }}
      error={translate(error ?? '')}
    />
  );
};
