import user from 'assets/img/navbar_components/user.png';
import userActive from 'assets/img/navbar_components/user_active.png';
import { isAccountant, isGatewayProvider, isSuperowner } from 'constants/Roles';
import { routes as routePaths } from 'constants/Routes';
import { useTranslate } from 'context/TranslateContext';
import { FC, Fragment } from 'react';
import { COMPANY_INFO, GATEWAY_PROVIDER_DOCUMENTS, RouteProps } from '../routeSettings';
import { CompanyDropdown } from './CompanyDropdown';
import './Navbar.scss';
import { NavButton } from './NavButton';
import { NavLogo } from './NavLogo';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';

export interface NavbarProps {
  location: {
    pathname: string;
  };
  routes: Array<RouteProps>;
  role: string;
}

export const Navbar: FC<NavbarProps> = ({ location: { pathname }, role, routes }: NavbarProps) => {
  const {
    auth: { features },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const { translate } = useTranslate();

  const closeNav = () => {
    document.body.classList.remove('show-menu');
  };

  const routePart = /^\/([^/]+)(\/.*)*/.exec(pathname);
  const getIsActive = (currentRoutePath: string) => {
    const currentRoutePart = /^\/([^/]+)(\/.*)*/.exec(currentRoutePath);
    return (routePart?.[1] ?? pathname) === (currentRoutePart?.[1] ?? currentRoutePath);
  };

  const isPreAuthorizeEnabled = () => {
    if (features) {
      return features?.pre_auth?.enable || features?.stores_enabled_tips_adjustment;
    }

    return false;
  };

  return (
    <>
      <div id="yedpay-navbar" className="yedpay-navbar hide-scrollbar">
        <div className="navlink-container">
          <div className="d-md-none" style={{ flexGrow: 0.05 }}></div>
          <NavLogo />
          {isSuperowner(role) && <CompanyDropdown />}
          {routePaths.map((route, index) => {
            const currentRoute = routes.find((routeObj) => routeObj.path === route);
            if (!currentRoute) {
              return <Fragment key={index}></Fragment>;
            }

            if (currentRoute.name === 'pre_authorize' && !isPreAuthorizeEnabled()) {
              return <Fragment key={index}></Fragment>;
            }

            return (
              <NavButton
                key={`nav-route-${index}`}
                to={currentRoute.path}
                src={currentRoute.img}
                srcActive={currentRoute.activeImg}
                isActive={getIsActive(currentRoute.path)}
                name={translate(currentRoute.name)}
                index={index}
              />
            );
          })}
          {isAccountant(role) && (
            <NavButton
              key={`nav-route-6`}
              to={COMPANY_INFO.path}
              src={COMPANY_INFO.img}
              srcActive={COMPANY_INFO.activeImg}
              isActive={getIsActive(COMPANY_INFO.path)}
              name={translate(COMPANY_INFO.name)}
              index={6}
            />
          )}
          {isGatewayProvider(role) && (
            <NavButton
              key={`nav-route-9`}
              to={GATEWAY_PROVIDER_DOCUMENTS.path}
              src={GATEWAY_PROVIDER_DOCUMENTS.img}
              srcActive={GATEWAY_PROVIDER_DOCUMENTS.activeImg}
              isActive={getIsActive(GATEWAY_PROVIDER_DOCUMENTS.path)}
              name={translate(GATEWAY_PROVIDER_DOCUMENTS.name)}
              index={9}
            />
          )}
          <NavButton
            className="d-md-none"
            to={'/user/profile-info'}
            src={user}
            srcActive={userActive}
            isActive={getIsActive('/user/profile-info')}
            name={translate('user')}
            index={999}
          />
          <div style={{ flexGrow: 1 }}></div>
          <NavButton
            className="d-none d-md-block"
            to={'/user/profile-info'}
            src={user}
            srcActive={userActive}
            isActive={getIsActive('/user/profile-info')}
            name={translate('user')}
            index={999}
          />
        </div>
      </div>
      <div id="menu-overlay" onClick={closeNav}></div>
    </>
  );
};
