import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, ThunkResult } from 'redux/types';
import { getPayByLinkCodeList } from 'services/API/PayByLinkCode';
import { GetCompanyPayByLinkCodeListResponse } from 'services/API/PayByLinkCode/interface';
import { receiveData } from './DataAction';

export const getPayByLinkList = (
  company_id: string,
  data = {},
): ThunkResult<Promise<GetCompanyPayByLinkCodeListResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await getPayByLinkCodeList(company_id, data);
    if (res.success) {
      dispatch(receiveData(res, 'payByLinkCodes'));
    }
    return res;
  });
