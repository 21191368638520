import { getCompanyDocumentsList } from 'actions/CompanyActions';
import { Input } from 'components/custom/input';
import SideFilter from 'components/custom/sideFilter';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import { useTranslate } from 'context/TranslateContext';
import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadOptions } from 'react-select-async-paginate';
import { documentProps } from 'redux/reducers/dataProps';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getCompanyList } from 'services/API/Company';
import { getDocument } from 'services/API/Document';
import { useSwal } from 'helpers/sweetalert';
import { ImageModal } from './ImageModal';

// Document Page for Gateway Provider
const Documents = () => {
  const { translate } = useTranslate();

  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [selectedDocument, setSelectedDocument] = useState<string>('');
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const {
    data: {
      documents: { documents, documentsPagination },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const [Swal] = useSwal();
  const dispatch = useDispatch<FunctionDispatch>();

  const searchCompany: LoadOptions<any, any> = async (keyword, loadedOptions, additional) => {
    const page = additional?.page || 1;
    const result = await getCompanyList({ page, keyword });

    const options: Array<any> = result.data.map((company) => ({
      label: company.name,
      value: company,
    }));
    return {
      options,
      hasMore: !!result.meta.pagination?.links.next ?? false,
      additional: {
        page: page + 1,
      },
    };
  };

  const onDocumentClicked = async (document: documentProps) => {
    const type = document.name.split('.').pop() ?? '';
    const res = await getDocument(document.id);
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('document'),
        text: res.message,
      });
      return;
    }
    if (!/(jepg|jpg|png|gif|bmp)/.test(type)) {
      window.open(res.data, '_blank');
    } else {
      setSelectedDocument(res.data);
      setImageModalOpen(true);
    }
  };

  const getDocuments = async (page = 1) => {
    if (!selectedCompany) {
      return;
    }
    const res = await dispatch(getCompanyDocumentsList(selectedCompany.id, { page }));
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('documents'),
        text: res.message || 'Oops',
      });
    }
  };
  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getDocuments();
  };

  const renderHeader = () => {
    return (
      <tr>
        <th className="border-top-0 border-end">{translate('document_name')}</th>
        <th className="border-top-0 border-end">{translate('document_type')}</th>
        <th className="border-top-0 border-end">{translate('br_number')}</th>
        <th className="border-top-0">{translate('expiry_date')}</th>
      </tr>
    );
  };
  const renderBody = () => {
    return documents.map((doc) => (
      <tr key={doc.size}>
        <td>
          <span className="text-primary cursor-pointer" onClick={() => onDocumentClicked(doc)}>
            {doc.name}
          </span>
        </td>
        <td>{doc.type || '-'}</td>
        <td>{doc.br_number || '-'}</td>
        <td>{doc.expiry_date || '-'}</td>
      </tr>
    ));
  };

  return (
    <>
      <TopHeader title={translate('document')} />
      <div id="main-content" className="hide-scrollbar">
        <CustomTable
          totalColumn={4}
          renderHeading={renderHeader}
          renderData={renderBody}
          pagination={documentsPagination}
          setCurrentPage={getDocuments}
        />
      </div>
      <div id="side-content" className="hide-scrollbar">
        <SideFilter
          onFilter={onFilterSearch}
          resetFilter={() => setSelectedCompany(undefined)}
          collapsible={false}>
          <Input
            type="react-select-async"
            value={{ label: selectedCompany?.name || '', value: selectedCompany?.id || '' }}
            loadOptions={searchCompany}
            onChange={setSelectedCompany}
            legend={translate('company').toUpperCase()}
            name="company_id"
            menuPortalTarget={document.body}
          />
        </SideFilter>
      </div>
      <ImageModal
        isOpen={imageModalOpen}
        setIsOpen={setImageModalOpen}
        document={selectedDocument}
        setDocument={setSelectedDocument}
      />
    </>
  );
};

export default Documents;
