// eslint-disable-next-line @typescript-eslint/no-var-requires
const watermarkjs = require('watermarkjs');

const rotate = (text: string) => (target: HTMLCanvasElement) => {
  const context = target.getContext('2d');
  if (!context) {
    return target;
  }

  const metrics = context.measureText(text);
  const currentFontSize = parseInt(context.font);
  const diagonalLength = Math.sqrt(target.width * target.width + target.height * target.height);
  let fontSize = currentFontSize * (diagonalLength / metrics.width);
  fontSize = fontSize - fontSize / currentFontSize / 2;

  context.globalAlpha = 0.2;
  context.fillStyle = '#ccc';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.font = fontSize + 'px sans-serif';
  context.shadowColor = '#000';
  context.shadowOffsetX = 7;
  context.shadowOffsetY = 7;
  context.translate(target.width / 2, target.height / 2);
  context.rotate(Math.atan(-target.height / target.width));
  context.fillText(text, 0, 0);

  return target;
};

export const addWatermark = (file: File, text: string): Promise<HTMLImageElement> => {
  return watermarkjs([file]).image(rotate(text));
};
