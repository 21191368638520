import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import img from 'assets/img/table/data_not_found.png';
import { Pagination } from 'components/custom/pagination';
import {
  ALIPAY_CIRCLEWRAPPED_SVG,
  FPS_CIRCLEWRAPPED_SVG,
  QUICKPASS_CIRCLEWRAPPED_SVG,
  WECHATPAY_CIRCLEWRAPPED_SVG,
  DBS_FPS_CIRCLEWRAPPED_SVG,
} from 'constants/gateways/gatewayLogos';
import { useTranslate } from 'context/TranslateContext';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import { useCountdown } from 'helpers/hooks/useCountdown';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import {
  AttributesEmvCode,
  AttributesFixedCode,
  CompanyQrCode,
} from 'services/API/FixedCode/interface';

export const DataTable = ({
  setCurrentPage,
  onRowClick,
  onQrCodeClick,
  selectedFixedCode,
}: any) => {
  const {
    data: {
      fixedCodes: { fixedCodes, fixedCodesPagination },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const hasAlipayOffline = getUserActivatedGateways().includes('1_1');
  const hasWeChatPayOffline = getUserActivatedGateways().includes('8_1');
  const hasUnionPayOffline = getUserActivatedGateways().includes('10_1');
  const hasHsbcFpsOffline = getUserActivatedGateways().includes('13_2');
  const hasDBSFpsOffline = getUserActivatedGateways().includes('13_4');

  const { translate } = useTranslate();
  const [countdown, setCountdown] = useCountdown();

  const onGetTheLinkBtnClicked = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      setCountdown(5);
    });
  };

  const getBadgeColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';
      case 'active':
        return 'primary';
      case 'gateway_not_activated':
        return 'warning';
      default:
        return '';
    }
  };

  const getStatus = (code: CompanyQrCode): string => {
    if (code.type === 'emv_code') {
      return (code.attributes as AttributesEmvCode).status ? 'Active' : 'Inactive';
    }
    return (code.attributes as AttributesFixedCode).status;
  };

  const getLinks = (code: CompanyQrCode) => {
    if (code.type === 'fixed_code') {
      return {
        qrcode: (code.attributes as AttributesFixedCode)._links.qrcode.zh,
        checkout: (code.attributes as AttributesFixedCode)._links.checkout.zh,
      };
    }
    return (code.attributes as AttributesEmvCode)._links;
  };

  return (
    <div className="table-responsive shadow-sm">
      <table className="table">
        <thead>
          <tr>
            <th className="border-end border-top-0">{translate('name')}</th>
            <th className="border-end border-top-0">{translate('store_name')}</th>
            <th className="border-end border-top-0">{translate('qr_code')}</th>
            <th className="border-end border-top-0">{translate('amount')}</th>
            <th className="border-end border-top-0">{translate('type')}</th>
            <th className="border-end border-top-0">{translate('code_link')}</th>
            <th className=" border-top-0">{translate('status')}</th>
          </tr>
        </thead>
        <tbody className="mt-3">
          {fixedCodes &&
            fixedCodes.map((code) => (
              <tr
                key={`${code.type}_${code.id}`}
                onClick={onRowClick(code)}
                className="cursor-pointer">
                <td className="align-middle">{code.attributes.name}</td>
                <td className="align-middle">{code.attributes.store_name}</td>
                <td className="align-middle">
                  <button
                    className="btn p-0"
                    onClick={onQrCodeClick(code)}
                    style={{ minWidth: 150 }}>
                    <img
                      src={getLinks(code).qrcode}
                      alt=""
                      className="img-fluid"
                      style={{ height: 150, width: 150 }}
                    />
                  </button>
                </td>
                <td className="align-middle">
                  {code.attributes.amount === 0
                    ? translate('not_fixed_amount')
                    : `$${code.attributes.amount} HKD`}
                </td>
                <td className="align-middle">
                  {code.type === 'fixed_code' && (
                    <>
                      <img
                        src={WECHATPAY_CIRCLEWRAPPED_SVG}
                        alt=""
                        className={hasWeChatPayOffline ? `m-2 h-100 d-inline-block` : `d-none`}
                      />
                      <img
                        src={ALIPAY_CIRCLEWRAPPED_SVG}
                        alt=""
                        className={hasAlipayOffline ? `m-2 h-100 d-inline-block` : `d-none`}
                      />
                    </>
                  )}
                  {code.type === 'emv_code' && (
                    <>
                      <img
                        src={WECHATPAY_CIRCLEWRAPPED_SVG}
                        alt="WeChat Pay"
                        className={
                          (code.attributes as AttributesEmvCode).support_gateway_codes.includes(
                            '8_1',
                          )
                            ? `m-2 h-100 d-inline-block`
                            : `d-none`
                        }
                      />
                      <img
                        src={QUICKPASS_CIRCLEWRAPPED_SVG}
                        alt="Quick Pass"
                        className={
                          (code.attributes as AttributesEmvCode).support_gateway_codes.includes(
                            '10_1',
                          )
                            ? `m-2 h-100 d-inline-block`
                            : `d-none`
                        }
                      />

                      <img
                        src={DBS_FPS_CIRCLEWRAPPED_SVG}
                        alt="DBS FPS"
                        className={
                          (code.attributes as AttributesEmvCode).support_gateway_codes.includes(
                            '13_4',
                          )
                            ? `m-2 h-100 d-inline-block`
                            : `d-none`
                        }
                      />

                      <img
                        src={FPS_CIRCLEWRAPPED_SVG}
                        alt="HSBC FPS"
                        className={
                          (code.attributes as AttributesEmvCode).support_gateway_codes.includes(
                            '13_2',
                          )
                            ? `m-2 h-100 d-inline-block`
                            : `d-none`
                        }
                      />
                    </>
                  )}
                </td>
                <td className="align-middle">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => onGetTheLinkBtnClicked(getLinks(code).checkout)}>
                    {countdown > 0 && code === selectedFixedCode ? (
                      translate('copied_at_your_clipboard')
                    ) : (
                      <FontAwesomeIcon icon={faCopy} />
                    )}
                  </button>
                </td>
                <td className="align-middle">
                  <span className={`text-${getBadgeColor(getStatus(code))} p-2 text-uppercase`}>
                    {translate(getStatus(code))}
                  </span>
                </td>
              </tr>
            ))}
          {fixedCodes.length === 0 && (
            <tr className="">
              <td colSpan={8} rowSpan={5} className="text-center">
                <div>
                  <img src={img} alt="" className="img-fluid my-4" />
                </div>
                <span className="grey-text">{translate('no_data')}</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination pagination={fixedCodesPagination} setCurrentPage={setCurrentPage} />
      <div className="mb-5 mb-md-0"></div>
    </div>
  );
};
