import { DetailRow } from 'components/custom/DetailRow';
import { CANCELLED, REFUNDED } from 'constants/format';
import GATEWAYS, { YEDPAY_GATEWAY_CODE } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { round } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { userProps } from 'redux/state/AuthState';
import { GlobalTypes } from 'redux/types';
import { TransactionProps } from 'services/API/Transactions/interface';
import { showUser } from 'services/API/User';
import { useSwal } from 'helpers/sweetalert';

interface TransactionModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  transaction?: TransactionProps;
  isAgentOrGatewayProvider: boolean;
}

// @todo Make this function global in TransactionGateways.ts
const findGatewayByCode = (gateway_code: string) => {
  return GATEWAYS.find((gateway) => gateway.gateway_code === gateway_code)?.name;
};

export const PayByLinkViewModal = ({
  isOpen,
  setIsOpen,
  transaction,
  isAgentOrGatewayProvider,
}: TransactionModalProps) => {
  if (!transaction) {
    return <></>;
  }
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [transactionUser, setTransactionUser] = useState<userProps>({} as userProps);

  const printRef = useRef<HTMLDivElement>(null);

  const getUser = async (user_id: string) => {
    if (!user_id) {
      return;
    }
    const res = await showUser(user_id);
    if (!res.success) {
      Swal.fire({
        title: translate('user'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    setTransactionUser(res.data);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isAgentOrGatewayProvider) {
      getUser(transaction.user_id ?? '');
    }
    // eslint-disable-next-line
  }, [transaction]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>{translate('transaction_details')}</ModalHeader>
      <ModalBody>
        <div ref={printRef} className="p-3">
          <div className="transaction-content">
            <h4>
              <DetailRow
                name={translate('company_name')}
                value={user?.company?.name ?? ''}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('transaction_id')}
                value={transaction.transaction_id ?? ''}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('reference_id')}
                value={transaction.reference_id || '-'}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('custom_id')}
                value={transaction.custom_id || '-'}
                nameWidth="col-5"
              />
              {isAgentOrGatewayProvider && (
                <>
                  <DetailRow
                    name={translate('store_name')}
                    value={transaction.store?.name ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('store_address')}
                    value={transaction.store?.address ?? ''}
                    nameWidth="col-5"
                  />
                </>
              )}
              {!isAgentOrGatewayProvider && (
                <>
                  <DetailRow
                    name={translate('created_at')}
                    value={transaction.created_at ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('paid_at')}
                    value={transaction.paid_at ?? ''}
                    nameWidth="col-5"
                  />
                  {transaction.status === REFUNDED.toLowerCase() && transaction.refunded > 0 && (
                    <DetailRow
                      name={translate('refunded_at')}
                      value={transaction.refunded_at ?? ''}
                      nameWidth="col-5"
                    />
                  )}
                </>
              )}
            </h4>
          </div>
          <hr className="dash-border" />
          <div className="transaction-content">
            <h4>
              <div className="row">
                <div className="col-12">
                  <DetailRow
                    name={translate('store_name')}
                    value={transaction.store?.name ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('store_address')}
                    value={transaction.store?.address ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('phone')}
                    value={transaction.store?.phone ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate(transactionUser.role)}
                    value={`${transactionUser.first_name} ${transactionUser.last_name}` ?? ''}
                    nameWidth="col-5"
                  />
                </div>
              </div>
            </h4>
          </div>
          {!isAgentOrGatewayProvider && (
            <>
              <hr className="dash-border" />
              <div className="transaction-content">
                <h4>
                  <DetailRow
                    name={translate('payer')}
                    value={transaction.payer ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('amount')}
                    value={`${transaction.amount} ${transaction.currency}` ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('fee')}
                    value={
                      `${round(
                        parseFloat(transaction.charge) + parseFloat(transaction.refund_charge),
                        2,
                      )} ${transaction.currency}` ?? ''
                    }
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('tip_amount')}
                    value={`${transaction.tips} ${transaction.currency}` ?? ''}
                    nameWidth="col-5"
                  />

                  {transaction.status === REFUNDED.toLowerCase() && transaction.refunded > 0 && (
                    <>
                      <DetailRow
                        name={translate('refunded')}
                        value={`${transaction.refunded} ${transaction.currency}`}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('refund_reason')}
                        value={transaction.refund_details?.remark ?? ''}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('handled_person')}
                        value={`${transaction.refund_details?.first_name ?? ''} ${
                          transaction.refund_details?.last_name ?? ''
                        }`}
                        nameWidth="col-5"
                      />
                    </>
                  )}
                </h4>
              </div>
              <hr className="dash-border" />

              <div className="transaction-content">
                <h4>
                  <DetailRow
                    name={translate('net')}
                    value={`${transaction.net} ${transaction.currency}` ?? ''}
                    nameWidth="col-5"
                    valueWidth="col-6 h3"
                  />
                  <DetailRow
                    name={translate('status')}
                    value={translate(transaction.status ?? '')}
                    nameWidth="col-5"
                    valueWidth="col-6 h3"
                  />
                </h4>
              </div>
              {transaction.extra_parameters && (
                <>
                  <div className="transaction-content">
                    <h4>
                      <div className="row">
                        <div className="col-12">
                          <DetailRow
                            name={translate('tip_amount')}
                            value={transaction.extra_parameters?.yb_tips_amount ?? ''}
                            nameWidth="col-5"
                          />
                        </div>
                      </div>
                    </h4>
                  </div>
                  <hr className="dash-border" />
                </>
              )}
              <hr className="dash-border" />
              {transaction.status === CANCELLED.toLowerCase() &&
                transaction.cancellation_details && (
                  <>
                    <div className="transaction-content">
                      <h4>
                        <div className="row">
                          <div className="col-12">
                            <DetailRow
                              name={translate('cancel_reason')}
                              value={transaction.cancellation_details.remark ?? ''}
                              nameWidth="col-5"
                            />

                            <DetailRow
                              name={translate('handled_person')}
                              value={
                                `${transaction.cancellation_details.first_name} ${transaction.cancellation_details.last_name}` ??
                                ''
                              }
                              nameWidth="col-5"
                            />
                          </div>
                        </div>
                      </h4>
                    </div>
                    <hr className="dash-border" />
                  </>
                )}

              {transaction.gateway_code !== YEDPAY_GATEWAY_CODE && (
                <>
                  <div className="transaction-content">
                    <h4>
                      <DetailRow
                        name={translate('gateway')}
                        value={findGatewayByCode(`${transaction.gateway_code}`) ?? ''}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('gateway_id')}
                        value={`${transaction.gateway_id}` ?? ''}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('wallet')}
                        value={transaction.wallet_type ?? '-'}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name="QR Code Id"
                        value={transaction.fixed_id ?? ''}
                        nameWidth="col-5"
                      />
                      <DetailRow
                        name={translate('forex')}
                        value={transaction.forex ?? '-'}
                        nameWidth="col-5"
                      />
                    </h4>
                  </div>
                  <hr className="dash-border" />
                </>
              )}

              {transaction.online_payment?.id && (
                <div className="transaction-content">
                  <h4>
                    <DetailRow
                      name={translate('subject')}
                      value={transaction.online_payment.subject ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate('email')}
                      value={transaction.online_payment.payment_data?.email ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate('remarks')}
                      value={transaction.online_payment.remarks ?? ''}
                      nameWidth="col-5"
                    />
                  </h4>
                </div>
              )}
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
