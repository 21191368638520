import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, ThunkResult } from 'redux/types';
import { AnyResponse } from 'services/API/common/Fetcher';
import {
  createTransaction as apiCreateTransaction,
  getCompanyTransactionList as apiGetCompanyTransactionList,
  getUserTransactionList as apiGetUserTransactionList,
} from 'services/API/Transactions';
import { CreateTransactionBody } from 'services/API/Transactions/interface';
import { CREATE_TRANSACTION } from './actionTypes';
import { receiveData } from './DataAction';

export const getUserTransactionList = (
  user_id: string,
  data: any = {},
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await apiGetUserTransactionList(user_id, data);
    if (!data.export && res.success) {
      dispatch(receiveData(res, 'transactions'));
    }
    return res;
  });
export const getCompanyTransactionList = (
  company_id: string,
  data: any = {},
  isShowLoader = true,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await apiGetCompanyTransactionList(company_id, data);
    if (!data.export && res.success) {
      dispatch(receiveData(res, 'transactions'));
    }
    return res;
  }, isShowLoader);

export const createTransaction = (
  store_id: string,
  data: CreateTransactionBody,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await apiCreateTransaction(store_id, data);
    if (res.success) {
      dispatch({
        type: CREATE_TRANSACTION,
        payload: res.data,
      });
    }
    return res;
  });
