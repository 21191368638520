import { checkStoreStatus } from 'helpers/functions/checkStoreStatus';
import axios, { defaultRequestHandler, getToken, params } from '../common/Fetcher';
import { DefaultResponse } from './../common/Fetcher';
import { ContentType } from './../common/Headers';
import {
  BankAccountFormProps,
  CreateCompanyBankResponse,
  CreateCompanyStoreProps,
  GatewayProviderGetCompanyResponse,
  GetCompanyAvailableGatewayListResponse,
  GetCompanyBankAccountsResponse,
  GetCompanyCredentialsResponse,
  GetCompanyDocumentListResponse,
  GetCompanyLogoListResponse,
  GetCompanyStoreListResponse,
  UpdateCompanyProps,
  UploadFileToCompanyResponse,
} from './interface';

// https://dev2.yedpay.com/document/admin/companyCredentialList
export const getCompanyCredentialList = (
  company_id: string,
  options: any = {},
): Promise<GetCompanyCredentialsResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/credentials?${params(options)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/uploadFile
export const uploadFileToCompany = (
  company_id: string,
  formData: FormData,
): Promise<UploadFileToCompanyResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/documents`, formData, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/companyStoreList
export const getCompanyStoreList = (
  company_id: string,
  data = {},
): Promise<GetCompanyStoreListResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/stores?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  ).then(checkStoreStatus);

// https://dev2.yedpay.com/document/admin/createStore
export const createCompanyStore = (company_id: string, body: CreateCompanyStoreProps) =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/stores`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/companyDocumentList
export const getCompanyDocumentsList = (
  company_id: string,
  data = {},
): Promise<GetCompanyDocumentListResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/documents?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

//https://dev2.yedpay.com/document/admin/companyLogoList
export const getCompanyLogoList = (company_id: string): Promise<GetCompanyLogoListResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/logos`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/updateCompany
export const updateCompanyInfo = (company_id: string, body: UpdateCompanyProps) =>
  defaultRequestHandler(
    axios.put(`/companies/${company_id}`, params(body), {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/companyBankAccount
export const getCompanyBankAccounts = (
  company_id: string,
): Promise<GetCompanyBankAccountsResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/accounts`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/createBankAccount
// @alert This endpoint is considered as @Deprecated
export const createCompanyBankAccount = (
  company_id: string,
  body: BankAccountFormProps,
): Promise<CreateCompanyBankResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/accounts`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/activateAutoSettlement
export const activateAutoSettlement = (company_id: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(
      `/companies/${company_id}/auto-settlement`,
      {},
      {
        headers: {
          authorization: getToken(),
          'Content-Type': ContentType.urlEncoded,
        },
      },
    ),
  );

// https://dev2.yedpay.com/document/admin/deactivateAutoSettlement
export const deactivateAutoSettlement = (company_id: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.delete(`/companies/${company_id}/auto-settlement`, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );

// @todo looking for docs link
// for gateway provider
export const getCompanyList = (params = {}): Promise<GatewayProviderGetCompanyResponse> =>
  defaultRequestHandler(
    axios.get(`/companies`, {
      headers: {
        authorization: getToken(),
      },
      params,
    }),
  );

// https://dev2.yedpay.com/companies/${company_id}/gateway-codes
export const getAvailableGatewayList = (
  company_id: string,
): Promise<GetCompanyAvailableGatewayListResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/gateway-codes`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const uploadDocument = (company_id: string, data: FormData): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/documents`, data, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const createCompanyBr = (company_id: string, data: FormData): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/brs`, data, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const createStoreBr = (company_id: string, data: FormData): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/store-brs`, data, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const createCi = (company_id: string, data: FormData): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/certificate-of-incorporation`, data, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const createIr = (company_id: string, data: FormData): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/irs`, data, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const createContract = (company_id: string, data: FormData): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/contracts`, data, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
