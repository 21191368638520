import { OFFLINE_STORE } from 'constants/format';
import {
  ALIPAY_GATEWAY_CODE,
  findGatewayByCode,
  HSBC_FPS_OFFLINE_GATEWAY_CODE,
  UNIONPAY_GATEWAY_CODE,
  WECHATPAY_GATEWAY_CODE,
} from 'constants/TransactionGateways';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import { useEffect } from 'react';
import { gateway as gatewayType } from 'redux/reducers/dataProps';

interface CodeSelectionBoxProps {
  isEdit: boolean;
  store_type: boolean | number | string;
  onSelectGateway: (isAlipay: boolean) => any;
  isAlipay?: boolean;
}

export const CodeSelectionBox = ({
  isEdit,
  store_type,
  onSelectGateway,
  isAlipay,
}: CodeSelectionBoxProps) => {
  const alipayGateway = findGatewayByCode(ALIPAY_GATEWAY_CODE) as gatewayType;
  const wechatpayGateway = findGatewayByCode(WECHATPAY_GATEWAY_CODE) as gatewayType;
  const unionpayGateway = findGatewayByCode(UNIONPAY_GATEWAY_CODE) as gatewayType;
  const hsbcGateway = findGatewayByCode(HSBC_FPS_OFFLINE_GATEWAY_CODE) as gatewayType;

  const hasAlipayOffline = getUserActivatedGateways().includes('1_1');
  const hasWeChatPayOffline = getUserActivatedGateways().includes('8_1');
  const hasUnionPayOffline = getUserActivatedGateways().includes('10_1');
  const hasFpsOffline = getUserActivatedGateways().some((code) => ['13_2', '13_4'].includes(code));

  const fixedCodeGatewaysCount = [hasAlipayOffline, hasWeChatPayOffline].filter(Boolean).length;
  const emvCodeGatewaysCount = [hasWeChatPayOffline, hasUnionPayOffline, hasFpsOffline].filter(
    Boolean,
  ).length;

  const fixedCodeLogoWidth = Math.floor(12 / fixedCodeGatewaysCount);
  const emvCodeLogoWidth = Math.floor(12 / emvCodeGatewaysCount);

  useEffect(() => {
    if (
      (fixedCodeGatewaysCount === 0 && emvCodeGatewaysCount >= 1) ||
      (hasWeChatPayOffline && !(hasUnionPayOffline || hasAlipayOffline))
    ) {
      onSelectGateway(false)();
    }
  }, []);

  return (
    <>
      {!isEdit &&
        fixedCodeGatewaysCount > 0 &&
        store_type == OFFLINE_STORE &&
        !(hasWeChatPayOffline && !(hasUnionPayOffline || hasAlipayOffline)) && (
          <div className="d-grid">
            <button
              type="button"
              onClick={onSelectGateway(true)}
              className="btn"
              style={{ boxShadow: isAlipay ? '0 0 0 0.2rem rgb(77 123 243 / 25%)' : 'none' }}>
              <img
                src={wechatpayGateway.img}
                alt={wechatpayGateway.name}
                title={wechatpayGateway.name}
                className={
                  hasWeChatPayOffline && (hasUnionPayOffline || hasAlipayOffline)
                    ? `col-md-${fixedCodeLogoWidth}`
                    : `d-none`
                }
              />
              <img
                src={alipayGateway.img}
                alt={alipayGateway.name}
                title={alipayGateway.name}
                className={hasAlipayOffline ? `col-md-${fixedCodeLogoWidth}` : `d-none`}
              />
            </button>
          </div>
        )}

      {!isEdit && emvCodeGatewaysCount > 0 && store_type == OFFLINE_STORE && (
        <div className="d-grid">
          <button
            type="button"
            onClick={onSelectGateway(false)}
            className="btn d-flex flex-wrap gx-2 align-items-center"
            style={{
              boxShadow: !isAlipay ? '0 0 0 0.2rem rgb(77 123 243 / 25%)' : 'none',
              rowGap: '1rem',
              justifyContent: 'space-evenly',
            }}>
            <img
              src={wechatpayGateway.img}
              alt={wechatpayGateway.name}
              title={wechatpayGateway.name}
              className={hasWeChatPayOffline ? `col-md-${emvCodeLogoWidth}` : `d-none`}
            />
            <img
              src={unionpayGateway.img}
              alt={unionpayGateway.name}
              title={unionpayGateway.name}
              className={hasUnionPayOffline ? `col-md-${emvCodeLogoWidth}` : `d-none`}
            />
            <img
              src={hsbcGateway.img}
              alt={'FPS'}
              title={'FPS'}
              className={hasFpsOffline ? `col-md-${emvCodeLogoWidth}` : `d-none`}
              style={{
                maxWidth: '25%',
              }}
            />
          </button>
        </div>
      )}
    </>
  );
};
