import { Text } from 'components/custom/Text';
import { GatewayGroup } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { useState } from 'react';
import { ApplyModal } from './applyModal';

interface GatewayCard {
  gatewayGroup: GatewayGroup;
  getCredentials: () => Promise<void>;
}

export const GatewayApplyCard = ({ gatewayGroup, getCredentials }: GatewayCard) => {
  const { translate, language } = useTranslate();
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);

  if (!gatewayGroup) {
    return <></>;
  }

  return (
    <div className="gateway-card">
      <div
        className={`gateway-card-header`}
        style={{
          backgroundImage: `url(${
            language === 'en' ? gatewayGroup.header_en : gatewayGroup.header
          })`,
        }}></div>
      <div className="gateway-card-body">
        <Text
          type={translate('gateway')}
          value={translate(gatewayGroup.name)}
          className={'fx-17'}
        />
        {gatewayGroup.wallet && (
          <Text
            type={translate('wallet')}
            value={translate(gatewayGroup.wallet)}
            className={'fx-17'}
          />
        )}
        <Text
          type={translate('gateway_type')}
          value={translate(gatewayGroup.type)}
          className={'fx-17'}
        />
        <Text
          type={translate('apply_status')}
          value={translate('not_applied')}
          className={'fx-17'}
        />
        <div className="d-grid">
          <button
            className="btn btn-outline-primary mt-3 fx-17"
            onClick={() => setIsApplyModalOpen(true)}>
            {translate('apply_immediately')}
          </button>
          <a
            className="btn btn-outline-secondary mt-3 fx-17 link-underline link-underline-opacity-0"
            href="/terms.html"
            target="_blank">
            {translate('terms')}
          </a>
        </div>
      </div>
      <ApplyModal
        isOpen={isApplyModalOpen}
        setIsOpen={setIsApplyModalOpen}
        gateway={gatewayGroup}
        getCredentials={getCredentials}
      />
    </div>
  );
};
