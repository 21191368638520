import { InfoHeader } from 'components/custom/info_header';
import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import SideFilter from 'components/custom/sideFilter';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { useTranslate } from 'context/TranslateContext';
import { RouteComponentProps } from 'react-router-dom';
import { ApiKey } from './ApiKey';
import { SignKey } from './SignKey';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { ApiKeyProps } from 'services/API/Keys/interface';
import { PaginationProps } from 'services/API/common/Fetcher';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import * as API from 'services/API/Keys';
import { getAllStore } from 'services/API/Store';
import Swal from 'sweetalert2';
import { StoreSelect } from 'components/custom/ReactSelectAsync/Select/StoreSelect';
import './ApiKey.scss';

export const AppKeys = ({ location }: { location: RouteComponentProps }) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const { translate, language } = useTranslate();

  const [isFetching, setIsFetching] = useState(false);
  const [name, setName] = useState('');
  const [store, setStore] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiList, setApiList] = useState<Array<ApiKeyProps>>([]);
  const [pagination, setPagination] = useState<PaginationProps | null>(null);
  const [allStores, setAllStores] = useState<Array<SelectOptionProps>>([]);
  const [sorting, setSorting] = useState({ column: '-created_at', direction: '' });
  const [storeKeyCondition, setStoreKeyCondition] = useState({
    storeWithKey: 1,
    storeWithoutKey: 1,
  });

  const filterToParams = () => {
    const data: { [key: string]: string } = {
      name,
      store_id: store?.id ?? '',
    };

    const names = Object.keys(data);
    const values = Object.values(data);
    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        if (
          !(
            storeKeyCondition.storeWithoutKey === 1 &&
            storeKeyCondition.storeWithKey === 0 &&
            names[index] === 'store_id'
          )
        ) {
          delete data[names[index]];
        }
      }
    });

    Object.assign(data, {
      limit: 10,
      page: currentPage,
      sort: sorting.direction === 'desc' ? '-' + sorting.column + ',id' : sorting.column + ',id',
    });

    return data;
  };

  const resetFilter = () => {
    setName('');
    setStore(null);
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getApiList();
    } else {
      setCurrentPage(1);
    }
  };

  const sortTable = (column: string) => {
    const directionArr = ['asc', 'desc'];
    const indexOfDirection = directionArr.indexOf(sorting.direction);
    let direction = '';

    switch (true) {
      case column !== sorting.column:
      case indexOfDirection === -1:
      case indexOfDirection + 1 === directionArr.length:
        direction = directionArr[0];
        break;
      default:
        direction = directionArr[indexOfDirection + 1];
        break;
    }

    setSorting({ column: column, direction: direction });
  };

  const onChangeStoreKeyCondition = (val: any) => {
    const tempObj = {
      ...storeKeyCondition,
      ...val,
    };

    if (tempObj?.storeWithKey == 0 && tempObj?.storeWithoutKey == 0) {
      return;
    }

    setStoreKeyCondition({
      ...storeKeyCondition,
      ...val,
    });
  };

  const getStoreKeyConditionForApi = () => {
    let val = null;
    switch (true) {
      case storeKeyCondition.storeWithKey === 1 && storeKeyCondition.storeWithoutKey === 0:
        val = true;
        break;
      case storeKeyCondition.storeWithoutKey === 1 && storeKeyCondition.storeWithKey === 0:
        val = false;
        break;
      default:
        val = null;
        break;
    }
    return val;
  };

  const getStoreSelectDefaultOption = () => {
    let defaultOption = [{ label: translate('all_stores'), value: '' }];
    if (storeKeyCondition.storeWithKey === 1 && storeKeyCondition.storeWithoutKey === 0) {
      defaultOption = [{ label: translate('stores_with_api_key'), value: '' }];
    }

    if (storeKeyCondition.storeWithKey === 0 && storeKeyCondition.storeWithoutKey === 1) {
      defaultOption = [{ label: translate('stores_without_api_key'), value: '' }];
    }

    return defaultOption;
  };

  const getApiList = async () => {
    const params = filterToParams();

    if (user?.company_id) {
      setIsFetching(true);
      const res = await API.getApiList(user.company_id, params);
      setIsFetching(false);
      if (!res.success) {
        await Swal.fire({
          icon: 'error',
          title: translate('api_keys'),
          text: res?.message || 'Oops',
        });
        return;
      }
      setApiList(res.data);
      setPagination(res.meta?.pagination ?? null);
    }
  };

  const getStores = async () => {
    if (user?.company_id) {
      const res = await getAllStore(user.company_id);
      if (!res.success) {
        await Swal.fire({
          icon: 'error',
          title: translate('store_list'),
          text: res?.message || 'Oops',
        });
        return;
      }
      const stores = res.data;
      const options = stores.map((store) => ({
        label: store.name,
        value: store.id,
      }));
      setAllStores([...options]);
    }
  };

  useEffect(() => {
    getStores();
    // eslint-disable-next-line
  }, [user, currentPage]);

  useEffect(() => {
    getApiList();
  }, [user, currentPage, sorting]);

  useEffect(() => {
    setStore(null);
  }, [storeKeyCondition]);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location as any} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="stores" name="app_keys" />
          <div className="bg-white pt-3 pb-4 round-bottom-lg">
            <SideFilter
              isLoading={isFetching}
              resetFilter={resetFilter}
              onFilter={onFilterSearch}
              defaultCollapsed={true}>
              <fieldset className={`my-3 input-fieldset`}>
                <legend>
                  <div className="d-flex align-items-center">
                    {/* {translate('store').toUpperCase()} */}
                    <div className="store-select-fieldset d-flex align-items-center">
                      <label className="form-label d-flex align-items-center mb-0">
                        <Input
                          type="checkbox"
                          value={storeKeyCondition.storeWithKey}
                          // legend={translate('show_stores_with_api_key').toUpperCase()}
                          name="store_with_key"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeStoreKeyCondition({
                              storeWithKey: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                        {translate('stores_with_api_key').toUpperCase()}
                      </label>
                    </div>
                    <div className="store-select-fieldset d-flex align-items-center">
                      <label className="form-label d-flex align-items-center mb-0">
                        <Input
                          type="checkbox"
                          value={storeKeyCondition.storeWithoutKey}
                          name="store_without_key"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeStoreKeyCondition({
                              storeWithoutKey: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                        {translate('stores_without_api_key').toUpperCase()}
                      </label>
                    </div>
                  </div>
                </legend>
                <div className="row g-0">
                  <div className="col-12">
                    <StoreSelect
                      key={`store-select-${storeKeyCondition?.storeWithKey}-${storeKeyCondition?.storeWithoutKey}-${language}`}
                      setStore={setStore}
                      store={store}
                      defaultOption={getStoreSelectDefaultOption()}
                      searchOptionsParams={{
                        company_application: getStoreKeyConditionForApi(),
                      }}
                    />
                  </div>
                </div>
              </fieldset>
              <Input
                type="text"
                value={name}
                legend={translate('name').toUpperCase()}
                name="name"
                onChange={(e) => setName(e.target.value)}
                placeholder={translate('name')}
              />
            </SideFilter>
            <SignKey />
            <hr />
            <ApiKey
              apiList={apiList}
              pagination={pagination}
              getApiList={getApiList}
              setCurrentPage={setCurrentPage}
              allStores={allStores}
              sortTable={sortTable}
              sorting={sorting}
              isLoading={isFetching}
            />
          </div>
        </div>
      </div>
    </>
  );
};
