import logo from 'assets/img/registrationForm/yourBusinessIcon.png';
import { FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import {
  ANY_OBJECT,
  MERCHANT_TYPE_ENTERPRISE,
  MERCHANT_TYPE_INDIVIDUAL,
  MERCHANT_TYPE_OPTIONS,
  TAXI_MCC,
  translateLabel,
  YEDPAY_ADDRESS,
  IS_NGO_OPTION,
} from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { components } from 'react-select';
import { getMccList as apiGetMccList } from 'services/API/MCC';
import { OfflineStoreProps, OnlineStoreProps, StepProps } from '../interface';
import { businessSetupValidate } from '../validator';

interface MCCListProps {
  name: string;
  name_zh: string;
  group_id: string;
  description: string;
  mcc_list: Array<MCCSubListProps>;
}

interface MCCSubListProps {
  description: string;
  description_zh: string;
  group_description: string;
  group_id: string;
  group_name: string;
  group_name_zh: string;
  mcc: string;
}

interface ConvertedMccListProps {
  label: string;
  value: string;
  description: string;
  options: Array<MccOptionProps>;
}

interface MccOptionProps {
  label: string;
  value: string;
  group_id: string;
}

const convertGroupOptions = (mccList: Array<MCCListProps>) =>
  mccList.map((item) => ({
    label: `${item.name}\n${item.name_zh}`,
    value: item.group_id,
    description: item.description,
    options: convertMccOptions(item.mcc_list),
  }));

const convertMccOptions = (mcc_list: Array<MCCSubListProps>) =>
  mcc_list.length === 0
    ? []
    : mcc_list.map((mcc) => ({
        label: `${mcc.description}\n${mcc.description_zh}`,
        value: mcc.mcc,
        group_id: mcc.group_id,
      }));

export const BusinessSetup = forwardRef(({ form, setForm }: StepProps, ref) => {
  const { translate } = useTranslate();

  const [mccList, setMccList] = useState<Array<ConvertedMccListProps>>([]);

  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const {
    company: {
      mcc,
      name,
      phone,
      contact_name,
      contact_mobile,
      business_description,
      merchant_type,
      is_ngo,
    },
    expected_activity: { transaction_avg, expected_monthly_inflow },
  } = form;

  const onMerchantTypeSelect = (merchantType: string) => {
    setCompany('merchant_type', merchantType);
  };
  const onMccSelect = (mcc: string) => {
    setCompany('mcc', mcc);
  };
  const onIsNgoSelect = (is_ngo: string) => {
    setCompany('is_ngo', is_ngo);
  };
  const setCompany = (name: string, value: string) => {
    let activation_type = form.activation_type;
    const company: any = {
      ...form.company,
      [name]: value,
    };
    let stores = form.stores;

    company[name] = value;
    if (name === 'name') {
      company['name'] = value;
    } else if (name === 'phone') {
      company['phone'] = value;
    } else if (name === 'contact_name') {
      company['contact_name'] = value;
    } else if (name === 'contact_mobile') {
      company['contact_mobile'] = value;
    } else if (name === 'activation_type') {
      activation_type = +value;
    } else if (name === 'merchant_type') {
      company.mcc = '';
      activation_type = 1;
      company['is_ngo'] = false;
      stores = [];
    } else if (name === 'mcc' && TAXI_MCC.toString() === `${value}`) {
      company.merchant_type = MERCHANT_TYPE_INDIVIDUAL;
      activation_type = 2;
      stores = [];
    }

    setForm({
      ...form,
      activation_type,
      company,
      stores,
    } as typeof form);
  };

  const onCompanyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.name, e.target.value);
  };

  const onExpectedActivityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      expected_activity: {
        ...form.expected_activity,
        [e.target.name]: parseInt(e.target.value) || '',
      },
    });
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const { isValid, errors } = businessSetupValidate(form);
      setErrors(errors);
      if (!isValid) {
        return false;
      }
      const data: typeof form.company & ANY_OBJECT = {
        ...form.company,
      };

      for (const [key, value] of Object.entries(data)) {
        if (value === '') {
          delete data[key];
        }
      }

      const stores: Array<OfflineStoreProps | OnlineStoreProps> = form.stores ? form.stores : [];

      if (merchant_type === MERCHANT_TYPE_INDIVIDUAL) {
        stores[0] = {
          address: YEDPAY_ADDRESS,
          district_id: 20,
          name: form.company.name,
          type: 1,
        };
      }

      setForm({
        ...form,
        company: data,
        position:
          form.company.mcc === `${TAXI_MCC}`
            ? 'driver'
            : merchant_type === MERCHANT_TYPE_INDIVIDUAL
            ? 'hawker'
            : '',
        phone: form.company.phone ?? '',
        stores,
      });
      return true;
    },
  }));

  useEffect(() => {
    const getMccList = async () => {
      const mccList = localStorage.getItem('mccList');
      if (mccList) {
        setMccList(JSON.parse(mccList));
        return;
      }
      const res = await apiGetMccList();
      if (!res || !res.success) {
        //@todo Add Toast for error
        return;
      }
      const mccOptions = convertGroupOptions(res.data);
      localStorage.setItem('mccList', JSON.stringify(mccOptions));
      setMccList(mccOptions);
    };
    getMccList();

    if (!form.company.business_description) {
      setForm({
        ...form,
        company: {
          ...form.company,
          business_description:
            form.company.mcc === `${TAXI_MCC}`
              ? 'Taxi'
              : merchant_type === MERCHANT_TYPE_INDIVIDUAL
              ? 'Hawker'
              : '',
        },
        expected_activity: {
          ...form.expected_activity,
          transaction_avg: 100,
          expected_monthly_inflow: 2000,
        },
      });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <FormHeader title={translate('business_setup')} src={logo} />
      <Input
        legend={translate('business_type').toUpperCase()}
        type="react-select"
        name="merchant_type"
        value={merchant_type}
        options={translateLabel(MERCHANT_TYPE_OPTIONS, translate)}
        onChange={onMerchantTypeSelect}
        placeholder={translate('choose_business_type')}
        isRequired
        error={translate(...(errors['company.merchant_type'] ?? ''))}
      />
      <Input
        legend={translate('business_nature').toUpperCase()}
        type="react-select"
        name="mcc"
        value={mcc}
        options={mccList}
        onChange={onMccSelect}
        searchIcon
        placeholder={translate('choose_business')}
        components={{
          Option: (props: any) => (
            <div title={props.data.value}>
              <components.Option {...props} />
            </div>
          ),
        }}
        isRequired
        error={translate(...(errors['company.mcc'] ?? ''))}
      />
      {merchant_type === MERCHANT_TYPE_ENTERPRISE && (
        <Input
          legend={translate('ngo').toUpperCase()}
          type="react-select"
          name="is_ngo"
          value={is_ngo as boolean}
          options={translateLabel(IS_NGO_OPTION, translate)}
          onChange={onIsNgoSelect}
          placeholder={translate('choose_business_type')}
          isRequired
          error={translate(...(errors['company.merchant_type'] ?? ''))}
        />
      )}
      <Input
        legend={translate(
          merchant_type === MERCHANT_TYPE_ENTERPRISE ? 'company_name' : 'store_name',
        ).toUpperCase()}
        type="text"
        name="name"
        value={name}
        onChange={onCompanyChange}
        placeholder={translate(
          merchant_type === MERCHANT_TYPE_ENTERPRISE
            ? 'enter_your_company_name'
            : 'enter_store_name',
        )}
        isRequired
        error={translate(...(errors['company.name'] ?? ''))}
      />
      {merchant_type === MERCHANT_TYPE_ENTERPRISE && (
        <Input
          legend={translate('company_phone').toUpperCase()}
          type="text"
          name="phone"
          value={phone as string}
          onChange={onCompanyChange}
          placeholder={translate('enter_your_company_phone')}
          isRequired
          error={translate(...(errors['company.phone'] ?? ''))}
        />
      )}
      <Input
        legend={translate('contact_name').toUpperCase()}
        type="text"
        name="contact_name"
        value={contact_name}
        onChange={onCompanyChange}
        placeholder={translate('enter_your_contact_name')}
        isRequired
        error={translate(...(errors['company.contact_name'] ?? ''))}
      />
      <Input
        legend={translate('contact_phone').toUpperCase()}
        type="text"
        name="contact_mobile"
        value={contact_mobile as string}
        onChange={onCompanyChange}
        placeholder={translate('enter_your_contact_mobile')}
        isRequired
        error={translate(...(errors['company.contact_mobile'] ?? ''))}
      />
      <Input
        legend={translate('business_description').toUpperCase()}
        type="text"
        name="business_description"
        value={business_description as string}
        onChange={onCompanyChange}
        placeholder={translate('enter_your_business_description')}
        isRequired
        error={translate(...(errors['company.business_description'] ?? ''))}
      />
      <Input
        legend={translate('average_ticket').toUpperCase()}
        type="text"
        name="transaction_avg"
        value={transaction_avg}
        onChange={onExpectedActivityChange}
        placeholder={translate('enter_your_average_ticket')}
        prefix={'$'}
        isRequired
        error={translate(...(errors['expected_activity.transaction_avg'] ?? ''))}
      />
      <Input
        legend={translate('average_monthly_sale')}
        type="text"
        name="expected_monthly_inflow"
        value={expected_monthly_inflow}
        onChange={onExpectedActivityChange}
        placeholder={translate('enter_your_average_monthly_sale')}
        prefix={'$'}
        isRequired
        error={translate(...(errors['expected_activity.expected_monthly_inflow'] ?? ''))}
      />
    </>
  );
});
