import moment from 'moment';
import { validateParam } from './standard';

export const dateRangeDurationLessEqualThan: validateParam = (data, fields, options = {}): any => {
  const d1 = moment(data[fields[0]]);
  const d2 = moment(data[fields[1]]);

  if (d2.diff(d1, 'days') > options.max) {
    return {
      [fields[0]]: options.err_msg || [
        'error_duration_must_be_less_equal_than',
        [options.max, options.unit],
      ],
      [fields[1]]: options.err_msg || [
        'error_duration_must_be_less_equal_than',
        [options.max, options.unit],
      ],
    };
  }
};
