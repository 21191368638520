import { getMe, logout, registerServiceProvider } from 'actions/AuthActions';
import { getIntegratorAction } from 'actions/IntegrationActions';
import { useTranslate } from 'context/TranslateContext';
import { toggleLoader } from 'helpers/screenLoader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { IntegratorProps } from 'services/API/Integration/interface';
import { useSwal } from 'helpers/sweetalert';
import { LoginServiceProviderConcent } from './login-service-provider-concent';

export const LoginRedirect = () => {
  const history = useHistory();
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const dispatch = useDispatch<FunctionDispatch>();
  const state = useSelector((state: GlobalTypes.RootState) => state);
  const [showServiceProviderConcent, setShowServiceProviderConcent] = useState(false);
  const [serviceProvider, setServiceProvider] = useState<IntegratorProps>();

  const exit = () => {
    dispatch(logout());
    localStorage.removeItem('authYDP');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    window.location.href = '/login'; // This line must not use history
  };

  if (!state.auth.isAuthenticated) {
    exit();
    return <></>;
  }

  const loginServiceProvider = async () => {
    toggleLoader(true);

    const urlParams = new URLSearchParams(decodeURIComponent(localStorage.urlParams || ''));
    const result = await dispatch(
      registerServiceProvider({
        service_provider_id: urlParams.get('service_provider_id') || '',
        extra_data: localStorage.urlParams || '',
      }),
    );

    if (result.success) {
      history.block(); // Remove default redirect to /login
      exit();
      localStorage.removeItem('urlParams');
      return (window.location.href = urlParams.get('return_url') || '/account-summary');
    }

    toggleLoader(false);
    Swal.fire({
      title: translate('Oops'),
      text: result.message || 'Oops',
      icon: 'error',
    });
  };

  useEffect(() => {
    toggleLoader(true);

    (async () => {
      const res = await dispatch(getMe());

      if (res == null) {
        return Swal.fire({
          title: translate('Oops'),
          text: "You don't have access right to the merchant portal.",
          icon: 'error',
        }).then(() => exit());
      }

      if (!res.success) {
        toggleLoader(false);
        await Swal.fire({
          title: translate('Oops'),
          text: res.message || 'Oops',
          icon: 'error',
        });
        exit();
        return;
      } else if (res.data.activated === false) {
        return history.replace('/business-setup');
      }

      const urlParams = new URLSearchParams(decodeURIComponent(localStorage.urlParams || ''));
      const serviceProviderCode = urlParams.get('service_provider_id') || null;
      if (serviceProviderCode) {
        const serviceProvider = (await dispatch(getIntegratorAction(serviceProviderCode))).data;
        setServiceProvider(serviceProvider);
        setShowServiceProviderConcent(true);
        toggleLoader(false);
        return;
      }

      localStorage.removeItem('urlParams');

      if (['gateway-provider', 'agent', 'operator'].includes(res.data.role)) {
        return history.replace('/transaction');
      }
      if (localStorage.getItem('initUrl')) {
        history.replace(localStorage.getItem('initUrl')!);
        localStorage.removeItem('initUrl');
      } else return history.replace('/account-summary');
    })();
  }, []);

  return (
    <>
      {showServiceProviderConcent && serviceProvider && (
        <LoginServiceProviderConcent
          serviceProvider={serviceProvider}
          accept={loginServiceProvider}
          denied={exit}
        />
      )}
    </>
  );
};
