import { params } from 'helpers/functions/param';
import axios, { defaultRequestHandler, getToken } from '../common/Fetcher';
import { GetBankCodeListResponse, GetBankInfoResponse } from './interface';

// https://dev2.yedpay.com/document/admin/showBankInfo
export const getBankInfo = (bank_info_id: string) =>
  defaultRequestHandler(
    axios.get(`/bankinfo/${bank_info_id}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/bankCodeList
export const getBankCodeList = (data = {}): Promise<GetBankCodeListResponse> =>
  defaultRequestHandler(
    axios.get(`/bankinfo/bank-codes?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/bankInfoList
export const getBankInfoList = (data = {}): Promise<GetBankInfoResponse> =>
  defaultRequestHandler(
    axios.get(`/bankinfo?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/bankInfoAll
export const getAllBankInfo = () =>
  defaultRequestHandler(
    axios.get(`/bankinfo/all`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
