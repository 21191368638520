const loader = document.getElementById('screen-loader');

export const toggleLoader = (shouldLoad: boolean) => {
  if (!loader) {
    console.log('Loader is not found');
    return;
  }
  if (shouldLoad) {
    loader.style.display = 'block';
    return;
  }

  loader.style.display = 'none';
};
