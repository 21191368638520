import RestfulAPI from '../common/Restful';
import { IntegratorEditProps, IntegratorProps } from './interface';

export const getIntegratorAPI = (
  integratorCode?: string,
): RestfulAPI<IntegratorProps, IntegratorEditProps> => {
  if (!integratorCode) {
    return new RestfulAPI<IntegratorProps, IntegratorEditProps>(`/integrators`);
  }

  return new RestfulAPI<IntegratorProps, IntegratorEditProps>(
    `/integrators`,
    `/integrators/${integratorCode}`,
  );
};
