import { faRedo, faSearchMinus, faSearchPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from 'context/TranslateContext';
import moment from 'moment';
import { getDocument } from 'pdfjs-dist';
import { useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface DocPreviewModalProps {
  isOpen: boolean;
  setIsOpen: (isModalOpen: boolean) => void;
  file?: File;
  setFile?: (file?: File) => void;
  onConfirm: (file: File) => void;
}

const ZOOM_MIN = 1.5;
const ZOOM_MAX = 5;

export const DocPreviewModal = ({
  isOpen,
  setIsOpen,
  file,
  setFile,
  onConfirm,
}: DocPreviewModalProps) => {
  const { translate } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [scale, setScale] = useState(1.5);
  const [rotation, setRotation] = useState(0);
  const [previewImage, setPreviewImage] = useState<HTMLImageElement>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async () => {
    if (!file || !previewCanvasRef || !previewCanvasRef.current || isLoading) {
      return;
    }
    setFile && setFile(undefined);
    setIsLoading(true);

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        return;
      }
      const image = new File([blob], file.name.replace(/\.\w+$/i, '.jpg'), {
        lastModified: moment().unix(),
        type: 'image/jpg',
      });

      onConfirm(image);
      setIsLoading(false);
      setIsOpen(false);
    });
  };

  const zoom = (zoom: number) => {
    if (isPreviewLoading) {
      return;
    }
    if (zoom > 0) setScale(Math.min(ZOOM_MAX, scale + zoom));
    else setScale(Math.max(ZOOM_MIN, scale + zoom));
  };
  const rotate = (angle: number) => {
    if (isPreviewLoading) {
      return;
    }

    let newAngle = rotation + angle;
    if (newAngle < 0) {
      newAngle += 360; // Canvas rotation
    }
    newAngle %= 360;

    setRotation(newAngle);
  };

  useEffect(() => {
    if (!previewImage || !previewCanvasRef || !previewCanvasRef.current) {
      return;
    }

    const displayPreview = async () => {
      setIsPreviewLoading(true);

      const canvas = previewCanvasRef.current!;
      switch (rotation) {
        case 0:
        case 180:
          canvas.height = previewImage.height;
          canvas.width = previewImage.width;
          break;
        case 90:
        case 270:
          canvas.height = previewImage.width;
          canvas.width = previewImage.height;
          break;
      }

      const ctx = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.drawImage(previewImage, -previewImage.width / 2, -previewImage.height / 2);

      setIsPreviewLoading(false);
    };
    displayPreview();
  }, [previewImage, rotation, scale]);

  useEffect(() => {
    if (!file) {
      return;
    }

    const loadPdf = async () => {
      const buffer = await file.arrayBuffer();

      const pdf = await getDocument(new Uint8Array(buffer)).promise;

      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale, rotation });

      const canvas = previewCanvasRef.current!;
      const ctx = canvas.getContext('2d')!;
      const renderContext = { canvasContext: ctx, viewport };
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render(renderContext!).promise;

      const img = new Image();
      img.src = canvas.toDataURL('image/jpg');
      img.onload = () => {
        setPreviewImage(img);
      };
    };

    const loadImg = () => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result?.toString() || '';
        img.onload = () => {
          setPreviewImage(img);
        };
      };
    };

    if (file.type === 'application/pdf') {
      loadPdf();
    } else {
      loadImg();
    }
  }, [file]);

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      onClosed={() => setFile && setFile(undefined)}
      unmountOnClose>
      <ModalHeader toggle={toggle}>{translate('preview')}</ModalHeader>
      <ModalBody
        className="text-center p-0"
        style={{
          height: 'calc(100vh - 250px)',
          overflow: 'auto',
          borderBottom: '1px solid #dee2e6',
        }}>
        <canvas className="w-100" ref={previewCanvasRef} />
      </ModalBody>

      <ModalBody className="text-center p-2">
        <button
          className="btn btn-outline-secondary btn-sm me-2 d-none"
          disabled={scale >= ZOOM_MAX}
          onClick={() => zoom(0.25)}>
          <FontAwesomeIcon icon={faSearchPlus} />
        </button>
        <button
          className="btn btn-outline-secondary btn-sm me-4 d-none"
          disabled={scale <= ZOOM_MIN}
          onClick={() => zoom(-0.25)}>
          <FontAwesomeIcon icon={faSearchMinus} />
        </button>

        <button className="btn btn-outline-secondary btn-sm me-2" onClick={() => rotate(-90)}>
          <FontAwesomeIcon icon={faUndo} />
        </button>
        <button className="btn btn-outline-secondary btn-sm" onClick={() => rotate(90)}>
          <FontAwesomeIcon icon={faRedo} />
        </button>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-primary" onClick={onSubmit} disabled={isLoading}>
          {translate('confirm')}
        </button>
      </ModalFooter>
    </Modal>
  );
};
