import { validateUsername, UsernameValidationRules } from './validator';
import { Input } from 'components/custom/input';
import { ValidationRule } from 'components/custom/ValidationRule';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Collapse } from 'reactstrap';

const initialValidationState: UsernameValidationRules = {
  lengthCorrect: false,
  startEndWithAlphanum: false,
  charactersCorrect: false,
};

interface UsernameProps {
  username: string;
  setUsername: (username: string) => void;
  errors: ANY_OBJECT;
  setErrors: Dispatch<SetStateAction<ANY_OBJECT>>;
}

export const Username = ({ username, setUsername, errors, setErrors }: UsernameProps) => {
  const { translate } = useTranslate();

  const [usernameRules, setUsernameRules] =
    useState<UsernameValidationRules>(initialValidationState);
  const [isUsernameCollapseOpen, setIsUsernameCollapseOpen] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const result = validateUsername(e.target.value);
    setUsernameRules(result);
    setUsername(e.target.value);
    if (result.overall) {
      setErrors({ ...errors, username: null });
    }
  };

  const onBlur = () => () => {
    const isAllValid = Object.values(usernameRules).every((rule) => rule === true);
    if (!isAllValid) {
      return;
    }
    setIsUsernameCollapseOpen(false);
  };

  return (
    <>
      <Input
        isRequired
        type="text"
        onFocus={() => setIsUsernameCollapseOpen(true)}
        onBlur={onBlur()}
        legend={translate('username').toUpperCase()}
        name="username"
        value={username}
        onChange={onChange}
        placeholder={translate('enter_username')}
        error={translate(...(errors?.username ?? ''))}
      />

      <Collapse isOpen={isUsernameCollapseOpen}>
        <div className="pt-1">
          <div className="rule">
            <span>{translate('allow_characters')}</span>
          </div>
          <ValidationRule
            isValid={usernameRules.lengthCorrect}
            rule={translate('contains_8_20_characters')}
          />
          <ValidationRule
            isValid={usernameRules.startEndWithAlphanum}
            rule={translate('start_end_with_alphanum')}
          />
          <ValidationRule
            isValid={usernameRules.charactersCorrect}
            rule={translate('contains_correct_characters')}
          />
        </div>
      </Collapse>
    </>
  );
};
