import axios, { ContentType, defaultRequestHandler } from '../common/Fetcher';
import { AnyResponse, getToken } from './../common/Fetcher';
import { ApplyCredentialBody, ApplyCredentialByTypeBody, UpdateCredentialBody } from './interface';

//https://dev2.yedpay.com/document/admin/updateCredential
export const updateCredential = (
  credential_id: string,
  body: UpdateCredentialBody,
): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.put(`/credentials/${credential_id}`, body, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const applyCredential = (
  company_id: string,
  body: ApplyCredentialBody,
): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.post(`/credentials/apply/${company_id}`, body, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const applyCredentialByType = (
  company_id: string,
  body: ApplyCredentialByTypeBody,
): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/credentials`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );
