import Logo from 'assets/img/registrationForm/uploadDocumentIcon.png';
import { FormHeader } from 'components/custom/form';
import { SelectButton } from 'components/custom/SelectButton';
import {
  findGatewayGroupByCode,
  GatewayGroup,
  HSBC_FPS_OFFLINE_GATEWAY_CODE,
  PAY_PLUS_OFFLINE_GATEWAY,
} from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { useSwal } from 'helpers/sweetalert';
import { StepProps } from '../interface';

export const ApplyGateways = forwardRef(
  ({ form, setForm, availableGatewayList }: StepProps, ref) => {
    const [Swal] = useSwal();
    const { translate } = useTranslate();
    const {
      auth: {
        user: { stores },
      },
    } = useSelector((state: GlobalTypes.RootState) => state);
    const type = stores[0].type;
    const isOnlineStore = type === 2;

    const getGatewayGroups = () => {
      const gatewayGroups: Array<GatewayGroup> = [];

      availableGatewayList.forEach((gateway) => {
        const subtypes = gateway.subtype
          .filter(({ is_online }) => is_online === isOnlineStore)
          .filter(({ gateway_code }) => !PAY_PLUS_OFFLINE_GATEWAY.includes(gateway_code));
        if (subtypes.length === 0) {
          return;
        }

        const group = findGatewayGroupByCode(subtypes[0].gateway_code);
        if (group && !gatewayGroups.includes(group)) {
          gatewayGroups.push(group);

          if (subtypes[0].gateway_code === '7_1') {
            gatewayGroups.push(findGatewayGroupByCode('7_5') as GatewayGroup);
          }

          return gatewayGroups;
        }
      });

      return gatewayGroups;
    };

    const isSelectedGatewayGroup = (gatewayGroup: GatewayGroup) => {
      const selectedCodes = form.gateway_codes.filter((value) => gatewayGroup.code.includes(value));
      return selectedCodes.length > 0;
    };

    const onClick = (gatewayGroup: GatewayGroup) => {
      const codes = gatewayGroup.code;

      let gateway_codes = [];
      if (isSelectedGatewayGroup(gatewayGroup)) {
        gateway_codes = form.gateway_codes.filter((x) => !codes.includes(x));
      } else {
        gateway_codes = form.gateway_codes.concat(codes);
      }

      setForm({
        ...form,
        gateway_codes,
      });
    };

    useImperativeHandle(ref, () => ({
      validate: () => {
        if (!form.gateway_codes.length) {
          Swal.fire({
            title: translate('gateway'),
            text: translate('choose_and_add_gateway'),
            icon: 'error',
          });
          return false;
        }
        return true;
      },
    }));
    return (
      <>
        <FormHeader title={translate('applicable_gateways')} src={Logo} />
        {getGatewayGroups().map((gateway, index) => {
          return (
            <SelectButton
              thumbnails={gateway.thumbnail}
              title={gateway.name}
              key={gateway.name}
              isSelected={isSelectedGatewayGroup(gateway)}
              onClick={() => onClick(gateway)}
            />
          );
        })}
      </>
    );
  },
);
