import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCompanyDocumentsList, getDocument } from 'actions/CompanyActions';
import { DISABLE_SHARED_TYPE, reactSelectDocumentTypeOptions } from 'constants/documents';
import { isOwner, isSuperowner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { documentProps } from 'redux/reducers/dataProps';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { deleteDocument as apiDeleteDocument } from 'services/API/Document';
import { useSwal } from 'helpers/sweetalert';
import './DocumentField.scss';
import { UpdateDocumentModal } from './UpdateDocumentModal';
import { ANY_OBJECT, translateLabel } from 'constants/format';
import { Pagination } from 'components/custom/pagination';
import { InfoHeader } from 'components/custom/info_header';
import SideFilter from 'components/custom/sideFilter';
import { Input } from 'components/custom/input';
import { EmptyResult } from 'components/custom/table/emptyResult';

const initialFilterState: ANY_OBJECT = {
  type: '',
};

export const DocumentField: FC = () => {
  const {
    auth: { user, roles },
    data: {
      documents: { documents: documentsData, documentsPagination },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [fetchingDocuments, setFetchingDocuments] = useState(true);

  const [filter, setFilter] = useState(initialFilterState);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedDoc, setSelectedDoc] = useState<documentProps>();
  const [selectedUrl, setSelectedUrl] = useState<string>('');
  const [selectedDocName, setSelectedDocName] = useState<string>('');
  const [isDocModalOpen, setIsDocModalOpen] = useState(false);
  const [imgDocs, setImgDocs] = useState<Record<string, Record<string, any>>>({});
  const [isImgLoadings, setisImgLoadings] = useState<Record<string, boolean>>({});
  const [isUploadDocModalOpen, setIsUploadDocModalOpen] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const { type } = filter;

  const filterToParams = () => {
    const data: { [key: string]: string } = {
      type,
    };

    const names = Object.keys(data);
    const values = Object.values(data);

    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        delete data[names[index]];
      }
    });

    Object.assign(data, {
      locked_at: '[null]',
      limit: 10,
      page: currentPage,
      sort: '-created_at',
    });

    return data;
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
  };

  const onFilterSelectChange = (name: string) => (val: string) => {
    setFilter({
      ...filter,
      [name]: val,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  const getInitialData = async () => {
    setFetchingDocuments(true);
    const params = filterToParams();
    const res = await dispatch(getCompanyDocumentsList(user?.company_id, params));
    setFetchingDocuments(false);
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('documents'),
        text: res.message || 'Oops',
      });
    }
  };

  const isImgFormat = (fileType: string) => /(jpeg|jpg|png|gif|bmp)/.test(fileType);

  const showDocument = async (e: any, item: documentProps) => {
    const fileType = item.name.split('.').pop() ?? '';

    if (['other', 'logo', 'picture'].indexOf(item.type) == -1 || !isImgFormat(fileType)) {
      return false;
    }

    const document_id = item.id as string;
    if (isImgLoadings[document_id]) {
      return false;
    }

    Object.entries(imgDocs).forEach((imgDoc: Record<string, any>) => {
      imgDocs[imgDoc[0]].display = 'none';
    });
    setImgDocs({ ...imgDocs });

    if (imgDocs[document_id]?.src) {
      imgDocs[document_id].display = 'block';
      setImgDocs({ ...imgDocs });
      return false;
    }

    isImgLoadings[document_id] = true;
    setisImgLoadings({ ...isImgLoadings });
    const res = await dispatch(getDocument(document_id, false));
    isImgLoadings[document_id] = false;
    setisImgLoadings({ ...isImgLoadings });
    if (!res.success) {
      return false;
    }

    const triggerEle = e.target;
    const currImgEle = triggerEle.parentNode.getElementsByClassName('hover-img-container')[0];

    if (!imgDocs[document_id]) {
      imgDocs[document_id] = {};
      imgDocs[document_id].src = res.data;
      imgDocs[document_id].display = 'block';

      setImgDocs({ ...imgDocs });
    }

    if (currImgEle) currImgEle.style.display = 'block';
  };

  const openDocument = async (item: documentProps) => {
    const document_id = item.id;
    const fileType = item.name.split('.').pop() ?? '';
    const res = await dispatch(getDocument(document_id));
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('documents'),
        text: res.message || 'Oops',
      });
    }

    if (!isImgFormat(fileType)) {
      window.open(res.data, '_blank');
    } else {
      setSelectedDocName(item.name);
      setSelectedUrl(res.data);
      setIsDocModalOpen(true);
    }
  };

  const confirmEditDocument = (document?: documentProps) => {
    setSelectedDoc(document);
    setIsUploadDocModalOpen(true);
  };

  const deleteDocument = async (document_id: string) => {
    const result = await Swal.fire({
      title: translate('are_you_sure_delete_document'),
      text: translate('you_wont_be_able_to_revert_this'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    });
    if (!result.value) {
      return;
    }
    setIsDeleting(true);
    const res = await apiDeleteDocument(document_id);
    if (!res.success) {
      Swal.fire({
        title: 'Delete Document',
        text: res?.message || 'Oops',
        icon: 'error',
      });
      setIsDeleting(false);
      return;
    }
    await getInitialData();
    setIsDeleting(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader
            src="company-info"
            name="documents"
            // caption='Manage Your documents'
          />
          <div className="bg-white round-bottom-lg">
            <SideFilter
              isLoading={fetchingDocuments}
              resetFilter={resetFilter}
              onFilter={onFilterSearch}
              defaultCollapsed={true}>
              <Input
                isRequired
                type="react-select"
                value={type}
                name="document_type"
                onChange={onFilterSelectChange('type')}
                defaultLabel={translate('all_types')}
                placeholder={translate('document_type')}
                legend={translate('document_type').toUpperCase()}
                options={translateLabel(reactSelectDocumentTypeOptions, translate, true)}
              />
            </SideFilter>
            <div className="bg-white p-4 mt-1 round-bottom-lg">
              {!roles.isAccountant && !isSuperowner(user.role) && (
                <div className="document mt-2 mb-3 mx-3 dash-border">
                  <button
                    className="btn btn-outline-secondary"
                    style={{ margin: 'auto' }}
                    onClick={() => confirmEditDocument()}>
                    {translate('upload_file')}
                  </button>
                </div>
              )}
              {!fetchingDocuments &&
                documentsData
                  .filter((doc) => doc.locked_at === '')
                  .map((doc, index) => {
                    return (
                      <div
                        className="document mt-2 mb-3 mx-3 row"
                        key={index}
                        style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          <span
                            className="col-3 cursor-pointer"
                            style={{
                              wordBreak: 'break-all',
                            }}
                            onClick={() => openDocument(doc)}
                            onMouseOver={(e) => showDocument(e, doc)}>
                            {doc.name}
                          </span>
                          <div className="doc-info col-md-6 row">
                            <span className="col-md-6 text-center">{translate(doc.type)}</span>
                            <span className="col-md-6 text-center">
                              {doc.shared ? translate('shared') : translate('not_shared')}
                            </span>
                          </div>
                          {isOwner(user.role) && (
                            <>
                              <div className="col-1">
                                <button
                                  className={'btn btn-sm btn-secondary'}
                                  type="button"
                                  disabled={DISABLE_SHARED_TYPE.includes(doc.document_type)}
                                  onClick={() => confirmEditDocument(doc)}>
                                  <FontAwesomeIcon icon={faEdit} color="#ffffff" />
                                </button>
                              </div>
                              <div className="col-1">
                                <button
                                  className="btn btn-danger btn-sm"
                                  type="button"
                                  disabled={isDeleting}
                                  onClick={() => deleteDocument(doc.id)}>
                                  <FontAwesomeIcon icon={faTrash} color="#ffffff" />
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                        {isImgLoadings[doc.id] && (
                          <div style={{ textAlign: 'center', width: '100%', margin: '10px' }}>
                            <Spinner size="md" />
                          </div>
                        )}
                        <div
                          className="hover-img-container"
                          style={{
                            textAlign: 'center',
                            width: '100%',
                            margin: '10px',
                            display: imgDocs[doc.id] ? imgDocs[doc.id].display : 'none',
                          }}>
                          <img
                            src={imgDocs[doc.id]?.src}
                            style={{
                              border: '1px solid black',
                              borderRadius: '5px',
                              height: '100%',
                              maxHeight: '500px',
                              maxWidth: '100%',
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              {documentsPagination && (
                <>
                  {documentsPagination.total < 1 && <EmptyResult text="no_data" />}
                  <div className="table-responsive admin mt-0">
                    <Pagination pagination={documentsPagination} setCurrentPage={setCurrentPage} />
                    <div className="mb-5 mb-md-0"></div>
                  </div>
                </>
              )}
              <DocumentsModal
                isModalOpen={isDocModalOpen}
                setIsModalOpen={setIsDocModalOpen}
                url={selectedUrl}
                docName={selectedDocName}
              />
              <UpdateDocumentModal
                isModalOpen={isUploadDocModalOpen}
                setIsModalOpen={setIsUploadDocModalOpen}
                editing_document={selectedDoc}
                reload={getInitialData}
              />
            </div>
          </div>
        </div>
        <div className="mb-5 mb-md-0"></div>
      </div>
    </>
  );
};

interface DocumentModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  url: string;
  docName: string;
}
const DocumentsModal = ({ isModalOpen, setIsModalOpen, url, docName }: DocumentModalProps) => {
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <Modal isOpen={isModalOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{docName}</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <img src={url} alt="" className="img-fluid" />
        </div>
      </ModalBody>
    </Modal>
  );
};
