import { FC, ReactNode } from 'react';

interface InputWrapperProps {
  children: ReactNode;
  legend?: string;
  showCalender: boolean;
  type: string;
  prefix?: string;
  suffix?: JSX.Element | string;
  error?: string;
  isRequired?: boolean;
}

export const InputWrapper: FC<InputWrapperProps> = ({
  children,
  legend,
  showCalender,
  error,
  type,
  prefix,
  suffix: Suffix,
  isRequired,
}: InputWrapperProps) => {
  return (
    <>
      {legend != undefined && (
        <fieldset
          className={`my-4 input-fieldset ${error ? 'error' : ''} ${
            showCalender ? 'input-fieldset-active' : ''
          }`}>
          <legend className={legend === '' ? 'd-none' : ''}>
            {legend} {isRequired === true && <span className="text-danger ms-1 fx-16">*</span>}
          </legend>
          <div
            className={
              ['react-select', 'react-select-async', 'select-text', 'date', 'password'].includes(
                type,
              )
                ? ''
                : 'd-flex'
            }>
            {prefix && <span className="input-prefix">{prefix}</span>}
            {children}
            {Suffix &&
              (typeof Suffix === 'string' ? (
                <span className="input-prefix">{Suffix}</span>
              ) : (
                Suffix
              ))}
          </div>
        </fieldset>
      )}

      {legend !== '' && !legend && (
        <>
          {prefix && <span className="input-prefix">{prefix}</span>}
          {children}
          {Suffix &&
            (typeof Suffix === 'string' ? <span className="input-prefix">{Suffix}</span> : Suffix)}
        </>
      )}

      {error && <span className="help-block text-start error-text fx-14">{error}</span>}
    </>
  );
};
