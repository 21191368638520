import { getGatewayAnalysisAction, getUserAnalysisAction } from 'actions/SalesAnalysisActions';
import { Input } from 'components/custom/input';
import { ReportSection } from 'components/custom/ReportSection';
import SideFilter from 'components/custom/sideFilter';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import { TIME_ZONE_FORMAT } from 'constants/format';
import {
  agent,
  convertSaleAnalysisGroupToOptions,
  gatewayProvider,
  isAgent,
  isGatewayProvider,
  SALES_ANALYSIS_GROUP,
  superOwner,
} from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { getNDaysEarlier } from 'helpers/functions/getDays';
import moment from 'moment';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadOptions } from 'react-select-async-paginate';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { DefaultResponse } from 'services/API/common/Fetcher';
import { getCompanyList } from 'services/API/Company';
import { getGatewaySalesAnalysis, getUserSalesAnalysis } from 'services/API/Sales_Analysis';
import { useSwal } from 'helpers/sweetalert';
import { SummaryTable } from './SummaryTable';

const initialState = {
  start_date: new Date(getNDaysEarlier(7)),
  end_date: new Date(),
  group_by: '',
  order_by: '',
  limit: 10,
  export: '',
  gateway_id: '',
  full_list: 0,
};

const valueToLabel = (value: any) => {
  if (!value) {
    return { label: '', value: null };
  }
  value.toString = () => value.id;
  return {
    label: value.name,
    value: value,
  };
};

export const SalesAnalysis = () => {
  const {
    auth: { user },
    data: {
      analysis: {
        sale: { data, pagination, group_by: saleGroupBy },
      },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const dispatch = useDispatch<FunctionDispatch>();
  const reportSectionRef = useRef<any>(null);
  const [Swal] = useSwal();
  const { translate, language } = useTranslate();

  const [filter, setFilter] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [summaryData, setSummaryData] = useState<any>();
  const [isExportDropdownLoading, setIsExportDropdownLoading] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState<Array<any>>([]);

  const {
    start_date,
    end_date,
    group_by,
    order_by,
    limit,
    full_list,
    gateway_id,
    export: exportType,
  } = filter;

  const searchCompany: LoadOptions<any, any> = async (keyword, loadedOptions, additional) => {
    const selectedCompanyIdArr = selectedCompanies.map((c) => c.id);
    const page = additional?.page || 1;
    const result = await getCompanyList({ page, keyword: keyword });
    const hasMore = !!result.meta.pagination?.links.next ?? false;
    const companies = result.data.filter((c) => !selectedCompanyIdArr.includes(c.id));

    return {
      options: companies.map(valueToLabel),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getGatewayOptions = () => {
    if (!user.gateways) {
      return [];
    }
    const allGatewayOption = [
      {
        label: translate('all_gateways'),
        value: '',
      },
    ];

    const userGatewayOptions = user.gateways.map((gateway) => ({
      label: `${translate('gateway')}: ${translate(gateway.name)}`,
      value: gateway.gid,
    }));

    return [...allGatewayOption, ...userGatewayOptions];
  };

  const onFilter = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };
  const resetFilter = () => {
    setFilter({
      ...initialState,
      group_by: SALES_ANALYSIS_GROUP[user.role]?.[0],
    });
    setSelectedCompanies([]);
  };
  const onFilterDateChange = (date: Date, name: string) => {
    setFilter({
      ...filter,
      [name]: date,
    });
  };
  const onFilterSelectChange = (name: string) => (value: string) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const getParams = () => {
    return Object.assign(
      {
        start_date: moment(start_date).format(TIME_ZONE_FORMAT),
        end_date: moment(end_date).endOf('day').format(TIME_ZONE_FORMAT),
        group_by: group_by || SALES_ANALYSIS_GROUP[user.role]?.[0],
      },
      order_by && { order_by },
      limit && { limit },
      exportType && { export: exportType },
      full_list && { full_list },
      gateway_id && { gateway_id },
      selectedCompanies.length && { company_id: selectedCompanies.map((c) => c.id).join(',') },
      currentPage && { page: currentPage },
    );
  };

  const getInitialData = async () => {
    const data = getParams();

    let res;
    switch (user.role) {
      case gatewayProvider: {
        let id = user.gateways?.[0]?.id;
        if (data.gateway_id) {
          id = user.gateways?.find(
            (gateway) => parseInt(gateway.gid) === parseInt(data.gateway_id),
          )?.id;
        }
        res = await dispatch(getGatewayAnalysisAction(`${id}`, data));
        break;
      }
      case superOwner:
      case agent:
        res = await dispatch(getUserAnalysisAction(user.id, data));
        break;
      default:
        throw new Error('No Analysis data provided for this role');
    }
    setSummaryData({
      total_paid: res.total_paid,
      total_paid_amount: res.total_paid_amount,
      total_charge: res.total_charge,
      total_refund: res.total_refund,
      total_refund_amount: res.total_refund_amount,
      total_void: res.total_void,
      total_void_amount: res.total_void_amount,
      total_cancel: res.total_cancel,
      total_transactions: res.total_transactions,
      average_ticket: res.average_ticket,
    });
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('analysis'),
        text: res.message || 'Oops',
      });
    }
  };

  const exportExcel = async () => {
    setIsExportDropdownLoading(true);
    const data = Object.assign({ ...getParams() }, { export: 'xlsx' });
    data.asyncExport = true;

    let res: DefaultResponse = {} as DefaultResponse;
    switch (user.role) {
      case gatewayProvider: {
        const id = user.gateways?.[0]?.id;
        res = await getGatewaySalesAnalysis(`${id}`, data);
        break;
      }
      case superOwner:
      case agent:
        res = await getUserSalesAnalysis(user.id, data);
        break;
      default:
        throw new Error('No Analysis data provided for this role');
    }

    await reportSectionRef?.current?.loadReports();
    setIsExportDropdownLoading(false);
    if (!res.success) {
      return;
    }

    window.open(res?.file, '_self');
  };

  const detailHeader = () => {
    let id, name;

    switch (saleGroupBy) {
      case 'mcc':
        id = translate('mcc');
        name = translate('mcc_description');
        break;
      case 'store':
        id = translate('store_id');
        name = translate('store_name');
        break;
      case 'gateway':
        id = translate('gateway_name');
        name = translate('gateway_name');
        break;
      default:
      case 'company':
        id = translate('company_id');
        name = translate('company_name');
        break;
    }

    return (
      <tr>
        <th className="border-end border-top-0">{id}</th>
        <th className="border-end border-top-0">{name}</th>
        <th className="border-end border-top-0">{translate('total_paid')}</th>
        <th className="border-end border-top-0">{translate('total_paid_amount')}</th>
        <th className="border-end border-top-0">{translate('total_charge')}</th>
        <th className="border-end border-top-0">{translate('total_refund')}</th>
        <th className="border-end border-top-0">{translate('total_refund_amount')}</th>
        <th className="border-end border-top-0">{translate('total_cancel')}</th>
        <th className="border-end border-top-0">{translate('total_transactions')}</th>
        <th className="border-top-0">{translate('average_ticket')}</th>
      </tr>
    );
  };

  const detailBody = () => {
    let id_field: string, name_field: string;

    switch (saleGroupBy) {
      case 'mcc':
        id_field = 'mcc';
        name_field = language === 'zh' ? 'description_zh' : 'description';
        break;
      case 'company':
        id_field = 'company_id';
        name_field = 'company_name';
        break;
      case 'store':
        id_field = 'store_id';
        name_field = 'store_name';
        break;
      case 'gateway':
        id_field = 'gateway_id';
        name_field = 'gateway_name';
        break;
      default:
    }

    return data
      ? data.map((sale, index) => {
          const {
            total_paid,
            total_paid_amount,
            total_charge,
            total_refund,
            total_refund_amount,
            total_cancel,
            total_transactions,
            average_ticket,
          } = sale;

          return (
            <tr key={index} className="cursor-pointer" onClick={() => sale}>
              <td>{sale[id_field]}</td>
              <td>{sale[name_field]}</td>
              <td>{total_paid}</td>
              <td>{total_paid_amount}</td>
              <td>{total_charge}</td>
              <td>{total_refund}</td>
              <td>{total_refund_amount}</td>
              <td>{total_cancel}</td>
              <td>{total_transactions}</td>
              <td>{average_ticket}</td>
            </tr>
          );
        })
      : [];
  };

  useEffect(() => {
    setFilter({
      ...filter,
      group_by: group_by ? group_by : SALES_ANALYSIS_GROUP[user.role]?.[0],
    });
    getInitialData();
    //eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <TopHeader title={translate('analysis')}>
        <button
          className="header-button btn btn-success"
          disabled={isExportDropdownLoading}
          onClick={() => exportExcel()}>
          {translate('generate_excel_report')}
        </button>
      </TopHeader>
      <div id="main-content" className="hide-scrollbar">
        <SummaryTable summaryData={summaryData} />
        <div className="mt-4"></div>
        <CustomTable
          totalColumn={10}
          renderHeading={detailHeader}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
          renderData={detailBody}
          allowHover
        />
      </div>
      <div id="side-content" className="hide-scrollbar">
        <SideFilter onFilter={onFilter} resetFilter={resetFilter}>
          <Input
            type="date"
            value={start_date}
            legend={translate('from').toUpperCase()}
            name="start_date"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="date"
            value={end_date}
            legend={translate('to').toUpperCase()}
            name="end_date"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="react-select"
            legend={translate('group_by').toUpperCase()}
            options={convertSaleAnalysisGroupToOptions(SALES_ANALYSIS_GROUP[user.role], translate)}
            value={group_by}
            name="group_by"
            onChange={onFilterSelectChange('group_by')}
            menuPortalTarget={document.body}
          />
          {user.gateways?.length && (
            <Input
              type="react-select"
              legend={translate('gateway').toUpperCase()}
              options={getGatewayOptions()}
              value={gateway_id}
              name="gateway_id"
              onChange={onFilterSelectChange('gateway_id')}
              menuPortalTarget={document.body}
            />
          )}
          {(isGatewayProvider(user.role) || isAgent(user.role)) && (
            <Input
              type="react-select-async"
              value={selectedCompanies.map(valueToLabel)}
              loadOptions={searchCompany}
              onChange={setSelectedCompanies}
              legend={translate('company').toUpperCase()}
              name="company_id"
              placeholder={translate('all_companies')}
              isMulti
              menuPortalTarget={document.body}
            />
          )}
        </SideFilter>
        <ReportSection ref={reportSectionRef} type="sales-analysis" />
      </div>
    </>
  );
};
