import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { useTranslate } from 'context/TranslateContext';
import { RouteComponentProps } from 'react-router-dom';
import { DocumentField } from './DocumentField';

export interface DocumentsProps {
  location: RouteComponentProps;
}

export const Documents = ({ location }: DocumentsProps) => {
  const { translate } = useTranslate();
  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location as any} type="admin" />
      <DocumentField />
    </>
  );
};
