import axios, {
  ContentType,
  defaultRequestHandler,
  DefaultResponse,
  getToken,
  params,
} from '../common/Fetcher';
import { CreateStoreTermInterface, StoreTermProps } from './interface';

export const createStoreTerm = (
  store_id: string,
  body: StoreTermProps,
): Promise<CreateStoreTermInterface> =>
  defaultRequestHandler(
    axios.post(`/stores/${store_id}/terms`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

export const updateStoreTerm = (id: string, data = {}): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.put(`/store-terms/${id}`, data, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );
