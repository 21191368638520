import { faCopy, faTrash, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import * as API from 'services/API/Keys';
import { ApiKeyProps, CreateApiKeyProps } from 'services/API/Keys/interface';
import { useSwal } from 'helpers/sweetalert';
import { validate } from './validator';
import { PaginationProps } from 'services/API/common/Fetcher';
import { Pagination } from 'components/custom/pagination';
import { EmptyResult } from 'components/custom/table/emptyResult';
import { StoreSelect } from 'components/custom/ReactSelectAsync/Select/StoreSelect';

interface ApiKeyComponentProps {
  apiList: ApiKeyProps[];
  pagination: PaginationProps | null;
  getApiList: () => Promise<void>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  allStores: Array<SelectOptionProps>;
  sortTable: (column: string) => void;
  sorting: { column: string; direction: string };
  isLoading: boolean;
}

export const ApiKey = ({
  apiList,
  pagination,
  getApiList,
  setCurrentPage,
  allStores,
  sortTable,
  sorting,
  isLoading,
}: ApiKeyComponentProps) => {
  const { translate } = useTranslate();

  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const [Swal] = useSwal();

  const [showCreateApiKey, setShowCreateApiKey] = useState(false);

  const [apiKeyName, setApiKeyName] = useState<string>('');
  const [store, setStore] = useState<any>(null);

  const [generatedApiKey, setGeneratedApiKey] = useState('');

  const [copiedMessageCountdown, setShowCopiedMessageCountdown] = useState(0);

  const [listIdStartFrom, setListIdStartFrom] = useState<number>(1);

  const [submitting, setSubmitting] = useState(false);
  const [apiId, setApiId] = useState('');

  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const sortingLable = (name: string) => {
    if (sorting.column !== name) {
      return (
        <span className="ms-2">
          <FontAwesomeIcon icon={faSort} />
        </span>
      );
    }

    if (sorting.direction === 'asc') {
      return (
        <span className="ms-2">
          <FontAwesomeIcon icon={faSortUp} />
        </span>
      );
    } else if (sorting.direction === 'desc') {
      return (
        <span className="ms-2">
          <FontAwesomeIcon icon={faSortDown} />
        </span>
      );
    }
  };

  const copyToClipboard = () => {
    const key = generatedApiKey;
    navigator.clipboard.writeText(key).then(() => {
      setShowCopiedMessageCountdown(5);
    });
  };

  const createNewApiKey = async () => {
    const body: CreateApiKeyProps = {
      store_id: store?.id,
      name: apiKeyName,
    };

    const { isValid, errors } = validate(body);
    setErrors(errors);

    if (!isValid) {
      return;
    }

    if (user?.company_id) {
      setSubmitting(true);
      const res = await API.createApiKey(user.company_id, body);
      setSubmitting(false);
      if (!res.success) {
        await Swal.fire({
          icon: 'error',
          title: translate('api_keys'),
          text: res?.message || 'Oops',
        });
        return;
      }
      const ApiObject = res.data;
      setGeneratedApiKey(ApiObject.api_key);

      await getApiList();
    }
  };

  const deleteApiKey = async (id: string) => {
    setApiId(id);
    setSubmitting(true);
    const res = await API.deleteApiKey(id);
    if (!res.success) {
      Swal.fire({
        title: translate('api_keys'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    await getApiList();
    setSubmitting(false);
    setApiId('');
  };

  const toggleShowCreateApiKey = () => {
    setShowCreateApiKey(!showCreateApiKey);
  };

  const onStoreChange = (val: string) => {
    setStore(val);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (copiedMessageCountdown > 0) {
      timeout = setTimeout(() => {
        setShowCopiedMessageCountdown(copiedMessageCountdown - 1);
      }, 1000);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [copiedMessageCountdown]);

  return (
    <div className="mt-4 px-3">
      <div className="row g-0 align-items-center">
        <div className="col">
          <h4 className="colfax-regular text-secondary">{translate('api_keys')}</h4>
          {generatedApiKey !== '' && (
            <h6 className="text-danger m-0 fx-14">{translate('new_api_key_notice')}</h6>
          )}
        </div>
        <div className="col-auto">
          {!showCreateApiKey && (
            <button className="btn btn-primary" type="button" onClick={toggleShowCreateApiKey}>
              {translate('add')}
            </button>
          )}
        </div>
      </div>
      <Collapse isOpen={showCreateApiKey}>
        {generatedApiKey === '' ? (
          <>
            <h6 className="colfax-regular text-secondary m-0">{translate('new_api_key')}</h6>
            <div className="row align-items-center g-0">
              <div className="col-lg-6">
                <Input
                  isRequired
                  name="apiKeyName"
                  value={apiKeyName}
                  onChange={(e) => setApiKeyName(e.target?.value)}
                  legend={`${translate('api_keys')} ${translate('name')}`}
                  type="text"
                  placeholder={translate('enter_api_key_name')}
                  error={translate(...(errors.name ?? ''))}
                />
              </div>
              <div className="col-lg-6">
                <StoreSelect
                  isRequired
                  showStatus
                  legend={translate('store').toUpperCase()}
                  setStore={onStoreChange}
                  store={store}
                  error={errors.store_id}
                />
              </div>
            </div>
            <div className="row justify-content-end g-0 mt-2">
              <button
                className="btn btn-success"
                type="button"
                onClick={createNewApiKey}
                disabled={submitting}>
                {submitting ? <Spinner size="sm" /> : <span>{translate('add')}</span>}
              </button>
            </div>
          </>
        ) : (
          <>
            <Input
              type="text"
              disabled
              name=""
              value={generatedApiKey}
              legend="API KEY"
              suffix={
                copiedMessageCountdown > 0 ? (
                  <p className="m-0 fx-16 colfax-regular text-primary">
                    {translate('copied_at_your_clipboard')}
                  </p>
                ) : (
                  <button className="btn" type="button" onClick={copyToClipboard}>
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                )
              }
            />
          </>
        )}
      </Collapse>
      <div className="mt-3">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col" className="border-top-0">
                #
              </th>
              <th
                scope="col"
                className="border-top-0"
                onClick={!isLoading ? () => sortTable('name') : undefined}
                style={{ cursor: 'pointer' }}>
                {translate('name')}
                {sortingLable('name')}
              </th>
              <th
                scope="col"
                className="border-top-0"
                onClick={!isLoading ? () => sortTable('stores.name') : undefined}
                style={{ cursor: 'pointer' }}>
                {translate('store')}
                {sortingLable('stores.name')}
              </th>
              <th scope="col" className="border-top-0">
                {translate('last_used')}
              </th>
              <th scope="col" className="border-top-0">
                {translate('actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              (apiList.length > 0 ? (
                apiList.map((api, index) => {
                  const store_name = allStores.find((store) => store.value === api.store_id)?.label;
                  return (
                    <tr key={api.id}>
                      <th scope="row" className="align-middle">
                        {listIdStartFrom + index}
                      </th>
                      <td className="align-middle">{api.name}</td>
                      <td className="align-middle">{store_name ?? '-'}</td>
                      <td className="align-middle">{api.last_used || '-'}</td>
                      <td className="align-middle">
                        <div className="px-1">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => deleteApiKey(api.id)}
                            disabled={submitting}>
                            {submitting && apiId === api.id ? (
                              <Spinner size="sm" />
                            ) : (
                              <FontAwesomeIcon icon={faTrash} color="#ffffff" />
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="">
                  <td colSpan={5} rowSpan={5} className="text-center">
                    <EmptyResult text="no_data" />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {pagination && (
          <div className="table-responsive admin mt-0">
            <Pagination
              pagination={pagination}
              setCurrentPage={setCurrentPage}
              setListIdStartFrom={setListIdStartFrom}
            />
            <div className="mb-5 mb-md-0"></div>
          </div>
        )}
        <div className="mb-5 mb-md-0"></div>
      </div>
    </div>
  );
};
