import swal2 from 'sweetalert2';
import { useTranslate } from 'context/TranslateContext';

const useSwal = () => {
  const { translate } = useTranslate();

  return [
    swal2.mixin({
      confirmButtonText: translate('sweetalert.confirm_button_text'),
      denyButtonText: translate('sweetalert.deny_button_text'),
      cancelButtonText: translate('sweetalert.cancel_button_text'),
      reverseButtons: true,
    }),
  ];
};

export { useSwal };
