import axios, { ContentType, defaultRequestHandler, getToken, params } from '../common/Fetcher';
import { CreateTokenBody, GetTokenListResponse } from './interface';

// https://dev2.yedpay.com/document/admin/tokenList
export const getPersonalTokenLists = (): Promise<GetTokenListResponse> =>
  defaultRequestHandler(
    axios.get(`/personal/token`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/createToken
export const createPersonalToken = (body: CreateTokenBody) =>
  defaultRequestHandler(
    axios.post(`/personal/token`, params(body), {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/deleteToken
export const deletePersonalToken = (token_id: string) =>
  defaultRequestHandler(
    axios.delete(`/personal/token/${token_id}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
