export const getArrayStatus = (array: Array<boolean>) => {
  const isAllPass = array.every(Boolean);
  const isAllFailed = array.every((val) => val === false);

  return {
    allPassed: isAllPass,
    partialPassed: !isAllPass && !isAllFailed,
    allFailed: isAllFailed,
  };
};
