import { getBankAccounts } from 'actions/CompanyActions';
import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { isOwner, isSuperowner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { deleteBankAccount } from 'services/API/Bank_Account';
import { BankAccountProps } from 'services/API/Company/interface';
import { useSwal } from 'helpers/sweetalert';
import { Card } from './Card';
import { BankModal } from './Modal';

export const BankAccounts = ({ location }: RouteComponentProps) => {
  const {
    auth: { user, roles },
    data: {
      accounts: { accounts },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [bankAccounts, setBankAccounts] = useState<Array<BankAccountProps>>([]);

  const [selectedBank, setSelectedBank] = useState<BankAccountProps | undefined>(undefined);

  const [modalOpen, setModalOpen] = useState(false);
  const isReadonly = isSuperowner(user.role);

  const setBank = (bank?: BankAccountProps) => {
    setSelectedBank(bank);
    setModalOpen(true);
  };

  const getInitialData = async () => {
    const res = await dispatch(getBankAccounts(user.company_id));
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('bank_account'),
        text: res.message || 'Oops',
      });
    }
  };

  const deleteBank = async (bank: BankAccountProps) => {
    const res = await deleteBankAccount(bank.id);
    if (!res.success) {
      await Swal.fire({
        title: translate('bank_account'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    await getInitialData();
    return;
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const lists = [...accounts].sort((a, b) =>
      a.is_default === true ? -1 : b.is_default === true ? 1 : 0,
    );
    setBankAccounts(lists);
    // eslint-disable-next-line
  }, [accounts]);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="bank-accounts" name="bank_accounts" />
          <div className="bg-white round-bottom-lg">
            <div className="card-container p-4">
              {!isReadonly && !bankAccounts.length && (
                <div className="custom-card place-holder">
                  <button className="btn btn-outline-secondary" onClick={() => setModalOpen(true)}>
                    {translate('create')}
                  </button>
                </div>
              )}
              {bankAccounts.map((bank, index) => {
                return (
                  <Card
                    bank={bank}
                    onSelect={() => setBank(bank)}
                    remove={() => deleteBank(bank)}
                    key={index}
                    showAction={isOwner(user.role)}
                  />
                );
              })}
            </div>
            <div className="mb-5 mb-md-0"></div>
          </div>
        </div>
      </div>

      <BankModal
        reloadBanks={getInitialData}
        setSelectedBank={setSelectedBank}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        bank={selectedBank}
        company_id={user.company_id}
      />
    </>
  );
};
