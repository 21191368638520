import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { getAllReport } from 'services/API/Report';
import { ExportReport } from 'services/API/Report/interface';

type ReturnUseGetData = [reports: Array<ExportReport>, loadReports: () => Promise<void>];

const useGetData = (type: string): ReturnUseGetData => {
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState<Array<ExportReport>>([]);
  const currentState = useRef({ reports });
  currentState.current = { reports };
  const loadDataInterval = useRef<NodeJS.Timeout>();
  const refreshInterval = 20000;

  const updateReportStatus = (report: ExportReport) => {
    report.isLoading = false;
    if (!report.file_name && !report.path) {
      report.isLoading = true;
    }

    let reportLoadFailed = false;
    const reportUpdateDiffMin = moment().diff(report.updated_at, 'm');
    const reportCreateDiffMin = moment().diff(report.created_at, 'm');
    if (report.status === 2) {
      reportLoadFailed = true;
    }

    if (!reportLoadFailed && report.isLoading) {
      if (reportUpdateDiffMin >= 30) {
        reportLoadFailed = true;
      }
    } else if (!reportLoadFailed && report.status === 0) {
      if (reportCreateDiffMin >= 60) {
        // set report to fail when status is not available and created at is more than 60 minutes
        reportLoadFailed = true;
      } else {
        report.isLoading = true;
      }
    }

    if (reportLoadFailed) {
      report.path = '';
      report.file_name = 'X';
      report.isLoading = false;
    }

    return report;
  };

  const getData = async (forceLoading = false) => {
    const reports = currentState.current.reports.map(updateReportStatus);
    setReports(reports);
    setIsLoading(true);

    setReports(
      (
        await getAllReport({
          type,
          limit: 5,
          'disable-debug': 1,
        })
      ).data?.map(updateReportStatus),
    );

    setIsLoading(false);
  };

  const loadReports = () => getData(true);

  useEffect(() => {
    loadReports();

    startRefreshInterval();

    return () => {
      if (loadDataInterval.current) {
        clearInterval(loadDataInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    const isEmptyResult = reports && reports.length === 0;
    if (reports && reports.length > 0) {
      const allReportsLoaded = reports.every((r) => !r.isLoading);
      clearInterval(loadDataInterval.current);
      if (!allReportsLoaded && !isEmptyResult) {
        startRefreshInterval();
      }
    } else {
      if (loadDataInterval.current) {
        clearInterval(loadDataInterval.current);
      }
    }
  }, [reports]);

  // function to start the interval
  const startRefreshInterval = () => {
    loadDataInterval.current = setInterval(getData, refreshInterval);
  };

  return [reports, loadReports];
};

export { useGetData };
