import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, ThunkResult } from 'redux/types';
import { getCompanyQrCodesList } from 'services/API/FixedCode';
import { GetCompanyQrCodeListResponse } from 'services/API/FixedCode/interface';
import { receiveData } from './DataAction';

export const getCompanyCodesList = (
  company_id: string,
  data = {},
): ThunkResult<Promise<GetCompanyQrCodeListResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await getCompanyQrCodesList(company_id, data);
    if (res.success) {
      dispatch(receiveData(res, 'fixedCodes'));
    }
    return res;
  });
