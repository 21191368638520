import { Input } from 'components/custom/input';
import { ANY_OBJECT, PROTOCOL_TYPE } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import { getAPI } from 'services/API/Checkout_Domain';
import { CheckoutDomainEditProps } from 'services/API/Checkout_Domain/interface';
import { useSwal } from 'helpers/sweetalert';
import { validate } from './validator';

export interface BankModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  getData: () => Promise<any>;
}

const initialState: CheckoutDomainEditProps = {
  name: '',
  domain_pattern: '',
  protocol: 'https',
};

export const CreateCheckoutDomainModal = ({ isOpen, setIsOpen, getData }: BankModalProps) => {
  const {
    auth: {
      user: { company_id },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [form, setForm] = useState<CheckoutDomainEditProps>(initialState);
  const [errors, setErrors] = useState<ANY_OBJECT>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { name, domain_pattern, protocol } = form;

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setForm(initialState);
    setErrors({});
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    const { isValid, errors } = validate(form);
    setErrors(errors);
    if (!isValid) {
      setIsSubmitting(false);
      return;
    }

    const api = getAPI(company_id);
    const res = await api.create(form);

    if (!res.success) {
      Swal.fire({
        title: translate('checkout_domain'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      setIsSubmitting(false);

      return;
    }
    await getData();
    setIsSubmitting(false);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {translate('create')} {translate('checkout_domain')}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={onSubmit}>
          <Input
            legend={translate('name').toUpperCase()}
            name="name"
            value={name}
            onChange={onChange}
            isRequired
            type="text"
            error={translate(...(errors.name ?? ''))}
          />

          <Input
            type="select-text"
            options={PROTOCOL_TYPE}
            selectName="protocol"
            selectValue={protocol}
            handleSelectChange={onChange}
            name="domain_pattern"
            value={domain_pattern}
            onChange={onChange}
            legend={translate('domain_name').toUpperCase()}
            placeholder="e.g.: example.com, *.example.com"
            isRequired
            error={translate(...(errors.domain_pattern ?? ''))}
          />

          <div className="d-grid">
            <button className="btn btn-primary mt-3">
              {isSubmitting ? <Spinner size="sm" /> : translate('create')}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
