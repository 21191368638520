import RestfulAPI from '../common/Restful';
import { AmlDomainEditProps, AmlDomainProps } from './interface';

export const getAPI = (
  companyId: string,
  amlDomainId?: string,
): RestfulAPI<AmlDomainProps, AmlDomainEditProps> => {
  if (!amlDomainId) {
    return new RestfulAPI<AmlDomainProps, AmlDomainEditProps>(
      `/companies/${companyId}/aml-domains`,
    );
  }

  return new RestfulAPI<AmlDomainProps, AmlDomainEditProps>(
    `/companies/${companyId}/aml-domains`,
    `/companies/${companyId}/aml-domains/${amlDomainId}`,
  );
};
