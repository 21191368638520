import edit from 'assets/img/icons/edit.png';
import { Text } from 'components/custom/Text';
import { ONLINE_STORE, STORE_STATUS_COLOR } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { fallbackURL } from 'helpers/Image/fallback';
import { StoreProps } from 'services/API/Store/interface';

export interface CardProps {
  store: StoreProps;
  setShowModal: (showModal: boolean) => void;
  setStore: (store: any) => void;
  showAction: boolean;
}

export const Card = ({ store, setShowModal, setStore, showAction }: CardProps) => {
  const { translate } = useTranslate();
  if (!store) {
    return <></>;
  }

  const { logo_url, name, address, aml_domains, id, type, store_identifier, status } = store;

  return (
    <div className="custom-card">
      <div className="custom-card-header">
        <img src={logo_url} onError={fallbackURL} />
        <span>{name}</span>
        {aml_domains &&
          aml_domains.map((domain) => (
            <p key={domain.id}>{`${domain?.protocol}://${domain?.domain}`}</p>
          ))}
        <p>{address}</p>
      </div>

      <div className="custom-card-body">
        <Text type={translate('store_id')} value={id} />
        <Text
          type={translate('type')}
          value={translate(type === ONLINE_STORE ? 'store_online' : 'store_offline')}
        />
        <Text type={translate('store_identifier')} value={store_identifier} />
        <Text
          className={`text-${STORE_STATUS_COLOR[status]}`}
          type={translate('status')}
          value={translate(status)}
        />
      </div>

      {showAction && (
        <div className="custom-card-footer">
          <button
            className="btn"
            type="button"
            onClick={() => {
              setShowModal?.(true);
              setStore?.(store);
            }}>
            <img src={edit} alt="" />
          </button>
        </div>
      )}
    </div>
  );
};
