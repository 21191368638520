import { ReactNode, RefObject } from 'react';
import './ScrollView.scss';

export interface ScrollViewProps {
  children: ReactNode;
  maxVh?: number;
  hideScrollbar?: boolean;
  onScroll?: () => void;
  reference?: RefObject<HTMLDivElement>;
}

export const ScrollView = ({
  children,
  maxVh,
  hideScrollbar,
  onScroll,
  reference,
}: ScrollViewProps) => {
  return (
    <div
      ref={reference}
      className={`scrollable${maxVh ? `-${maxVh}` : ''} ${hideScrollbar ? 'hide-scrollbar' : ''}`}
      onScroll={() => onScroll && onScroll()}>
      {children}
    </div>
  );
};
