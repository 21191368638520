import logo from 'assets/img/registrationForm/yourBusinessIcon.png';
import { FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { reactDistrictsOptions } from 'constants/district';
import { ANY_OBJECT, DOMAIN_TYPE, HTTPS, PROTOCOL_TYPE, translateLabel } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { OfflineStoreProps, OnlineStoreProps, StepProps } from '../interface';
import { storeValidate } from '../validator';

const initialStore = {
  name: '',
  type: 1,
  address: '',
  phone: '',
  email: '',
  district_id: 0,
  domain: {
    name: '',
    type: 1,
    protocol: HTTPS,
    domain: '',
  },
};

export const AssignStore = forwardRef(({ form, setForm }: StepProps, ref) => {
  const { translate } = useTranslate();

  // Online: 2 | Offline: 1
  // Initially set the state as Offline Store.
  const [store, setStore] = useState<OnlineStoreProps | OfflineStoreProps>(
    initialStore as OfflineStoreProps,
  );

  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const onStoreTypeChange = (value: 1 | 2) => {
    const tempStore: any = {
      ...store,
      type: value,
    };

    setStore(tempStore);
    setForm({
      ...form,
      stores: [tempStore],
    });
  };

  const onDistrictChange = (value: number) => {
    const tempStore: any = {
      ...store,
      district_id: value,
    };

    setStore(tempStore);
    setForm({
      ...form,
      stores: [tempStore],
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tempStore: any = {
      ...store,
      [e.target.name]: e.target.value,
    };

    setStore(tempStore);
    setForm({
      ...form,
      stores: [tempStore],
    });
  };

  const onDomainChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'domain' && form.auto_fill['domain']) {
      return;
    }
    const domain = {
      ...store.domain!,
      [e.target.name]: e.target.value,
    };
    const tempStore: any = {
      ...store,
      domain,
    };

    setStore(tempStore);
    setForm({
      ...form,
      stores: [tempStore],
    });
  };

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const name = e.target.name;

    if (name === 'protocol' && form.auto_fill['domain.protocol']) {
      return;
    }

    const domain = {
      ...store.domain!,
      [name]: name === 'type' ? parseInt(e.target.value) : e.target.value,
    };
    const tempStore: any = {
      ...store,
      domain,
    };

    setStore(tempStore);
    setForm({
      ...form,
      stores: [tempStore],
    });
  };

  useEffect(() => {
    let storeData: any = form.stores?.[0] || {};
    let domainData: any = storeData?.domain || { ...initialStore.domain };

    if (form.auto_fill['store.type']) {
      storeData = { ...storeData, type: parseInt(form.auto_fill['store.type']) as 1 | 2 };
    }
    if (form.auto_fill['store.name']) {
      storeData = { ...storeData, name: form.auto_fill['store.name'] };
    }
    if (form.auto_fill['domain']) {
      domainData = {
        ...domainData,
        domain: form.auto_fill['domain'],
        name: form.auto_fill['store.name'],
      };
    }
    if (form.auto_fill['domain.protocol']) {
      domainData = { ...domainData, protocol: form.auto_fill['domain.protocol'] };
    }

    setStore({
      ...store,
      ...storeData,
      domain: domainData,
    });

    // eslint-disable-next-line
  }, []);
  const { name, phone, email, district_id, address, domain, type } = store;

  useImperativeHandle(ref, () => ({
    validate: () => {
      const { isValid, errors } = storeValidate(store);
      setErrors(errors);

      if (!isValid) {
        return false;
      }

      const data: { [key: string]: any } = {
        ...store,
      };

      const names = Object.keys(data);
      const values = Object.values(data);

      values.forEach((val: any, index) => {
        if (val === '') {
          delete data[names[index]];
        }
      });

      if (data.type === 1) {
        delete data.domain;
      }

      const newForm = {
        ...form,
        stores: [data],
      };

      setForm(newForm as typeof form);

      return true;
    },
  }));

  return (
    <>
      <FormHeader title={translate('assign_stores')} src={logo} />
      {typeof form.auto_fill['store.type'] === 'undefined' && (
        <div className="row">
          <div className="col-6 col-lg-4 d-grid">
            <button
              type="button"
              onClick={() => onStoreTypeChange(2)}
              className={`btn btn-${type === 2 ? 'primary' : 'outline-primary'}`}>
              {translate('store_online')}
            </button>
          </div>
          <div className="col-6 col-lg-4 d-grid">
            <button
              type="button"
              onClick={() => onStoreTypeChange(1)}
              className={`btn btn-${type === 1 ? 'primary' : 'outline-primary'}`}>
              {translate('store_offline')}
            </button>
          </div>
        </div>
      )}
      <Input
        legend={translate('store_name').toUpperCase()}
        type="text"
        name="name"
        value={name}
        onChange={onChange}
        placeholder={translate('enter_store_name')}
        isRequired
        error={translate(...(errors['name'] ?? ''))}
      />

      {type === 1 && (
        <>
          <Input
            type="react-select"
            legend={translate('district').toUpperCase()}
            name=""
            options={reactDistrictsOptions}
            onChange={onDistrictChange}
            placeholder={translate('search_keyword')}
            value={district_id as number}
            isRequired
            error={translate(...(errors['district_id'] ?? ''))}
          />
          <Input
            type="text"
            legend={translate('address').toUpperCase()}
            name="address"
            placeholder={translate('enter_address')}
            value={address as string}
            onChange={onChange}
            isRequired
            error={translate(...(errors['address'] ?? ''))}
          />
        </>
      )}

      <Input
        legend={translate('contact_phone').toUpperCase()}
        type="text"
        name="phone"
        value={phone as string}
        onChange={onChange}
        placeholder={translate('enter_your_contact_mobile')}
        error={translate(...(errors['phone'] ?? ''))}
      />
      <Input
        legend={translate('email').toUpperCase()}
        type="text"
        name="email"
        value={email as string}
        onChange={onChange}
        placeholder={translate('enter_your_email_address')}
        error={translate(...(errors['email'] ?? ''))}
      />

      {type === 2 && (
        <>
          <hr />
          <Input
            legend={translate('domain_display_name')}
            type="text"
            name="name"
            value={domain?.name as string}
            onChange={onDomainChange}
            placeholder={translate('enter_domain_display_name')}
          />

          <Input
            type="select"
            options={translateLabel(DOMAIN_TYPE, translate, true)}
            value={domain?.type as number}
            onChange={onSelectChange}
            legend={translate('domain_type')}
            disabled={form.auto_fill['domain']}
            name="type"
            isRequired
          />

          <Input
            type="select-text"
            options={PROTOCOL_TYPE}
            selectName="protocol"
            name="domain"
            placeholder={translate('enter_domain_name')}
            selectValue={domain?.protocol as string}
            handleSelectChange={onSelectChange}
            value={domain?.domain as string}
            onChange={onDomainChange}
            legend={translate('domain_name')}
            isRequired
            disabled={form.auto_fill['domain']}
            error={translate(...(errors['domain.domain'] ?? ''))}
          />
        </>
      )}
    </>
  );
});
