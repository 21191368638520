import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from 'context/TranslateContext';
import { FormEvent, ReactNode, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import './sideFilter.scss';

interface SideFilterProps {
  onFilter: (e: FormEvent<HTMLFormElement>) => void;
  resetFilter: () => void;
  children?: ReactNode;
  isLoading?: boolean;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
}

export const SideFilter = ({
  children,
  resetFilter,
  onFilter,
  isLoading,
  collapsible = true,
  defaultCollapsed = false, //for desktop
}: SideFilterProps) => {
  const { translate } = useTranslate();
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 576px)').matches);
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const toggleCollapsed = () => {
    if (collapsible) {
      setIsCollapsed(!isCollapsed);
    } else {
      setIsCollapsed(false);
    }
  };

  useEffect(() => {
    isMobile ? setIsCollapsed(true) : setIsCollapsed(defaultCollapsed);
  }, [isMobile]);

  useEffect(() => {
    const handleResize = () => {
      const match = window.matchMedia('(max-width: 576px)').matches;
      setIsMobile(match);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="filter-container d-flex flex-column justify-content-between bg-white shadow-sm mb-4">
      <div className={`filter-title ${collapsible && 'pointer'}`} onClick={toggleCollapsed}>
        <span className="colfax-bold">{translate('filter')}</span>
        {collapsible && (
          <span className="colfax-bold">
            <FontAwesomeIcon icon={isCollapsed ? faAngleDown : faAngleUp} className="me-2 mt-1" />
          </span>
        )}
      </div>
      <form className={`filter-form ${isCollapsed ? 'collapsed' : ''} `} onSubmit={onFilter}>
        <div className="filter-body mb-3">{children}</div>
        <div className="filter-action">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0 d-grid">
              <button className="btn btn-outline-secondary" onClick={resetFilter} type="button">
                {translate('clear_filter')}
              </button>
            </div>
            <div className="col-md-6 d-grid">
              <button className="btn btn-primary" disabled={isLoading}>
                {isLoading ? <Spinner size="sm" /> : translate('filter')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SideFilter;
