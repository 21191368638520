import ErrorSelectedIcon from 'assets/img/registrationForm/error-select.png';
import selectedIcon from 'assets/img/registrationForm/selected.png';
import unselectedIcon from 'assets/img/registrationForm/un-selected.png';
import { useTranslate } from 'context/TranslateContext';
import { MouseEvent } from 'react';
import './index.scss';

interface SelectButtonProps {
  thumbnails: Array<string>;
  title: string;
  isSelected: boolean;
  onClick: (e: MouseEvent) => void;
  error?: boolean;
}

export const SelectButton = ({
  thumbnails,
  title,
  isSelected,
  onClick,
  error,
}: SelectButtonProps) => {
  const { translate } = useTranslate();

  let className = 'd-flex justify-content-between align-items-center gateway-selection';
  className += isSelected ? ' gateway-selected' : '';
  className += error ? ' gateway-error' : '';

  return (
    <div className={className} onClick={onClick}>
      <div className="d-flex align-items-center gateway-info">
        {thumbnails.map((thumbnail, index) => (
          <img src={thumbnail} key={index} alt="" className="gateway thumbnail" />
        ))}
        <span>{translate(title)}</span>
      </div>
      <img
        src={error ? ErrorSelectedIcon : isSelected ? selectedIcon : unselectedIcon}
        alt=""
        className="select-icon"
      />
    </div>
  );
};
