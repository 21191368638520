import { Input } from 'components/custom/input';
import { default as defaultCurrencyArr, ReactSelectCurrencyOptions } from 'constants/Currencies';
import GATEWAYS, { AVAILABLE_TXN_GATEWAY_IDS } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { uniqBy } from 'lodash';
import { ChangeEvent, FC, Fragment, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CredentialProps } from 'redux/state/AuthState';
import { createTransactionValidator as validate } from './validator';
import { WebCamReader } from './WebCamReader';
import { StoreSelect } from 'components/custom/ReactSelectAsync/Select/StoreSelect';
import { OFFLINE_STORE } from 'constants/format';

export interface TransactionModalProps {
  credentials: Array<CredentialProps>;
  isTransactionModalOpen: boolean;
  onHide: () => void;
  onCompleteCreate?: (() => void) | undefined;
}

const initialState = {
  amount: '',
  currency: defaultCurrencyArr[0],
  gateway_id: '',
  wallet: '',
  store_id: '',
  token: '',
};

export const TransactionModal: FC<TransactionModalProps> = ({
  credentials,
  isTransactionModalOpen,
  onHide,
  onCompleteCreate,
}: TransactionModalProps) => {
  const usableGatewayIds = credentials
    .filter(
      (credential) =>
        credential.status === 'activated' &&
        AVAILABLE_TXN_GATEWAY_IDS.includes(credential.gateway_id),
    )
    .map((credential) => credential.gateway_id);

  const usableGateways = uniqBy(
    GATEWAYS.filter((gateway) => usableGatewayIds.includes(parseInt(gateway.gateway_id, 10))),
    'gateway_id',
  );

  const [store, setStore] = useState<any>(null);
  const [transaction, setTransaction] = useState(initialState);
  const [errors, setErrors] = useState<any>({});
  const { translate } = useTranslate();

  const [showQRModal, setShowQRModal] = useState(false);

  const { amount, currency, gateway_id } = transaction;

  const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.currentTarget.name === 'amount') {
      setTransaction({
        ...transaction,
        amount: (e.target as any).rawValue,
      });
    } else {
      setTransaction({
        ...transaction,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const handleClick = (value: string) => {
    setTransaction({
      ...transaction,
      gateway_id: value,
    });
  };

  const handleSelectChange = (name: string) => (value: string) => {
    setTransaction({
      ...transaction,
      [name]: value,
    });
  };
  const handleStoreChange = (value: any) => {
    setStore(value);
    handleSelectChange('store_id')(value?.id);
  };

  const handleCreate = () => {
    const { errors, isValid } = validate(transaction);
    setErrors(errors);
    if (isValid) {
      setShowQRModal(true);
    }
  };

  const dischargeTransaction = () => {
    onHide();
    setStore(null);
    setTransaction(initialState);
    setErrors({});
  };

  const handleCreatedTransaction = () => {
    dischargeTransaction();
    if (onCompleteCreate) {
      onCompleteCreate();
    }
  };

  return (
    <Modal
      isOpen={isTransactionModalOpen}
      toggle={dischargeTransaction}
      onExit={dischargeTransaction}
      unmountOnClose={true}
      className="round-lg">
      <ModalHeader toggle={dischargeTransaction}>{translate('create_transaction')}</ModalHeader>
      {usableGateways.length > 0 ? (
        <ModalBody>
          <div className="row">
            <div className="col-sm-11 col-sm-offset-1">
              <p>{translate('select_gateway')}:</p>
            </div>
          </div>
          <div className="text-center mb-2 d-flex justify-content-around">
            {usableGateways.map((gateway) => {
              if (+gateway.gateway_id === 10) {
                return <Fragment key={gateway.gateway_id} />;
              }
              return (
                <button
                  key={gateway.gateway_sub_name}
                  className={`btn ${
                    gateway.gateway_id === gateway_id ? 'border-active-primary' : ''
                  }`}
                  name="gateway_id"
                  value={gateway.gateway_id}
                  onClick={() => handleClick(gateway.gateway_id)}>
                  <img src={gateway.logo} alt="" className="img-lg" />
                </button>
              );
            })}
          </div>
          {gateway_id !== '' && (
            <>
              <Input
                isRequired
                legend={translate('amount')}
                type="cleave"
                value={amount}
                name="amount"
                onChange={handleChange}
                placeholder={translate('enter_amount')}
                prefix="$"
                cleaveOptions={{
                  numeral: true,
                  numeralDecimalScale: 2,
                  numeralIntegerScale: 7,
                  numeralPositiveOnly: true,
                }}
                error={translate(...(errors.amount ?? ''))}
              />
              <Input
                isRequired
                legend={translate('currency')}
                type="react-select"
                value={currency}
                name="currency"
                onChange={handleSelectChange('currency')}
                placeholder={translate('choose_currency')}
                options={ReactSelectCurrencyOptions}
                error={translate(...(errors.currency ?? ''))}
              />

              <StoreSelect
                isRequired
                legend={translate('store').toUpperCase()}
                setStore={handleStoreChange}
                store={store}
                searchOptionsParams={{ type: OFFLINE_STORE }}
                error={errors.store_id}
              />
            </>
          )}
          <WebCamReader
            isModalOpen={showQRModal}
            setIsModalOpen={setShowQRModal}
            transaction={transaction}
            onCompleteCreate={handleCreatedTransaction}
          />
          {gateway_id !== '' && (
            <div className="d-grid">
              <button className="btn btn-primary round-lg mt-4" onClick={() => handleCreate()}>
                {translate('create')}
              </button>
            </div>
          )}
        </ModalBody>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center p-4 text-secondary">
          <h4>{translate('apply_a_payment_gateway_first')}</h4>
        </div>
      )}
    </Modal>
  );
};
