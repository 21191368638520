import {
  address as addressValidator,
  fileSizeLessThan,
  greaterEqualThan,
  isDate,
  isInt,
  lengthBetween,
  lengthEqual,
  packValidateResults,
  required,
  accountHolder,
} from 'helpers/validators';
import {
  BankAccountProps,
  BeneficialOwnerProps,
  BusinessRegistration,
  IrRegistration,
  CertificateOfIncorporation,
} from 'services/API/Auth/interface';

export const validateBr = (data: BusinessRegistration, address: string) => {
  return packValidateResults([
    required({ ...data, address }, [
      'file_id',
      'br_number',
      'branch_number',
      'expiry_date',
      'address',
    ]),
    isInt(data, ['br_number', 'branch_number']),
    addressValidator(data, ['address']),
    lengthEqual(data, ['br_number'], { min: 8 }),
    lengthEqual(data, ['branch_number'], { min: 3 }),
    isDate(data, ['expiry_date'], { format: 'YYYY-MM-DD' }),
  ]);
};

export const validateIr = (data: IrRegistration, address: string) => {
  return packValidateResults([
    required({ ...data, address }, ['file_id', 'address', 'ir_number', 'company_name']),
    addressValidator(data, ['address']),
  ]);
};

export const validateCi = (data: CertificateOfIncorporation) => {
  const results = [];
  if (data.file_id) {
    results.push(required(data, ['ci_number', 'date_of_establishment', 'company_name']));
    results.push(isDate(data, ['date_of_establishment'], { format: 'YYYY-MM-DD' }));
  }
  return packValidateResults(results);
};

export const validateOwner = (data: BeneficialOwnerProps, address: string, isTaxi: boolean) => {
  return packValidateResults([
    required(data, [
      'legal_name',
      'proof_of_identity',
      'date_of_birth',
      'nationality',
      'percentage_owned',
    ]),
    isTaxi ? required({ address }, ['address']) : undefined,
    addressValidator(data, ['address']),
    required({ id_proof: data.file }, ['id_proof']),
    fileSizeLessThan({ id_proof: data.file }, ['id_proof']),
    isDate(data, ['date_of_birth'], { format: 'YYYY-MM-DD' }),
    greaterEqualThan(data, ['percentage_owned'], { min: 30 }),
  ]);
};

export const validateBank = (data: BankAccountProps) => {
  const results = [];
  if (data.holder) {
    results.push(required(data, Object.keys(data)));
    results.push(lengthBetween(data, ['number'], { min: 6, max: 13 }));
    results.push(accountHolder(data, ['holder']));
  }
  return packValidateResults(results);
};
