import {
  DBS_FPS_OFFLINE_GATEWAY_CODE,
  DBS_FPS_ONLINE_GATEWAY_CODE,
  HSBC_FPS_ONLINE_GATEWAY_CODE,
} from 'constants/TransactionGateways';
import { lengthEqual, packValidateResults, required, email } from 'helpers/validators';

export const validate = (
  data: any,
  isCybersource = false,
  fpsWithProxy = false,
  gatewayCode = '',
  fpsProxy = '',
) => {
  const results = [required(data, ['agreeTerms'])];

  if (
    [
      HSBC_FPS_ONLINE_GATEWAY_CODE,
      DBS_FPS_ONLINE_GATEWAY_CODE,
      DBS_FPS_OFFLINE_GATEWAY_CODE,
    ].includes(gatewayCode)
  ) {
    results.push(required(data, ['merchant_id']));
  }

  if (gatewayCode == HSBC_FPS_ONLINE_GATEWAY_CODE) {
    results.push(lengthEqual(data, ['merchant_id'], { min: 15 }));
  }

  if (fpsWithProxy) {
    results.push(required(data, ['fps_proxy_type', fpsProxy]));
    if (fpsProxy == 'fps_id') {
      const reg1 = new RegExp(/^(\d{7}|\d{9})$/);
      if (!reg1.test(data.fps_id)) {
        results.push({ fps_id: ['error_length_must_be_equal_multi', [7, 9]] });
      }
    } else if (fpsProxy == 'phone') {
      results.push(lengthEqual(data, ['phone'], { min: 8 }));
    } else if (fpsProxy == 'email') {
      results.push(email(data, ['email']));
    }
  }

  if (fpsWithProxy && (data.email || data.phone)) {
    results.push(required(data, ['clearing_code']));
    results.push(lengthEqual(data, ['clearing_code'], { min: 3 }));
  }

  return packValidateResults(results);
};
