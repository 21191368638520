import { useTranslate } from 'context/TranslateContext';
import { FC } from 'react';
import './InfoHeader.scss';

export interface InfoHeaderProps {
  src?: string;
  name: string;
  caption?: string;
}

export const InfoHeader: FC<InfoHeaderProps> = ({
  src = 'company-info',
  name,
  caption,
}: InfoHeaderProps) => {
  const { translate } = useTranslate();

  return (
    <div className={`box-header header-${src}`}>
      <div className="d-flex justify-content-between header-container">
        <div className="header-text">
          <h3 className="text-white">{translate(name)}</h3>
          {caption && <p className="text-white colfax-regular">{caption}</p>}
        </div>
        {/*<FontAwesomeIcon icon={faCog} color='#ffffff' size={'lg'} /> */}
      </div>
    </div>
  );
};
