interface DetailRowProps {
  name: string;
  value: string;
  nameWidth?: string;
  valueWidth?: string;
  right?: boolean;
  display?: boolean;
}

export const DetailRow = ({
  name,
  value,
  nameWidth = 'col-6',
  valueWidth = 'col-6',
  right = false,
  display = true,
}: DetailRowProps) => {
  if (!value || !`${value}`.replace(/ /g, '')) {
    return null;
  }
  return (
    <div className={display ? `row` : 'd-none'}>
      <div className={nameWidth}>
        <div className="colfax-regular">{name}:</div>
      </div>
      <div className={valueWidth}>
        <div className={`colfax-regular ${right ? 'text-end' : ''}`}>{value}</div>
      </div>
    </div>
  );
};
