import edit from 'assets/img/icons/edit.png';
import removeIcon from 'assets/img/icons/remove.png';
import { Text } from 'components/custom/Text';
import { useTranslate } from 'context/TranslateContext';
import { fallbackURL } from 'helpers/Image/fallback';
import moment from 'moment';
import { FC } from 'react';
import { BankAccountProps } from 'services/API/Company/interface';

export interface CardProps {
  bank: BankAccountProps;
  onSelect: () => void;
  remove: () => void;
  showAction: boolean;
}

export const Card: FC<CardProps> = ({ bank, onSelect, remove, showAction }: CardProps) => {
  const { translate } = useTranslate();

  if (!bank) {
    return <></>;
  }

  const { branch, code, number, name, currency, updated_at, is_default, holder } = bank;
  return (
    <div style={{ position: 'relative' }}>
      {is_default && (
        <div className="ribbon-wrapper">
          <div className="ribbon">{translate('main_account')}</div>
        </div>
      )}

      <div className="custom-card">
        <div className="custom-card-header">
          <img src="" onError={fallbackURL} />
          <h4 className="">{name}</h4>
          <p>{[code, branch, number].join('-')}</p>
        </div>

        <div className="custom-card-body">
          <Text type={translate('holder')} value={holder} />
          <Text type={translate('currency')} value={currency} />
          <Text
            type={translate('created_at')}
            value={moment(updated_at).format('YYYY-MM-DD HH:mm:ss')}
          />
        </div>

        {showAction && (
          <div className="custom-card-footer">
            <button className="btn" onClick={onSelect}>
              <img src={edit} alt="" />
            </button>

            <button className="btn" onClick={remove}>
              <img src={removeIcon} alt="" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
