import axios, { defaultRequestHandler, getToken } from '../common/Fetcher';

export const getFpsTransactionList = (company_id: string, data = {}) => {
  const params = {
    include: 'store,company,fpsCode',
    ...data,
  };
  return defaultRequestHandler(
    axios.get(`/companies/${company_id}/integration-transactions`, {
      headers: {
        authorization: getToken(),
      },
      params,
    }),
  );
};

export const getFpsTransaction = (
  company_id: string,
  integration_transactions_id: string,
  data = {},
) => {
  const params = {
    include: 'store,company,fpsCode',
    ...data,
  };
  return defaultRequestHandler(
    axios.get(`/companies/${company_id}/integration-transactions/${integration_transactions_id}`, {
      headers: {
        authorization: getToken(),
      },
      params,
    }),
  );
};
