import moment from 'moment';
import { FC, MouseEventHandler, MouseEvent, useEffect, useState } from 'react';
import * as React from 'react';
import calender, {
  CALENDAR_MONTHS,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  isDate,
  isSameDay,
  isSameMonth,
  WEEK_DAYS,
} from '../utils/calender';
import './Calender.scss';

interface state {
  current: Date;
  month: number;
  year: number;
}

export interface CalenderProps {
  date: Date;
  onDateChanged: (date: Date, name: string) => void;
  name?: string;
}

let pressureTimeout: number;
let pressureTimer: number;
let dayTimeout: number;

export const Calender: FC<CalenderProps> = ({ date, onDateChanged, name = '' }: CalenderProps) => {
  const getStateFromDate = (date: Date): state => {
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();

    return {
      current: date,
      month: +_date.getMonth() + 1,
      year: _date.getFullYear(),
    };
  };

  const getStateFromProps = (): state => {
    return getStateFromDate(date);
  };

  const [state, setState] = useState({
    ...getStateFromProps(),
    today: new Date(),
    selectDate: new Date(),
  });

  const [shouldClearDayTimeout, setShouldClearDayTimeout] = useState(false);

  const [showYearSelector, setShowYearSelector] = useState(false);

  const getCalenderDates = () => {
    const { current, month, year } = state;
    const calenderMonth = month || +current.getMonth() + 1;
    const calenderYear = year || current.getFullYear();

    return calender(calenderMonth, calenderYear);
  };

  const renderMonthAndYear = (nextMonth = 0) => {
    const { month, year } = state;

    const getMonth = (indexOfMonth: number) => {
      return Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(indexOfMonth, 11))];
    };

    const monthName = getMonth(month - 1 + nextMonth);

    return (
      <>
        <div
          onClick={() => setShowYearSelector(!showYearSelector)}
          className="calender-year-button">
          {year} - {monthName.substring(0, 3)}
        </div>
      </>
    );
  };

  const renderYearSelector = () => {
    const { year: selectedYear } = state;
    const startYear = selectedYear - 100;
    const years = [...new Array(150)].map((val: number, index: number) => startYear + index);

    return (
      <div className="calender-year-selector" id="calender-year-selector">
        <ul>
          {years.map((year: number) => {
            return (
              <li
                key={year}
                onClick={() => gotoYear(year)}
                className={`calender-year ${
                  year === selectedYear
                    ? 'calender-year-selected'
                    : year === new Date().getFullYear()
                    ? 'calender-year-current'
                    : 'calender-year-normal'
                }`}>
                {year}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderDayLabel = (day: string, index: number) => {
    const label = WEEK_DAYS[day].toUpperCase();

    return <span key={label}>{label.substring(0, 3)}</span>;
  };

  const renderCalenderDate = (date: Array<any>, index: number) => {
    const { current, month, year, today } = state;
    const _date = new Date(date.join('-'));

    const isToday = isSameDay(_date, today);
    const isCurrent = current && isSameDay(_date, current);
    const inMonth =
      month > 0 && year > 0 && isSameMonth(_date, new Date([year, month, 1].join('-')));

    const onClick = gotoDate(_date, name);

    const props = { index, inMonth, onClick, title: _date.toDateString() };

    interface DateComponentProps {
      index: number;
      inMonth: boolean;
      onClick: MouseEventHandler;
      title: string;
      children: React.ReactNode;
    }

    const DateComponent: FC<DateComponentProps> = (props: DateComponentProps) => {
      const { children, inMonth, onClick } = props;

      return (
        <div
          className={`calender-cell ${
            isCurrent === true
              ? 'calender-day-current'
              : isToday === true
              ? 'calender-day-today calender-day'
              : 'calender-day'
          }
          ${inMonth && !isCurrent && !isToday ? 'calender-day-bold' : 'calender-day-fade'}
          `}
          onClick={onClick}>
          <span>{children}</span>
        </div>
      );
    };

    return (
      <DateComponent key={getDateISO(_date)} {...props}>
        {_date.getDate()}
      </DateComponent>
    );
  };

  const gotoDate = (date: Date, name: string) => (evt: MouseEvent) => {
    evt && evt.preventDefault();
    const { current } = state;
    if (!(current && isSameDay(date, current))) {
      date = moment(date).startOf('day').toDate();
      setState({
        ...state,
        ...getStateFromDate(date),
        selectDate: date,
      });
      onDateChanged(date, name);
    }
    return evt;
  };

  const gotoPreviousMonth = () => {
    const { month, year } = state;
    setState({
      ...state,
      ...getPreviousMonth(month, year),
    });
  };

  const gotoNextMonth = () => {
    const { month, year } = state;
    setState({
      ...state,
      ...getNextMonth(month, year),
    });
  };

  const gotoPreviousYear = () => {
    const { year } = state;
    setState({
      ...state,
      year: year - 1,
    });
  };

  const gotoNextYear = () => {
    const { year } = state;
    setState({
      ...state,
      year: year + 1,
    });
  };

  const gotoYear = (year: number) => {
    setState({
      ...state,
      year,
    });
    setShowYearSelector(false);
  };

  const clearPressureTimer = () => {
    pressureTimer && window.clearInterval(pressureTimer);
    pressureTimeout && window.clearTimeout(pressureTimeout);
  };

  const clearDayTimeout = () => {
    dayTimeout && window.clearTimeout(dayTimeout);
  };

  useEffect(() => {
    const now = +new Date();
    const tmr = +new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000;
    const ms = tmr - now;

    //eslint-disable-next-line
    dayTimeout = window.setTimeout(() => {
      setState({
        ...state,
        today: new Date(),
      });
      setShouldClearDayTimeout(true);
    }, ms);

    return () => {
      clearPressureTimer();
      clearDayTimeout();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shouldClearDayTimeout === true) {
      clearDayTimeout();
    }
    //eslint-disable-next-line
  }, [shouldClearDayTimeout]);

  useEffect(() => {
    // Looking for a way to avoid direct dom manipulation
    if (showYearSelector) {
      const div = document.getElementById('calender-year-selector');
      if (!div) {
        return;
      }
      // one year = (30.03px: height) + (5px: margin);
      // set initial scroll to 100year later(current year) = (30.03 + 5) * 100
      div.scrollTop = 3503;
    }
  }, [showYearSelector]);

  interface TogglerProps {
    children: React.ReactNode;
  }

  const Toggler: FC<TogglerProps> = ({ children }: TogglerProps) => (
    <div className="calender-toggler">
      <div className="btn-group">
        <button className="btn btn-sm" onClick={() => gotoPreviousYear()}>
          {`<<`}
        </button>
        <button className="btn btn-sm" onClick={() => gotoPreviousMonth()}>
          {`<`}
        </button>
      </div>
      {children}
      <div className="btn-group">
        <button className="btn btn-sm" onClick={() => gotoNextMonth()}>
          {`>`}
        </button>
        <button className="btn btn-sm" onClick={() => gotoNextYear()}>
          {`>>`}
        </button>
      </div>
    </div>
  );

  return (
    <div className="calender-container">
      <div className="calender-title">
        <Toggler>{renderMonthAndYear()}</Toggler>
      </div>

      {showYearSelector ? (
        renderYearSelector()
      ) : (
        <>
          <div className="calender-week-days-container">
            {Object.keys(WEEK_DAYS).map(renderDayLabel)}
          </div>
          <div className="calender-date-grid">{getCalenderDates().map(renderCalenderDate)}</div>
        </>
      )}
    </div>
  );
};
