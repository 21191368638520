import { getCompanyDocumentsList } from 'actions/CompanyActions';
import { Input } from 'components/custom/input';
import {
  ALLOW_MULTIPLE_UPLOAD_TYPE,
  BR,
  CI,
  CONTRACT,
  DISABLE_SHARED_TYPE,
  IR,
  OTHERS,
  reactSelectDocumentTypeOptions,
  STORE_BR,
} from 'constants/documents';
import {
  AGREEMENT_TYPE_OPTIONS,
  CONTRACT_TYPE_OPTIONS,
  reactSelectBoolean,
  translateLabel,
} from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { getArrayStatus } from 'helpers/functions/arrayStatus';
import { convertStoreToOptions } from 'helpers/functions/convertStoreToOptions';
import moment from 'moment';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { documentProps } from 'redux/reducers/dataProps';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import {
  createCi,
  createCompanyBr,
  createContract,
  createStoreBr,
  uploadDocument,
  createIr,
} from 'services/API/Company';
import { updateDocument } from 'services/API/Document';
import { useSwal } from 'helpers/sweetalert';
import { updateDocumentProps } from 'types';
import { validate } from './validator';
import { getAllStore } from 'services/API/Store';
import { SelectOptionProps } from 'components/custom/input/interfaces';

export interface UpdateDocumentModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  editing_document?: documentProps;
  reload: () => void;
}

export const UpdateDocumentModal = ({
  isModalOpen,
  setIsModalOpen,
  editing_document,
  reload,
}: UpdateDocumentModalProps) => {
  const initialState = {
    _method: 'PUT',
    ir_number: '',
    br_number: '',
    branch_number: '',
    ci_number: '',
    client_approved_at: new Date(),
    company_name: '',
    contract_type: '',
    date_of_establishment: new Date(),
    document_type: '',
    expiry_date: new Date(),
    generated_at: new Date(),
    shared: false,
    store_id: '',
    type: '',
    id: '',
  };

  const initialErrorState = {
    ...initialState,
    client_approved_at: '',
    date_of_establishment: '',
    expiry_date: '',
    file: '',
    generated_at: '',
  };

  const dispatch = useDispatch<FunctionDispatch>();
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const toggle = () => {
    reset();
    setIsModalOpen(!isModalOpen);
  };

  const [data, setData] = useState<updateDocumentProps>(initialState);
  const [files, setFiles] = useState<FileList>([] as any);
  const [errors, setErrors] = useState<any>(initialErrorState);
  const [updating, setUpdating] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const hasEditingDocument = editing_document != null;
  const [allStores, setAllStores] = useState<Array<SelectOptionProps>>([]);

  const {
    _method,
    ir_number,
    br_number,
    branch_number,
    ci_number,
    client_approved_at,
    company_name,
    contract_type,
    date_of_establishment,
    document_type,
    expiry_date,
    generated_at,
    shared,
    store_id,
    type,
  } = data;

  const reset = () => {
    setFiles([] as any);
    setData(initialState);
    setErrors(initialErrorState);
    setWarningMessage('');
  };

  const handleDocumentTypeChange = (document_type: string) => {
    if (DISABLE_SHARED_TYPE.includes(document_type.toUpperCase())) {
      setWarningMessage(
        translate('are_you_sure_change_document_type_to_type').replace(
          '{type}',
          translate(document_type),
        ) + translate('you_wont_be_able_to_revert_this'),
      );
      setData({
        ...data,
        document_type,
        shared: false,
      });
    } else {
      setWarningMessage('');
      setData({
        ...data,
        document_type,
      });
    }
  };

  const handleSharedChange = (bool: boolean) => {
    setData({
      ...data,
      shared: bool,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleContractTypeChange = (contract_type: string) => {
    setData({
      ...data,
      contract_type,
    });
  };

  const handleTypeChange = (type: string) => {
    setData({
      ...data,
      type,
    });
  };

  const handleSelectChange = (name: string) => (val: string) => {
    setData({
      ...data,
      [name]: val,
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setFiles(files);
    }
  };

  const handleDateChange = (date: Date, name: string) => {
    setData({
      ...data,
      [name]: date,
    });
  };

  const handleUpload = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const { isValid, errors } = validate(data, files, hasEditingDocument);
    setErrors(errors);

    if (!isValid) {
      return;
    }
    setUpdating(true);

    const appendAndUpload = async (file?: File) => {
      let api: any;
      let res: any;
      const fd = new FormData();
      fd.append('document_type', document_type);
      fd.append('type', document_type);

      switch (document_type) {
        case BR:
          fd.append('br_number', br_number!);
          fd.append('branch_number', branch_number!);
          fd.append('company_name', company_name!);
          fd.append('expiry_date', moment(expiry_date).format('YYYY-MM-DD'));
          api = createCompanyBr;
          break;

        case STORE_BR:
          fd.append('br_number', br_number!);
          fd.append('branch_number', branch_number!);
          fd.append('expiry_date', moment(expiry_date).format('YYYY-MM-DD'));
          fd.append('store_id', store_id!);

          api = createStoreBr;
          break;

        case CI:
          fd.append('company_name', company_name!);
          fd.append('ci_number', ci_number!);
          fd.append('date_of_establishment', moment(date_of_establishment).format('YYYY-MM-DD'));
          api = createCi;
          break;

        case IR:
          fd.append('ir_number', ir_number!);
          fd.append('company_name', company_name!);
          api = createIr;
          break;

        case CONTRACT:
          fd.append('client_approved_at', moment(client_approved_at).format('YYYY-MM-DD'));
          fd.append('contract_type', contract_type!);
          fd.append('generated_at', moment(generated_at).format('YYYY-MM-DD'));
          fd.delete('type');
          fd.append('type', type!);
          api = createContract;
          break;

        case OTHERS:
          fd.append('shared', shared!.toString());
          api = uploadDocument;
          break;

        default:
          // PICTURE and LOGO
          api = uploadDocument;
          break;
      }

      if (hasEditingDocument) {
        fd.append('_method', _method!);
        api = updateDocument;
        res = await api(editing_document!.id, fd);
      } else {
        fd.append('file', file!);
        res = await api(user.company_id, fd);
      }

      return res.success;
    };

    if (hasEditingDocument) {
      await appendAndUpload();
    } else {
      const fileArray = Array.from(files!);

      const status = await Promise.all(
        fileArray.map(async (file) => {
          return await appendAndUpload(file);
        }),
      );

      const { allPassed, partialPassed, allFailed } = getArrayStatus(status);
      if (allPassed) {
        Swal.fire({
          icon: 'success',
          title: translate('upload_document'),
          text: translate('congratulations_document_successfully_uploaded'),
        });
      }
      if (partialPassed) {
        Swal.fire({
          icon: 'warning',
          title: translate('upload_document'),
          text: translate('something_wrong_with_file_upload_please_check'),
        });
      }
      if (allFailed) {
        Swal.fire({
          icon: 'error',
          title: translate('upload_document'),
          text: translate('failed_to_upload_document'),
        });
      }
    }

    setUpdating(false);
    reload();
    reset();
    toggle();
    return true;
  };

  const getStores = async () => {
    if (user?.company_id) {
      const res = await getAllStore(user.company_id);
      if (!res.success) {
        await Swal.fire({
          icon: 'error',
          title: translate('store_list'),
          text: res?.message || 'Oops',
        });
        return;
      }
      const stores = res.data;
      const options = stores.map((store) => ({
        label: store.name,
        value: store.id,
      }));
      setAllStores([...options]);
    }
  };

  useEffect(() => {
    getStores();
    if (editing_document) {
      setData({
        ...initialState,
        document_type: editing_document.document_type,
        shared: editing_document.shared,
      });
    } else {
      setData(initialState);
    }
  }, [editing_document]);

  return (
    <Modal isOpen={isModalOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {' '}
        {editing_document ? translate('update_document') : translate('upload_document')}
      </ModalHeader>
      <ModalBody>
        <Input
          isRequired
          type="react-select"
          value={document_type}
          name="document_type"
          onChange={handleDocumentTypeChange}
          placeholder={
            editing_document ? translate(editing_document.type) : translate('choose_document_type')
          }
          legend={translate('document_type').toUpperCase()}
          options={translateLabel(reactSelectDocumentTypeOptions, translate, true)}
          error={translate(...(errors.document_type ?? ''))}
        />

        {document_type !== '' && (
          <>
            {document_type === OTHERS && (
              <Input
                isRequired
                type="react-select"
                value={shared!}
                name="shared"
                onChange={handleSharedChange}
                legend={translate('shared').toUpperCase()}
                options={translateLabel(reactSelectBoolean, translate, true)}
              />
            )}

            {document_type === IR && (
              <Input
                isRequired
                type="text"
                value={ir_number!}
                name="ir_number"
                onChange={handleChange}
                legend={translate('ir_number').toUpperCase()}
                error={translate(...(errors.ir_number ?? ''))}
              />
            )}

            {(document_type === BR || document_type === CI || document_type === IR) && (
              <Input
                isRequired
                type="text"
                value={company_name!}
                name="company_name"
                onChange={handleChange}
                legend={translate('document_company_name').toUpperCase()}
                error={translate(...(errors.company_name ?? ''))}
              />
            )}

            {document_type === STORE_BR && (
              <Input
                isRequired
                type="react-select"
                value={store_id!}
                name="store_id"
                onChange={handleSelectChange('store_id')}
                legend={translate('store_id').toUpperCase()}
                options={allStores}
                placeholder={translate('please_choose_store')}
                error={translate(...(errors.store_id ?? ''))}
              />
            )}

            {(document_type === BR || document_type === STORE_BR) && (
              <>
                <Input
                  isRequired
                  type="cleave"
                  value={br_number!}
                  name="br_number"
                  onChange={handleChange}
                  legend={translate('br_number').toUpperCase()}
                  numberOnly={true}
                  cleaveOptions={{
                    blocks: [8],
                    numeralPositiveOnly: true,
                  }}
                  placeholder="00000000"
                  error={translate(...(errors.br_number ?? ''))}
                />
                <Input
                  isRequired
                  type="cleave"
                  value={branch_number!}
                  name="branch_number"
                  onChange={handleChange}
                  legend={translate('branch_code').toUpperCase()}
                  numberOnly={true}
                  cleaveOptions={{
                    blocks: [3],
                    numeralPositiveOnly: true,
                  }}
                  placeholder="000"
                  error={translate(...(errors.branch_number ?? ''))}
                />
                <Input
                  isRequired
                  type="date"
                  value={expiry_date!}
                  name="expiry_date"
                  onChange={handleDateChange}
                  legend={translate('expiry_date').toUpperCase()}
                  error={translate(...(errors.expiry_date ?? ''))}
                />
              </>
            )}

            {document_type === CI && (
              <>
                <Input
                  isRequired
                  type="text"
                  value={ci_number!}
                  name="ci_number"
                  onChange={handleChange}
                  legend={translate('ci_number').toUpperCase()}
                  error={translate(...(errors.ci_number ?? ''))}
                />

                <Input
                  isRequired
                  type="date"
                  value={date_of_establishment!}
                  name="date_of_establishment"
                  onChange={handleDateChange}
                  legend={translate('date_of_establishment').toUpperCase()}
                  error={translate(...(errors.date_of_establishment ?? ''))}
                />
              </>
            )}

            {document_type === CONTRACT && (
              <>
                <Input
                  isRequired
                  type="react-select"
                  value={contract_type!}
                  name="contract_type"
                  onChange={handleContractTypeChange}
                  legend={translate('contract_type').toUpperCase()}
                  options={translateLabel(CONTRACT_TYPE_OPTIONS, translate, true)}
                />
                <Input
                  isRequired
                  type="react-select"
                  value={type!}
                  name="type"
                  onChange={handleTypeChange}
                  legend={translate('type').toUpperCase()}
                  options={translateLabel(AGREEMENT_TYPE_OPTIONS, translate, true)}
                />
                <Input
                  isRequired
                  type="date"
                  value={generated_at!}
                  name="generated_at"
                  onChange={handleDateChange}
                  legend={translate('generated_at').toUpperCase()}
                  error={translate(...(errors.generated_at ?? ''))}
                />
                <Input
                  isRequired
                  type="date"
                  value={client_approved_at!}
                  name="client_approved_at"
                  onChange={handleDateChange}
                  legend={translate('client_approved_at').toUpperCase()}
                  error={translate(...(errors.client_approved_at ?? ''))}
                />
              </>
            )}
            {!editing_document && (
              <Input
                isRequired
                type="file"
                name="file"
                files={files}
                onChange={handleFileChange}
                multiple={ALLOW_MULTIPLE_UPLOAD_TYPE.includes(document_type.toUpperCase())}
                placeholder={translate('select_file')}
                legend={translate('upload_file').toUpperCase()}
                accept="image/*,application/pdf,.doc,docx,application/msword"
                error={translate(...(errors.file ?? ''))}
              />
            )}
            {editing_document && <div className="mx-2 mt-2 text-danger">{warningMessage}</div>}

            <div className="d-grid">
              <button
                className="btn btn-primary mt-3 rounded-3"
                onClick={handleUpload}
                disabled={updating}>
                {hasEditingDocument ? translate('update') : translate('upload')}
              </button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
