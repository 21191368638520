import _ from 'lodash';
import validator from 'validator';
export * from './standard';
export * from './date';
export { validator };

type validateResult = {
  errors: any;
  isValid: boolean;
};
export const packValidateResults = (results: Array<any>): validateResult => {
  const errors = _.defaults({}, ...results);
  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};
