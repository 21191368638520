import firebase from 'firebase/app';
import 'firebase/messaging';
import { toApiTruthy } from 'helpers/functions/converter';
import { createNanoEvents } from 'nanoevents';
import { createDevice, updateDevice } from 'services/API/Device';
import { CreateDeviceResponse, DeviceProps } from 'services/API/Device/interface';
import UAparser from 'ua-parser-js';

interface Events {
  onNotification: (payload: any) => void;
}

const messageEmitter = createNanoEvents<Events>();

const onNotification = (cb: (payload: any) => void) => {
  return messageEmitter.on('onNotification', cb);
};
export default onNotification;

export const initFirebaseMessaging = async (): Promise<void> => {
  if (firebase.apps.length > 0) {
    return;
  }

  try {
    const messaging = await getFirebaseMessaging();
    const token = await getToken(messaging);
    await registerDevice(token);

    messaging.onMessage((payload) => {
      messageEmitter.emit('onNotification', payload);
    });
  } catch (e) {}

  return;
};

const getFirebaseMessaging = async (): Promise<firebase.messaging.Messaging> => {
  firebase.initializeApp((window as any).firebaseConfig);

  return firebase.messaging();
};

const getToken = async (messaging: firebase.messaging.Messaging): Promise<string> => {
  await Notification.requestPermission();

  return messaging.getToken();
};

const registerDevice = async (token: string): Promise<CreateDeviceResponse> => {
  const parser = new UAparser();
  const browserVersion = parser.getBrowser().name + ' ' + parser.getBrowser().version;

  const deviceData: DeviceProps = {
    platform: 'web',
    token: token,
    provider: 'fcm',
    name: browserVersion.substr(0, 30),
    enabled: toApiTruthy(true),
  };

  let result = null;
  if (localStorage.device_id && localStorage.device_user_id === localStorage.current_user_id) {
    result = await updateDevice(localStorage.device_id, deviceData);
  } else {
    result = await createDevice(deviceData);
  }

  if (result.success) {
    localStorage.device_id = result.data.id;
    localStorage.device_user_id = localStorage.current_user_id;
    console.log('push notification register successful.');
  } else {
    console.error('push notification register unsuccessful.');
  }

  return result;
};
