import { ANY_OBJECT } from 'constants/format';
import { isOperator } from 'constants/Roles';
import {
  email,
  lengthEqual,
  packValidateResults,
  username,
  password,
  required,
  isInt,
} from 'helpers/validators';
import { createUserProps } from 'types';

export const validate = (data: createUserProps, isEdit: boolean) => {
  const results: Array<ANY_OBJECT> = [
    required(data, ['role', 'email', 'username', 'first_name', 'last_name']),
    username(data, ['username']),
    lengthEqual(data, ['phone'], { min: 8 }),
    isInt(data, ['phone']),
    email(data, ['email']),
    password(data, ['password']),
  ];

  if (!isEdit) {
    results.push(required(data, ['password', 'confirm_password']));
  }

  if (!isEdit && isOperator(data.role)) {
    results.push(required(data, ['store_id']));
  }

  if (data.password !== data.confirm_password) {
    results.push({ confirm_password: ['error_password_mismatch'] });
  }

  return packValidateResults(results);
};
