import {
  address,
  isInt,
  lengthEqual,
  packValidateResults,
  required,
  url,
} from 'helpers/validators';
import { UpdateCompanyProps } from 'services/API/Company/interface';

export const validate = (data: UpdateCompanyProps) =>
  packValidateResults([
    required(data, ['name', 'address', 'contact_name', 'contact_mobile', 'business_description']),
    address(data, ['address']),
    lengthEqual(data, ['contact_mobile'], { min: 8 }),
    isInt(data, ['contact_mobile'], { min: 8 }),
    url(data, ['website'], {
      protocols: ['http', 'https'],
      require_protocol: true,
    }),
  ]);
