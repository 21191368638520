import LOGO from 'assets/img/print_qrcode/logo.png';
import back from 'assets/img/print_qrcode/print_back.png';
import dialog from 'assets/img/print_qrcode/print_dialog.png';
import {
  ALIPAY_QR_PNG,
  FPS_QR_PNG,
  UNIONPAY_QR_PNG,
  WECHAT_QR_PNG,
  VISA_QR_PNG,
  MASTERCARD_QR_PNG,
} from 'constants/gateways/gatewayLogos';
import { useTranslate } from 'context/TranslateContext';
import { fabric } from 'fabric';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import html2canvas from 'html2canvas';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import './PrintModal.scss';

interface PrintModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedStore: any | null;
}

export const PrintModal = ({ isOpen, setIsOpen, selectedStore }: PrintModalProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const hasAlipayOnline = getUserActivatedGateways().includes('4_1');
  const hasWeChatPayOnline = getUserActivatedGateways().includes('8_2');
  const hasUnionPayOnline = getUserActivatedGateways().some((code) =>
    ['9_1', '9_5'].includes(code),
  );

  const hasVisaOnline = getUserActivatedGateways().includes('12_1');
  const hasMastercardOnline = getUserActivatedGateways().includes('12_2');
  const hasFpsOnline = getUserActivatedGateways().includes('13_1');

  const emvCodeGatewaysCount = [
    hasVisaOnline,
    hasMastercardOnline,
    hasAlipayOnline,
    hasWeChatPayOnline,
    hasUnionPayOnline,
    hasFpsOnline,
  ].filter(Boolean).length;

  const emvCodeLogoWidthCal = (emvCodeGatewaysCount: any) => {
    switch (true) {
      case emvCodeGatewaysCount > 0 && emvCodeGatewaysCount <= 2:
        return 6;
      case emvCodeGatewaysCount >= 3:
        return 4;
      default:
        return 6;
    }
  };

  const emvCodeLogoWidth = emvCodeLogoWidthCal(emvCodeGatewaysCount);

  const iText = new fabric.IText(user.company.name, {
    fontFamily: 'colfax regular',
    fill: '#fff',
    left: 80,
    top: 100,
  });

  const printRef = useRef<HTMLDivElement>(null);
  const page1Ref = useRef<HTMLDivElement>(null);
  const page2Ref = useRef<HTMLDivElement>(null);

  const [printPage1, setPrintPage1] = useState(true);
  const [printPage2, setPrintPage2] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const [fabricCanvas, setFabricCanvas] = useState<null | fabric.Canvas>(null);

  const [error, setError] = useState('');

  const { translate } = useTranslate();

  const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    if (e.target.id === 'page1') {
      setPrintPage1(e.target.checked);
      return;
    }
    setPrintPage2(e.target.checked);
  };

  const onPrint = () => {
    if (!printRef.current && !page1Ref.current && !page2Ref.current) {
      console.error('Ref Object is not found');
      return;
    }
    if (printPage1 && printPage2) {
      print(printRef.current!);
      return;
    }
    if (printPage1) {
      print(page1Ref.current!);
      return;
    }
    if (printPage2) {
      print(page2Ref.current!);
      return;
    }
  };

  const print = (el: HTMLDivElement) => {
    setIsPrinting(true);
    setTimeout(async () => {
      const canvas = await html2canvas(el, {
        useCORS: true,
        backgroundColor: '#ffffff',
        scale: 1,
      });
      const link = document.createElement('a');

      link.setAttribute('download', `precreate-link-${selectedStore?.value?.id}.png`);

      link.setAttribute(
        'href',
        canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'),
      );
      link.click();
      setIsPrinting(false);
    });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setPrintPage1(true);
    setPrintPage2(true);
  };

  const initializeFabric = async () => {
    await new Promise((res) =>
      setTimeout(() => {
        res(true);
      }, 1000),
    );

    if (!isOpen) {
      return;
    }

    if (fabricCanvas !== null) {
      iText.text = 'Tap and pay';
      fabricCanvas.renderAll();
      return;
    }

    const canvas = new fabric.Canvas('canvas', {
      width: 464,
      height: 786,
    });

    canvas.add(iText);
    canvas.renderAll();

    setFabricCanvas(canvas);
  };

  useEffect(() => {
    initializeFabric();
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (!printPage1 && !printPage2) {
      setError(translate('at_least_select_one_page_to_print'));
    }
    //eslint-disable-next-line
  }, [printPage1, printPage2]);

  //Should only use .png for printing
  const qrcode: string = selectedStore?.value?._links?.[1]?.href;

  const gateway: any = (
    <>
      <div
        className={
          hasVisaOnline
            ? `col-${emvCodeLogoWidth} my-1 d-flex align-items-center justify-content-center`
            : `d-none`
        }>
        <img
          src={VISA_QR_PNG}
          alt="VISA"
          className={hasVisaOnline ? `h-42px mw-100` : `d-none`}
          draggable={false}
        />
      </div>
      <div
        className={
          hasMastercardOnline
            ? `col-${emvCodeLogoWidth} my-1 d-flex align-items-center justify-content-center`
            : `d-none`
        }>
        <img
          src={MASTERCARD_QR_PNG}
          alt="MASTERCARD"
          className={hasMastercardOnline ? `h-42px mw-100` : `d-none`}
          draggable={false}
        />
      </div>
      <div
        className={
          hasUnionPayOnline
            ? `col-${emvCodeLogoWidth} my-1 d-flex align-items-center justify-content-center`
            : `d-none`
        }>
        <img
          src={UNIONPAY_QR_PNG}
          alt="UNIONPAY"
          className={hasUnionPayOnline ? `h-42px mw-100` : `d-none`}
          draggable={false}
        />
      </div>
      <div
        className={
          hasAlipayOnline
            ? `col-${emvCodeLogoWidth} my-1 d-flex align-items-center justify-content-center`
            : `d-none`
        }>
        <img
          src={ALIPAY_QR_PNG}
          alt="Alipay Pay"
          className={hasAlipayOnline ? `h-42px mw-100` : `d-none`}
          draggable={false}
        />
      </div>
      <div
        className={
          hasWeChatPayOnline
            ? `col-${emvCodeLogoWidth} my-1 d-flex align-items-center justify-content-center`
            : `d-none`
        }>
        <img
          src={WECHAT_QR_PNG}
          alt="WeChat Pay"
          className={hasWeChatPayOnline ? `h-42px mw-100` : `d-none`}
          draggable={false}
        />
      </div>
      <div
        className={
          hasFpsOnline
            ? `col-${emvCodeLogoWidth} my-1 d-flex align-items-center justify-content-center`
            : `d-none`
        }>
        <img
          src={FPS_QR_PNG}
          alt="FPS"
          className={hasFpsOnline ? `h-42px mw-100` : `d-none`}
          draggable={false}
        />
      </div>
    </>
  );
  // }

  return (
    <Modal isOpen={isOpen} toggle={toggle} unmountOnClose={false}>
      <ModalHeader toggle={toggle}>{translate('download_printable')}</ModalHeader>
      <ModalBody>
        <div className="" ref={printRef}>
          {printPage1 && (
            <div className="page" ref={page1Ref}>
              <div className="front">
                <div className="qr-container">
                  <div className="qr-content">
                    <h1>歡迎掃碼付款</h1>
                    <img src={dialog} alt="Dialog" />
                  </div>
                  {/**Place Canvas here for name editing */}

                  <canvas id="canvas"></canvas>

                  <div className="qr-code-dynamic">
                    <img src={qrcode} alt="QR Code" className="w-100" draggable={false} />
                    <div className="mt-2 w-100">
                      <div
                        className={`row ${emvCodeGatewaysCount <= 2 && 'justify-content-center'}`}
                        style={{ rowGap: '5px' }}>
                        {gateway}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="qr-footer">
                  <img src={LOGO} alt="Yedpay" className="img-fluid yedpay-print-logo" />
                </div>
              </div>
            </div>
          )}
          {printPage2 && (
            <div className="page" ref={page2Ref}>
              <img src={back} alt="" draggable={false} className="img-fluid img-container" />
            </div>
          )}
        </div>
        <div className="text-center">
          <div className="d-flex align-items-center justify-content-center">
            <input type="checkbox" id="page1" checked={printPage1} onChange={onCheck} />
            <label htmlFor="page1" className="form-label m-0 ms-1">
              Page 1
            </label>
            <div className="me-2"></div>
            <input type="checkbox" id="page2" checked={printPage2} onChange={onCheck} />
            <label htmlFor="page2" className="form-label m-0 ms-1">
              Page 2
            </label>
          </div>
          {error !== '' && <p className="text-danger">{error}</p>}
          <button
            className="btn btn-primary mt-3"
            onClick={onPrint}
            disabled={error !== '' || isPrinting}>
            {isPrinting ? <Spinner size="sm" /> : translate('print')}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
