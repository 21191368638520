import { logout } from 'actions/AuthActions';
import store from 'redux/store';
import { FunctionDispatch } from 'redux/types';
import Swal from 'sweetalert2';

const logoutUser = () => {
  (store.dispatch as FunctionDispatch)(logout());
};

const handleServerError = (customErrorHandler?: () => unknown) => {
  if (customErrorHandler) {
    customErrorHandler();
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Server Error',
      text: 'We are sorry...',
    });
  }
  // throw new Error('Internal Server Error...');
};

const statusCodeHandler = (response: any, customErrorHandler?: () => unknown) => {
  if (!navigator.onLine) {
    response = { data: { message: 'Network connection failed.' } };
  } else if (!response?.ok) {
    switch (response?.status) {
      default:
        break;
      case 422: // Wrong input
        response.data.message = response.data.errors?.[0]?.message ?? response.data.message;
        break;
      case 401:
      case 423:
        logoutUser();
        break;
      case 500:
        handleServerError(customErrorHandler);
    }
  }
  return response;
};

export function defaultRequestHandler(
  fetch: Promise<any>,
  callback?: (response: unknown) => unknown,
  customErrorHandler?: () => unknown,
) {
  return fetch
    .then((response) => statusCodeHandler(response, customErrorHandler))
    .then((response) => response.data)
    .then((response: any) => {
      if (callback) {
        callback(response);
      } else {
        return response;
      }
    });
  // .catch((err) => console.error(err));
}

export const loginRequestHandler = (
  fetch: Promise<any>,
  callback?: (response: unknown) => unknown,
) => {
  return fetch
    .then((response) => statusCodeHandler(response))
    .then((response) => response.data)
    .then((response) => (callback ? callback(response) : response))
    .catch((err) => {
      console.log(err);
    });
};

export const showDocumentRequestHandler = (
  fetch: Promise<any>,
  callback?: (response: unknown) => unknown,
) => {
  return fetch
    .then((res) => {
      const url = URL.createObjectURL(res.data);
      return callback ? callback({ success: true, data: url }) : { success: true, data: url };
    })
    .catch((err) => {
      console.error('Error', err);
      return callback ? callback({ success: false }) : { success: false };
    });
};
