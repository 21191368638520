import axios, {
  ContentType,
  defaultRequestHandler,
  DefaultResponse,
  getToken,
  params,
} from '../common/Fetcher';
import {
  CreateEmvCodeResponse,
  CreateFixedCodeResponse,
  GetCompanyFixedCodeListResponse,
  GetCompanyQrCodeListResponse,
  QrCodeBodyProps,
  UpdateFixedCodeResponse,
} from './interface';

// https://dev2.yedpay.com/document/admin/companyQrCodeList
export const getCompanyQrCodesList = (
  company_id: string,
  data = {},
): Promise<GetCompanyQrCodeListResponse> =>
  defaultRequestHandler(
    axios.get(
      `/companies/${company_id}/qr-codes?sort=-created_at&include=company,store&${params(data)}`,
      {
        headers: {
          authorization: getToken(),
        },
      },
    ),
  );

// https://dev2.yedpay.com/document/admin/createFixedCode
export const createEmvCode = (
  store_id: string,
  body: QrCodeBodyProps,
): Promise<CreateEmvCodeResponse> =>
  defaultRequestHandler(
    axios.post(`/stores/${store_id}/emv-codes`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/companyFixedCodeList
export const getCompanyFixedCodesList = (
  company_id: string,
  data = {},
): Promise<GetCompanyFixedCodeListResponse> =>
  defaultRequestHandler(
    axios.get(`/fixedcode/companies/${company_id}/barcodes?include=store&${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/createFixedCode
export const createFixedCode = async (
  company_id: string,
  store_id: string,
  body: QrCodeBodyProps,
): Promise<CreateFixedCodeResponse> => {
  // await defaultRequestHandler(
  await axios.post(
    `/fixedcode/companies/${company_id}/activate`,
    {},
    {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    },
  );
  // );

  await axios.post(
    `/fixedcode/stores/${store_id}/activate`,
    {},
    {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    },
  );

  return defaultRequestHandler(
    axios.post(`/fixedcode/stores/${store_id}/barcodes`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );
};

// https://dev2.yedpay.com/document/admin/updateFixedCode
export const updateFixedCode = (
  fixed_code_id: string,
  body: QrCodeBodyProps,
): Promise<UpdateFixedCodeResponse> =>
  defaultRequestHandler(
    axios.put(`/fixedcode/barcodes/${fixed_code_id}`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/activateFixedCode
export const activateFixedCode = (fixed_code_id: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(
      `/fixedcode/barcodes/${fixed_code_id}/activate`,
      {},
      {
        headers: {
          authorization: getToken(),
        },
      },
    ),
  );

// https://dev2.yedpay.com/document/admin/deactivateFixedCode
export const deactivateFixedCode = (fixed_code_id: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(
      `/fixedcode/barcodes/${fixed_code_id}/deactivate`,
      {},
      {
        headers: {
          authorization: getToken(),
        },
      },
    ),
  );
