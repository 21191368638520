import { Password } from 'components/custom/Auth/Password';
import { Username } from 'components/custom/Auth/Username';
import { Input } from 'components/custom/input';
import { BOOLEAN_OPTIONS, translateLabel } from 'constants/format';
import { AVAILABLE_ROLES_FOR_CREATE_FOR_REACT_SELECT, operator } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { removeEmptyValuePair } from 'helpers/functions/removeEmptyValuePair';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadOptions } from 'react-select-async-paginate';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { userProps } from 'redux/state/AuthState';
import { GlobalTypes } from 'redux/types';
import { DefaultResponse } from 'services/API/common/Fetcher';
import { getCompanyStoreList } from 'services/API/Company';
import { createUser, showUser, updateUser } from 'services/API/User';
import { CreateUserProps, EditUserProps } from 'services/API/User/interface';
import { useSwal } from 'helpers/sweetalert';
import '../../layouts/button/button.scss';
import { validate } from './validator';
export interface CreateUserModelProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  reloadUserList: () => void;
  userId?: string;
}

const initialState = {
  role: '',
  email: '',
  phone: '',
  username: '',
  first_name: '',
  last_name: '',
  password: '',
  confirm_password: '',
  can_refund: true,
  stores: [] as Array<any>,
};

const valueToLabel = (value: any) => {
  if (!value) {
    return { label: '', value: null };
  }
  value.toString = () => value.id;
  return {
    label: value.name,
    value: value,
  };
};

export const UserModel = ({ isOpen, setIsOpen, reloadUserList, userId }: CreateUserModelProps) => {
  const {
    auth: {
      user: { company_id },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const [form, setForm] = useState(initialState);
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [errors, setErrors] = useState<any>({});
  const {
    role,
    email,
    phone,
    username,
    first_name,
    last_name,
    password,
    confirm_password,
    can_refund,
  } = form;
  const [selectedUser, setSelectedUser] = useState<userProps>();
  const isEdit = typeof userId !== 'undefined';

  const toggle = () => {
    setForm(initialState);
    setIsOpen(!isOpen);
  };

  const searchStore: LoadOptions<any, any> = async (keyword, loadedOptions, additional) => {
    const selectedStoreIdArr = form.stores.map((s) => s.id);
    const page = additional?.page || 1;
    const result = await getCompanyStoreList(company_id, { page, keyword: keyword.toLowerCase() });
    const hasMore = !!result!.meta!.pagination?.links.next ?? false;
    const stores = result.data.filter((store) => !selectedStoreIdArr.includes(store.id));

    return {
      options: stores.map(valueToLabel),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (name: string) => (val: any) => {
    setForm({
      ...form,
      [name]: val,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { errors: err, isValid } = validate(
      { ...form, store_id: form.stores.map((s) => s.id).join(',') },
      isEdit,
    );
    setErrors(err);
    if (!isValid) {
      return false;
    }
    setErrors({});

    let res: DefaultResponse;
    let message: string;
    let body = {
      email,
      phone,
      first_name,
      last_name,
      username,
      password,
      can_refund,
    } as CreateUserProps | EditUserProps;
    if (form.stores.length) {
      body.stores = form.stores.map((s) => s.id);
    }
    body = removeEmptyValuePair(body);

    if (selectedUser) {
      res = await updateUser(selectedUser.id, body as EditUserProps);
      message = res.success
        ? 'congratulations_new_user_successfully_updated'
        : res.errors?.[0].message ?? res.message ?? 'Oops';
    } else {
      (body as CreateUserProps).role = form.role;

      res = await createUser(body as CreateUserProps);
      message = res.success
        ? 'congratulations_new_user_successfully_created'
        : res.errors?.[0].message ?? res.message ?? 'Oops';
    }

    await Swal.fire({
      title: translate(isEdit ? 'update_user' : 'create_user'),
      text: translate(message),
      icon: res.success ? 'success' : 'error',
    });

    if (res.success) {
      reloadUserList();
      toggle();
    }
  };

  useEffect(() => {
    if (userId) {
      (async () => {
        const selectedUser = (await showUser(userId, { include: 'stores' })).data;
        selectedUser.phone = selectedUser.phone?.split(' ')?.[1] ?? '';
        setSelectedUser(selectedUser);
        setForm({ ...selectedUser, password: '', confirm_password: '' });
      })();
    }
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {translate(selectedUser ? 'edit' : 'create')} {translate('user')}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          {selectedUser === undefined && (
            <Input
              type="react-select"
              legend={translate('role').toUpperCase()}
              options={translateLabel(AVAILABLE_ROLES_FOR_CREATE_FOR_REACT_SELECT, translate)}
              name="role"
              placeholder={translate('choose_role')}
              value={role}
              onChange={handleSelectChange('role')}
              isRequired
              error={translate(...(errors.role ?? ''))}
            />
          )}
          {role !== '' && (
            <>
              <Input
                type="text"
                legend={translate('email').toUpperCase()}
                name="email"
                value={email}
                onChange={handleChange}
                isRequired
                error={translate(...(errors.email ?? ''))}
              />
              <Input
                type="text"
                legend={translate('phone').toUpperCase()}
                name="phone"
                value={phone}
                onChange={handleChange}
                error={translate(...(errors.phone ?? ''))}
              />
              <Username
                username={username}
                setUsername={(username: string) => setForm({ ...form, username })}
                setErrors={setErrors}
                errors={errors}
              />
              <div className="row g-0">
                <div className="col-lg-6">
                  <Input
                    type="text"
                    legend={translate('first_name').toUpperCase()}
                    name="first_name"
                    value={first_name}
                    onChange={handleChange}
                    isRequired
                    error={translate(...(errors.first_name ?? ''))}
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    type="text"
                    legend={translate('last_name').toUpperCase()}
                    name="last_name"
                    value={last_name}
                    onChange={handleChange}
                    isRequired
                    error={translate(...(errors.last_name ?? ''))}
                  />
                </div>
              </div>
              <div className="row g-0">
                <div className="col-lg-6">
                  <Password
                    password={password}
                    setPassword={(password) => setForm({ ...form, password })}
                    setErrors={setErrors}
                    errors={errors}
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    type="password"
                    legend={translate('confirm_password').toUpperCase()}
                    name="confirm_password"
                    value={confirm_password}
                    onChange={handleChange}
                    isRequired={!selectedUser}
                    error={translate(...(errors.confirm_password ?? ''))}
                    autoComplete={'off'}
                  />
                </div>
              </div>
              {
                role === operator && (
                  <>
                    <Input
                      type="react-select-async"
                      isMulti
                      isRequired
                      legend={translate('store').toUpperCase()}
                      name="store_id"
                      loadOptions={searchStore}
                      value={form.stores.map(valueToLabel)}
                      placeholder={translate('choose_store')}
                      onChange={handleSelectChange('stores')}
                      error={translate(...(errors.store_id ?? ''))}
                    />
                  </>
                ) //Store
              }

              <Input
                type="react-select"
                legend={translate('can_refund').toUpperCase()}
                name="can_refund"
                options={translateLabel(BOOLEAN_OPTIONS, translate, true)}
                value={can_refund}
                placeholder=""
                onChange={handleSelectChange('can_refund')}
                isRequired
                error={translate(...(errors.can_refund ?? ''))}
              />

              <div className="d-grid">
                <button className="btn btn-primary mt-3">
                  {translate(selectedUser ? 'update' : 'create')}
                </button>
              </div>
            </>
          )}
        </form>
      </ModalBody>
    </Modal>
  );
};
