import { EMAIL_REGEX } from 'constants/format';
import isEmpty from 'lodash/isEmpty';
import { RequestFirstSetPasswordProps } from 'services/API/Auth/interface';

export const validate = (body: RequestFirstSetPasswordProps, type: string) => {
  const error: { [key: string]: string } = {};

  const { email, country_code, phone } = body;

  if (type === 'email') {
    if (!EMAIL_REGEX.test(email)) {
      error.email = 'error_invalid_email_format';
    }
  }

  if (type === 'phone') {
    if (country_code === '') {
      error.country_code = 'error_required';
    }
    if (phone === '') {
      error.phone = 'error_required';
    }
  }

  return {
    isValid: isEmpty(error),
    error,
  };
};
