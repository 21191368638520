import { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios, {
  defaultRequestHandler,
  DefaultResponse,
  getToken,
  params as paramsFunc,
} from './Fetcher';
import { ContentType } from './Headers';

export interface ArrayResponse<T> extends DefaultResponse {
  data: Array<T>;
}
export interface SingleResponse<T> extends DefaultResponse {
  data: T;
}

class RestfulAPI<T, E> {
  multiplePath: string;
  singlePath: string;

  constructor(multiplePath: string, singlePath = '') {
    this.multiplePath = multiplePath;
    this.singlePath = singlePath;
  }

  getAll(params = {}): Promise<ArrayResponse<T>> {
    return defaultRequestHandler(
      axios.get(`${this.multiplePath}?${paramsFunc(params)}`, {
        headers: {
          authorization: getToken(),
        },
        // params,
      }),
    );
  }
  get(params = {}): Promise<SingleResponse<T>> {
    if (!this.singlePath) {
      throw new Error('Missing ID');
    }

    return defaultRequestHandler(
      axios.get(this.singlePath, {
        headers: {
          authorization: getToken(),
        },
        params,
      }),
    );
  }
  create(body: E): Promise<SingleResponse<T>> {
    return defaultRequestHandler(
      axios.post(this.multiplePath, body, {
        headers: {
          authorization: getToken(),
          'Content-Type': ContentType.json,
        },
      }),
    );
  }
  update(body: E): Promise<SingleResponse<T>> {
    if (!this.singlePath) {
      throw new Error('Missing ID');
    }

    return defaultRequestHandler(
      axios.put(this.singlePath, body, {
        headers: {
          authorization: getToken(),
          'Content-Type': ContentType.json,
        },
      }),
    );
  }
  delete(params = {}): Promise<SingleResponse<T>> {
    if (!this.singlePath) {
      throw new Error('Missing ID');
    }

    return defaultRequestHandler(
      axios.delete(this.singlePath, {
        headers: {
          authorization: getToken(),
        },
        params,
      }),
    );
  }
  action(config: AxiosRequestConfig) {
    return defaultRequestHandler(
      axios.request({
        ...config,
        headers: {
          authorization: getToken(),
          ...config.headers,
        },
      }),
    );
  }
}

export default RestfulAPI;
