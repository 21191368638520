import { createContext, FC, ReactNode, useEffect, useState } from 'react';

const initialState = {
  width: window.innerWidth,
};

export interface WidthDetectorProviderProps {
  children: ReactNode;
}

export const WidthDetectorContext = createContext(initialState);

export const WidthDetector: FC<WidthDetectorProviderProps> = ({
  children,
}: WidthDetectorProviderProps) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <WidthDetectorContext.Provider value={{ width }}>{children}</WidthDetectorContext.Provider>
  );
};
