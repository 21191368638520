import { FC } from 'react';
import './Text.scss';

interface IText {
  type: string;
  value: string;
  className?: string;
}

export const Text: FC<IText> = ({ type, value, className }: IText) => {
  return (
    <div className={`text-infos mt-3 ${className || ''}`}>
      <span>{type}</span>
      <p>{value}</p>
    </div>
  );
};
