import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { ThunkResult } from 'redux/types';
import { AnyResponse } from 'services/API/common/Fetcher';
import { getAPI } from 'services/API/Ttp_Device';
import { TtpDeviceEditProps } from 'services/API/Ttp_Device/interface';

export const getTtpDeviceListAction = (
  companyId: string,
  params?: object,
): ThunkResult<Promise<AnyResponse>> => asyncRequestHandler(() => getAPI(companyId).getAll(params));

export const deleteTtpDeviceAction = (
  companyId: string,
  TtpId: string,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => getAPI(companyId, TtpId).delete());

export const updateTtpDeviceAction = (
  companyId: string,
  TtpId: string,
  Ttp: TtpDeviceEditProps,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => getAPI(companyId, TtpId).update(Ttp));

export const enableTtpDeviceAction = (
  companyId: string,
  TtpId: string,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => {
    const api = getAPI(companyId, TtpId);
    const url = api.singlePath + '/enable';
    return api.action({
      url,
      method: 'POST',
    });
  });
export const disableTtpDeviceAction = (
  companyId: string,
  TtpId: string,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => {
    const api = getAPI(companyId, TtpId);
    const url = api.singlePath + '/disable';
    return api.action({
      url,
      method: 'POST',
    });
  });
