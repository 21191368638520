import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import { isAccountant, isSuperowner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import { updateStore as apiUpdateStore } from 'services/API/Store';
import { UpdateStoreProps } from 'services/API/Store/interface';
import { createStoreTerm, updateStoreTerm } from 'services/API/StoreTerm';
import { StoreTermFormProps } from 'services/API/StoreTerm/interface';
import { UploadLogoModal } from './UploadLogoModal';
import { validateDynamicLink } from './validator';

interface StoreTermModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isEdit: boolean;
  reload: () => void;
  logos: Array<SelectOptionProps>;
  store: any | null;
}

const initialState = {
  store_id: '',
  delivery_en: '',
  delivery_zh: '',
  cancellation_en: '',
  cancellation_zh: '',
} as StoreTermFormProps;

const storeFormInitialState = {
  name: '',
  phone: '',
  email: '',
  logo: '',
} as UpdateStoreProps;

export const CreateEditTermModal = ({
  isOpen,
  setIsOpen,
  isEdit,
  reload,
  logos,
  store,
}: StoreTermModalProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [storeForm, setStoreForm] = useState<UpdateStoreProps>(storeFormInitialState);
  const [form, setForm] = useState<StoreTermFormProps>(initialState);
  const [storeId, setStoreId] = useState('');
  const [isUploadDocModalOpen, setIsUploadDocModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});

  // Form Functions
  const onStoreFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreForm({
      ...storeForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (name: string) => (value: any) => {
    setStoreForm({
      ...storeForm,
      [name]: value,
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setStoreId(store ? store?.value.id : '');

    if (store) {
      setStoreForm({
        name: store.value.name,
        phone: store.value.phone,
        email: store.value.email,
        logo: store.value.logo,
      });
    } else {
      setStoreForm(storeFormInitialState);
    }

    if (!isEdit) {
      initialState.cancellation_en = translate('store_term_default_cancellation_en');
      initialState.cancellation_zh = translate('store_term_default_cancellation_zh');
      initialState.delivery_en = '3 - 14';
    }

    if (isEdit) {
      const attr: any = {};

      attr.cancellation_en = store.value.store_term?.cancellation?.en;
      attr.cancellation_zh = store.value.store_term?.cancellation?.zh;
      attr.delivery_en = store.value.store_term?.delivery?.en;

      setForm(attr);
    } else {
      setForm(initialState);
    }
  }, [store, isOpen]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      store_id: '',
      delivery: { en: '', zh: '' },
      cancellation: { en: '', zh: '' },
    };
    body.delivery.en = form.delivery_en;
    body.delivery.zh = form.delivery_en;
    body.cancellation.en = form.cancellation_en;
    body.cancellation.zh = form.cancellation_zh;

    const { errors, isValid } = validateDynamicLink(form, storeId, storeForm);
    setErrors(errors);
    if (!isValid) {
      return;
    }

    const updateStoreRes = await apiUpdateStore(store!.value.id, storeForm);
    if (!updateStoreRes.success) {
      Swal.fire({
        title: translate('update_store'),
        text: updateStoreRes?.message ?? 'Oops',
        icon: 'error',
      });
      return;
    }

    let apiCall: () => Promise<any>;

    if (isEdit) {
      apiCall = () => updateStoreTerm(store.value.store_term.id, body);
    } else {
      apiCall = () => createStoreTerm(storeId, body);
    }

    setIsLoading(true);
    const res = await apiCall();
    setIsLoading(false);

    if (!res.success) {
      await Swal.fire({
        title: translate('terms'),
        text: res.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    await Swal.fire({
      icon: 'success',
      title: translate('store_details_and_terms'),
      text: translate('congratulations_store_and_terms_successfully_updated'),
    });
    reload();
    toggle();
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{translate('update_store_detail_and_term')}</ModalHeader>
        <ModalBody>
          <div className="p-2">
            <form onSubmit={onSubmit}>
              <div className="justify-content-center">
                <div className="text-center mb-2">
                  <div className="text-start">
                    <Input
                      value={storeForm.phone}
                      onChange={onStoreFormChange}
                      name="phone"
                      type="text"
                      legend={translate('phone').toUpperCase()}
                      error={translate(...(errors.name ?? ''))}
                    />
                    <Input
                      value={storeForm.email}
                      onChange={onStoreFormChange}
                      name="email"
                      type="text"
                      legend={translate('email').toUpperCase()}
                      error={translate(...(errors.email ?? ''))}
                    />
                    <Input
                      value={storeForm.logo}
                      onChange={handleSelectChange('logo')}
                      name="logo"
                      options={logos}
                      defaultLabel={translate('select_logo')}
                      type="react-select"
                      legend={translate('logo').toUpperCase()}
                    />
                    {!isAccountant(user.role) && !isSuperowner(user.role) && (
                      <div className="document mt-2 mb-3 row mx-3">
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          style={{ margin: 'auto' }}
                          onClick={() => setIsUploadDocModalOpen(true)}>
                          {translate('upload_new_logo')}
                        </button>
                      </div>
                    )}
                    <Input
                      type="text"
                      value={form.delivery_en}
                      name="delivery_en"
                      maxLength={7}
                      legend={translate('delivery_terms').toUpperCase()}
                      onChange={onChange}
                      error={translate(...(errors.delivery_en ?? ''))}
                    />

                    <div className="cancellation-input">
                      <Input
                        type="textarea"
                        value={form.cancellation_en}
                        name="cancellation_en"
                        legend={translate('cancellation_terms_en').toUpperCase()}
                        onChange={onChange}
                        error={translate(...(errors.cancellation_en ?? ''))}
                      />
                      <Input
                        type="textarea"
                        value={form.cancellation_zh}
                        name="cancellation_zh"
                        legend={translate('cancellation_terms_zh').toUpperCase()}
                        onChange={onChange}
                        error={translate(...(errors.cancellation_zh ?? ''))}
                      />
                    </div>

                    <div className="d-grid">
                      <button className="btn btn-primary mt-3" disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" /> : translate('submit')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <UploadLogoModal
        isModalOpen={isUploadDocModalOpen}
        setIsModalOpen={setIsUploadDocModalOpen}
      />
    </>
  );
};
