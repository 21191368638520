export const dataURLtoBlob = (dataUrl: string) => {
  const arr = dataUrl.split(',') as [string, string],
    mime = (arr[0].match(/:(.*?);/) as [string, string])[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',') as [string, string],
    mime = (arr[0].match(/:(.*?);/) as [string, string])[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
