import logo from 'assets/img/registrationForm/companylogo_bluepicblackword.png';
import { Animated } from 'components/custom/AnimatedContainer';
import { LanguageDropdown } from 'components/custom/TranslateDropdown';
import { FC, ReactNode, useEffect } from 'react';
import './authentication.scss';

export interface AuthenticationLayoutProps {
  children: ReactNode;
}

export const AuthenticationLayout: FC<AuthenticationLayoutProps> = ({
  children,
}: AuthenticationLayoutProps) => {
  useEffect(() => {
    const body = document.body;
    body.classList.add('grey-bg');
    return () => {
      body.classList.remove('grey-bg');
    };
  }, []);

  return (
    <>
      <Animated type="fadeInDown" className="container position-relative">
        <div className="fixed-lang-dropdown">
          <LanguageDropdown />
        </div>
        <div id="reg-wrapper">
          <div className="mx-auto yedpay-logo w-30">
            <img src={logo} alt="Yedpay Logo" className="w-100" />
          </div>
          <div className="px-lg-0 px-4">{children}</div>
        </div>
      </Animated>
    </>
  );
};
