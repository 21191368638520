import { updateBalance } from 'actions/BalanceActions';
import { useTranslate } from 'context/TranslateContext';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { FunctionDispatch } from 'redux/types';
import onNotification, { initFirebaseMessaging } from 'services/firebase';
import { useSwal } from 'helpers/sweetalert';

export const PushNotification = () => {
  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate, hasTranslate } = useTranslate();

  const displayToast = (prefix: string, status: string) => {
    let func;
    switch (status) {
      case 'pending':
      case 'processing':
      case 'waiting_from_bank':
      default:
        func = toast.info;
        break;
      case 'paid':
      case 'completed':
        func = toast.success;
        break;
      case 'cancelled':
      case 'refunded':
        func = toast.warning;
        break;
      case 'failed':
      case 'rejected':
        func = toast.error;
        break;
    }
    if (hasTranslate(status)) {
      func(`${prefix}: ${translate(status)}`);
    }
  };

  useEffect(() => {
    initFirebaseMessaging();

    return onNotification((payload: any) => {
      try {
        const data = JSON.parse(payload.data.json);

        switch (data.push_type) {
          case 2: //CompletedSettlement
            displayToast(
              `${translate('settlement')} ($${data.amount} ${data.currency})`,
              data.status,
            );
            break;
          case 1: //NewPayment
          case 4: //FailPayment
            displayToast(translate('transaction_status'), data.status);
            break;
          case 3: //UpdatedPayment
            data.transactions.forEach((transaction: any) => {
              displayToast(translate('transaction_status'), transaction.status);
            });
            break;
        }
      } catch (e) {}

      dispatch(updateBalance()).then((res) => {
        if (!res.success) {
          Swal.fire({
            icon: 'error',
            title: translate('user'),
            text: res.message || 'Oops!',
          });
        }
      });
    });
  }, []);

  return <ToastContainer limit={5} />;
};
