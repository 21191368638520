import { faPen, faPrint, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from 'context/TranslateContext';
import { useState } from 'react';
import { Collapse } from 'reactstrap';
import { activateFixedCode, deactivateFixedCode } from 'services/API/FixedCode';
import {
  AttributesEmvCode,
  AttributesFixedCode,
  CompanyQrCode,
} from 'services/API/FixedCode/interface';
import { useSwal } from 'helpers/sweetalert';

interface ActionBoxProps {
  isOpen: boolean;
  fixedCode?: CompanyQrCode | null;
  onEdit: (fixedCode: CompanyQrCode) => void;
  reload: () => void;
  setIsPrintModalOpen: (isPrintOpen: boolean) => void;
}

interface RowProps {
  name: string;
  data: string;
}

export const ActionBox = ({
  isOpen,
  fixedCode,
  onEdit,
  reload,
  setIsPrintModalOpen,
}: ActionBoxProps) => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [isLoading, setIsLoading] = useState(false);

  const onDisable = async () => {
    setIsLoading(true);
    const res = await deactivateFixedCode(fixedCode?.id ?? '');
    Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('deactivate_code'),
      text: res.success ? translate('qr_code_successfully_disabled') : res?.message || 'Oops',
    });
    setIsLoading(false);
    reload();
  };

  const onEnable = async () => {
    setIsLoading(true);
    const res = await activateFixedCode(fixedCode?.id ?? '');
    Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('activate_code'),
      text: res.success ? translate('qr_code_successfully_enabled') : res?.message || 'Oops',
    });
    setIsLoading(false);
    reload();
  };

  const openPrintModal = () => {
    setIsPrintModalOpen(true);
  };

  const Row = ({ name, data }: RowProps) => {
    return (
      <div className="row">
        <div className="col-6">{name}</div>
        <div className="col-6 colfax-regular">{data}</div>
      </div>
    );
  };

  const attr: any = {};
  if (fixedCode?.type === 'fixed_code') {
    const attributes = fixedCode?.attributes as AttributesFixedCode;
    attr.name = attributes.subtitle_name
      ? `${attributes.name} (${attributes.subtitle_name})`
      : attributes.name;
    attr.store_name = attributes.store_name;
    attr.status = attributes.status.toLowerCase() === 'active';
  } else if (fixedCode?.type === 'emv_code') {
    const attributes = fixedCode?.attributes as AttributesEmvCode;
    attr.name = attributes.name;
    attr.store_name = attributes.store_name;
    attr.status = !!attributes.status;
  }

  return (
    <>
      <div className="bg-white mb-4 round-sm">
        <div className="filter-title ">
          <span className="colfax-bold">{translate('actions')}</span>
        </div>
        <div className="py-1">
          <Collapse isOpen={isOpen}>
            <div className="px-3 py-3">
              <Row name={translate('name')} data={attr.name ?? ''} />
              <Row name={translate('store_name')} data={attr.store_name ?? ''} />
            </div>

            <div className="border-bottom" />
            <div className="px-3 py-3">
              <div className="row g-0">
                {fixedCode?.type === 'fixed_code' && attr.status ? (
                  <>
                    <div className="col-auto text-center">
                      <button
                        className="btn btn-warning"
                        onClick={() => onEdit(fixedCode!)}
                        disabled={isLoading}>
                        <FontAwesomeIcon icon={faPen} />
                        &nbsp;
                        {translate('edit')}
                      </button>
                    </div>
                    &nbsp;
                    <div className="col-auto text-center">
                      <button className="btn btn-danger" onClick={onDisable} disabled={isLoading}>
                        <FontAwesomeIcon icon={faTrash} />
                        &nbsp;
                        {translate('disable')}
                      </button>
                    </div>
                  </>
                ) : (
                  fixedCode?.type === 'fixed_code' &&
                  !attr.status && (
                    <>
                      <div className="col text-center">
                        <button className="btn btn-primary" onClick={onEnable} disabled={isLoading}>
                          <FontAwesomeIcon icon={faUndo} />
                          &nbsp;
                          {translate('enable')}
                        </button>
                      </div>
                    </>
                  )
                )}

                <div className="col text-center">
                  <button className="btn btn-primary" onClick={openPrintModal}>
                    <FontAwesomeIcon icon={faPrint} />
                    &nbsp;
                    {translate('download_printable')}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};
