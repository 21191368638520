import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import { useTranslate } from 'context/TranslateContext';
import { Html5Qrcode } from 'html5-qrcode';
import {
  QrcodeErrorCallback,
  QrcodeSuccessCallback,
  QrDimensionFunction,
  QrDimensions,
} from 'html5-qrcode/esm/core';
import { useEffect, useState } from 'react';

const qrcodeRegionId = 'html5qr-code-full-region';

export interface Html5QrcodePluginProps {
  fps?: number;
  qrbox?: number | QrDimensions | QrDimensionFunction | undefined;
  verbose?: boolean;
  aspectRatio?: number | undefined;
  disableFlip?: boolean | undefined;
  videoConstraints?: MediaTrackConstraints | undefined;
  qrCodeSuccessCallback: QrcodeSuccessCallback;
  qrCodeErrorCallback?: QrcodeErrorCallback;
}

export const Html5QrcodePlugin = (props: Html5QrcodePluginProps) => {
  const [html5Qrcode, setHtml5Qrcode] = useState<Html5Qrcode>();
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>();
  const [facingModeOptions, setFacingModeOptions] = useState<Array<SelectOptionProps>>([]);

  const { translate } = useTranslate();

  const createConfig = (props: Html5QrcodePluginProps) => {
    const config: any = {};
    if (props.fps) {
      config.fps = props.fps;
    }
    if (props.qrbox) {
      config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
      config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
      config.disableFlip = props.disableFlip;
    }
    return config;
  };

  useEffect(() => {
    if (!html5Qrcode) {
      return;
    }
    localStorage.setItem('cameraFacingMode', facingMode as string);

    (async () => {
      const config = createConfig(props);
      try {
        await html5Qrcode.stop();
      } finally {
        html5Qrcode.start(
          { facingMode },
          config,
          props.qrCodeSuccessCallback,
          props.qrCodeErrorCallback,
        );
      }
    })();
  }, [facingMode]);

  useEffect(() => {
    const code = new Html5Qrcode(qrcodeRegionId);
    setHtml5Qrcode(code);

    let defaultFacingMode = localStorage.getItem('cameraFacingMode');
    if (defaultFacingMode !== 'user') {
      defaultFacingMode = 'environment';
    }

    setTimeout(async () => {
      setFacingMode(defaultFacingMode as 'user' | 'environment');
      const cameras = await Html5Qrcode.getCameras();
      if (cameras.length > 1) {
        setFacingModeOptions([
          { label: translate('back_camera'), value: 'environment' },
          { label: translate('front_camera'), value: 'user' },
        ]);
      }
    });

    return () => {
      code.stop();
      code.clear();
    };
  }, []);
  return (
    <>
      {facingMode && facingModeOptions.length > 0 && (
        <Input
          type="react-select"
          legend=""
          options={facingModeOptions}
          value={facingMode}
          name="facingMode"
          isSearchable={false}
          onChange={setFacingMode}
        />
      )}
      <div id={qrcodeRegionId} />
    </>
  );
};
