import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { GATEWAY_ORDER, HIDE_GATEWAY, PAY_PLUS_GATEWAY } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { GatewayList } from '../../Account/payment/GatewayList';
import { useGateways } from '../../Account/payment/hooks';
import '../../layouts/card/GatewayCard.scss';

export const PayPlusPaymentGateway = () => {
  const {
    auth: { user, credentials },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const { translate } = useTranslate();

  const [mccAvailableGatewayCodeList, getCredentials] = useGateways(user.company_id);
  if (mccAvailableGatewayCodeList.length === 0 && credentials.length === 0) {
    return <></>;
  }

  const availableApplyPayPlusGatewayCodeList = mccAvailableGatewayCodeList
    .filter(
      (code) => !credentials.map((cred) => cred.gateway_code as string)?.includes(code) ?? false,
    )
    .filter((code) => !HIDE_GATEWAY.includes(code))
    .filter((code) => PAY_PLUS_GATEWAY.includes(code))
    .sort((a, b) => GATEWAY_ORDER.indexOf(a) - GATEWAY_ORDER.indexOf(b));

  const appliedPayPlusGateways = credentials.filter((cred) =>
    PAY_PLUS_GATEWAY.includes(cred.gateway_code),
  );

  return (
    <>
      <TopHeader title={translate('pay_plus')} textOnly={false} />
      <SideNavbar location={location as any} type="payplus" />
      <div id="main-content" className="hide-scrollbar">
        <div id="gateway-content" className="hide-scrollbar" style={{ paddingBottom: '3rem' }}>
          {appliedPayPlusGateways.length > 0 && (
            <GatewayList
              title={translate('my_gateways')}
              codeList={appliedPayPlusGateways.map((cred) => cred.gateway_code)}
              isApplied={true}
              getCredentials={getCredentials}
              appliedOnlineGateway={true}
              textOnly={true}
            />
          )}
          {availableApplyPayPlusGatewayCodeList.length > 0 && (
            <GatewayList
              title={translate('setup_payment_gateways')}
              caption={translate('choose_and_add_gateway')}
              codeList={availableApplyPayPlusGatewayCodeList}
              isApplied={false}
              getCredentials={getCredentials}
              appliedOnlineGateway={true}
              textOnly={true}
            />
          )}
        </div>
      </div>
    </>
  );
};
