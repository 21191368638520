import axios, { ContentType, defaultRequestHandler, getToken } from '../common/Fetcher';
import { DefaultResponse, showDocumentRequestHandler } from './../common/Fetcher';
import { getDocumentResponse } from './interface';

// https://dev2.yedpay.com/document/admin/showDocument
export const getDocument = (document_id: string): Promise<getDocumentResponse> =>
  showDocumentRequestHandler(
    axios.get(`/documents/${document_id}`, {
      headers: {
        authorization: getToken(),
      },
      responseType: 'blob',
    }),
  ) as Promise<getDocumentResponse>;

// https://dev2.yedpay.com/document/admin/deleteDocument
export const deleteDocument = (document_id: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.delete(`/documents/${document_id}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const updateDocument = (document_id: string, data: FormData) =>
  defaultRequestHandler(
    axios.post(`/documents/${document_id}`, data, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );
