import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import './NavButton.scss';

interface INavButton {
  to: string;
  src: string;
  srcActive: string;
  isActive: boolean;
  index: number;
  name: string;
  className?: string;
}

export const NavButton: FC<INavButton> = ({
  to,
  src,
  srcActive,
  isActive,
  index,
  name,
  className,
}: INavButton) => {
  return (
    <NavLink to={to} className={`link ${className ?? ''}`}>
      <div className={`route `}>
        <div className={`rounded-circle img-fluid route${index}`}>
          <img src={isActive ? srcActive : src} alt="nav element" className={` img-fluid `} />
        </div>
        <span style={{ marginLeft: '1rem' }}>{name}</span>
      </div>
    </NavLink>
  );
};
