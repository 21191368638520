import { fetchWithdrawal } from 'actions/SettlementActions';
import { Input } from 'components/custom/input';
import { ReactSelectCurrencyOptions } from 'constants/Currencies';
import { useTranslate } from 'context/TranslateContext';
import { useIdempotencyKey } from 'helpers/hooks/useIdempotencyKey';
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FunctionDispatch } from 'redux/types';
import { useSwal } from 'helpers/sweetalert';
import { validate } from './validator';

const initialState = {
  amount: '',
  currency: 'HKD',
};

export interface WithdrawalModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (state: boolean) => void;
  reloadData?: () => Promise<void>;
}

export const WithdrawalModal: FC<WithdrawalModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  reloadData,
}: WithdrawalModalProps) => {
  const [data, setData] = useState(initialState);
  const [addIdempotencyKey, resetIdempotencyKey] = useIdempotencyKey<typeof data>();
  const [errors, setErrors] = useState<any>({});

  const { translate } = useTranslate();
  const [Swal] = useSwal();

  const dispatch = useDispatch<FunctionDispatch>();

  const { amount, currency } = data;

  const handleInputChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: (e.target as any).rawValue,
    });
  };

  const handleSelectChange = (name: string) => (value: string) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleCloseModal = () => {
    if (reloadData) {
      reloadData();
    }
    setIsOpenModal(false);
    setData(initialState);
    setErrors({});
  };

  const onSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { isValid, errors } = validate(data);
    setErrors(errors);
    if (!isValid) {
      return;
    }

    const res = await dispatch(fetchWithdrawal(data));
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('withdrawal'),
      text: res.message,
    });

    handleCloseModal();
  };

  useEffect(resetIdempotencyKey, [isOpenModal]);

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={handleCloseModal}
      unmountOnClose={true}
      className="round-lg"
      centered={true}>
      <ModalHeader toggle={handleCloseModal}>{translate('withdrawal')}</ModalHeader>
      <ModalBody>
        <Input
          legend={translate('currency').toUpperCase()}
          name="currency"
          onChange={handleSelectChange('currency')}
          value={currency}
          type="react-select"
          placeholder="Select Currency"
          options={ReactSelectCurrencyOptions}
          error={translate(...(errors.currency ?? ''))}
        />
        <Input
          legend={translate('amount').toUpperCase()}
          name="amount"
          onChange={handleInputChange}
          value={amount}
          type="cleave"
          placeholder={translate('enter_amount')}
          prefix="$"
          cleaveOptions={{
            numeral: true,
            numeralDecimalScale: 2,
            numeralIntegerScale: 7,
            numeralPositiveOnly: true,
          }}
          error={translate(...(errors.amount ?? ''))}
        />
        <div className="d-grid">
          <button className="btn btn-primary mt-4 round-lg" onClick={onSubmit}>
            {translate('send')}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
