import { FormContainer } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { AuthenticationLayout } from 'components/layouts/authentication.layout';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { DefaultResponse } from 'services/API/common/Fetcher';
import { register as rsoRegister } from 'services/API/RsoRegistration';
import { RegisteredReturn } from 'services/API/RsoRegistration/interface';
import Swal from 'sweetalert2';
import { RegistrationFormProps, store_type } from './interface';
import { validate } from './validator';
import { useLocation } from 'react-router-dom';

const initialState = {
  email: '',
  company_name: '',
  phone: '',
};

export const RsoRegister = () => {
  const { translate } = useTranslate();
  const location = useLocation();

  const [form, setForm] = useState<RegistrationFormProps>(initialState);
  const [leadSource, setLeadSource] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState<ANY_OBJECT>({});

  useEffect(() => {
    switch (location.pathname) {
      case '/dbs-register':
        setLeadSource('DBS');
        break;
      case '/meepay-register':
        setLeadSource('MeePay');
        break;
      case '/polly-register':
        setLeadSource('Polly');
        break;
      default:
        setLeadSource('Self-registered');
    }
  }, []);

  const onFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onStoreTypeChange = (store_type: store_type) => {
    setForm({
      ...form,
    });
  };

  const onGatewaysChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      ...form,
      lead_source: leadSource,
    };

    const { errors, isValid } = validate(payload);
    setErrors(errors);
    if (!isValid) {
      return;
    }

    setIsLoading(true);
    let res = await rsoRegister(payload);
    setIsLoading(false);

    if ((res as RegisteredReturn).data?.enrollment_url) {
      window.location.href = res.data.enrollment_url as string;
    } else {
      res = res as DefaultResponse;
      const message = res.errors ? res.errors[0].message : res.message;
      await Swal.fire({
        icon: 'error',
        title: translate('register'),
        text: message,
      });
    }
  };

  return (
    <AuthenticationLayout>
      <FormContainer>
        <form onSubmit={onSubmit}>
          <Input
            legend={translate('company_name').toUpperCase()}
            type="text"
            name="company_name"
            value={form.company_name}
            onChange={onFormChange}
            placeholder={translate('enter_your_company_name')}
            isRequired
            error={translate(...(errors['company_name'] ?? ''))}
          />
          <Input
            isRequired
            type="text"
            legend={translate('email').toUpperCase()}
            name="email"
            value={form.email as string}
            onChange={onFormChange}
            placeholder={translate('enter_your_email_address')}
            error={translate(...(errors.email ?? ''))}
          />
          <Input
            isRequired
            type="text"
            legend={translate('phone').toUpperCase()}
            name="phone"
            value={form.phone as string}
            onChange={onFormChange}
            placeholder={translate('enter_your_contact_mobile')}
            error={translate(...(errors.phone ?? ''))}
          />
          {errors.gateways && (
            <span className="help-block text-start error-text fx-14">
              {translate(errors.gateways)}
            </span>
          )}

          <div className="row mt-4">
            <div className="col-12 col-lg-12 d-grid">
              <button className="btn btn-primary" disabled={isLoading === true}>
                {isLoading && (
                  <>
                    <Spinner size="sm" />
                    &nbsp;
                  </>
                )}
                {translate('submit')}
              </button>
            </div>
          </div>
        </form>
      </FormContainer>
    </AuthenticationLayout>
  );
};
