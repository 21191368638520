import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, ThunkResult } from 'redux/types';
import { getDeviceList } from 'services/API/Device';
import { GetDeviceListResponse } from 'services/API/Device/interface';
import { receiveData } from './DataAction';

export const getDevicesListAction = (data?: object): ThunkResult<Promise<GetDeviceListResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch): Promise<GetDeviceListResponse> => {
    const res: GetDeviceListResponse = await getDeviceList(data);
    if (res.success) {
      dispatch(receiveData(res, 'devices'));
    }

    return res;
  });
