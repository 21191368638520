import Compress from 'compress.js';
import { MAX_UPLOAD_SIZE } from 'constants/format';

export const compression = async (file: File) => {
  if (file.size <= MAX_UPLOAD_SIZE) {
    return file;
  }

  const compress = new Compress();
  const compressed = await compress.compress([file], {
    size: 2,
    quality: 0.5,
    resize: true,
  });
  const compressedFile = compressed[0];
  const base64str = compressedFile.data;
  const ext = compressedFile.ext;
  const base64ToFile: any = Compress.convertBase64ToFile(base64str, ext);
  base64ToFile.lastModified = new Date();
  base64ToFile.name = file.name;
  return base64ToFile;
};
