import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthStateProps } from 'redux/state/AuthState';
import { GlobalTypes } from 'redux/types';

interface StateProps {
  auth: AuthStateProps;
}

const checkAuth = function (Component: FC) {
  const AuthWrapper: FC<StateProps> = ({ auth }: StateProps) => {
    const { isAuthenticated } = auth;
    const history = useHistory();

    useEffect(() => {
      if (isAuthenticated) {
        history.replace('/login/redirect');
      }
      //eslint-disable-next-line
    }, [isAuthenticated]);

    return <Component />;
  };

  const mapStateToProps = (state: GlobalTypes.RootState): StateProps => ({
    auth: state.auth,
  });

  return connect(mapStateToProps)(AuthWrapper);
};
export default checkAuth;
