import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, ThunkResult } from 'redux/types';
import { AnyResponse, DefaultResponse } from 'services/API/common/Fetcher';
import { getAllSettlement, requestSettlement } from 'services/API/Settlement';
import {
  GetSettlementListResponse,
  RequestSettlementBody,
} from 'services/API/Settlement/interface';
import { updateBalance } from './BalanceActions';
import { receiveData } from './DataAction';

export const fetchWithdrawal = (data: RequestSettlementBody) =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await requestSettlement(data);
    if (res.success) {
      dispatch(updateBalance());
    }
    return res;
  });

export const getSettlementAction = (data = {}) =>
  asyncRequestHandler<GetSettlementListResponse>(async (dispatch: FunctionDispatch) => {
    const res = await getAllSettlement(data);
    if (res.success) {
      dispatch(receiveData(res, 'settlements'));
    }
    return res;
  });
