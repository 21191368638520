import { Input } from 'components/custom/input';
import { isSuperowner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FC, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { UpdateCompanyProps } from 'services/API/Company/interface';

export interface CompanyFieldProps {
  data: UpdateCompanyProps;
  setData: (e: ChangeEvent<HTMLInputElement>) => void;
  submitFunction: (e: FormEvent<HTMLFormElement>) => void;
  errors: any;
}

export const CompanyField: FC<CompanyFieldProps> = ({
  data,
  setData,
  submitFunction,
  errors,
}: CompanyFieldProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const { translate } = useTranslate();

  const { id, name, address, contact_name, contact_mobile, website, business_description } = data;
  const isReadonly = isSuperowner(user.role);

  return (
    <div className="bg-white p-4 mt-1 round-bottom-lg">
      <div className="">
        <form onSubmit={submitFunction}>
          <Input
            value={id}
            legend={translate('company_id')}
            name="company_id"
            type="text"
            disabled
          />
          <Input
            value={name}
            legend={translate('company_name')}
            name="name"
            type="text"
            onChange={setData}
            isRequired
            error={translate(...(errors.name ?? ''))}
          />
          <Input
            value={address}
            legend={translate('address')}
            name="address"
            type="text"
            onChange={setData}
            isRequired
            error={translate(...(errors.address ?? ''))}
          />
          <Input
            value={contact_name}
            legend={translate('contact_name')}
            name="contact_name"
            type="text"
            onChange={setData}
            isRequired
            error={translate(...(errors.contact_name ?? ''))}
          />
          <Input
            value={contact_mobile}
            legend={translate('company_phone')}
            name="contact_mobile"
            type="text"
            onChange={setData}
            isRequired
            error={translate(...(errors.contact_mobile ?? ''))}
          />
          <Input
            value={website}
            legend={translate('website')}
            name="website"
            type="text"
            onChange={setData}
            error={translate(...(errors.website ?? ''))}
          />
          <Input
            value={business_description}
            legend={translate('business_description')}
            name="business_description"
            type="text"
            onChange={setData}
            isRequired
            error={translate(...(errors.business_description ?? ''))}
          />
          {!isReadonly && (
            <div className="text-end mt-3">
              <button className="btn btn-primary">{translate('submit')}</button>
            </div>
          )}
        </form>
      </div>
      <div className="mb-5 mb-md-0"></div>
    </div>
  );
};
