import { getMe as apiGetMe } from 'actions/AuthActions';
import userIcon from 'assets/img/icons/user_online.png';
import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { userProps } from 'redux/state/AuthState';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { useSwal } from 'helpers/sweetalert';
import { ProfileForm } from './profileForm';

export const Profile = ({ location }: RouteComponentProps) => {
  const { auth } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [data, setData] = useState<userProps | null>(null);

  const getInitialData = async () => {
    const res = await dispatch(apiGetMe());
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('profile'),
        text: res.message,
      });
      return;
    }
    setData(res?.data);
  };

  useEffect(() => {
    getInitialData();
    setData(auth.user);
    // eslint-disable-next-line
  }, []);

  if (!data) {
    return <> </>;
  }

  const { first_name, last_name, email } = data;

  return (
    <>
      <TopHeader title={translate('user')} />
      <SideNavbar location={location} type="user" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader
            src="company-info"
            name="profile_info"
            // caption='Manage your company profiles'
          />
          <div className="bg-white round-bottom-lg">
            <div className="row p-4">
              <div className="col-md-4">
                <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                  <img src={userIcon} alt="" className="img-90px me-2 mb-2" />
                  <div className="box-field-title text-center">
                    <h4>{`${first_name} ${last_name}`}</h4>
                    <p className="grey-text colfax-regular m-0">{email}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <ProfileForm data={data} reloadData={getInitialData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
