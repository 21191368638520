import { Input } from 'components/custom/input';
import { SETTLEMENT_OPTIONS, translateLabel } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { FormEvent, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { DefaultResponse } from 'services/API/common/Fetcher';
import { activateAutoSettlement, deactivateAutoSettlement } from 'services/API/Company';
import { useSwal } from 'helpers/sweetalert';

interface SettlementSettingModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  currentSettlementType: boolean;
  company_id: string;
  reloadUser: () => Promise<void>;
}

export const SettlementSettingModal = ({
  isOpen,
  setIsOpen,
  currentSettlementType,
  company_id,
  reloadUser,
}: SettlementSettingModalProps) => {
  const [isAutoSettlement, setIsAutoSettlement] = useState(false);

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const onChange = (val: boolean) => {
    setIsAutoSettlement(val);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let apiCall: () => Promise<DefaultResponse>;
    if (isAutoSettlement) {
      apiCall = () => activateAutoSettlement(company_id);
    } else {
      apiCall = () => deactivateAutoSettlement(company_id);
    }

    const res = await apiCall();
    const isSuccess = res.success;

    await Swal.fire({
      icon: isSuccess ? 'success' : 'error',
      text: res?.message,
      title: translate('auto_settlement'),
    });
    await reloadUser();
    setIsOpen(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsAutoSettlement(currentSettlementType);
  }, [currentSettlementType]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{translate('settlement_setting')}</ModalHeader>
      <ModalBody>
        <form onSubmit={onSubmit}>
          <Input
            type="react-select"
            legend={translate('auto_settlement').toUpperCase()}
            name="auto_settlement"
            value={isAutoSettlement}
            onChange={onChange}
            options={translateLabel(SETTLEMENT_OPTIONS, translate, true)}
          />
          <div className="d-grid">
            <button className="mt-3 btn btn-primary">{translate('submit')}</button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
