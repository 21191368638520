import logo from 'assets/img/registrationForm/yourBusinessIcon.png';
import { FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { DocPreviewModal } from 'components/custom/modals/DocPreviewModal';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';
import { CertificateOfIncorporation } from 'services/API/Auth/interface';
import { uploadFileToCompany } from 'services/API/Company';
import { OCRResultCI, UploadFileToCompanyResponse } from 'services/API/Company/interface';
import { useSwal } from 'helpers/sweetalert';
import { StepProps } from '../interface';
import { validateCi } from '../validator';

const initialState: CertificateOfIncorporation = {
  file_id: '',
  ci_number: '',
  date_of_establishment: '',
  company_name: '',
};

export const CIUpload = forwardRef(({ form, setForm, setIsLoading }: StepProps, ref) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [ci, setCi] = useState<CertificateOfIncorporation>(initialState);
  const [errors, setErrors] = useState<ANY_OBJECT>({});
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
  const [lockInput, setLockInput] = useState(true);
  const [uploadFile, setUploadFile] = useState<File>();
  const [uploadStatus, setUploadStatus] = useState<'' | 'uploading' | 'on_verification' | 'error'>(
    '',
  );

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.name === 'address') {
      setForm({
        ...form,
        address: e.target.value,
      });
      return;
    }

    const certificate_of_incorporation = {
      ...ci,
      [e.target.name]: e.target.value,
    };

    setCi(certificate_of_incorporation);
  };

  const onCISelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    setUploadFile(file);
    setIsUploadFileModalOpen(true);
  };

  const onCiChange = async (file: File) => {
    setIsLoading(true);
    const data = await getOCRResult(file);
    setIsLoading(false);

    if (!data) {
      setUploadFile(undefined);
      setCi({
        ...ci,
        uploaded: false,
      });
      return;
    }

    const {
      id,
      ocr_result: { ci_number, date_of_establishment, company_name },
    } = data;

    setCi({
      ...ci,
      file_id: id ?? '',
      ci_number: ci_number ?? '',
      date_of_establishment: date_of_establishment ?? '',
      company_name: company_name ?? '',
      uploaded: true,
    });
  };

  const getOCRResult = async (file: File) => {
    if (!user.company_id) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'ocr_ci');
    formData.append('shared', 'false');

    setUploadStatus('uploading');

    let res: UploadFileToCompanyResponse | null = null;
    try {
      res = await uploadFileToCompany(user.company_id, formData);

      if (!res.success) {
        throw new Error(res?.message || 'Oops');
      } else if (!(res.data as OCRResultCI).ocr_result.is_valid) {
        throw new Error(translate('ocr_fail_msg'));
      }
      setUploadStatus('on_verification');
    } catch (err: any) {
      setUploadStatus('error');
      await Swal.fire({
        title: translate('ocr_failed'),
        text: err.message,
        icon: 'error',
      });
      return;
    } finally {
      setLockInput(false); // Allow Input even when ocr is failed.
    }

    return res?.data as OCRResultCI;
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const { isValid, errors } = validateCi(ci);
      setErrors(errors);

      if (!isValid) {
        return false;
      }
      setForm({
        ...form,
        certificate_of_incorporation: ci,
      });

      return true;
    },
  }));

  useEffect(() => {
    const ci = form.certificate_of_incorporation;
    setCi(ci);

    if (ci && ci.uploaded) {
      setUploadStatus('on_verification');
      setLockInput(false);
    }
    // eslint-disable-next-line
  }, []);

  const { ci_number, date_of_establishment, company_name } = ci;

  return (
    <>
      <FormHeader title={translate('certificate_of_incorporation')} src={logo} />
      <div className="mt-2"></div>
      <Input
        type="file-progress"
        name=""
        status={uploadStatus}
        onChange={onCISelected}
        legend={translate('upload_ci').toUpperCase()}
        error={translate(...(errors.file_id ?? ''))}
      />
      <Input
        name="ci_number"
        type="text"
        onChange={onChange}
        legend={translate('ci_number').toUpperCase()}
        value={ci_number}
        disabled={lockInput}
        error={translate(...(errors.ci_number ?? ''))}
      />
      <Input
        name="date_of_establishment"
        type="cleave"
        onChange={onChange}
        legend={translate('date_of_establishment').toUpperCase()}
        placeholder="YYYY-MM-DD"
        value={date_of_establishment}
        cleaveOptions={{
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        }}
        disabled={lockInput}
        error={translate(...(errors.date_of_establishment ?? ''))}
      />
      <Input
        name="company_name"
        type="text"
        onChange={onChange}
        legend={translate('company_name').toUpperCase()}
        value={company_name}
        disabled={lockInput}
        error={translate(...(errors.company_name ?? ''))}
      />
      <DocPreviewModal
        isOpen={isUploadFileModalOpen}
        setIsOpen={setIsUploadFileModalOpen}
        file={uploadFile}
        setFile={setUploadFile}
        onConfirm={onCiChange}
      />
    </>
  );
});
