import { FormContainer } from 'components/custom/form';
import { StepIndicator } from 'components/custom/form/StepIndicator/Indicator';
import { ProcessButton } from 'components/custom/form/StepIndicator/ProcessButton';
import { AuthenticationLayout } from 'components/layouts/authentication.layout';
import { MERCHANT_TYPE_INDIVIDUAL, TAXI_MCC } from 'constants/format';
import { APPLY_ONLINE_STORE_GATEWAY } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { Fragment, FormEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GlobalTypes } from 'redux/types';
import * as ApiAuth from 'services/API/Auth';
import { ActivationForm } from 'services/API/Auth/interface';
import * as ApiCompany from 'services/API/Company';
import { CompanyAvailableGateway } from 'services/API/Company/interface';
import { useSwal } from 'helpers/sweetalert';
import { ApplyGateways } from './steps/ApplyGateways';
import { BankAccount } from './steps/BankAccount';
import { BeneficialOwner } from './steps/BeneficialOwner';
import { BRUpload } from './steps/BR-Upload';
import { IRUpload } from './steps/IR-Upload';
import { CIUpload } from './steps/CI-Upload';
import { Declaration } from './steps/Declaration';
import { Documents } from './steps/Document';

const OfflineSteps = [
  BRUpload,
  CIUpload,
  BeneficialOwner,
  BankAccount,
  Documents,
  ApplyGateways,
  Declaration,
];
const OnlineSteps = [BRUpload, CIUpload, BeneficialOwner, BankAccount, Documents, Declaration];
const OfflineSteps_NGO = [
  IRUpload,
  CIUpload,
  BeneficialOwner,
  BankAccount,
  Documents,
  ApplyGateways,
  Declaration,
];
const OnlineSteps_NGO = [IRUpload, CIUpload, BeneficialOwner, BankAccount, Documents, Declaration];

const IndividualStep = [BeneficialOwner, BankAccount, Documents, ApplyGateways, Declaration];

const initialState: ActivationForm = {
  bypass_ocr: false,
  auto_settlement: false,
  address: '',
  business_registration: {
    file_id: '',
    br_number: '',
    branch_number: '',
    expiry_date: '',
    type_of_business: 0,
    uploaded: false,
  },
  ir_registration: {
    file_id: '',
    ir_number: '',
    company_name: '',
    uploaded: false,
  },
  certificate_of_incorporation: {
    file_id: '',
    ci_number: '',
    company_name: '',
    date_of_establishment: '',
    uploaded: false,
  },
  beneficial_owners: [],
  bank_account: {
    name: '',
    holder: '',
    code: '',
    branch: '',
    number: '',
    currency: 'HKD',
  },
  documents: [],
  gateway_codes: [],
};

export const BusinessSetup = () => {
  const history = useHistory();
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const {
    auth: {
      user: {
        company: { id: companyId, mcc, merchant_type, is_ngo },
        stores,
      },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const type = stores[0].type;
  const isOnlineStore = type === 2;
  const isIndividual =
    (merchant_type as string).toUpperCase() === MERCHANT_TYPE_INDIVIDUAL.toUpperCase();
  const isTaxi = `${mcc}` === `${TAXI_MCC}`;
  const isNGO = is_ngo;
  const validateRef = useRef<any>(null);

  const [current, setCurrent] = useState(0);
  const [form, setForm] = useState<ActivationForm>(initialState);
  const [zip, setZip] = useState<Blob | undefined>(undefined);
  const [isBottom, setIsBottom] = useState(false);
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [availableGatewayList, setAvailableGatewayList] = useState<Array<CompanyAvailableGateway>>(
    [],
  );

  const {
    bypass_ocr,
    auto_settlement,
    address,
    business_registration,
    ir_registration,
    beneficial_owners,
    gateway_codes,
    documents,
    // Optional
    certificate_of_incorporation,
    bank_account,
  } = form;

  useEffect(() => {
    const getAvailableGatewayList = async () => {
      const { data } = await ApiCompany.getAvailableGatewayList(companyId);
      setAvailableGatewayList(data);
    };
    getAvailableGatewayList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOnlineStore) {
      setForm({
        ...form,
        gateway_codes: APPLY_ONLINE_STORE_GATEWAY,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSteps = () => {
    if (isOnlineStore) {
      if (isNGO) {
        return OnlineSteps_NGO;
      }
      return OnlineSteps;
    } else if (isIndividual) {
      return IndividualStep;
    }
    if (isNGO) {
      return OfflineSteps_NGO;
    }
    return OfflineSteps;
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = await validateRef?.current?.validate();
    if (!isValid) {
      return;
    }
    if (current < getSteps().length - 1) {
      setCurrent(current + 1);
      return;
    }

    if (!zip) {
      return;
    }

    const gatewayCodes = isOnlineStore ? APPLY_ONLINE_STORE_GATEWAY : gateway_codes;
    const body = Object.assign(
      {
        bypass_ocr,
        auto_settlement,
        address,
        beneficial_owners,
        documents,
        gateway_codes: gatewayCodes,
      },
      !isIndividual && !isNGO && { business_registration },
      isNGO && { ir_registration },
    ) as ActivationForm;

    if (body.business_registration) {
      delete body.business_registration.type_of_business;
    }

    if (certificate_of_incorporation?.file_id !== '') {
      body.certificate_of_incorporation = certificate_of_incorporation;
    }
    if (bank_account?.number !== '') {
      body.bank_account = bank_account;
    }

    setIsLoading(true);
    const res = await ApiAuth.activateAccount(body);

    if (!res.success) {
      setIsLoading(false);
      return Swal.fire({
        icon: 'error',
        title: translate('business_setup'),
        text: res.message || 'Oops',
      });
    }

    const uploadZipRes = await uploadZipFile(zip);
    setIsLoading(false);

    if (!uploadZipRes.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('business_setup'),
        text: uploadZipRes.message || 'Oops',
      });
    }

    await Swal.fire({
      icon: 'success',
      title: translate('business_setup'),
      text: uploadZipRes.message || translate('completed'),
      showCancelButton: false,
    });

    history.replace('/login/redirect');
  };

  const uploadZipFile = async (zip: Blob) => {
    const fd = new FormData();
    fd.append('file', zip);
    return await ApiAuth.activateOwnerWithZipFile(fd);
  };

  const totalStep = getSteps().length;
  let nextBtnText = '';
  if (totalStep - 1 === current && isBottom) {
    nextBtnText = translate('submit');
  } else if (totalStep - 1 === current && !isBottom) {
    nextBtnText = translate('scroll_to_accept_agreement');
  } else {
    nextBtnText = translate('next');
  }

  return (
    <AuthenticationLayout>
      <FormContainer>
        <form onSubmit={submit}>
          <StepIndicator current={current} totalStep={totalStep} />
          {getSteps().map((Step, index) => {
            if (current === index) {
              return (
                <Step
                  setIsBottom={setIsBottom}
                  setZip={setZip}
                  setIsLoading={setIsLoading}
                  key={index}
                  form={form}
                  setForm={setForm}
                  ref={validateRef}
                  mcc={mcc}
                  isTaxi={isTaxi}
                  isOnlineStore={isOnlineStore}
                  documents={files}
                  setDocuments={setFiles}
                  availableGatewayList={availableGatewayList}
                />
              );
            }
            return <Fragment key={index}></Fragment>;
          })}
          <ProcessButton
            current={current}
            setStep={setCurrent}
            totalStep={totalStep}
            submitText={translate('submit')}
            isLoading={isLoading}>
            {!isLoading && (
              <div className="d-grid">
                <button
                  className="btn btn-primary"
                  disabled={totalStep - 1 === current && !isBottom}>
                  {nextBtnText}
                </button>
              </div>
            )}
          </ProcessButton>
        </form>
      </FormContainer>
    </AuthenticationLayout>
  );
};
