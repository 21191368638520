export const SET_CURRENT_LANG = 'SET_CURRENT_LANG';
export const GET_USER = 'GET_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_AUTH = 'SET_AUTH';
export const LOGOUT = 'LOGOUT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_ME = 'UPDATE_ME';
export const CREATE_DEVICE = 'CREATE_DEVICE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const DISABLE_DEVICE = 'DISABLE_DEVICE';
export const SET_COMPANY = 'SET_COMPANY';

export const ON_SUPEROWNER_COMPANY_SELECT = 'ON_SUPEROWNER_COMPANY_SELECT';
export const GET_COMPANY_BALANCE = 'GET_COMPANY_BALANCE';
export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';
export const GET_STORES = 'GET_STORES';

export const GET_ME = 'GET_ME';
export const UPDATE_BALANCE = 'UPDATE_BALANCE';
export const GET_CREDENTIALS = 'GET_CREDENTIALS';

export const GET_STORE_OPTIONS = 'GET_STORE_OPTIONS';

export const RECEIVE_DATA = 'RECEIVE_DATA';
export const RECEIVE_LOGOS = 'RECEIVE_LOGOS';
export const TABLE_CURRENT_PAGE = 'TABLE_CURRENT_PAGE';
export const SET_SELECTED_DOCUMENT = 'SET_SELECTED_DOCUMENT';

export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';

export const GET_ANALYSIS = 'GET_ANALYSIS';
export const SET_FEATURES = 'SET_FEATURES';
