import { faPowerOff, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteTtpDeviceAction,
  disableTtpDeviceAction,
  enableTtpDeviceAction,
  getTtpDeviceListAction,
} from 'actions/TtpDeviceActions';
import { InfoHeader } from 'components/custom/info_header';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { DISABLED, TTP_DEVICE_STATUS_COLOR } from 'constants/format';
import { isOwner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { TtpDeviceProps } from 'services/API/Ttp_Device/interface';
import { useSwal } from 'helpers/sweetalert';

export const TtpDevices = () => {
  const {
    auth: {
      user: { company_id, role },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [ttpDevices, setTtpDevices] = useState<Array<TtpDeviceProps>>([]);
  const [nextPageCursor, setNextPageCursor] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isReadonly = !isOwner(role);

  const deleteTtpDevice = async (TtpDevice: TtpDeviceProps) => {
    const res = await dispatch(deleteTtpDeviceAction(company_id, TtpDevice.id));
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('delete_ttp_device'),
      text: res.message ?? '',
    });
    if (!res.success) {
      return;
    }

    await getData();
  };

  const confirmDeleteTtpDevice = (TtpDevice: TtpDeviceProps) => {
    Swal.fire({
      icon: 'warning',
      title: translate('delete_ttp_device'),
      text: translate('are_you_sure_delete_ttp_device'),
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => deleteTtpDevice(TtpDevice),
    });
  };

  const toggleStatusTtpDevice = async (TtpDevice: TtpDeviceProps) => {
    let title = 'disable_ttp_device';
    let action = disableTtpDeviceAction;
    if (TtpDevice.status.toLowerCase() === DISABLED) {
      title = 'enable_ttp_device';
      action = enableTtpDeviceAction;
    }

    const res = await dispatch(action(company_id, TtpDevice.id));
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate(title),
      text: res.message ?? '',
    });
    if (!res.success) {
      return;
    }

    await getData();
  };

  const confirmToggleStatusTtpDevice = (TtpDevice: TtpDeviceProps) => {
    let title = 'disable_ttp_device';
    let msg = 'are_you_sure_disable_ttp_device';
    if (TtpDevice.status.toLowerCase() === DISABLED) {
      title = 'enable_ttp_device';
      msg = 'are_you_sure_enable_ttp_device';
    }

    Swal.fire({
      icon: 'warning',
      title: translate(title),
      text: translate(msg),
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => toggleStatusTtpDevice(TtpDevice),
    });
  };

  const renderPagination = () => {
    if (nextPageCursor !== '') {
      return (
        <div className="text-center">
          <button
            className="btn btn-secondary"
            onClick={() => getData(nextPageCursor, false)}
            disabled={isLoading}>
            {!isLoading && translate('load_more')}
            {isLoading && <FontAwesomeIcon spin={true} icon={faSpinner} />}
          </button>
        </div>
      );
    }
    return <></>;
  };

  const getData = async (cursor = '', isNewLoading = true) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const res = await dispatch(
      getTtpDeviceListAction(company_id, {
        cursorNext: cursor,
        limit: 50,
        status: ['enabled', 'disabled', 'restricted', 'revoked'],
      }),
    );

    if (!res.success) {
      setIsLoading(false);
      return Swal.fire({
        icon: 'error',
        title: translate('ttp_device'),
        text: res.message ?? 'Oops',
      });
    }

    if (isNewLoading) {
      setTtpDevices(res.data ?? []);
    } else {
      setTtpDevices([...ttpDevices, ...(res.data ?? [])]);
    }
    setNextPageCursor(res.hasNext ? (res.cursorNext as string) : '');
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location as any} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="company-info" name="ttp_device" />
          <div className="pt-3 bg-white">
            <CustomTable
              totalColumn={6}
              renderHeading={() => (
                <tr>
                  <th scope="col" className="border-top-0">
                    #
                  </th>
                  <th scope="col" className="border-top-0">
                    {translate('name')}
                  </th>
                  <th scope="col" className="border-top-0">
                    {translate('status')}
                  </th>
                  {!isReadonly && (
                    <th scope="col" className="border-top-0" style={{ maxWidth: 100 }}>
                      {translate('actions')}
                    </th>
                  )}
                </tr>
              )}
              renderData={() =>
                ttpDevices.map((TtpDevice, index) => (
                  <tr key={TtpDevice.id}>
                    <th scope="row" className="align-middle">
                      {index + 1}
                    </th>
                    <td className="align-middle">{`${TtpDevice.brand} - ${TtpDevice.model}`}</td>
                    <td>
                      <span
                        className={`text-${
                          TTP_DEVICE_STATUS_COLOR[TtpDevice.status.toLowerCase()]
                        } align-middle`}>
                        {translate(TtpDevice.status.toLowerCase())}
                      </span>
                    </td>

                    {!isReadonly && (
                      <td className="align-middle">
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => confirmToggleStatusTtpDevice(TtpDevice)}>
                          <FontAwesomeIcon icon={faPowerOff} />
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => confirmDeleteTtpDevice(TtpDevice)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              }
              renderPagination={renderPagination}></CustomTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default TtpDevices;
