import { logout } from 'actions/AuthActions';
import { useTranslate } from 'context/TranslateContext';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { FunctionDispatch } from 'redux/types';

export interface ProcessButtonProps {
  current: number;
  setStep?: Dispatch<SetStateAction<number>>;
  submitText: string;
  totalStep: number;
  children?: ReactNode;
  isLoading?: boolean;
}

export const ProcessButton = ({
  current,
  setStep,
  submitText,
  totalStep,
  children,
  isLoading,
}: ProcessButtonProps) => {
  const { translate } = useTranslate();

  const history = useHistory();

  const dispatch = useDispatch<FunctionDispatch>();

  const logoutUser = () => {
    dispatch(logout());
    history.push('/login');
  };

  const back = () => {
    current - 1 >= 0 ? setStep && setStep(current - 1) : logoutUser();
  };
  return (
    <div className="row mt-4">
      <div className="col-6 col-lg-4 d-grid">
        <button
          className="btn btn-outline-secondary"
          onClick={back}
          type="button"
          disabled={isLoading === true}>
          {translate('back')}
        </button>
      </div>
      <div className="col-6 col-lg-8 d-grid">
        {children ? (
          children
        ) : (
          <button className="btn btn-primary" disabled={isLoading === true}>
            {isLoading && (
              <>
                <Spinner size="sm" />
                &nbsp;
              </>
            )}
            {current === totalStep - 1 ? submitText : translate('next')}
          </button>
        )}
      </div>
    </div>
  );
};
