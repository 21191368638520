import { Input } from 'components/custom/input';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { LoadOptions } from 'react-select-async-paginate';
import { getBankInfoList } from 'services/API/Bank_Info';

const valueToLabel = (value: any) => {
  if (!value) {
    return { label: '', value: null };
  }
  return {
    label: `${value.branch_code} - ${value.branch_name}`,
    value: value,
  };
};

export const BranchCodeSelector = ({ clearingCode, value, onChange, error, isRequired }: any) => {
  const { translate } = useTranslate();
  const [selectedValue, setSelectedValue] = useState<any>();
  const [shouldReload, setShouldReload] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const searchBranchCode: LoadOptions<any, any> = async (keyword, loadedOptions, additional) => {
    const page = additional?.page || 1;
    const data: any = {
      page,
      clearing_code: clearingCode,
    };
    if (keyword !== '') {
      data.branch_code = `*${keyword}*`;
    }
    const result = await getBankInfoList(data);

    const options: Array<any> = result.data.map(valueToLabel);
    return {
      options,
      hasMore: !!result.meta.pagination?.links.next ?? false,
      additional: {
        page: page + 1,
      },
    };
  };

  const changeSelected = (value: any) => {
    setSelectedValue(value);
    onChange(value);
  };

  useEffect(() => {
    const initValue = async () => {
      let val = value;
      if (value && typeof value === 'string') {
        const bankInfoList = (
          await getBankInfoList({ clearing_code: clearingCode, branch_code: value })
        ).data;
        if (bankInfoList) {
          val = bankInfoList[0];
        } else {
          val = {
            branch_name: '',
            branch_code: value,
          };
        }
      }
      setSelectedValue(val);
      setLoaded(true);
    };
    initValue();
  }, []);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    changeSelected('');
    setShouldReload(true);
    setTimeout(() => setShouldReload(false));
  }, [clearingCode]);

  if (shouldReload) {
    return <></>;
  }

  return (
    <Input
      type="react-select-async"
      value={valueToLabel(selectedValue)}
      loadOptions={searchBranchCode}
      onChange={changeSelected}
      legend={translate('branch_code').toUpperCase()}
      name=""
      isRequired={isRequired}
      error={error}
    />
  );
};
