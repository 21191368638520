import { BankCodeSelector } from 'components/custom/BankCodeSelector/BankCodeSelector';
import { BranchCodeSelector } from 'components/custom/BankCodeSelector/BranchCodeSelector';
import { Input } from 'components/custom/input';
import { ANY_OBJECT, reactSelectBoolean, translateLabel } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { toApiTruthy } from 'helpers/functions/converter';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { updateBankAccount } from 'services/API/Bank_Account';
import { UpdateBankAccountResponse } from 'services/API/Bank_Account/interface';
import { createCompanyBankAccount } from 'services/API/Company';
import {
  BankAccountFormProps,
  BankAccountProps,
  CreateCompanyBankResponse,
} from 'services/API/Company/interface';
import { useSwal } from 'helpers/sweetalert';
import { validate } from './validator';

export interface BankModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  bank: BankAccountProps | undefined;
  setSelectedBank: (bank: BankAccountProps | undefined) => void;
  company_id: string;
  reloadBanks: () => Promise<void>;
}

const initialState = {
  code: '',
  branch: '',
  number: '',
  currency: 'HKD',
  name: '',
  holder: '',
  is_default: true,
};

export const BankModal = ({
  isOpen,
  setIsOpen,
  bank,
  setSelectedBank,
  company_id,
  reloadBanks,
}: BankModalProps) => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [form, setForm] = useState<BankAccountFormProps>(initialState);
  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { code, branch, number, currency, name, holder, is_default } = form;

  const onSelectChange = (name: string) => (val: string | boolean) => {
    if (name === 'code') {
      setForm({
        ...form,
        branch: '',
      });
    }
    setForm({
      ...form,
      [name]: val,
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'holder') {
      e.target.value = e.target.value.toUpperCase();
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setForm(initialState);
    setSelectedBank(undefined);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    const body: BankAccountFormProps = { ...form };
    body.code = typeof body.code === 'string' ? body.code : (body.code as any).clearing_code;
    body.branch = typeof body.branch === 'string' ? body.branch : (body.branch as any).branch_code;
    body.is_default = toApiTruthy(body.is_default);

    const { isValid, errors } = validate(body);
    setErrors(errors);
    if (!isValid) {
      setIsSubmitting(false);
      return;
    }

    let apiCall: () => Promise<UpdateBankAccountResponse | CreateCompanyBankResponse>;

    if (bank) {
      apiCall = () => updateBankAccount(bank.id, body);
    } else {
      apiCall = () => createCompanyBankAccount(company_id, body);
    }

    const res = await apiCall();
    if (!res.success) {
      setIsSubmitting(false);

      await Swal.fire({
        title: translate('bank_account'),
        text: res.message || 'Oops',
        icon: 'error',
      });
      return;
    }

    await reloadBanks();
    setIsSubmitting(false);
    toggle();
  };

  useEffect(() => {
    if (bank) {
      setForm({
        ...bank,
      });
    }
  }, [bank]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {translate(bank ? 'edit' : 'create')} {translate('bank_account')}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={onSubmit}>
          <Input
            legend={translate('holder').toUpperCase()}
            name="holder"
            value={holder}
            onChange={onChange}
            isRequired
            type="text"
            error={translate(...(errors.holder ?? ''))}
          />
          <BankCodeSelector
            isRequired
            value={code}
            onChange={onSelectChange('code')}
            error={translate(...(errors.code ?? ''))}
          />
          {code && (
            <BranchCodeSelector
              isRequired
              clearingCode={(code as any).clearing_code ?? code}
              value={branch}
              onChange={onSelectChange('branch')}
              error={translate(...(errors.branch ?? ''))}
            />
          )}
          <Input
            legend={translate('account_number').toUpperCase()}
            name="number"
            value={number}
            onChange={onChange}
            isRequired
            type="text"
            error={translate(...(errors.number ?? ''))}
          />
          <Input
            legend={translate('currency').toUpperCase()}
            name="currency"
            value={currency}
            onChange={onSelectChange('currency')}
            options={[{ label: 'HKD', value: 'HKD' }]}
            type="react-select"
            isRequired
            error={translate(...(errors.currency ?? ''))}
          />
          <Input
            legend={translate('nickname').toUpperCase()}
            name="name"
            value={name}
            onChange={onChange}
            isRequired
            type="text"
            error={translate(...(errors.name ?? ''))}
          />
          <Input
            type="react-select"
            legend={translate('set_as_default').toUpperCase()}
            name="is_default"
            onChange={onSelectChange('is_default')}
            options={translateLabel(reactSelectBoolean, translate, true)}
            value={is_default!}
            isRequired
            error={translate(...(errors.is_default ?? ''))}
          />

          <div className="d-grid">
            <button className="btn btn-primary mt-3" disabled={isSubmitting}>
              {isSubmitting ? <Spinner size="sm" /> : translate(bank ? 'update' : 'create')}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
