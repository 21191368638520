import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { ThunkResult } from 'redux/types';
import { getAPI } from 'services/API/Aml_Domain';
import { AmlDomainEditProps, AmlDomainProps } from 'services/API/Aml_Domain/interface';
import { AnyResponse } from 'services/API/common/Fetcher';
import { ArrayResponse } from 'services/API/common/Restful';

export const getAmlDomainListAction = (
  companyId: string,
  params?: object,
): ThunkResult<Promise<ArrayResponse<AmlDomainProps>>> =>
  asyncRequestHandler(() => getAPI(companyId).getAll(params));

export const deleteAmlDomainAction = (
  companyId: string,
  amlDomainId: string,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => getAPI(companyId, amlDomainId).delete());

export const updateAmlDomainAction = (
  companyId: string,
  amlDomainId: string,
  amlDomain: AmlDomainEditProps,
): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(() => getAPI(companyId, amlDomainId).update(amlDomain));
