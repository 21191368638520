import RestfulAPI from '../common/Restful';
import { CheckoutDomainEditProps, CheckoutDomainProps } from './interface';

export const getAPI = (
  companyId: string,
  checkoutDomainId?: string,
): RestfulAPI<CheckoutDomainProps, CheckoutDomainEditProps> => {
  if (!checkoutDomainId) {
    return new RestfulAPI<CheckoutDomainProps, CheckoutDomainEditProps>(
      `/companies/${companyId}/checkout-domains`,
    );
  }

  return new RestfulAPI<CheckoutDomainProps, CheckoutDomainEditProps>(
    `/companies/${companyId}/checkout-domains`,
    `/companies/${companyId}/checkout-domains/${checkoutDomainId}`,
  );
};
