import { params } from 'helpers/functions/param';
import axios, { ContentType, defaultRequestHandler, getToken } from '../common/Fetcher';
import { DefaultResponse, params as paramsFunc } from './../common/Fetcher';
import {
  CreateApiKeyProps,
  CreateApiKeyResponse,
  GetAPIListResponse,
  GetCompanySignKeyResponse,
} from './interface';

// https://dev2.yedpay.com/document/admin/showCompanySignKey
export const getCompanySignKey = (company_id: string): Promise<GetCompanySignKeyResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/sign-key`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/generateCompanySignKey
export const createCompanySignKey = (company_id: string): Promise<GetCompanySignKeyResponse> =>
  defaultRequestHandler(
    axios.post(
      `/companies/${company_id}/sign-key`,
      {},
      {
        headers: {
          authorization: getToken(),
        },
      },
    ),
  );

// https://dev2.yedpay.com/document/admin/applicationList
export const getApiList = (company_id: string, data = {}): Promise<GetAPIListResponse> => {
  const params = {
    ...data,
  };

  return defaultRequestHandler(
    axios.get(
      `/companies/${company_id}/applications?include=company,store,domain&${paramsFunc(data)}`,
      {
        headers: {
          authorization: getToken(),
        },
      },
    ),
  );
};

// https://dev2.yedpay.com/document/admin/createApplication
export const createApiKey = (
  company_id: string,
  data: CreateApiKeyProps,
): Promise<CreateApiKeyResponse> =>
  defaultRequestHandler(
    axios.post(`/companies/${company_id}/applications`, params(data), {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/deleteApplication
export const deleteApiKey = (api_id: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.delete(`/applications/${api_id}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
