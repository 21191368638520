import axios, { ContentType, defaultRequestHandler, getToken, params } from '../common/Fetcher';
import { BankAccountFormProps } from '../Company/interface';
import { DeleteBankAccountResponse, UpdateBankAccountResponse } from './interface';

// https://dev2.yedpay.com/document/admin/updateAccount
// @alert This endpoint is considered as @Deprecated
export const updateBankAccount = (
  bank_account_id: string,
  body: BankAccountFormProps,
): Promise<UpdateBankAccountResponse> =>
  defaultRequestHandler(
    axios.put(`/accounts/${bank_account_id}`, params(body), {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/deleteAccount
// @alert This endpoint is considered as @Deprecated
export const deleteBankAccount = (bank_account_id: string): Promise<DeleteBankAccountResponse> =>
  defaultRequestHandler(
    axios.delete(`/accounts/${bank_account_id}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
