import axios, { ContentType, defaultRequestHandler } from '../common/Fetcher';
import { AnyResponse, getToken } from './../common/Fetcher';

export const getFeaturesConfigData = (): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.get('/features/company', {
      headers: {
        authorization: getToken(),
      },
    }),
  );
