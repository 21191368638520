import { FC, ReactNode } from 'react';
import './Container.scss';

interface FormContainerProps {
  children: ReactNode;
}

export const FormContainer: FC<FormContainerProps> = ({ children }: FormContainerProps) => {
  return (
    <div className="wrapper yedpay-form">
      <div className="d-flex justify-content-between flex-column yedpay-form-body p-4">
        {children}
      </div>
    </div>
  );
};
