import { getMe } from 'actions/AuthActions';
import { EXCEPTION_OF_BUSINESS_SETUP } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { useSwal } from 'helpers/sweetalert';
import { BusinessSetup } from '../';

const Element = () => {
  const {
    auth: { isAuthenticated, user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const dispatch = useDispatch<FunctionDispatch>();

  const [loading, setLoading] = useState(true);

  const getUser = async () => {
    const res = await dispatch(getMe());
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('business_setup'),
        text: res.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!user) {
      getUser();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <></>;
  }

  if (!isAuthenticated || !user || EXCEPTION_OF_BUSINESS_SETUP.includes(user?.role)) {
    return <Redirect to="/login" />;
  }

  if (user?.activated) {
    return <Redirect to="/login/redirect" />;
  }

  return <BusinessSetup />;
};

export default Element;
