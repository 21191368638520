import { faPen, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from 'context/TranslateContext';
import { useState } from 'react';
import { Collapse } from 'reactstrap';

interface ActionBoxProps {
  isOpen: boolean;
  // fixedCode?: CompanyQrCode | null;
  onEdit: (selectedStore: any) => void;
  reload: () => void;
  setIsPrintModalOpen: (isPrintOpen: boolean) => void;
  selectedStore: any | null;
}

interface RowProps {
  name: string;
  data: string;
}

export const ActionBox = ({
  isOpen,
  selectedStore,
  onEdit,
  reload,
  setIsPrintModalOpen,
}: ActionBoxProps) => {
  const { translate } = useTranslate();

  const [isLoading, setIsLoading] = useState(false);

  const openPrintModal = () => {
    setIsPrintModalOpen(true);
  };

  const Row = ({ name, data }: RowProps) => {
    return (
      <div className="row">
        <div className="col-6">{name}</div>
        <div className="col-6 colfax-regular">{data}</div>
      </div>
    );
  };

  const attr: any = {};
  attr.name = selectedStore?.value?.name;
  attr.store_name = selectedStore?.value?.name;

  return (
    <>
      <div className="bg-white mb-4 round-sm">
        <div className="filter-title ">
          <span className="colfax-bold">{translate('actions')}</span>
        </div>
        <div className="py-1">
          <Collapse isOpen={isOpen}>
            <div className="px-3 py-3">
              <Row name={translate('name')} data={attr.name ?? ''} />
              <Row name={translate('store_name')} data={attr.store_name ?? ''} />
            </div>

            <div className="border-bottom" />
            <div className="px-3 py-3">
              <div className="row g-0">
                <div className="col text-center">
                  <button
                    className="btn btn-warning"
                    onClick={() => onEdit(selectedStore)}
                    disabled={isLoading}>
                    <FontAwesomeIcon icon={faPen} />
                    &nbsp;
                    {translate('edit')}
                  </button>
                </div>
                <div className="col text-center">
                  <button className="btn btn-primary" onClick={openPrintModal}>
                    <FontAwesomeIcon icon={faPrint} />
                    &nbsp;
                    {translate('download_printable')}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};
