import { FormContainer, FormHeader } from 'components/custom/form';
import { ProcessButton } from 'components/custom/form/StepIndicator/ProcessButton';
import { Input } from 'components/custom/input';
import { AuthenticationLayout } from 'components/layouts/authentication.layout';
import { ANY_OBJECT, RESET_PASSWORD_MEDIA_OPTIONS, translateLabel } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useState } from 'react';
import { requestResetPassword } from 'services/API/Auth';
import { RequestResetPasswordProps } from 'services/API/Auth/interface';
import { useSwal } from 'helpers/sweetalert';
import { validate } from './validator';

const initialState: RequestResetPasswordProps = {
  email: '',
  country_code: '+852',
  phone: '',
};

export const ForgotPassword = () => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [form, setForm] = useState(initialState);
  const [type, setType] = useState('email');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ANY_OBJECT>({});

  const { email, country_code, phone } = form;

  const correctCountryCode = () => {
    const newCountryCode = country_code.replace(/ /g, '');
    if (!country_code.includes('+') && country_code !== '') {
      setForm({
        ...form,
        country_code: `+${newCountryCode}`,
      });
      return;
    }
    setForm({
      ...form,
      country_code: newCountryCode,
    });
  };

  const onTypeChange = (value: string) => {
    setType(value);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    const { isValid, error } = validate(form, type);
    setError(error);

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    const body = {} as RequestResetPasswordProps;

    if (type === 'email') {
      body.email = email;
    }

    if (type === 'phone') {
      body.country_code = country_code;
      body.phone = phone;
    }

    const res = await requestResetPassword(body);

    Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('reset_password'),
      text: res.success
        ? translate(
            type === 'email'
              ? 'we_send_verification_code_to_your_email'
              : 'we_send_verification_code_to_your_phone',
          )
        : res.message || 'Oops',
    });

    setIsLoading(false);
  };

  return (
    <AuthenticationLayout>
      <FormContainer>
        <FormHeader noImage title={translate('forgot_password')} />
        <form onSubmit={onSubmit}>
          <Input
            type="react-select"
            onChange={onTypeChange}
            value={type}
            name="type"
            legend={translate('validation_method').toUpperCase()}
            options={translateLabel(RESET_PASSWORD_MEDIA_OPTIONS, translate)}
          />

          {type === 'email' && (
            <Input
              type="text"
              legend={translate('email').toUpperCase()}
              onChange={onChange}
              name="email"
              value={email}
              disabled={isLoading}
              error={translate(error.email)}
              placeholder={translate('enter_your_email_address')}
            />
          )}
          {type === 'phone' && (
            <div className="row g-0">
              <div className="col-lg-4">
                <Input
                  type="text"
                  legend={translate('country_code').toUpperCase()}
                  onChange={onChange}
                  name="country_code"
                  value={country_code}
                  onBlur={correctCountryCode}
                  disabled={isLoading}
                  error={translate(error.country_code)}
                />
              </div>
              <div className="col-lg-8">
                <Input
                  type="text"
                  legend={translate('sms').toUpperCase()}
                  onChange={onChange}
                  name="phone"
                  value={phone}
                  disabled={isLoading}
                  error={translate(error.phone)}
                  placeholder={translate('enter_phone')}
                />
              </div>
            </div>
          )}
          <ProcessButton
            current={0}
            totalStep={1}
            isLoading={isLoading}
            submitText={translate('submit')}
          />
        </form>
      </FormContainer>
    </AuthenticationLayout>
  );
};
