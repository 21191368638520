import { FormContainer, FormHeader } from 'components/custom/form';
import { ProcessButton } from 'components/custom/form/StepIndicator/ProcessButton';
import { Input } from 'components/custom/input';
import { AuthenticationLayout } from 'components/layouts/authentication.layout';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { forceUpdatePassword } from 'services/API/Auth';
import { useSwal } from 'helpers/sweetalert';
import '../../layouts/button/button.scss';
import { validate } from './validator';

const initialState = {
  password: '',
  new_password: '',
  new_password_confirmation: '',
};

interface ForceResetPasswordProps {
  match: {
    params: {
      token: string;
      userID: string;
    };
  };
}

export const ForceResetPassword = ({ match: { params } }: ForceResetPasswordProps) => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const history = useHistory();

  const [form, setForm] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState<ANY_OBJECT>({});

  const { password, new_password, new_password_confirmation } = form;

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { isValid, error } = validate(form);
    setError(error);
    if (!isValid) {
      return;
    }
    setIsLoading(true);
    const res = await forceUpdatePassword(params.userID, params.token, form);
    setIsLoading(false);

    if (res.success) {
      await Swal.fire({
        icon: 'success',
        title: translate('reset_password'),
        text: translate('congratulations_password_was_success_reset'),
      });
      history.replace('/login');
    } else {
      Swal.fire({
        icon: 'error',
        title: translate('reset_password'),
        text: res.message || 'oops',
      });
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  if (!params.token || !params.userID) {
    Swal.fire({
      icon: 'error',
      title: translate('error'),
      text: translate('server_error'),
    });
    history.push('/login');
    return <></>;
  }

  return (
    <AuthenticationLayout>
      <FormContainer>
        <FormHeader noImage title={translate('reset_password')} />
        <form onSubmit={onSubmit}>
          <Input
            type="password"
            legend={translate('password').toUpperCase()}
            name="password"
            value={password}
            onChange={onChange}
            error={error.password}
          />

          <Input
            type="password"
            legend={translate('new_password').toUpperCase()}
            name="new_password"
            value={new_password}
            onChange={onChange}
            error={error.new_password}
          />

          <Input
            type="password"
            legend={translate('confirm_new_password').toUpperCase()}
            name="new_password_confirmation"
            value={new_password_confirmation}
            onChange={onChange}
            error={error.new_password_confirmation}
          />
          <ProcessButton
            current={0}
            totalStep={1}
            isLoading={isLoading}
            submitText={translate('submit')}
          />
        </form>
      </FormContainer>
    </AuthenticationLayout>
  );
};
